var d3 = require("d3"),
    c = require("infra/utils/common"),
    add_tooltip = require('../tooltip/tooltip.js');

TopBar.HEIGHT = 9;
TopBar.DIVIDER = 2;
TopBar.TEXT_MARGIN = 10;
TopBar.TRANSITION_DURATION = 1000;
TopBar.DEFAULT_CLASS = 'trending-default';
TopBar.DEFAULT_COLOR = '#DDDEE1';
TopBar.DEFAULT_VIEW_TYPE = 'normal';
TopBar.DEFAULT_PRECISION = 1;
TopBar.DEFAULT_FORCED_PRECISION = 2;
TopBar.MINIMUM_BAR_WIDTH = 0.5;
TopBar.DIVIDER_OFFSET = 65;

TopBar.isSplitted = function(type) {
  return type != TopBar.DEFAULT_VIEW_TYPE;
};


function TopBar(g, x, width, type, configuration) {

  this.configuration = configuration || {};
  this.labelSuffix = this.configuration.labelSuffix || '';
  this.g = g;
  this.element = d3.select(this.g);
  this.x = x;
  this.barWidth = width;
  var self = this;

  this.leftContainer = this.element.append("g").attr("class", "left-container");
  this.leftBar = this.leftContainer.append("rect")
    .attr("class", function(d) {
      return "bar left " + (c.isString(d.class) ? d.class : TopBar.DEFAULT_CLASS);
    })
    .attr("fill", TopBar.DEFAULT_COLOR)
    .attr("x", this.x(0))
    .attr("y", 0)
    .attr("width", 0)
    .attr("height", TopBar.HEIGHT);

  this.leftLabel = this.leftContainer.append("text")
    .attr("class", "text left")
    .attr("x", this.x(0) - TopBar.TEXT_MARGIN)
    .attr("y", TopBar.HEIGHT / 2);

  this.rightContainer = this.element.append("g")
      .attr("class", "right-container")
      .on("mouseover", function (d) {
        if (type === "normal") {
          // tooltip is only supported for 1-sided view for now.
          if (_.isUndefined(self.rightContainerTooltipApi)) {
            let thisElement = this;
            self.rightContainerTooltipApi = new Promise((resolve) => {
              add_tooltip(thisElement, 'info', {
                style: {classes: 'common-tooltip-info'},
                overwrite: false,
                show: {
                  event: event.type,
                  ready: true,
                  delay: 0
                },
                hide: {
                  event: 'mouseleave click',
                  effect: false,
                  delay: 100
                },
                content: {text: "TEMP"},
                events: {
                  render: (event, api) => {
                    api.disable();
                    resolve(api);
                  }
                }
              });
            });
            self.rightContainerTooltipApi.then((api) => {
              let title = getRightTitle(d);
              if (title) {
                api.set('content.text', title);
                api.enable();
              } else {
                api.disable();
              }
            });
          }
        }
      });
  this.rightBar = this.rightContainer.append("rect")
    .attr("class", function(d) {
      return "bar right" + " " + (c.isString(d.class) ? d.class : TopBar.DEFAULT_CLASS);
    })
    .attr("fill", TopBar.DEFAULT_COLOR)
    .attr("x", this.x(0) + TopBar.DIVIDER)
    .attr("y", 0)
    .attr("width", 0)
    .attr("height", TopBar.HEIGHT);

  this.rightLabel = this.rightContainer.append("text")
    .attr("class", "text right")
    .attr("x", this.x(0) + TopBar.DIVIDER + TopBar.TEXT_MARGIN)
    .attr("y", TopBar.HEIGHT / 2);

}

TopBar.prototype.setData = function(data, type, measure) {
  this.data = data;
  var self = this;
  this.startPosition = 0;
  if (TopBar.DEFAULT_VIEW_TYPE != type) {
    this.startPosition = this.barWidth - TopBar.DIVIDER_OFFSET;
    this.x = d3.scale.linear().range([0, this.startPosition]);
    this.x.domain([0, 100]);
  }

  if (TopBar.isSplitted(type)) {
    this.leftBar.transition().duration(TopBar.TRANSITION_DURATION)
      .attr("x", getLeftX(this.data, this.x, this.startPosition, type, measure))
      .attr("width", getLeftBarWidth(this.data, this.x, type, measure));
    this.leftLabel.transition().duration(TopBar.TRANSITION_DURATION)
      .text(this.formatLabel(getLeftLabel(this.data, type, measure),
        this.configuration.precision || TopBar.DEFAULT_PRECISION, TopBar.DEFAULT_FORCED_PRECISION) + self.labelSuffix + (isPercent(measure) ? '%' : ''))
      .attr("x", getLeftLabelX(this.data, this.x, this.startPosition, type, measure));
    this.divider = this.element.append("path")
      .classed("split-bar-divider", true)
      .attr("d", "M0,0V" + TopBar.HEIGHT)
      .attr("transform", "translate(" + (this.startPosition + this.x(0)) + ",0)");
    this.divider.transition().duration(TopBar.TRANSITION_DURATION)
      .attr("transform", "translate(" + (this.startPosition + this.x(0)) + ",0)");
  }

  var margin = this.startPosition + TopBar.DIVIDER;

  this.rightBar.transition().duration(TopBar.TRANSITION_DURATION)
    .attr("x", getRightX(this.data, this.x, margin, type, measure))
    .attr("width", getRightBarWidth(this.data, this.x, type, measure));

  this.rightLabel.transition().duration(TopBar.TRANSITION_DURATION)
    .text(this.formatLabel(getRightLabel(this.data, type, measure),
      this.configuration.precision ||  TopBar.DEFAULT_PRECISION, TopBar.DEFAULT_FORCED_PRECISION) + self.labelSuffix + (isPercent(measure) ? '%' : ''))
    .attr("x", getRightLabelX(this.data, this.x, margin, type, measure))
    .attr("class", "text right" + (isPercent(measure) ? " percent" : ""));
};

TopBar.prototype.destroy = function() {
  if (this.rightContainerTooltipApi) this.rightContainerTooltipApi.then((api) => api.destroy(true));
}

TopBar.prototype.formatLabel = function(value, precision = TopBar.DEFAULT_PRECISION, forcedPrecision = TopBar.DEFAULT_FORCED_PRECISION) {
  var formatted = 0;
  if (value === null){
    value = 0;
  }
  if (value != formatted) {
    if (c.isNumber(precision) && -1 < precision < 10) {
      if (c.isNumber(value)) {
        try {
          if (c.isNumber(forcedPrecision) && 1 < forcedPrecision < 10) {
            var limit = 1 / (10 * (forcedPrecision - 1));
            if (value < limit) {
              return parseFloat(d3.format('.' + (precision+1) + 'f')(value));
            }
          }
          formatted = parseFloat(d3.format('.' + precision + 'f')(value));
        } catch (e){
          console.log('Failed to format the value of ' + value, e);
        }
      } else if (value != null && _.isObject(value)) {
        if (c.isString(value)) {
          if (value.length > 0 && !isNaN(value)) try {
            var parsed = parseInt(value);
            if (!isNaN(parsed)) return this.formatLabel(parsed, precision, forcedPrecision);
          } catch (e){ console.log('Failed to format the value of ' + value, e); }
        } else if (c.is(value)) {
          return this.formatLabel(value.value, precision, forcedPrecision);
        }
      }
    } else if (c.isNumber(value)) {
      return this.formatLabel(value, 1, forcedPrecision);
    }
  }
  return formatted;
}

function right(type, measure) {
  var key = type == 'normal' ? 'value' : 'right';
  switch (measure) {
    case 'absolute':
      return 'abs_' + key;
    case 'relative':
    case 'sov':
      return key;
    default:
      return measure;
  }
};

function getRightBarWidth(data, x, type, measure) {
  var field = data.hasOwnProperty(measure) ? data[measure] : data[right(type, measure)];
  var barWidth = x(field) - x(0);
  return barWidth == 0 ? TopBar.MINIMUM_BAR_WIDTH : barWidth;
}

function getRightX(data, x, margin, type, measure) {
  return x(0) + margin;
}

function isPercent(measure) {
  return measure == 'distribution';
}

function getRightLabel(data, type, measure) {
  if(data.hasOwnProperty(measure)){
    return c.getNumber(data[measure]);
  }
  var v = right(type, measure);
  var key = measure == 'absolute' ? v : v + '_original';
  return c.getNumber(data[key], data[v]);
}

function getRightLabelX(data, x, margin, type, measure) {
  return margin + getRightBarWidth(data, x, type, measure) + TopBar.TEXT_MARGIN;
}

function getRightTitle(data) {
  return data['title'];
}

function left(measure) {
  return measure == 'absolute' ? 'abs_left' : 'left';
}

function getLeftBarWidth(data, x, type, measure) {
  var barWidth = x(0) - x(-data[left(measure)]);
  return barWidth == 0 ? TopBar.MINIMUM_BAR_WIDTH : barWidth;
}

function getLeftX(data, x, margin, type, measure) {
  var value = data.hasOwnProperty(measure) ? data[measure] : data[left(measure)];
  var leftValue = value == 0 ? (TopBar.MINIMUM_BAR_WIDTH / 2) : value;
  return x(-leftValue) + margin;
};

function getLeftLabel(data, type, measure) {
  if(data.hasOwnProperty(measure)){
    return c.getNumber(data[measure])
  }
  var v = left(measure);
  var key = measure == 'absolute' ? v : v + '_original';
  return c.getNumber(data[key], data[v]);
}

function getLeftLabelX(data, x, margin, type, measure) {
  return getLeftX(data, x, margin, type, measure) - TopBar.TEXT_MARGIN;
}

module.exports = TopBar;
