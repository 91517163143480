import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getLinkedinSegmentDemographicsDistribution: (audience, userId) => ({
    url: `${HOST}/linkedin/get_audience_distribution`,
    /* eslint-disable-next-line camelcase */
    payload: { audience, user_id: userId }
  }),

  createAudienceTargetTaxonomy: (segmentId, channel, marketId) => ({
    url: `${HOST}/audience_target/create_taxonomy/${channel}/${segmentId}/${marketId}`
  }),

  createAudienceTargetUserList: (segmentId, channel, ids) => ({
    url: `${HOST}/audience_target/create_user_list/${channel}/${segmentId}`,
    payload: { ids }
  }),

  getAudienceTaxonomyCategory: (segmentId) => ({
    url: `${HOST}/audience_target/taxonomy_category/${segmentId}`
  })
};
