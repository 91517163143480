require('./mixpanel-base');

module.exports = angular.module(__filename, []).service('mixpanelDiscovery', ['$state', '$window', 'context', 'mixpanelService',
    function ($state, $window, context, mixpanelService) {
        var appName = 'Discovery';
        var widgetName = mixpanelService.extractWidget($state.current);
        var snapshot = context.current;
        var lastSearch = angular.copy(discovery());
        var lastTerms = lastSearch.terms;

        function extractTimeframe() {
            var timeframe;
            timeframe = mixpanelService.buildTimeframe(snapshot.discovery_timeframe);
            return timeframe;
        }

        function discovery() {

            var result = {};
            var allTerms = mixpanelService.buildTerms(snapshot.terms);
            result.widget = mixpanelService.extractWidget($state.current);
            result.terms = allTerms.terms.concat(allTerms.bl_terms);
            result.refinedTerms = allTerms.bl_terms.length > 0;
            result.timeframe = extractTimeframe();
            result.topics = _.map(snapshot.topics, 'name');
            result.geo = _.map(snapshot.geo, 'label');
            result.insightsChannels = snapshot.insightsChannels.label;
            result.audience = _.map(snapshot.audience, 'summary');
            result.language = snapshot.language? snapshot.language.value : "en";
            angular.extend(result, mixpanelService.buildProgram(context));
            switch (widgetName) {
                case 'streams':
                    result.articleType = _.map(snapshot.articleType, 'label');
                    result.streamsChannels = _.map(snapshot.channels, 'label');
                    break;
                case 'bubbles':
                    result.bubblesChannels = snapshot.bubblesChannels.label;
                    result.keyTrends = snapshot.keyTrends;
                    break;
                default:
                    result.gridChannels = snapshot.bubblesChannels.label;
            }
            return result;
        }

        function trackPageView(widget) {
            if (widget == 'grid') {
                widget = mixpanelService.extractWidget($state.current);
            }
            mixpanelService.trackPageView(appName, widget);
            widgetName = widget;
        }

        function trackExport() {
            mixpanelService.trackExport(appName, widgetName);
        }

        function trackSort(column) {
           var props = {
                widgetName: widgetName,
                appName: appName,
                'column title': column.title 
           }
           
           mixpanelService.trackSort(props);
        }

        function trackDiscoverySearch() {
            var props = angular.copy(discovery());
            if (angular.equals(props, lastSearch)) return;
            lastSearch = angular.copy(props);
            props.fullSearch = angular.copy(discovery());
            mixpanelService.sendToMixpanel('Discovery - Search', props);
            if (angular.equals(props.terms, lastTerms)) return;
            mixpanelService.trackSearch(appName, widgetName, props.terms, props.refinedTerms);
            lastTerms = props.terms;
        }

        function trackBubbleClicked(bubble) {
            var props = {
                'selectedBubble': bubble,
                'search': discovery()
            };
            mixpanelService.sendToMixpanel('Discovery - Bubbles - Bubble Clicked', props);
        }

        function trackStream(streamUrl) {
            var props = {
                'selectedStream': streamUrl,
                'search': discovery()
            };
            mixpanelService.sendToMixpanel('Discovery - Streams - Stream Clicked', props);
        }

        function trackContentDriveClick(row) {
             var props = {
                widgetName: widgetName,
                appName: appName,
                'Phrase': row.phrase 
             }

            mixpanelService.sendToMixpanel('Discovery - Phrase table - Content Driver button', props);
        }

        function trackAudience(val) {
            if (_.isEmpty(val)) return;
             var props = {
                widgetName: widgetName,
                appName: appName,
                'Audience name': val[0].label
             }

            mixpanelService.sendToMixpanel('Discovery - Channel Insights - Audience Skew', props);
        }

        return {
            trackPageView: trackPageView,
            trackExport: trackExport,
            trackDiscoverySearch: trackDiscoverySearch,
            trackContentDriveClick: trackContentDriveClick,
            trackAudience: trackAudience,
            trackSort: trackSort,
            trackBubbleClicked: trackBubbleClicked,
            trackStream: trackStream
        }
    }
]);
