import 'angular-modal-service';

var dashboardModule = angular.module(__filename, [
    'angularModalService',
    require("widgets/brand-index/brand-index-widget").name,
    require("widgets/geo-widget/geo-widget").name,
    require("widgets/brand-perceptions-widget/brand-perceptions-widget").name,
    require("widgets/audience-widget/audience-widget").name,
    require("widgets/news-widget/news-widget").name,
    require("widgets/news-ticker/news-ticker").name,
    require("widgets/rt-associations-widget/rt-associations-widget").name,
    require('pages/programs/program_settings/program-settings').name,
    require("infra/mixpanel/mixpanel-dashboard").name
]);

function buildDashboardTerms(TermsMold, context) {
    if (!context.program.brand.name) return Promise.resolve([]);
    var mold = new TermsMold(context.current);
    return mold.replace([context.program.brand.name].concat(context.program.competitors.map(function (c) {
        return c.name;
    }).sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }))).then(function () {
        return mold._value;
    });
}

buildDashboardTerms.$inject = ["TermsMold", "context"];
function DashBoardController($scope, topicsTree, TermsMold, context, dashboard_terms, mixpanelDashboard, ModalService, programService) {
    $scope.$root.helpPage = '2_The_Dashboard';
    $scope.timeframe = 30;
    $scope.topics = topicsTree.topics;
    $scope.dashboard_terms = dashboard_terms;
    $scope.$watch("context.program", function () {
        buildDashboardTerms(TermsMold, context).then(function (value) {
            $scope.dashboard_terms = value;
            openProgramIfEmpty();
            mixpanelDashboard.trackDashboardSearch();
        });
    });


    var closeListener = context.onChange(mixpanelDashboard.trackDashboardSearch);
    $scope.$on('$destroy', closeListener);
    mixpanelDashboard.trackPageView();

    function openProgramIfEmpty() {
        if ($scope.dashboard_terms.length == 0) {
            programService.program(context.program.id).then(function (program) {
                ModalService.showModal({
                    template: require('pages/programs/program_settings/program-settings.html'),
                    inputs: {program: program, initialView: 'dashboard interests'},
                    controller: 'programSettingsController'
                });
            });
        }
    }
}

DashBoardController.$inject = ["$scope", "topicsTree", "TermsMold", "context", "dashboard_terms", "mixpanelDashboard", "ModalService", "programService"];

dashboardModule.stateConfig = {
    name: "dashboard",
    url: "/dashboard",
    template: require("./dashboard.html"),
    display: "Dashboard",
    controller: DashBoardController,
    resolve: {
        dashboard_terms: buildDashboardTerms
    },
    data: {
      permissions: ['dashboard']
    },
    context: {}
};

export default dashboardModule;