require('./mixpanel-base');

module.exports = angular.module(__filename, []).service('mixpanelDashboard', ['$state', '$window', 'context', 'mixpanelService',
    function ($state, $window, context, mixpanelService) {
        var appName = 'Dashboard';
        var widgetName = 'Dashboard';
        var lastSearch = angular.copy(dashboard());
        var lastTerms = [];
        lastTerms = [context.program.brand.name].concat(_.map(context.program.competitors, 'name'));

        function dashboard() {
            var result = {};
            result.widget = widgetName;
            if (context.program.brand && context.program.brand.name) {
                result.terms = [context.program.brand.name.toLowerCase()];
                result.terms = result.terms.concat(_.map(context.program.competitors, 'name'));
            }
            else {
                result.terms = 'no interests'
            }
            result.geo = _.map(context.current.geo, 'label');
            angular.extend(result, mixpanelService.buildProgram(context));
            return result;
        }

        function trackPageView() {
            mixpanelService.trackPageView(appName, widgetName);
        }

        function trackDashboardSearch() {
            var props = angular.copy(dashboard());
            if (angular.equals(props, lastSearch)) return;
            lastSearch = angular.copy(props);
            mixpanelService.sendToMixpanel('Dashboard - Search', props);
            if (angular.equals(props.terms, lastTerms)) return;
            //false since boolean_logics are not available in Dashboard
            mixpanelService.trackSearch(appName, widgetName, props.terms, false);
            lastTerms = props.terms;
        }

        return {
            trackPageView: trackPageView,
            trackDashboardSearch: trackDashboardSearch
        }
    }
]);
