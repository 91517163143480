import React from 'react';
import PropTypes from 'prop-types';
import './SegmentsImageToggleButtonSelector.scss';

import ImageToggleButtonSelector from '../../../../common/ImageToggleButtonSelector/ImageToggleButtonSelector';

const getImageDimensionsByNumberOfSegments = (numSegments) => {
  if (numSegments === 5) return { imageWidth: '192px', imageHeight: '119px' };
  if (numSegments === 6) return { imageWidth: '160px', imageHeight: '99px' };
  return { imageWidth: '242px', imageHeight: '150px' };
};

const SegmentsImageToggleButtonSelector = ({ segmentsData, selectedSegment, onToggle }) => {
  const { imageWidth, imageHeight } = getImageDimensionsByNumberOfSegments(segmentsData.length);

  return (
    <div className="segments-image-toggle-button-selector-component">
      <ImageToggleButtonSelector
        togglesData={ segmentsData }
        selectedToggle={ selectedSegment }
        onToggle={ onToggle }
        iconId="plus-in-circle"
        imageWidth={ imageWidth }
        imageHeight={ imageHeight }
        isDownTriangleVisible={ false }>
      </ImageToggleButtonSelector>
    </div>
  );
};

SegmentsImageToggleButtonSelector.propTypes = {
  segmentsData: PropTypes.array.isRequired,
  selectedSegment: PropTypes.string,
  onToggle: PropTypes.func.isRequired
};

export default SegmentsImageToggleButtonSelector;