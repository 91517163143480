"use strict";
var uuidv1 = require('uuid/v1'),
    config = require('infra/config');

module.exports = angular.module(__filename, [
    ]).service('userDetails', ['$http', function ($http) {
    
    return {

        getIP: function(onNewIP){
            
            //using webRTC
            var pc = new window.RTCPeerConnection({
                iceServers: []
            }),
            noop = function() {},
            localIPs = {},
            ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
            key;

            function iterateIP(ip) {
                if (!localIPs[ip]) onNewIP(ip);
                localIPs[ip] = true;
            }

             //create a bogus data channel
            pc.createDataChannel("");

            // create offer and set local description
            pc.createOffer().then(function(sdp) {
                sdp.sdp.split('\n').forEach(function(line) {
                    if (line.indexOf('candidate') < 0) return;
                    line.match(ipRegex).forEach(iterateIP);
                });
                
                pc.setLocalDescription(sdp, noop, noop);
            }).catch(function(reason) {
                // An error occurred, so handle the failure to connect
            });

            //listen for candidate events
            pc.onicecandidate = function(ice) {
                if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
                ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
                pc.close();
            };
        },


        send: function(user){
            if(!user || !user.id){
                return;
            }

            this.getIP((ip)=>{
                let uuid = localStorage.uuid || uuidv1();
                localStorage.uuid = uuid;
                $http.post(config.USER_MGMT_API+'/ledger/user?disable_notifications=1', {
                    i: ip,
                    uuid: uuid
                }).success(()=> {
                    
                }).error(()=> {
                    console.log('error in ledger')
                })
            });
        }
    }

}]);


