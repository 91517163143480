import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './GaugeBar.scss';
import Icon from '../Icon/Icon';

const getLastHighlightedTick = (percentage, numOfTicks) => Math.ceil((percentage || 0) / 100.0 * numOfTicks) - 1;

const GaugeBar = ({ lowLabel, highLabel, percentage, numOfTicks }) => {
  const highlightedTicks = getLastHighlightedTick(percentage, numOfTicks);

  return (
    <div className="gauge-bar-component">
      <div className="gauge-label low">{ lowLabel }</div>
      <div className="tick-bars">
        { [...Array(numOfTicks)].map((_, i) =>
          <div key={ i } className={ classNames('tick', { highlighted: i <= highlightedTicks }) }>
            { (i === highlightedTicks) && <Icon iconId="triangle-up" className="tick-pointer-icon" /> }
          </div>
        ) }
      </div>
      <div className="gauge-label high">{ highLabel }</div>
    </div>);
};

GaugeBar.propTypes = {
  lowLabel: PropTypes.string,
  highLabel: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  numOfTicks: PropTypes.number
};

GaugeBar.defaultProps = {
  numOfTicks: 15
};

export default GaugeBar;