import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import Icon from '../../Icon/Icon';
import getDataView from './AmobeeTableUtils';

import './AmobeeTable.scss';
const ROW_HEIGHT = 30;

class AmobeeTable extends React.Component {

  constructor(props) {
    super(props);
    this.tbodyRef = React.createRef();
    this.searchInputRef = React.createRef();
     
    this.state = {

      //infinite scrolling
      tbodyScroll: 0,
      tbodyHeight: 800,
  
      //sort & search
      sortByColumn:  props.config.sortByColumn,
      isSortAsc: props.config.isSortAsc,
      isSearchOpened: false
    };
  }

  componentDidMount() {

    //on resize
    this.resizeObserver = new ResizeObserver((items) => {

      const tbody = items[0].target;
      this.setState({
        tbodyHeight: Math.max(tbody.offsetHeight, ROW_HEIGHT * 2),
        isScroll: tbody.scrollHeight > tbody.clientHeight
      });

    });

    this.resizeObserver.observe(this.tbodyRef.current);

    this.focusSearch();
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  onScroll() {
    this.setState({ tbodyScroll : this.tbodyRef.current.scrollTop });
  }

  onWheelScroll(event) {
    //wheel scroll breaks infinite scroll
    //so we update scroll position manually
      
    event.preventDefault();

    let top = this.state.tbodyScroll;
    const dir = event.deltaY > 0 ? 1 : -1;
    top += dir * ROW_HEIGHT;
    top = Math.max(top, 0);

    //dont over-scroll
    
    const maxRows   = this.props.data.length;
    const rows = Math.round(this.tbodyRef.current.offsetHeight / ROW_HEIGHT);
    const maxScrollRows = maxRows - rows;
    const padding = 0.1;
    top = Math.min(top, (padding + maxScrollRows) * ROW_HEIGHT);

    try {
      this.tbodyRef.current.scrollTo(0, top);
    } catch (er) {}
    this.setState({ tbodyScroll : top });
  }

  sort(clickedColumn) {
    //if you click  column 3 times,
    //table reverts to default sorted column

    const getNextSortState = (currentSortedColumn, isDefaultSortAsc, clickedColumn) => {
      if (currentSortedColumn !== clickedColumn) {
        return {
          sortByColumn: clickedColumn, 
          isSortAsc: this.props.config.isSortAsc
        };
      }
     
      if (!isDefaultSortAsc) {
        return {
          sortByColumn: this.props.config.sortByColumn, 
          isSortAsc: this.props.config.isSortAsc
        };
      }
     
      return {
        sortByColumn: clickedColumn, 
        isSortAsc: !this.props.config.isSortAsc
      };
    };

    this.setState(getNextSortState(
      this.state.sortByColumn, 
      this.state.isSortAsc == this.props.config.isSortAsc, 
      clickedColumn),
    () => {
      this.focusSearch();
    });
  }

  toggleSearch(event) {
    if (this.state.isSearchOpened) {
      this.setState({ isSearchOpened: false, searchString: '' }); 
    } else {  
      this.setState({ isSearchOpened: true, searchString: '' }, () => 
        this.focusSearch()
      ); 
    
    }

    event.stopPropagation();
  }

  search() {
    
    //save height, as it may change by search
    const tbodyHeight = this.state.tbodyHeight;

    this.setState({
      searchString: this.searchInputRef.current.value, 
      tbodyHeight: tbodyHeight });

  }

  focusSearch() {
    if (this.state.isSearchOpened) {
      this.searchInputRef.current.focus(); 
    }
  }

  render() {

    const columns = this.props.config.columns;
    const dataView = getDataView({
      data: this.props.data,
      columns: columns,
      sortByColumn: this.state.sortByColumn,
      isSortAsc: this.state.isSortAsc,
      searchString: this.state.searchString
    });

    const columnHeader = (column, i) => {
      
      if (i > 0 ) {

        return column.headerValue ? 
          column.headerValue() : 
          column.header;
  
      }
      
      //1st column = search   
      return <span>
        
        <span onClick={ (event) => this.toggleSearch(event) }><Icon iconId="search" width="16px" height="16px"
          className="search pointer"/></span>
 
        {
          this.state.isSearchOpened ? 
          
            <span>
              <input ref={ this.searchInputRef } className="search-input"
                onKeyUp={ this.search.bind(this) } 
                onClick={ (event) => event.stopPropagation() }/> 
            
              <span className="close-search" onClick={ (event) => this.toggleSearch(event) }><Icon iconId="x-thick" width="9px" height="9px" className="search-close pointer"/></span>
            </span> : 

            column.header
        }

        <span> ({ dataView.length })</span>
        
      </span>;  
    };

    //head
    const thead = columns.map((column, i) => 
 
      <div className={ classNames('column', column.className, { 
        'selected': this.state.sortByColumn == column.value,
        'non-sortable' : column.isSortable == false }) }

      style={ { 'flex': column.flexValue || 1 } }
      key={ i } 
        
      onClick={ column.isSortable != false ? 
        this.sort.bind(this, column.value) : null }>
                
        { columnHeader(column, i) }
          
        {
          this.state.sortByColumn === column.value && column.isSortable != false ? 
            this.state.isSortAsc ? 
                
              <Icon iconId="triangle-up" className="sort" width="10px" height="10px"/> :
              <Icon iconId="triangle-down" className="sort" width="11px" height="11px"/> :
            null
        }
      </div>
    );

    //body

    const getRowCells = (row) => columns.map((column, i) => {

      const value = row[column.value];

      return <div 
        className={ classNames('column', column.className) } 
        style={ { 'flex': column.flexValue || 1 } }
        key={ i } title={ value }>
        {
          column.displayValue ? 
            column.displayValue(row) : 
            value
        } 
      </div>;
    });
    
    /*infinite scrolling
    ====================
    1. first we calculate tbodyHeight & tbodyScrollTop
       on resize / scroll events

    2. then we calculate rowsShown to show only rows in view area

    3. finally we add margin to 1st/last rows
       to add hidden rows to tbody height
    */

    const rowsInPage = Math.ceil(this.state.tbodyHeight / ROW_HEIGHT);
    
    const startRow = Math.ceil(this.state.tbodyScroll / ROW_HEIGHT);
    
    const rowsShown = dataView.slice(startRow, startRow + rowsInPage);

    const getRowMargins = (row, i) => {
      
      if (i == 0) {
        return { marginTop: this.state.tbodyScroll };
      }
        
      if (i == rowsShown.length - 1) {
          
        const rowsLength = dataView.length;
        const tbodyScroll = this.state.tbodyScroll;

        return { marginBottom: 
            (rowsLength - rowsShown.length) * ROW_HEIGHT - tbodyScroll };
      }

      return {};
    };

    const tbody = rowsShown.map((row, i) => <div key={ i } className="row" style={ getRowMargins(row, i) }>{ getRowCells(row) }</div>); 
 
    return (
      <div className={ classNames('amobee-table-component', { 
        'large-header': this.props.config.isLargeHeader }) }>
        
        <div className="row thead">{ thead }</div>
        
        <div ref={ this.tbodyRef }
          className={ classNames('tbody', { 'is-scroll': this.state.isScroll }) }
          onScroll={ this.onScroll.bind(this) }
          onWheel={ this.onWheelScroll.bind(this) }>{ tbody }</div>
      
      </div>
    );
  }

}

AmobeeTable.propTypes = {
  data:      PropTypes.array.isRequired,

  config: PropTypes.shape({
    
    columns: PropTypes.arrayOf(PropTypes.shape({
      
      value:         PropTypes.string.isRequired,
      header:        PropTypes.string.isRequired,
      displayValue:  PropTypes.any,
      headerValue:   PropTypes.any,
      className:     PropTypes.string,
      isSortable:    PropTypes.bool,
      isSearchable:  PropTypes.bool
    
    })).isRequired,

    sortByColumn:      PropTypes.string,
    isSortAsc:         PropTypes.bool,
    isLargeHeader:     PropTypes.bool,
    onDataViewChanged: PropTypes.func

  }).isRequired

};

export default AmobeeTable;
