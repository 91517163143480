import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getGenres: () => ({
    url: `${HOST}/tv/genres`,
  }),
  getCommercials: (channel) => ({
    url: `${HOST}/tv/commercials/${channel}`,
  })
};
