var ex = require("infra/utils/export");

function ChannelsController($scope, insightsExportService) {
    insightsExportService.setTitle('Channels');

    $scope.showConsumptionChannelWidget = function () {
        return $scope.context.current.insightsChannels.value === 'articles';
    };
}

ChannelsController.$inject = ['$scope', 'insightsExportService'];

module.exports.stateConfig = {
    name: "channels",
    url: "/channels",
    template: require("./channels.insights.html"),
    display: "Insights",
    data: {
      permissions: ['channels']
    },
    controller: ChannelsController,
    context: {}
};
