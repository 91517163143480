var consumptionIndex = require("../../widgets/consumption-index/consumption-index-widget"),
    topicTrends = require("../../widgets/topic-trends/topic-trends-widget"),
    sentiments = require("../../widgets/sentiment-index/sentiment-widget"),
    topAssociationIndex = require("../../widgets/top-association/top-association-widget"),
    timingWidget = require("../../widgets/timing-widget/timing-widget"),
    landscapeComponent = require("../../widgets/landscape-widget/landscape-widget"),
    context = require('infra/context/context.js'),
    keywordSuggesterModule = require('data/keyword_suggester.srv.js'),
    barChart = require('common/bar-chart.drv/bar-chart.drv.js'),
    channelsMold = require("infra/context/filters/insightsChannels-mold"),
    mixpanel = require("../../infra/mixpanel/mixpanel-insights"),
    examplesDataModel = require("../../data/insights/examples-data-model"),
    c = require("infra/utils/common"),
    $ = require("jquery");

var insightsModule = angular.module(__filename, [
    consumptionIndex.name,
    topicTrends.name,
    sentiments.name,
    topAssociationIndex.name,
    timingWidget.name,
    keywordSuggesterModule.name,
    context.name,
    barChart.name,
    channelsMold.name,
    mixpanel.name,
    examplesDataModel.name,
    landscapeComponent.name,
    require("data/insights/insights-export-service").name,
    require("../../widgets/referrals-widget/referrals-widget").name,
    require("../../widgets/consumption-channel/consumption-channel-widget").name,
    require("../../widgets/insights-geo-widget/insights-geo-widget").name
]);

var helpPages = {
    consumption: '4_3_Consumption_Trends',
    topic: '4_4_Topics',
    sentiment: '4_5_Sentiment',
    association: '4_6_Associations',
    channels: '',
    geo: ''
};

ConsumptionController.$inject = ['$scope', 'context', '$state', 'geoService',  'mixpanelInsights', 'abiPermissions',
                                 '$timeout', 'examplesDataModel', 'notificator', 'insightsExportService', '$rootScope',
                                 'baseInsightsService', 'TIMES'];
function ConsumptionController($scope, context, $state, geoService, mixpanelInsights, abiPermissions, $timeout,
                               examplesDataModel, notificator, insightsExportService, $rootScope, baseInsightsService,
                               TIMES) {

    var self = this;
    var INSUFFICIENT_DATA_MESSAGE = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";
    $scope.abiPermissions = abiPermissions;
    $scope.tabs = [];

    $scope.predefinedTabs = [
      {name: 'consumption', display: 'Consumption Trends', permission: 'consumption trends', channels: ['web channel','video channel','social channel','sg telco channel','au telco channel']},
      {name: 'association', display: 'Associations', permission: 'associations', channels: ['web channel','social channel','sg telco channel','au telco channel']},
      {name: 'sentiment', display: 'Sentiment', permission: 'sentiment', channels: ['web channel','video channel','social channel','sg telco channel','au telco channel']},
      {name: 'timing', display: 'Timing', permission: 'timing', channels: ['web channel','social channel','sg telco channel']},
      {name: 'landscape', display: 'Landscape', permission: 'landscape', channels: ['web channel','social channel','sg telco channel']},
      {name: 'topic', display: 'Topics', permission: 'topics', channels: ['web channel','video channel','social channel','sg telco channel','au telco channel']},
      {name: 'channels', display: 'Channels', permission: 'channels', channels: ['web channel','sg telco channel','au telco channel']},
      {name: 'geo', display: 'Geos', displayForChannels: ['articles', 'sg_telco', 'au_telco'],
       permission: 'geos', rootScopeField: 'geoInsightsChannelsFilter',channels: ['geos.US','sg telco channel','au telco channel']}
    ];

    $scope.sgWebSourceFilter = $rootScope.sgWebSourceFilter;

    $scope.hasPermissions = function(channels){
        var result = false;
        _.forEach(channels,function(value){
            result = $scope.abiPermissions.hasPermission(value);
            if(result)
                return false;
        });
        return result;
    };

    $scope.addTabsByPermission = function(){
        _.forEach($scope.predefinedTabs, function(tabData){
            if(tabData.name === 'landscape' || $scope.hasPermissions(tabData.channels)){
                $scope.tabs.push(tabData);
              } else {
                $scope.abiPermissions.removePermission(tabData.permission);
            }
        })
    };

    $scope.addTabsByPermission();

    $scope.context = context;
    $scope.examplesData = examplesDataModel;
    $scope.letterClicked = '';
    insightsExportService.clear();
    $rootScope.helpPage = helpPages[$state.current.name.split('.')[1]];
    $rootScope.$broadcast('timeframe-vars-update', null, TIMES.INSIGHTS_OFFSET, false);

    $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context), $state.current.name);

    $scope.supportedLanguages = function(){
        return context.current._language_mold.supportedLanguages($state, context);
    };

    $scope.isSupportLanguage = function () {
        return context.current._language_mold.isSupportLanguage($state, context);
    };

    $scope.shouldShowTopics = function () {
        return context.current._language_mold.shouldShowTopics($state, context);
    };

    $scope.isResolveKeywords = function(){
        return context.current._language_mold.isResolveKeywords($state, context);
    };

    $scope.tabsClicked = function (tab) {
        insightsExportService.clear();
        $scope.insightsError = false;

        context.current._language_mold.getLanguage({current:{name: "insights."+tab}}, context);
        
        setTimeout(()=>{
            $rootScope.helpPage = helpPages[tab];
            $state.go('^.' + tab);
            $scope.setBlankScreen(tab);
        },99);
    };
    var closeListener = context.onChange(mixpanelInsights.trackInsightsSearch);
    const landscapeChannelListener = $scope.$on('landscapeChannelChanged', function(event, landscapeChannel) {
        $scope.insightsChannelLandscape = landscapeChannel;
    });

    $scope.$on('$destroy', () => {
      closeListener();
      landscapeChannelListener();
    });

    $scope.shouldDisplayTab = (channels,rootScopeField) => {
      if(!channels) return true;
      return _.includes(channels, context.current.insightsChannels.value)
            && _.includes(_.map($rootScope[rootScopeField], 'value'), context.current.insightsChannels.value) ;
    };

    $scope.setBlankScreen = function(tab) {
      if (tab === undefined) {
        tab = $state.current.name.split('.')[1];
      }
      switch(tab) {
        case 'landscape':
          $scope.blankScreenMsg = "Type in few brands and define the landscape KPIs to see how they benchmark against each other.";
          $scope.blankScreenImage = "zero_state_insights_landscape.svg";
          break;
        default:
          $scope.blankScreenMsg = "Type in an interest to analyze its consumption drivers.";
          $scope.blankScreenImage = "zero_state_insights.svg";
      }
    };

    $scope.setBlankScreen();

    $scope.areSocialTermsDisabled = function() {
        if ($state.includes('*.landscape')) {
            return ($scope.insightsChannelLandscape || {}).value != 'tweets';
        } else {
            return context.current.insightsChannels.value != 'tweets' || $state.includes('*.channels');
        }
    };

    $scope.getChannelsFilter = function() {
        if (($scope.user || {}).id === 1029) return [{label: 'Video', value: 'videos'}]; //TODO: temporary

        let channels;
        if ($state.includes('*.association')) {
          channels = $rootScope.insightsAssociationsChannelsFilter;
        } else if ($state.includes('*.geo')) {
          channels = $rootScope.geoInsightsChannelsFilter;
        } else if ($state.includes('*.timing')) {
          channels = $rootScope.insightsTimingChannelsFilter;
        } else if ($state.includes('*.channels')) {
          channels = $rootScope.insightsReferralsChannelsFilter;
        } else {
          channels = $rootScope.insightsChannelsFilter;
        }

        return channels;
    };

    $scope.arePostsDisabled = function () {
        return $state.includes('*.association');
    };

    $scope.timeframeHasDatesMoreThenMonth = function () {
        return c.hasDatesOlderThanOneMonth(context.current.insights_timeframe);
    };

    $scope.showBlankScreen = function (insufficient) {
        $scope.blankScreenMsg = '';
        return $scope.insightsError;
    };

    $scope.showSubGeos = function () {
        const stateSupportSubGeos = $state.includes('*.consumption') || $state.includes('*.topic') ||
                                    $state.includes('*.association') || $state.includes('*.geo') ||
                                    $state.includes('*.channels') || $state.includes('*.timing') ||
                                    $state.includes('*.landscape');
        if (stateSupportSubGeos) {
            var channels = c.getChannels($state, context);
            return geoService.showSubGeos(channels, $state.current.name) &&
                   !(_.includes(channels, 'sg_telco') && !abiPermissions.hasPermission('SG Telco Electoral Filter'));
        }

        return false;
    };

    $scope.insightsGeos = function () {
        if($state.includes('*.landscape') && abiPermissions.hasPermission('sg telco channel') &&
           !abiPermissions.hasPermission('web channel') && !abiPermissions.hasPermission('social channel')){
            return [geoService.STATES.Singapore];
        }
        return geoService.geosForChannel(geoService.geos, $state, context);
    };

    $scope.$watch('context.current.insightsChannels', function () {
        $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context), $state.current.name);
    });

    // not sure works..
    $scope.$watch('context.current.geoInsightsChannels', function () {
        $rootScope.Geos.setSubGeos($scope, () => context.current._insightsSubGeos_mold, c.getChannels($state, context));
    });


    $scope.insufficientNotification = function (seeds) {
        notificator.notify({body: INSUFFICIENT_DATA_MESSAGE.replace("<seed>", seeds.join(", "))});
    };

    $scope.$on('contentDriversPaneToggled', function (event, filter) {
        $timeout(function () {
            _.each($scope.examplesData.visible_examples, function (example) {
                if (self.compareDataWithFilter(example, filter)) {
                    example.hide = !filter.show;
                }
            });
        }, 0);
    });

    $scope.$on('chartCircleMouseover', function (event, letter) {
        self.switchContentDriverAttrsByLetter(letter, "highlight", true);
    });

    $scope.$on('chartCircleMouseout', function (event, letter) {
        if ($scope.letterClicked != letter) {
            self.switchContentDriverAttrsByLetter(letter, "highlight", false);
        }
    });

    $scope.$on('chartCircleClick', function (event, data) {
        self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
        $scope.letterClicked = data.letter;
        $scope.$broadcast('resetHighlightedCirclesExceptClicked');
        self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], true);
        $scope.examplesData.open = true;

        _.each($scope.examplesData.filters, function (filter) {
            if (self.compareDataWithFilter(filter, data) || $scope.examplesData.filter_type == 'sentiment') {
                filter.show = true;
            }
        });
        _.each($scope.examplesData.visible_examples, function (example) {
            if (self.compareDataWithFilter(example, data) || $scope.examplesData.filter_type == 'sentiment') {
                example.hide = false;
            }
        });

        $scope.$root.$broadcast('openContentDrivers', "content_drivers");

        $timeout(function () {
            var contentWindow = $(".content");
            var currentArticle = $(".content-driver[letter=" + data.letter + "]").first();
            contentWindow.animate({scrollTop: contentWindow.scrollTop() + (currentArticle.position().top - contentWindow.position().top)}, '30');
        }, 10);
    });

    $scope.onWidgetPanelClick = function () {
        if ($scope.examplesData.visible) {
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
            $scope.letterClicked = '';
            $scope.$broadcast('widgetPanelClick');
        }
    };

    $scope.onContentDriverMouseover = function (letter, index) {
        if ($scope.examplesData.alphabetized) {
            self.switchContentDriverAttrsByLetter(letter, "highlight", true);
            $scope.$broadcast('contentDriverMouseover', letter);
        } else {
            self.switchContentDriverAttrsByIndex(index, "selected", true);
        }
    };

    $scope.onContentDriverMouseout = function (letter, index) {
        if ($scope.examplesData.alphabetized) {
            if ($scope.letterClicked != letter) {
                self.switchContentDriverAttrsByLetter(letter, "highlight", false);
                $scope.$broadcast('contentDriverMouseout', letter);
            }
        } else {
            self.switchContentDriverAttrsByIndex(index, "selected", false);
        }
    };

    $scope.onContentDriverClick = function (letter) {
        if ($scope.examplesData.alphabetized) {
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], false);
            $scope.letterClicked = letter;
            $scope.$broadcast('resetHighlightedCirclesExceptClicked');
            self.switchContentDriverAttrsByLetter($scope.letterClicked, ["highlight", "selected"], true);
            $scope.$broadcast('contentDriverClick', letter);
        }
    };

    $scope.showAudienceFilter = function () {
        return (baseInsightsService.shouldSendAudience(context.current.insightsChannels.value, abiPermissions) &&
               ($state.includes('*.consumption') || $state.includes('*.association') || $state.includes('*.topic') ||
                $state.includes('*.channels')))
                && $scope.isResolveKeywords();
    };

    $scope.hideChannels = function() {
        return $state.includes('*.landscape');
    };

    $scope.showGeoFilter = function() {
        return (($scope.user || {}).id !== 1029);
    };

    $scope.showSgWebSourceFilter = function() {
        return $state.includes('*.landscape') &&
               (_.some($scope.context.current.geo, {cc: "SG"}) ||
                (_.isEmpty($scope.context.current.geo) && _.some($scope.insightsGeos(), {cc: "SG"}))) &&
               abiPermissions.hasPermission('web channel') && abiPermissions.hasPermission('sg telco channel');
    };

    $scope.getVisibleExamples = function() {
        return _.uniqBy($scope.examplesData.visible_examples, (e) => e.url.toLowerCase() + ($scope.examplesData.alphabetized ? e.letter : ""));
    };

    $scope.$on('contentDriversPaneOpenByClick', function (event) {
        $scope.$broadcast('contentDriversPaneOpen');
    });

    $scope.$on('resetLetterClicked', function (event) {
        $scope.letterClicked = '';
    });

    $scope.$on('insightsError', function (event, error) {
        $scope.insightsError = true;
        $scope.showBlankScreen(error);
        insightsExportService.clear();
    });

    $scope.$on('insightsInsufficientData', function (event, data) {
        if (data && !_.isEmpty(data)) {
            $scope.insufficientNotification(data);
        }
    });

    function handleInsightsChange(event, newVals, old) {
        var terms = [];
        angular.copy(context.current.terms, terms);
        terms = _.filter(terms, function (t) {
            return !$scope.arePostsDisabled() || t.type != 'post';
        });
        if (terms.length == 0) {
            $scope.setBlankScreen();
            $scope.insightsError = true;
            insightsExportService.clear();
        } else {
            $scope.insightsError = false;
        }
    }

    var listenerTimeframe = $scope.$root.$on('context-updated', handleInsightsChange);
    var refreshViewListener = $rootScope.$on('refresh-view', function (event) {
        context.cacheBaster = Date.now();
    });

    $scope.$on('$destroy', listenerTimeframe);
    $scope.$on('$destroy', refreshViewListener);



    this.switchContentDriverAttrsByLetter = function (letter, attrs, value) {
        $timeout(function () {
            if (!c.isArray(attrs)) attrs = [attrs];
            _.each($scope.examplesData.visible_examples, function (example) {
                if (example.letter == letter) {
                    _.each(attrs, function (attr) {
                        example[attr] = value;
                    });
                }
            });
        }, 0);
    };

    this.switchContentDriverAttrsByIndex = function (index, attrs, value) {
        $timeout(function () {
            if (!c.isArray(attrs)) attrs = [attrs];
            _.each(attrs, function (attr) {
                $scope.examplesData.visible_examples[index][attr] = value;
            });
        }, 0);
    };

    $scope.$state = $state;

    this.compareDataWithFilter = function (data, filter) {
        var shouldFilter = false;

        if ($scope.examplesData.filter_type == 'sentiment') {
            shouldFilter = (data.sentiment == filter.sentiment);
        } else if ($scope.examplesData.filter_type == 'trend') {
            shouldFilter = (data.class == filter.class);
        }

        return shouldFilter;
    };

    $scope.$watch("context.current._insightsSubGeos_mold._value", (newValue, oldValue) => {
      if(!_.isEqual(newValue, oldValue)) {
        context.current.insightsAllSubGeosSelected = $scope.dataTrees.subGeos.isAllChecked();
      }
    });

}

var association = require('./association.insights').stateConfig;
var consumption = require('./consumption.insights').stateConfig;
var sentiment = require('./sentiment.insights').stateConfig;
var topic = require('./topic.insights').stateConfig;
var channels = require('./channels.insights').stateConfig;
var timing = require('./timing.insights').stateConfig;
var landscape = require('./landscape.insights').stateConfig;
var geo = require('./geo.insights').stateConfig;

insightsModule.stateConfig = {
    name: "insights",
    url: "/insights",
    template: require("./insights.html"),
    display: "Insights",
    context: {
        insights_timeframe: "TimeframeMold"
    },
    data: {
      permissions: ["insights"]
    },
    controller: ConsumptionController,
    redirectTo: ['$state', 'abiPermissions', 'toState', 'toParams', function($state, abiPermissions, toState, toParams) {
      var getPermissions = _.property("data.permissions");
      var firstAuthorizedState = function(states) {
        return _.find(states, function(stateName) {
          var state = $state.get(stateName);
          var permissions = getPermissions(state);
          return _.isUndefined(permissions) || abiPermissions.hasPermission(permissions);
        });
      };

      var tabs = _.map(toState.children, function(s) {return s.name; });
      return { state: firstAuthorizedState(tabs) };
    }],
    children: [
        consumption,
        topic,
        sentiment,
        association,
        channels,
        timing,
        landscape,
        geo
    ]
};

module.exports = insightsModule;
