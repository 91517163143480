/*
input range 
-----------

mandatory: value, step, min, max, titletext, titlemin, titlemax
*/

var DOT_MARGIN = 30, DEBOUNCE=2000, timer=null;

module.exports = angular.module(__filename, []).directive("dottedSlider", [function() {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope:{
            'value':    '=', 
            'min':      '@',
            'max':      '@',
            'step':     '@',
            'titletext':'@',
            'titlemin': '@',
            'titlemax': '@'},
        
        template: require('./dotted-slider.html'),
        
        link: function(scope, elem, attrs, ngModel){

            scope.values = [];
          
            var i, min = 1*attrs.min, max = 1*attrs.max, step = 1*attrs.step,
                    input = $(elem).find("input[type=range]");

            for(i=min;i<=max;i+=step){
                scope.values.push(i);
            }

            $(".range_back").css("width",(DOT_MARGIN*scope.values.length-5)+"px");

            scope.dotPos = function(index){
                return (index*DOT_MARGIN)+'px';
            }

            scope.select = function(val){
                elem.find("input[type=range]").val(val);
                clearTimeout(timer);
                setTimeout(function(){
                    scope.value=val;
                    scope.$apply();
                },DEBOUNCE);
            }
        }
    }
}]);

