const tmpl = require("./examples-popup.html");

const DEFAULT_SETTINGS = {
    events: {
        visible: function (event, api) {
            api.elements.content.find('.desc').dotdotdot();
        }
    },
    show: {ready: true, solo: false, event: 'click'},
    style: {classes: 'example-popup popup-window', tip: {width: 20, height: 10}}
};


module.exports = angular.module(__filename, [
    require('../popup/popup-service').name,
    require('infra/mixpanel/mixpanel-base').name
])
    .service("examplesPopupService", ['popupService', 'abiPermissions', 'mixpanelService', function (popupService, abiPermissions, mixpanelService) {
        return {
            activate: function (elem, data, bindings) {
                if (!angular.isString(data.exampleId) || data.exampleId.length < 1) {
                    data.exampleId = (new Date()).getTime();
                    data.type = 'default';
                }
                function lifestyleTooltip(audience) {
                    if (audience == 1){
                      return 'as ';
                    }
                    if (audience == 0.00) {
                      return 'un';
                    }
                    if (audience > 1) {
                      return '×' + audience + ' times more ';
                    }
                    if (audience < 1 ) {
                      return '×' + (1 / audience).toFixed(2) + ' times less ';
                    }
                }

                function reportUrl(url){
                    mixpanelService.trackUrlClick(url);
                }

                let extendedData = {};
                const display_buttons = !_.isEmpty(bindings);
                const is_trend = data.hasOwnProperty('node_data') && data.node_data.class == 'term-trending';
                angular.extend(extendedData, data, {
                    node_data: data.node_data,
                    abs_consumption: Math.abs(data.consumption) > 1000 ? 'Above 1000' : Math.abs(data.consumption),
                    abs_audience: (data.audience ? Math.abs(data.audience) : (0)).toFixed(2),
                    display_buttons: display_buttons,
                    tooltipString: lifestyleTooltip(Math.abs(data.audience).toFixed(2)),
                    is_trend: is_trend,
                    allowed_boolean: abiPermissions.hasPermission(['seed refinement']),
                    reportUrl: reportUrl,
                    isAudienceDefined: _.isNumber(data.audience)
                });
                popupService.pop(elem, extendedData, DEFAULT_SETTINGS, tmpl, bindings);
            }
        }
    }]
);
