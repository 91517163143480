import React from 'react';
import Widget from '../../../../common/Widget/Widget';
import PropTypes from 'prop-types';
import AudiencePreview from '../AudiencePreview/AudiencePreview';
import { createPromiseEvaluatedToComponentProps } from './AudiencePreviewWidgetUtils';
import { noop } from 'lodash';

const AudiencePreviewWidget = (props) => (  
  <Widget 
    name="AudiencePreviewWidget"
    dataService={ createPromiseEvaluatedToComponentProps } 
    dataServiceParams={ [props.audienceSegment, props.channel, props.userId, props.audienceDistributionService, props.isRequestCancelledService, props.trackTooNarrow] }
    onRender={ (res) => (<AudiencePreview onExploreAudience={ props.onExploreAudience } { ...res }></AudiencePreview>) }
    style={ { height: '87px' } } 
    isSmallSpinner={ true }
    onDataLoaded={ (data) => props.onAudienceDataLoaded(data.hasData) }
    emptyStateRender={ () => (<AudiencePreview onExploreAudience={ props.onExploreAudience } ></AudiencePreview>) } />
);

AudiencePreviewWidget.propTypes = {
  audienceDistributionService: PropTypes.func.isRequired,
  isRequestCancelledService: PropTypes.func.isRequired,
  audienceSegment: PropTypes.any.isRequired,
  channel: PropTypes.any.isRequired,
  userId:  PropTypes.number.isRequired,
  trackTooNarrow: PropTypes.func.isRequired,
  onExploreAudience: PropTypes.func.isRequired,
  onAudienceDataLoaded: PropTypes.func
};

AudiencePreviewWidget.defaultProps = {
  onAudienceDataLoaded: noop
};

export default AudiencePreviewWidget;