var mixPanel = require('./mixpanel-base');

module.exports = angular.module(__filename, [
    mixPanel.name
]).service('mixpanelAudience', ['$state', '$window', 'context', 'mixpanelService', function ($state, $window, context, mixpanelService) {

    var appName = 'Audience';
    var widgetName;
    var snapshot = context.current;
    var lastSearch = angular.copy(audience());

    function audience() {
        var result = {segment: {}};
        var demographics = [];
        var interests = [];
        var websites = [];
        var lifestyle = [];
        _.each(snapshot.audience_segment, function (seg) {
            switch (seg.type) {
                case "demographics":
                    demographics.push(_.map(seg.age, 'label'));
                    demographics.push(_.map(seg.ethnicity, 'label'));
                    demographics.push(_.map(seg.geo, 'label'));
                    demographics.push(_.map(seg.income, 'label'));
                    demographics.push(_.map(seg.gender, 'lable'));
                    demographics.push(_.map(seg.operand, 'label'));
                    break;
                case "interests":
                    interests.push(_.map(seg.required, 'text'));
                    interests.push(_.map(seg.excluded, 'text'));
                    interests.push(_.map(seg.included, 'text'));
                    interests.push(_.map(seg.levelOfIntent, 'text'));
                    interests.push(_.map(seg.operand, 'label'));
                    break;
                case "websites":
                    websites.push(_.map(seg.excluded, 'text'));
                    websites.push(_.map(seg.included, 'text'));
                    websites.push(_.map(seg.required, 'text'));
                    websites.push(_.map(seg.operand, 'label'));
                    break;
                case "lifestyle":
                    lifestyle.push(seg.label);
                    lifestyle.push(_.map(seg.operand, 'label'));
                    break;
                default:
                    return result;
            }
            result.segment.demographics = demographics;
            result.segment.interests = interests;
            result.segment.websites = websites;
            result.segment.lifestyle = lifestyle;
        });
        return result;
    }

    function trackPageView(widget) {
        widgetName = widget;
        mixpanelService.trackPageView(appName, widgetName);
    }

    function trackExport() {
        mixpanelService.trackExport(appName, widgetName);
    }

    function trackSort(column) {
       var props = {
            widgetName: widgetName,
            appName: appName,
            'column title': column.title 
       }
       
       mixpanelService.trackSort(props);
    }

    //builder

    function trackSegment(segment) {
        var props = {'Segment Type': segment};
        mixpanelService.sendToMixpanel('Audience-Builder - Segment Type Clicked', props);
    }

    function trackTooNarrow() {
        var props = audience();
        mixpanelService.sendToMixpanel('Audience-Builder - Too Narrow Segment Parameters', props);
    }

    function trackExploreAudience() {
        var props = audience();
        mixpanelService.sendToMixpanel('Audience-Builder - Explore Audience Segment Parameters', props);
    }

    function trackAdvancedSegmentation() {
        mixpanelService.sendToMixpanel('Audience-Builder - Advanced Segmentation Clicked');
    }

    //explorer

    function trackRefine() {
        mixpanelService.sendToMixpanel('Audience-Explorer - Refine Audience Clicked');
    }

    function trackTimelineUserExample(user_example) {
        var example_chosen = {'gender': user_example.gender, 'age': user_example.age};
        var props = {'Example Chosen': example_chosen};
        mixpanelService.sendToMixpanel('Audience-Explorer - Timeline User Example', props);
    }

    function trackMatchingMoments() {
        mixpanelService.sendToMixpanel('Audience-Explorer - Timeline Matching Moments Clicked');
    }

    function trackClickEvent(url_clicked) {
        var props = {'URL Clicked': url_clicked};
        mixpanelService.sendToMixpanel('Audience-Explorer - Timeline Click on Event (with URL)', props);
    }

    function trackScrollDate() {
        mixpanelService.sendToMixpanel('Audience-Explorer - Timeline Scroll Date');
    }

    function trackTestingInterests(type, interestsToTestSearchResults, isAdvanced) {
        const eventName = `Audience Explorer - Interests - ` + (isAdvanced ? 'Testing Advanced Interests' : 'Testing Interests');
        const props = audience();
        props["segmentString"] = JSON.stringify(props["segment"]);
        props["interest type"] = type;
        props["interests to test"] = interestsToTestSearchResults.map((r) => r.text);
        props["first interest to test"] = interestsToTestSearchResults[0].text;
        props["num of interests to test"] = interestsToTestSearchResults.length;
        if(isAdvanced) {
            props["raw advanced interests to test"] = interestsToTestSearchResults;
            ['required', 'included', 'excluded'].forEach((o) => {
              
              props[`advanced interests to test: num of ${o}`] = _.sum(interestsToTestSearchResults.map((r) => (r[o] || []).length));
            });
        }
        mixpanelService.sendToMixpanel(eventName, props);
    }

    return {
        trackPageView,
        trackExport,
        trackSort,
        trackSegment,
        trackTooNarrow,
        trackExploreAudience,
        trackAdvancedSegmentation,
        trackRefine,
        trackTimelineUserExample,
        trackMatchingMoments,
        trackClickEvent,
        trackScrollDate,
        trackTestingInterests
    }
}]);
