import Datamap from 'datamaps';
const DEFAULTS = require('./map-component-defaults');
const Zoom = require('./map-zoom');

class MapComponent {
  constructor($scope, $window, $element) {
    this.$scope = $scope;
    this.$window = $window;
    this.$element = $element;
  }

  $onInit() {
    this.resizeFunc = _.debounce(this._setContainerSize.bind(this), 10);
    this.popupContainer = this.$element.find(".popup-container")[0];
    this.keyUpFunc = _.debounce(this.emitZoneClicked.bind(this), 2000);
    this.multiRegionsClicked = false;
    angular.element(this.$window).bind('resize', this.resizeFunc);
    angular.element(this.$window).bind('keyup', this.keyUpFunc);
  }

  $onDestroy() {
    angular.element(this.$window).off('resize', this.resizeFunc);
    angular.element(this.$window).off('keyup', this.keyUpFunc);
  }

  $onChanges(changesObj) {
    if (this.map) {
      if (changesObj.data && changesObj.data.currentValue && !changesObj.data.isFirstChange()) {
        this.updateColors(changesObj.data.currentValue);
      }

      if (changesObj.mapName && !changesObj.mapName.isFirstChange()) {
        this.drawMap();
        this._updateMapPosition();
      }
    } else {
      this.drawMap();
    }
  }

  emitZoneClicked($event) {
    if (!this.data || !this.multiRegionsClicked || $event.keyCode !== 16) return;
    this.multiRegionsClicked = false;
    this.$scope.$emit('zoneClicked', this.selectedRegions);
  }

  updateColors(data) {
    this.map.updateChoropleth(this.buildDataWithColors(data), {reset: true})
  }

  buildDataWithColors(data) {
    const pairs = _.fromPairs(_.map(data, (value, key) => {
      if (!value.classColor) return {};
      const tagElement = angular.element(`.${value.classColor}`)[0];
      let fillColor = DEFAULTS.fills.defaultFill;
      if (!_.isUndefined(tagElement)) {
        const classColor = this.$window.getComputedStyle(tagElement, null).fill
        const elementColor = classColor.replace(/[^\d,]/g, '').split(',');

        const opacity = value.opacity;
        const [r, g, b] = _.map(elementColor, color =>
          opacity > 1
          ? parseInt((2 - opacity) * color)
          : parseInt(((1 - opacity) * 255) + (opacity * color))
        )

        fillColor = `rgb(${r}, ${g}, ${b})`;
      }

      return [
        `id_${key}`, {
          fillColor,
          zoneData: value
        }
      ];
    }));

    return _.pickBy(pairs)
  }

  onZoomUpdate(transform, scale) {
    this.transform = transform;
    this.scale = scale;
  }


  /* Datamaps support custom popup on hover, but we have our own implementation.
     We want to display the popup above the mouse location (Datamaps doesn't support custom location).
     We use the Datamaps popup method to get the zone data.
     We always return undefined so the default popup won't be displayed.
  */
  popupTemplateFunc(geo, data) {
    // Datamaps hides exceptions in popup. in case of error, log it.
    try {
      const [mouseX, mouseY] = d3.mouse(this.mapContainer);
      this.popupContainer.innerHTML = this.popupTemplate(geo, data);
      this.popupContainer.style.left = mouseX - (this.popupContainer.offsetWidth / 2) + "px";
      this.popupContainer.style.top = mouseY - (this.popupContainer.offsetHeight + 20) + "px";

      return undefined;
    } catch (e) {
      console.error("Error when trying to display popup");
      console.error(e);
    }
  }

  resetSelectedMapZones(allMapZones) {
    allMapZones
      .attr("id", null)
      .style("stroke", DEFAULTS.geographyConfig.borderColor)
      .style('stroke-width', DEFAULTS.geographyConfig.borderWidth + 'px');
  }

  drawMap() {
    this.mapContainer = this.$element.find(".datamaps-container")[0];
    if (!this.mapContainer) return;
    this._setContainerSize()
    const width = this.mapContainer.parentElement.offsetWidth;
    const height = this.mapContainer.parentElement.offsetHeight
    this.mapContainer.innerHTML = "";
    this.multiRegionsClicked = false;

    var map = new Datamap({
      element: this.mapContainer,
      geographyConfig: Object.assign({
        dataUrl: `maps/${this.mapName}.topojson`,
        popupTemplate: this.popupTemplateFunc.bind(this), // What to display on zone hover.
        highlightFillColor: (data) => ((data.zoneData && this.data[data.zoneData.key]) ? data.fillColor : '#81878c'),
      }, this.geographyConfig || DEFAULTS.geographyConfig),
      scope: 'collection', //The key in the topojson where the data is.
      fills: DEFAULTS.fills, // defaultFill for all the zones
      setProjection: (element) => {
        this.projection = ((this.customProjection && this.customProjection()) || d3.geo.mercator().center(this.mapOptions.center))
                          .scale(this.mapContainer.offsetWidth * this.mapOptions.scaleFactor)
                          .translate([
                            (this.mapContainer.offsetWidth / 2),
                            (this.mapContainer.offsetHeight / (this.mapOptions.heightOffset || 2))
                          ])
        const path = d3.geo.path().projection(this.projection);
        return {path, projection: this.projection};
      },
      done: (datamap) => {
        const self = this;
        const allMapZones = datamap.svg.selectAll('.datamaps-subunit');

        const appendSelectedAtEnd = () => {
          self.popupContainer.classList.add("show-popup");
          const selectedElements = d3.select('am-map-component g #selected-zone');
          const highlightedElements = d3.select('am-map-component g #highlighted-zone');

          [ ...highlightedElements, ...selectedElements].forEach((selectedElement) => {
            if (selectedElement[0]) {
              selectedElement[0].parentNode.appendChild(selectedElement[0])
            }
          })
        }

        self.highlightUserSelectedRegions(appendSelectedAtEnd);

        const mapClicked = (element, geography) => {
          let id, name;
          let emitZoneClicked = false;
          const resetAllSelectedZones = () => {
            self.selectedRegions = [];
            emitZoneClicked = true;
            this.resetSelectedMapZones(allMapZones);
          }

          if (geography) {
            id = geography.id.split('_')[1];
            name = geography.properties.name;

            if (id == 0) {
              name = undefined;
              id = undefined;
              resetAllSelectedZones();
            } else if (d3.event.shiftKey && !_.isEmpty(id)) {
              if (!_.find(self.selectedRegions, { id: id }) && _.has(self.data, id)) {
                d3.select(element).attr('id', 'selected-zone');
                self.selectedRegions = [ ...self.selectedRegions, { id, name, ...self.data[id] } ];
                this.multiRegionsClicked = true;
              } else {
                this.resetSelectedMapZones(d3.select(element));
                self.selectedRegions = _.reject(self.selectedRegions, { id: id });
                this.multiRegionsClicked = true;
              }
            } else if (!d3.event.shiftKey && !_.isEmpty(id) && _.has(self.data, id)) {
              this.resetSelectedMapZones(allMapZones);
              d3.select(element).attr('id', 'selected-zone');
              self.selectedRegions = [{ id, name, ...self.data[id] }];
              emitZoneClicked = true;
            }
          } else {
            resetAllSelectedZones();
          }

          // Datamaps render the last element that was hovered last under the `g` element
          // <svg>
          //   <g>
          //     ... (all other path elements)
          //     <last element that was hovered>
          //   </g>
          // </svg>
          // this causes issue with the highlight border for the clicked element.
          // This is why we always need to append the last selected element at the end of the <g>
          // On click and also on hover.
          appendSelectedAtEnd();
          emitZoneClicked && self.data && self.$scope.$emit('zoneClicked', self.selectedRegions);
        }

        d3.select('am-map-component svg').on('click', function(data) {
          mapClicked(this, data);
        });

        allMapZones.on('click', function(geography) {
          d3.event.stopPropagation();
          mapClicked(this, geography)
        });

        allMapZones.on('mouseenter', function(geography) {
          const geoId = geography.id.split('_')[1];
          if (!_.isEmpty(geoId) && geoId != 0 && this.id !== 'selected-zone') d3.select(this).attr('id', 'highlighted-zone');
          appendSelectedAtEnd();
        });

        allMapZones.on('mouseleave', function() {
          if (this.id === 'highlighted-zone') d3.select(this).attr('id', null);
          appendSelectedAtEnd();
          self.popupContainer.classList.remove("show-popup");
          self.popupContainer.innerHTML = "";
        })
      }
    });

    this.map = map;
    this.zoom = new Zoom({
      $container: this.mapContainer,
      datamap: this.map,
      scale: {max: this.mapOptions.maxScale, maxOut: 0.7}
    });
  }

  _setContainerSize() {
    if (this.mapContainer) {
      const width = this.mapContainer.parentElement.offsetWidth;
      const height = this.mapContainer.parentElement.offsetHeight
      this.mapContainer.style.height = height+"px";
      this.mapContainer.style.width = width+"px";
    }
  }

  _updateMapPosition() {
    const scale = this.scale || 1;
    const transform = this.transform || [0, 0];
  }

  highlightUserSelectedRegions(appendSelectedAtEndFunc) {
    if (!this.selectedRegions) return;

    this.selectedRegions.forEach((selectedRegion) => {
      if (!selectedRegion.id) return;
      const element = d3.select(`path.datamaps-subunit.id_${selectedRegion.id}`)[0][0];
      if (element) d3.select(element).attr('id', 'selected-zone');
    });
    appendSelectedAtEndFunc();
  }
}

MapComponent.$inject = ["$scope", "$window", "$element"];
module.exports = angular.module(__filename, []).component('amMapComponent', {
  template: require('./map-component.html'),
  bindings: {
    mapName: '<', // topojson file
    popupTemplate: '<', // What to display on zone hover.
    data: '<',
    geographyConfig: '=',
    mapOptions: '<',
    onMapViewChanged: '&',
    customProjection: '<',
    selectedRegions: '<'
  },
  controller: MapComponent
});
