const dotdotdot = require("jquery.dotdotdot"),
    add_tooltip = require('../tooltip/tooltip.js');
module.exports = require("angular").module(__filename, [
    require("../am-spinner.drv/am-spinner.drv").name
]).directive('selectableBarChart', ["$window", "$timeout", function ($window, $timeout) {
        return {
            restrict: 'E',
            template: require('./selectable-bar-chart.drv.html'),
            scope: {
                data: "=",
                chartTitle: "=",
                onSelect: "&",
                noDataMsg: "=",
                deleteFn: "=?",
                //params used in multi-checkbox mode
                checkboxes: "=",
                showSelectAllToggle: "=",
                disabledIds: "=?",
                emphasisedIds: "=?",
                showSpinner: "&?",
                query: "="
            },
            link: function ($scope, $element) {
                $scope.interestTypes = [{value: 'websites', label: 'Websites'}, {value: 'phrases', label: 'Phrases'}];
                const onSelect = $scope.onSelect() ? $scope.onSelect() : angular.noop;
                $scope.selectionEnabled = $scope.onSelect();
                const scrollContainer = $element.children().children('.scroll-container');

                $scope.showSpinner = true;
                $scope.selectAll = false;

                $scope.selectElement = (element) => {
                    if (!$scope.selectionEnabled) return;
                    $scope.selectedElementId = element.id;
                    onSelect(element);
                };

                $scope.isSelected = (element) => element.id === $scope.selectedElementId;
                
                $scope.$watch('data', () => {
                    $scope.showSpinner = !$scope.data;
                    $scope.queryTitle = ($scope.query || {}).title;

                    if ($scope.data && $scope.data.length && $scope.selectionEnabled)
                        $scope.selectElement($scope.data[0]);

                    scrollContainer.scrollTop(0);

                    //reset selection
                    if ($scope.checkboxes && $scope.selectAll) $element.find("#select-all-checkbox").trigger("click");
                });

                //checkboxes mode
                $scope.toggleSelected = (bar, $event) => {
                    $scope.$parent.toggleSelectedBar(bar, $event);
                };

                $scope.toggleSelectAll = () => {
                    let queryTitle = $scope.queryTitle, disabledIds = $scope.disabledIds || {};

                    if ($scope.selectAll) {
                        $scope.data.filter((row) => !row.disabled && !disabledIds[row.id + queryTitle]).forEach((row) => { row.selected = true });
                    } else {
                        $scope.data.forEach((row) => { row.selected = false });
                    }
                    
                    $scope.$parent.toggleSelectedAllBar($scope.selectAll, $scope.data.filter((row) => row.selected));
                };

                function defaultDeleteFn(idOfElementToDelete, elementToDelete) {
                    $scope.data = $scope.data.filter((el) => el && el.id === idOfElementToDelete || el === elementToDelete);
                }
                $scope.deleteFn = $scope.deleteFn || defaultDeleteFn;

                $scope.$on("openContentDrivers", () => {
                    //unselect after adding to target
                    if ($scope.checkboxes) {
                        const cb = $element.find("#select-all-checkbox");
                        if (cb.hasClass("checked")) {
                            cb.trigger("click");
                        } else {
                            $scope.$watch('::data', () => {
                                if($scope.data) $scope.data.forEach((cell) => cell.selected = false);
                            });
                        }
                    }
                });
            }
        }
    }]
);
