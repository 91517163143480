var dotdotdot = require("jquery.dotdotdot"),
    add_tooltip = require('../tooltip/tooltip.js');;

module.exports = angular.module(__filename, [
    require("common/broken-img-fallback.drv").name
]).directive('tvShowInfo', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        template: require('./tv-show-info.drv.html'),
        scope: {
            "tvShow": "=",
            "scoreView": "="
        },
        link: function($scope, $element) {
            let addTooltipCB = function(isActive, element) {
                if(isActive) {
                    add_tooltip(element.context, 'info', {style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
                } else {
                    element.context.title = "";
                }
            };

            $scope.statsTooltipText = function audienceConsumptionComparisonTooltip(obj) {
                const percentValue = obj["interest-portion"].toFixed(1);
                const value = obj["uniqueness-index"].toFixed(1);
                const clientsVerb = 'are interested in';
                const avgConsumerComparison = value == 1 ? 'as the average consumer' : 'than the average consumer';

                let uniquenessValueQuantifier;
                if(value == 1) uniquenessValueQuantifier = 'as';
                if(value > 1) uniquenessValueQuantifier = 'x' + value + ' times more';
                if(value < 1) uniquenessValueQuantifier = 'x' + (1 / value).toFixed(1) + ' times less';

                return `${percentValue}% of the target audience ${clientsVerb} ${obj.phrase}.` + `</br>` +
                 `Consumers of ${obj.phrase} are ${uniquenessValueQuantifier} likely to be in the target audience than the average consumer.`;
            }

            $timeout(function () {
                $element.find('.dotdotdot').each(function () {
                    $(this).dotdotdot();
                });
                $element.find('.title-dotdotdot').each(function () {
                    $(this).dotdotdot({
                        callback: addTooltipCB,
                        wrap: "letter"
                    });
                });
            }, 0);
        }
    }
}])
