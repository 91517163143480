import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ToggleSwitch.scss';

const ToggleSwitch = (props) => (
  <div className={ classNames('toggle-switch-component', { 'on': props.isOn }) }
    onClick={ () =>  props.onToggle(!props.isOn) }>
      
    <a className={ classNames('label', { 'with-text': props.label }) }>{ props.label }</a>
      
    <div className="switch inline-block pointer">
      <span className="toggle"></span>
      <span className="on-text">On</span>
      <span className="off-text">Off</span>
    </div>
  </div>
);

ToggleSwitch.propTypes = {
  label:    PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  isOn:     PropTypes.bool
};

ToggleSwitch.defaultProps = {
  isOn: false,
  label: ''
};

export default ToggleSwitch;
