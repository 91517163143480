var d3 = require("d3"),
	c = require("infra/utils/common"),
	$ = require("jquery"),
	add_tooltip = require('../tooltip/tooltip.js');

VerticalBarChart.BAR_SPACE_WIDTH = 70;
VerticalBarChart.LABEL_SPACE_HEIGHT = 62;
VerticalBarChart.LABEL_SPACE_PAD = 15;
VerticalBarChart.LABEL_LINE_HEIGHT = 23;
VerticalBarChart.LABEL_CHARACTER_WIDTH = 7.8;
VerticalBarChart.MAX_LABEL_LENGTH = 12;
VerticalBarChart.STATIC_BAR_WIDTH = true;
VerticalBarChart.BAR_WIDTH = 15.7;
VerticalBarChart.BAR_VALUE_LABEL_PAD = 15;
VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN = 20;
VerticalBarChart.MINIMU_BAR_HEIGHT = 2;
VerticalBarChart.TOOLTIP_DEFAULT_POSITION = {my: 'left center', at: 'right center'};
VerticalBarChart.TOOLTIP_LEFT_POSITION = {my: 'right center', at: 'left center'};

var LABEL_FONT = {
	family: 'Helvetica',
	size: 12,
	stretch: 'normal',
	style: 'normal',
	variant: 'normal',
	weight: 'normal'
};

var unknown = "unknown-term";

function VerticalBarChart(callback, entries, configuration) {
	var self = this;
	this.popup = callback;
	this.map = {};
	this.filtered = {};
	this.configuration = c.is(configuration) ? configuration : {element: 'vertical-bar-chart'};
	this.space = (this.configuration.grouped) ? (VerticalBarChart.BAR_SPACE_WIDTH/3)*2 : VerticalBarChart.BAR_SPACE_WIDTH;
	this.labelPad = (this.configuration.grouped) ? VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN/2.5 : VerticalBarChart.BAR_VALUE_LABEL_LEFT_MARGIN;
	this.canvasWidth = 0;
	if (c.isArray(entries)) {
		this.entries = JSON.parse(JSON.stringify(entries));
		c.sortByNumeric(this.entries, 'order', false);
		_.each(this.entries, function (entry) {
			self.map[entry.key] = entry.class;
		});
	} else {
		this.entries = [];
	}

    function splitTextToLines(text, lines) {
        var max = VerticalBarChart.MAX_LABEL_LENGTH;
        var r = [], t = [];
        var w = text.split(' ');
        _.each(w, function (word) {
            var l = {line: word.trim()};
            if (l.line.length > 0) {
                if (l.line.length > max) {
                    l.original = l.line;
                    l.line = l.line.substring(0, max - 2) + '..';
                } else if (t.length > 0 && (t[t.length - 1].length + l.line.length + 1) <= max) {
                    t[t.length - 1] = t[t.length - 1] + ' ' + l.line;
                    l.line = null;
                }

                if (c.isString(l.line)) {
                    t.push(l);
                }
            }
        });
        for (var i = 0; i < lines; i++) {
            r[i] = c.is(t[i]) && c.isString(t[i].line) ? t[i] : {line: ''};
        }
        return r;
    }

	this.setData = function(data, max, pad) {
		var self = this;
		this.clean();
		if (c.isArray(data) && c.isArray(this.entries)) {
			var stackData = [], terms = {};
			_.each (data, function(term, index) {
				var total = 0, y = 0, stacks = [];
				var display = term.display ? term.display : term.label;
				var stacked = {
					id: term.id,
					source: term.label,
					labels: splitTextToLines(display, 2),
					term: term.label // TODO Consider to remove
				};
				_.each (stacked.labels, function(l) {
					l.width = self.getTextWidth(l.line, LABEL_FONT);
				});
				if (stacked.term.length > VerticalBarChart.MAX_LABEL_LENGTH) {  // TODO Consider to remove
					var valid = c.cutText(stacked.term, VerticalBarChart.MAX_LABEL_LENGTH, '..');
					var mapped = self.filtered[valid];
					if (c.is(mapped) && c.isNumber(mapped.i)) {
						mapped.i = mapped.i + 1;
						valid = valid + mapped.i;
					}
					self.filtered[valid] = { i: 1, source: stacked.term };
					stacked.source = stacked.term;
					stacked.term = valid;
				}
				_.each (self.entries, function(entry) {
					var v = term[entry.key];
					if (!(v == 0 && (entry.key == 'positive' || entry.key == 'negative'))) {
						var description = (term.description && term.description[entry.key]) ?
											term.description[entry.key] : '' + term[entry.key];
						var s = {
							"name": entry.key,
							"term-class": term.class,
							"description": description
						};
						total += v;
						s.y0 = y;
						y += v;
						s.y1 = y;
						stacks.push(s);
					}
				});
				stacked.total = formatNumericLabel(total, 1, false, 5);
                stacked.original = formatNumericLabel(data[index].value,
					(c.isNumber(self.configuration.precision) ? self.configuration.precision : 1), false, 5);
					if (stacked.original >= 1000) {
						if (stacked.original >= 1000000) {
							stacked.original = Math.round(stacked.original/1000000) + 'M';
						} else {
							stacked.original = Math.round(stacked.original/1000) + 'K';
						}
					}
				stacked.stacks = stacks;
				stackData.push(stacked);
			});
			this.draw(this.container, stackData, max, pad);
		}
	};

    function formatNumericLabel(value, precision, forced, maxPrecision) {
        if (c.isNumber(value)) {
            var formatted = value == 0 ? '0' : value.toFixed(precision);
            if (formatted == 0 && !forced && maxPrecision > precision && formatted != value) {
                return formatNumericLabel(value, precision + 1, forced, maxPrecision);
            }
            return formatted;
        }
        return 0;
    }

	this.clean = function() {
		this.filtered = {};
		this.canvasWidth = 0;
		if (!c.is(this.container)) {
			this.container = d3.select('#' + this.configuration.element);
		}
		this.container.selectAll("*").remove();
	};

	this.getDimensions = function(data, pad) {
		var dimensions = {
			height: 0 - VerticalBarChart.LABEL_SPACE_HEIGHT - VerticalBarChart.LABEL_LINE_HEIGHT,
			width: (data.length * this.space),
			top: c.isNumber(pad) ? pad + VerticalBarChart.LABEL_SPACE_HEIGHT/2 : VerticalBarChart.LABEL_SPACE_HEIGHT/2,
			right: 0,
			bottom: VerticalBarChart.LABEL_LINE_HEIGHT * 2,
			left: 0
		};
		var h = c.getElementHeight((c.isString(this.configuration.parent)) ? this.configuration.parent : this.configuration.element);
		dimensions.height += (h - (c.isNumber(this.configuration.margin) ? this.configuration.margin : 0));
		return dimensions;
	};

    this.getTextWidth = function (text, font) {
        if (c.isString(text)) {
            var t = text.trim();
            if (c.isString(t)) {
                var ruler = $('#text-ruler');
                if (!c.isElement(ruler) && ruler.length <= 0) {
                    $("body").append("<span id=\"text-ruler\"></span>");
                    ruler = $('#text-ruler');
                    ruler.css('visibility', 'hidden');
                    ruler.css('white-space', 'nowrap');
                }

                c.setElementFont(ruler, font);
                ruler.html(t);
                var w = ruler.width();
                ruler.html('');
                return c.isNumber(w) && w > 0 ? w : text.length * 7.8;
            }
        }
        return 0;
    };

	this.draw = function(element, data, max, pad) {
		if (c.isArray(data) && c.is(element)) {
			var self = this;
			var dimensions = this.getDimensions(data, pad);
			this.canvasWidth = dimensions.width + dimensions.left + dimensions.right;
			var x = d3.scale.ordinal()
				.rangeRoundBands([0, dimensions.width], .1);
			var y = d3.scale.linear()
				.rangeRound([dimensions.height, 0]);
			var xAxis = d3.svg.axis()
				.scale(x)
				.orient("bottom");
			// var yAxis = d3.svg.axis().scale(y).orient("left").tickFormat(d3.format(".2s"));
			x.domain(data.map(function(d) {
				return d.term;
			}));
			y.domain([0, max]);
			var svg = element.append("svg")
				.attr("class", 'canvas')
				.style("width", self.canvasWidth + 'px')
				.style("height", dimensions.height + dimensions.top + dimensions.bottom + 'px')
				.append("g")
				.attr("transform", "translate(" + dimensions.left + "," + dimensions.top + ")");
			var terms = svg.selectAll(".term")
				.data(data)
				.enter().append("g")
				.attr("class", "g")
				.attr("transform", function(d) { // d.source
					return "translate(" + x(d.term) + ",0)";
				});
			var xzp = self.space - 3 - VerticalBarChart.BAR_WIDTH/2;
			var yzp = dimensions.height + VerticalBarChart.LABEL_SPACE_PAD;
			if (this.configuration.grouped) {
				svg.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", function(d) {
						return (self.canvasWidth - (self.configuration.title.length * 6.2))/2;
					})
					.attr("y", yzp)
					.text(function(d) {
						return self.configuration.title;
					});
			} else {
				terms.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", function(d) {
						var g = xzp - d.labels[0].width;
						return g > 0 ? g/2 : 0;
					})
					.attr("y", yzp)
					.text(function(d) {
						return d.labels[0].line;
					})
					.append("svg:title")
					.text(function(d) {
						return d.source;
					});
				terms.append("text")
					.attr("class", "vertical-bar-term-label")
					.attr("x", function(d) {
						var g = xzp - d.labels[1].width;
						return g > 0 ? g/2 : 0;
					})
					.attr("y", yzp + (VerticalBarChart.LABEL_LINE_HEIGHT*2)/3)
					.text(function(d) {
						return d.labels[1].line;
					})
					.append("svg:title")
					.text(function(d) {
						return d.source;
					});
			}
			var bw = VerticalBarChart.STATIC_BAR_WIDTH ? VerticalBarChart.BAR_WIDTH : x.rangeBand();
			terms.selectAll("rect")
				.data(function(d) { return d.stacks; })
				.enter()
				.append("rect")
					.attr("width", bw)
					.attr("y", function(d) { return y(d.y1); })
					.attr("height", function(d) {
						return y(d.y0) - y(d.y1) == 0 ? VerticalBarChart.MINIMU_BAR_HEIGHT : y(d.y0) - y(d.y1);
					})
					.attr("title", function(d) {
						var description_num = parseFloat(d.description);
						if(d.description.slice(-1) == '%'){
							return description_num.toFixed(1) + '%';
						}
						if(c.isNumber(description_num)){
							if(d.description.indexOf('.') != -1){
								description_num = description_num.toFixed(1);
							}
							return description_num.toLocaleString();
						}
						return d.description;
					})
					.attr("transform", "translate(" + ((self.space + 15.7)/2 - 15.7 - 6) + ",0)")
					.attr("class", function(d) {
						var n = self.map[d.name];
						n = n == '' ? d['term-class'] : n;
						return c.isString(n) ? n : unknown;
					});
			var position = VerticalBarChart.TOOLTIP_DEFAULT_POSITION;
			if (self.configuration.hasOwnProperty('tooltip') && self.configuration.tooltip == "left"){
				position = VerticalBarChart.TOOLTIP_LEFT_POSITION;
			}
			_.each(terms.selectAll("rect"),function(element,i){
				add_tooltip(element,'info',{position: position, style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
			});
			var lm = self.labelPad + bw/2;
			lm = (c.isNumber(self.configuration.precision) && self.configuration.precision == 0) ? lm - 2 : lm;
			terms.append("text")
				.attr("class", "vertical-bar-label")
				.attr("x", function(d) {
					return lm - (c.isString(d.original) ? d.original.length * 2.4 : 0);
				})
				.attr("y", function(d) {
					return y(d.total) - VerticalBarChart.BAR_VALUE_LABEL_PAD;
				})
				.text(function(d) {
                    return d.original;
				});
		}
	};
}

module.exports = VerticalBarChart;
