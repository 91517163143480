import 'angular-modal-service';
import config from 'infra/config';
const queries = {};
    

queries.targetlist = {
  title: 'targets',
  dataArray: [],
  columns: [

          {id: 'name', title: 'Target', tooltip: 'Target', ellipsis: true, alwaysShown:true, displayValue: "full_name"},
          {id: '_id', title: 'ID', tooltip: 'ID', flex:'0-3', search: true, alwaysShown:true, displayValue: "full_id"},
          {id: 'target_type_display', ellipsis: true, flex:'0-7', title: 'Type', tooltip: 'Type', search: true},
          {id: '_program_name', title: 'Program', tooltip: 'Program', search: true, ellipsis: true},
          {id: '_user_name', title: 'Created by', tooltip: 'Created by', search: true, search: true, flex:'0-5'},
          {id: 'date_added', title: 'Date Created', ellipsis: true, tooltip: 'Date Created', search: true,  displayValue: "date_added_formated"},
          {id: 'updated_by', title: 'Updated by', tooltip: 'Updated by', ellipsis: true, search: true, flex:'0-5'},
          {id: 'date_updated', title: 'Date Updated', tooltip: 'Date Updated', ellipsis: true, search: true, displayValue: "date_updated_formated" },
          {id: 'channel_display', title: 'Channel', tooltip: 'Activated To', search: true, flex:'0-5'},
          {id: 'activated_to_sorted', title: 'Activated To', tooltip: 'Activated To', ellipsis: true, displayValue: 'activated_to_display', search: true, flex:'0-7'}
          ],

  crud: true,
  fixedSize: false,
  order: 'name',
  reverse: false,
  excel: false
};

angular.module(__filename, [
  'angularModalService',
  require("pages/targets/targets-common").name

]).controller('targetsController', ['$scope', '$http', '$q', 'Excel', '$filter', 'TargetsCommon', 'CHANNEL', 'ModalService', 'notificator', '$timeout', 'confirmAction','mgmtServiceFactory', 'mixpanelSettings',
    function ($scope, $http, $q, Excel, $filter, TargetsCommon, CHANNEL, ModalService, notificator, $timeout, confirmAction, mgmtServiceFactory, mixpanelSettings) {
        
        $scope.queries=queries;
        $scope.targets = [];
        $scope.currentTarget = {};

        $scope.init = function () {
          TargetsCommon.getTargets({scope:$scope, all:true, cb: $scope.initCB});
        };

        $scope.initCB = function(){
          $scope.queries.targetlist.show($scope.targets);
        }

        //events;
        var updateTargetsHandlerWatch = $scope.$root.$on('updateTargets', function (event, obj) {
            TargetsCommon.updateTargetsHandler(obj, $scope);
        });
        
        var updateTargetsCBWatch = $scope.$root.$on('updateTargetsCB', function (event, obj) {
            if(obj.action=='remove-target' || 
              obj.action=='update-target'){
              TargetsCommon.showUpdatedList($scope, obj.targets);
            }
        });

        $scope.$on('$destroy', function () { //exit grid
            updateTargetsCBWatch(); //clears watches
            updateTargetsHandlerWatch();
        });
 
       
        $scope.refresh = function(){
          $scope.queries.targetlist.loading = true;
          $scope.init();
        }

        $scope.edit = function(target){
          TargetsCommon.edit(target, '', $scope);
        }

        $scope.delete = function(target){
          $scope.deleteTarget = target;
        }

        $scope.deleteConfirm = function(){
          TargetsCommon.deleteConfirm($scope);
        }

        $scope.$root.createExcelWorkbook = function (users) {

          if(!$scope.targets.length){
            return;
          }
          
          var workbook = Excel.builder.createWorkbook();
          workbook.fileName = 'targets.xlsx';

          var desc = {}
          queries.targetlist.columns.forEach((col)=>{
            desc[col.title] = {key: col.displayValue || col.id, width: 16};
          });

          let arr = $scope.targets.slice(0);
          $scope.queries.targetlist.sortArray(arr, $scope.queries.targetlist.sort);

          Excel.addTableSheet(workbook, arr, desc, {name: 'targets'});
          
          return workbook;     
        };

        $scope.init();
    }
]).stateConfig = {
    name: "targets",
    url: "/targets",
    template: require("./targets-settings.html"),
    display: "Settings",
    controller: 'targetsController',
    data: {
      permissions: ['targets view']
    }
};

module.exports = angular.module(__filename);
