import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AudienceTargetModal.scss';

import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import { isEmpty } from 'lodash';

const AudienceTargetModal = ({ isOpen, onSubmit, onCancel, marketsPromise, audienceName, isNewAudienceTarget, isProcessing }) => {
  const modalTitle = 'Activate your defined audience';
  const description = isNewAudienceTarget ? 'Create a targetable segment in the DMP for your defined audience' : 'Update your targetable segment in the DMP with the updated audience attributes';
  const submitLabel = isNewAudienceTarget ? 'Create' : 'Update';
  const amobeeMarket = { label: '884 - Amobee, Inc.', value: '884', name: 'Amobee, Inc.' };
  const dropdownValuesProps = marketsPromise ? { promise: marketsPromise } : { values: [amobeeMarket] };
  const [isMarketsPromiseLoading, setIsMarketsPromiseLoading] = marketsPromise ? useState(true) : useState(false);
  const [selectedMarket, setSelectedMarket] = useState(amobeeMarket);
  const  onMarketChange = useCallback(
    (value) => {
      setSelectedMarket(value);
    },
    [selectedMarket],
  );

  useEffect(() => {
    if (!marketsPromise) return;
    marketsPromise.then(() => {
      setIsMarketsPromiseLoading(false);
    });
  });

  const summaryTextBuilder = (selectedValues) => selectedValues[0].label;

  return (
    <div className="audience-target-modal-component">
      <ModalWithConfirmationButtons
        width="500px"
        modalTitle={ modalTitle }
        isOpen={ (isOpen || isProcessing) }
        isCancelDisabled={ isProcessing }
        isSubmitDisabled={ isProcessing || isEmpty(selectedMarket) || isMarketsPromiseLoading }
        isDisabledEnterKeyPress={ false }
        isYAutoScrollEnabled={ false }
        submitLabel={ submitLabel }
        onSubmit={ () => onSubmit(selectedMarket) }
        onCancel={ () => onCancel() }
        showSpinner={ isProcessing } >
        <div className="audience-target-modal-content">
          <div className="row">
            <div className="description">{ description }</div>
          </div>
          <div className="row">
            <div className="label audience-name-label">
              Audience Name:
              <span className="audience-name-text">{ audienceName }</span>
            </div>
            
          </div>
          <div className="row market-row">
            <div className="label">Activate to market:</div>
            <div className="market-dropdown">
              <Dropdown
                { ...dropdownValuesProps }
                selectedValue={ selectedMarket }
                isMulti={ false }
                isSearchable={ false }
                onSelect={ onMarketChange }
                shouldCloseOnSelect={ true }
                shouldCloseOnClickOutside={ true }
                summaryTextBuilder={ summaryTextBuilder }>
              </Dropdown>
            </div>
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

AudienceTargetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  marketsPromise: PropTypes.object,
  audienceName: PropTypes.string,
  isNewAudienceTarget: PropTypes.bool,
  isProcessing: PropTypes.bool
};

AudienceTargetModal.defaultProps = {
};

export default AudienceTargetModal;
