import 'ng-file-upload'
import config from 'infra/config';
import common from 'infra/utils/common';
import { concat, compact } from 'lodash';
const audienceSegmentBuilderHelper = require('./audience-segment-builder-helper');

const AUDIENCE_INSIGHTS_URL_V1 = config.AUDIENCE_PROFILER_API + '/api/1/ui-related-keywords2';
const AUDIENCE_INSIGHTS_URL_V2 = config.AUDIENCE_PROFILER_API + '/api/v2/audience/interests/';
const AUDIENCE_USER_HISTORY_URL = config.AUDIENCE_PROFILER_API + '/api/1/highlighted-user-history';
const AUDIENCE_SEGMENT_DISTRIBUTION_URL = config.AUDIENCE_PROFILER_API + '/api/v2/audience/demographics';
const LINKEDIN_METADATA_API = config.USER_MGMT_API + '/linkedin/get_entities_meta_data';
const LINKEDIN_ACCESS_TOKEN_EXISTS_API = config.USER_MGMT_API + '/linkedin/check_user_access_token_status';
const LINKEDIN_GET_TOP_CONTENT_FOR_AUDIENCE_API = config.USER_MGMT_API + '/linkedin/get_top_content_for_audience';
const LINKEDIN_GET_COMPANIES_INFO_BY_NAME_API = config.USER_MGMT_API + '/linkedin/get_organizations_info_by_name';
const FIRST_PARTY_METADATA_API = config.USER_MGMT_API + '/first_party/get_entities_meta_data';
const FIRST_PARTY_API = config.USER_MGMT_API + '/first_party';
const FIRST_PARTY_UPLOADER = config.FIRST_PARTY_UPLOADER + '/first-party-upload'
const FIRST_PARTY_BY_USER_API = config.USER_MGMT_API + '/first_party/user';
const SG_CHANNELS = ['snbb', 'data_spark'];
const TV_CHANNELS = ['linear_tv', 'smart_tv'];
const TV_SEARCH_TYPES = ['tv', 'genres', 'networks'];
const QUERY_NAMES = {phrases: "xw", websites: "td", topics: "new-topics", searches: "gsw", searchesBidStream: 'iw', apps: 'app'};
const QUERY_NAMES_V2 = {phrases: 'frequent-keywords', websites: 'domains', topics: 'topics', searches: 'searches'};
const AudienceService = require('../react/services/AudienceService');
const newTvService = require('../react/services/TvService');

export default angular.module(__filename, [require('data/tv-service').name, require('./audience-skew-service').name, 'ngFileUpload'])
    .service("audienceInsightsService", ['$q', '$http', 'audienceSkewService', 'keywords', 'errorMgmt', 'util',
                                         'filtersPartition', 'cancellableHttp', 'abiPermissions', 'tvService',
                                         '$rootScope', 'mgmtServiceFactory', 'Upload', 'context',
        function ($q, $http, audienceSkewService, keywordsService, errorMgmt, util, filtersPartition, cancellableHttp,
                  abiPermissions, tvService, $rootScope, mgmtServiceFactory, Upload, context) {
            // cache for demographics / insights / tv data requests
            let lastDemographicsCacheKey, lastReqCacheKey, lastDemographicsPromise, lastReqPromise, linkedinMetaDataPromise, 
                linkedinAccessTokenPromise, tvGenresMetaDataPromise, firstPartyMetaDataPromise, segmentPropertiesReqs = {},
                firstPartyService = mgmtServiceFactory.createService('first_party');
            const tvChannelToTvShowsMetaDataPromise = {}; // { articles: tvShowsMetaDataPromise, linear_tv: linearTvShowsMetaDataPromise, smart_tv: smartTvShowsMetaDataPromise }
            const tvChannelToTvNetworksMetaDataPromise = {}; // { articles: tvShowsMetaDataPromise, linear_tv: linearTvShowsMetaDataPromise, smart_tv: smartTvShowsMetaDataPromise }
            const tvChannelToTvCommercialsPromise = {}; // { linear_tv: linearTvCommercialsPromise, smart_tv: smartTvCommercialsPromise }

            // linkedin data
            this.getLinkedinMetaData = getLinkedinMetaData;
            this.getLinkedinTopContentForAudience = getLinkedinTopContentForAudience;
            this.getLinkedinCompaniesInfoByNames = getLinkedinCompaniesInfoByNames;
            this.getLinkedinAudienceDistribution = getLinkedinAudienceDistribution;
            this.checkLinkedinUserAccessTokenStatus = checkLinkedinUserAccessTokenStatus;
            //1st party data
            this.getFirstPartyMetaData = getFirstPartyMetaData;
            this.getFirstPartyDataByProgram = getFirstPartyDataByProgram;
            this.getFirstPartyDataByUser = getFirstPartyDataByUser;
            this.createFirstPartySegment = firstPartyService.create;
            this.createFirstPartySegmentWithFile = createFirstPartySegmentWithFile;
            this.updateFirstPartySegment = updateFirstPartySegment;
            this.updateFirstPartySegmentWithFile = updateFirstPartySegmentWithFile;
            this.deleteFirstPartySegment = deleteFirstPartySegment;
            // demographics data
            this.getFullDemographicsDataWithGenderAgeBySegment = getFullDemographicsDataWithGenderAgeBySegment;
            this.getFullDemographicsDataWithGenderAge = getFullDemographicsDataWithGenderAgeByLogicalStatement;
            this.getDemographicsDataForPreviewBySegment = getDemographicsDataForPreviewBySegment;
            this.getDemographicsDataForPreview = getDemographicsDataForPreviewByLogicalStatement;
            this.getDemographicsDataByKeywordsAndCountries = getDemographicsDataByKeywordsAndCountries;
            // tv shows
            this.getTvShowsMetaData = getTvShowsMetaData;
            this.getTvNetworksMetaData = getTvNetworksMetaData;
            this.getTvGenresMetaData = getTvGenresMetaData;
            this.getTvCommercialsMetaData = getTvCommercialsMetaData;
            // insights data
            this.getRawInterestsDataV1 = getRawInterestsDataV1;
            this.getInterestsData = getInterestsDataV1;
            this.getSegmentInterestsData = getSegmentInterestsData;
            this.getSegmentInterestsDataByIds = getSegmentInterestsDataByIds;
            this.getSegmentAdvancedInterestsDataByQueries = getSegmentAdvancedInterestsDataByQueries;
            this.getPhrasesIndexInLifestyle = getPhrasesIndexInLifestyle;
            this.getTvInfo = getTvInfo;
            this.getSegmentIds = getSegmentIds;
            // audience target
            this.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
            this.createAudienceTargetUserList = createAudienceTargetUserList;
            this.getAudienceTaxonomyCategory = getAudienceTaxonomyCategory;
            // user history data
            this.userHistory = getUserHistory;
            // utility fns
            this.getSegmentParams = getSegmentParamsV1;
            this.isRequestCancelled = isRequestCancelled;
            // constants
            this.QUERY_NAMES = QUERY_NAMES;
            this.QUERY_NAMES_V2 = QUERY_NAMES_V2;

            // linked-in data: service methods

            function checkLinkedinUserAccessTokenStatus (userId) {
                if (linkedinAccessTokenPromise) return linkedinAccessTokenPromise;
                return linkedinAccessTokenPromise = $http({
                    url: LINKEDIN_ACCESS_TOKEN_EXISTS_API,
                    params: { user_id: userId },
                    method: 'GET',
                }).then((res) => res.data)
            }

            function getLinkedinMetaData (userId) {
                const TYPES_TO_SORT = ['countries', 'industries', 'regions', 'us_states'];

                function mapMetaDataObjectToValueLabelArray (metaDataObject, type) {
                    metaDataObject = _.map(metaDataObject, (val, key) => ({label: val, value: key}));
                    return TYPES_TO_SORT.includes(type) ? _.sortBy(metaDataObject, (object) => object.label) : metaDataObject;
                }

                if (linkedinMetaDataPromise) return linkedinMetaDataPromise;
                return linkedinMetaDataPromise = $http({
                    url: LINKEDIN_METADATA_API,
                    method: 'GET',
                    cache: true,
                    params: { user_id: userId }
                }).then((res) => {
                    if (res.data.error && res.data.error.reason === 'access_token_expired') return linkedinTokenExpired();
                    return _.mapValues(res.data, (value, key) => key === "regions_by_state" ? value : mapMetaDataObjectToValueLabelArray(value, key));
                })
            }

            function getTvShowsMetaData (channel) {
                if (tvChannelToTvShowsMetaDataPromise[channel]) return tvChannelToTvShowsMetaDataPromise[channel];
                return tvChannelToTvShowsMetaDataPromise[channel] = tvService.getShows(channel);
            }

            function getTvNetworksMetaData (channel) {
                if (tvChannelToTvNetworksMetaDataPromise[channel]) return tvChannelToTvNetworksMetaDataPromise[channel];
                return tvChannelToTvNetworksMetaDataPromise[channel] = tvService.getNetworks(channel);
            }

            function getTvGenresMetaData () {
                if (tvGenresMetaDataPromise) return tvGenresMetaDataPromise;
                return tvGenresMetaDataPromise = newTvService.getGenres();
            }

            function getTvCommercialsMetaData(channel) {
                if (tvChannelToTvCommercialsPromise[channel]) return tvChannelToTvCommercialsPromise[channel];
                return tvChannelToTvCommercialsPromise[channel] = newTvService.getCommercials(channel);
            }

            function getFirstPartyMetaData () {
                if (firstPartyMetaDataPromise) return firstPartyMetaDataPromise;
                return firstPartyMetaDataPromise = $http({
                    url: FIRST_PARTY_METADATA_API,
                    method: 'GET',
                    cache: true
                }).then((res) => _.sortBy(res.data, o => o.label.toLowerCase()));
            }

            function getFirstPartyDataByProgram (program_id, callback = angular.noop) {
                return $http({
                    url: FIRST_PARTY_API + '/' + program_id,
                    method: 'GET'
                }).then(function (res) {
                    callback(res.data);
                    return _.map(res.data, normalizeFirstPartyRecord);
                });
            }

            function getFirstPartyDataByUser (user_id) {
                return $http({
                    url: FIRST_PARTY_BY_USER_API + '/' + user_id,
                    method: 'GET'
                }).then((res) => _.map(res.data, normalizeFirstPartyRecord));
            }

            function normalizeFirstPartyRecord (record) {
                let segment = _.clone(record.segment);
                segment.programs = _.map(record.programs, function (program) {
                    program.name = util.isMyInterestsProgram(program) ? "My Interests" : program.name;
                    return program;
                });
                segment.creator = record.creator;
                segment.label = segment.name;
                segment.value = segment.segment_value;
              return segment;
            }

            function createFirstPartySegmentWithFile (data, file, callbacks) {
                return executeUpload(FIRST_PARTY_UPLOADER, data, file, 'POST', callbacks);
            }

            function updateFirstPartySegment(segment_id, data, callbacks) {
                let success_callback = callbacks.success || angular.noop;
                callbacks.success = function (res) {
                    success_callback(res);
                    if(!_.includes(data.programs, context.program.id)) {
                        deleteSegmentFromContext(data.segment_value);
                    } else {
                        updateSegmentNameInContext(segment_id, data.name);
                    }
                };
                return firstPartyService.update(segment_id, data).then(callbacks.success, callbacks.error);
            }

            function updateFirstPartySegmentWithFile (segment_id, data, file, callbacks) {
                let success_callback = callbacks.success || angular.noop;
                callbacks.success = function (res) {
                    success_callback(res);
                    deleteSegmentFromContext(data.segment_value);
                    updateSegmentNameInContext(segment_id, data.name);
                };
                return executeUpload(FIRST_PARTY_UPLOADER + '/' + segment_id, data, file, 'PUT', callbacks);
            }

            function deleteFirstPartySegment(segment_id, segment_value) {
                deleteSegmentFromContext(segment_value);
                return firstPartyService.delete(segment_id);
            }

            function executeUpload(url, data, file, method, callbacks) {
                callbacks = callbacks || {};
                data = data || {};
                data.file = file;
                let upload = Upload.upload({
                    url: url,
                    data: data,
                    method: method
                });
                return upload.then(callbacks.success || angular.noop,
                                   callbacks.error || angular.noop,
                                   callbacks.progress || angular.noop);
            }

            function updateSegmentNameInContext (segment_id, name) {
                _.each(context.current.firstPartyAudience, function(rec){
                    if(rec.id == segment_id) {
                        rec.name = name;
                        rec.label = name;
                        rec.summary = name;
                    }
                });
            }

            function deleteSegmentFromContext (segment_value) {
                _.remove(context.current.firstPartyAudience, {'segment_value': segment_value});
            }

            function convertSegmentToLinkedinAudience (audience, isCountApi = false) {
                const propertyToApi = {age: 'ages', country: 'countries', functions: 'functions', gender: 'genders', industries: 'industries', jobTitles: 'titles', names: 'companies', regions: 'regions', seniorities: 'seniorities', sizes: 'company_sizes', state: 'states'};
                let audienceAsKeyValMap = audience.reduce((acc, curVal) => Object.assign({}, acc, _.mapKeys(curVal, (value, key) => propertyToApi[key])), {}); // {ages: [{label: '1-10', value: 'urn:xxx'}, {...}], genders: [{...},{...}]}
                audienceAsKeyValMap = _.pick(audienceAsKeyValMap, Object.values(propertyToApi)); // select only needed attributes
                if (isCountApi) {
                    // In count api, 'locations' contains the values for 'countries', 'states' and 'regions' 
                    const {countries, states, regions, ...audienceWithLocation } = { ...audienceAsKeyValMap, locations: compact(concat(audienceAsKeyValMap['countries'], audienceAsKeyValMap['regions'], audienceAsKeyValMap['states'])) }
                    audienceAsKeyValMap = audienceWithLocation;
                }
                return _.mapValues(audienceAsKeyValMap, (val) => _.map(val, (val) => val.value || val.id)); // {age: ['urn:xxx', 'urn:yyy'], gender: [...]}
            }

            function getLinkedinTopContentForAudience (audience, userid) {
                audience = convertSegmentToLinkedinAudience(audience);
                return $http({
                    url: LINKEDIN_GET_TOP_CONTENT_FOR_AUDIENCE_API,
                    method: 'POST',
                    cache: true,
                    data: {audience: audience, user_id: userid}
                }).then((res) => {
                    if (res.data.error && res.data.error.reason === 'access_token_expired') return linkedinTokenExpired();
                    return res.data;
                })
            }

            function getLinkedinAudienceDistribution (audience, {disableNotification, userId} = {}) {
                audience = convertSegmentToLinkedinAudience(audience, true);
                //const url = disableNotification ? `${LINKEDIN_GET_AUDIENCE_DIST_API}?disable_notification=1` : LINKEDIN_GET_AUDIENCE_DIST_API;
                const promise = AudienceService.getLinkedinSegmentDemographicsDistribution(audience, userId);
                return promise.then(res => {
                       if (res.error && res.error.reason === 'access_token_expired') return linkedinTokenExpired();
                       return res.status === 'error' ? res : {data: res, status: 'ok'}; 
                })
            }

            function getLinkedinCompaniesInfoByNames (companiesNames, userId) {
                if (_.isEmpty(companiesNames)) return $q.resolve([]);
                return $http({
                    url: LINKEDIN_GET_COMPANIES_INFO_BY_NAME_API,
                    method: 'POST',
                    cache: true,
                    data: {companies: companiesNames, user_id: userId}
                }).then((res) => {
                    if (res.data.error && res.data.error.reason === 'access_token_expired') return linkedinTokenExpired();
                    return res.data;
                }, (err) => resolve([]));
            }

            function linkedinTokenExpired() {
                errorMgmt.customError("Your LinkedIn access token has expired, please refresh the page to continue.")
            }

            // demographics data: service methods

          function getRawDemographicsDataByLogicalStatement (query, filterMapping, {channel, cachePromise = true, sampleSize = 5000, disableNotification = false} = {}) {
                let filtersCacheKey = JSON.stringify(query);
                if (filtersCacheKey === lastDemographicsCacheKey) return lastDemographicsPromise;

                const url = disableNotification ? `${AUDIENCE_SEGMENT_DISTRIBUTION_URL}?disable_notification=1` : AUDIENCE_SEGMENT_DISTRIBUTION_URL;
                const promise = cancellableHttp.$http({
                    url,
                    method: 'POST',
                    cache: true,
                    data: {query, 'sample-size': sampleSize, ...(filterMapping && {'filter-mapping': filterMapping}), ...(SG_CHANNELS.includes(channel) && {"use-sg-races": abiPermissions.hasPermission("sg telco ethnicity")})}
                })
                    .cancellableThen((res) => {
                        if (!res) return false;
                        if (res.status === 200 && res.data.status === 'ok')
                            for (let dataType of ['distribution', 'skew'])
                                res.data.data = fixAgeLegal(res.data.data, dataType);
                        return res.data
                    }, responseFailed(!disableNotification));

                if (cachePromise) {
                    lastDemographicsCacheKey = filtersCacheKey;
                    lastDemographicsPromise = promise;
                }
                return promise;
            }

            function getFullDemographicsDataWithGenderAgeBySegment (segment, options = {}) {
                const query = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, options.channel, $rootScope.Geos.geos);
                const filterMapping = audienceSegmentBuilderHelper.convertAudienceSegmentToFilterMapping(segment, options.channel);
                return getFullDemographicsDataWithGenderAgeByLogicalStatement(query, filterMapping, options);
            }

            function getFullDemographicsDataWithGenderAgeByLogicalStatement (query, filterMapping, options = {}) {
                const rawDataPromise = getRawDemographicsDataByLogicalStatement(query, filterMapping, options);
                const transformedDataPromise = rawDataPromise.then((data) => {
                    if (!(data && data.data) || data.status !== 'ok') return data;

                    const dataParts = {
                        audienceSize: transformAudienceSize(data.data),
                        gender: transformGender(data.data),
                        genderAge: transformGenderAge(data.data, query, filterMapping, options),
                        income: transformIncome(data.data),
                        ethnicity: transformEthnicity(data.data)
                    };
                    return $q.all(Object.values(dataParts))
                        .then((resultsArr) => _.fromPairs(_.zip(Object.keys(dataParts), resultsArr)))
                        .then(transformDataByTypeToDataByDistSkew)
                        .then((transformedData) => Object.assign(transformedData, {status: data.status}));
                });

                transformedDataPromise.cancel = rawDataPromise.cancel;
                return transformedDataPromise;
            }

            function getDemographicsDataForPreviewBySegment (segment, options = {}) {
                let query, filterMapping;
                switch (options.channel) {
                    case 'linkedin':
                        query = segment;
                        break;
                    default:
                        query = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, options.channel, $rootScope.Geos.geos);
                        filterMapping = audienceSegmentBuilderHelper.convertAudienceSegmentToFilterMapping(segment, options.channel);
                        break;
                }
                return getDemographicsDataForPreviewByLogicalStatement(query, filterMapping, options);
            }

            function getDemographicsDataForPreviewByLogicalStatement (query, filterMapping, options = {}) {
                let rawDataPromise;
                switch (options.channel) {
                    case 'linkedin':
                        rawDataPromise = getLinkedinAudienceDistribution(query, options);
                        break;
                    default:
                        rawDataPromise = getRawDemographicsDataByLogicalStatement(query, filterMapping, options);
                        break;
                }
                const transformedDataPromise = rawDataPromise.then((res) => {
                    if (!(res && res.data) || res.status !== 'ok') return res;

                    let dataParts;
                    switch (options.channel) {
                        case 'linkedin':
                            dataParts = {
                                audienceSize: {population: res.data.total},
                                gender: {distribution: _.fromPairs(_.map(formatDataToPercent(res.data.gender, false), (o) => Object.entries(o)[0]))},
                                age: {distribution: sortByLabelsNum(formatDataToPercent(res.data.age))}
                            };
                            break;
                        default:
                            dataParts = {
                                audienceSize: transformAudienceSize(res.data),
                                gender: transformGender(res.data),
                                age: transformAge(res.data)
                            };
                            break;
                    }

                    return $q.all(Object.values(dataParts))
                        .then((resultsArr) => _.fromPairs(_.zip(Object.keys(dataParts), resultsArr)))
                        .then(transformDataByTypeToDataByDistSkew)
                        .then((transformedData) => Object.assign(transformedData, {status: res.status}));
                });

                transformedDataPromise.cancel = rawDataPromise.cancel;
                return transformedDataPromise;
            }

            function getDemographicsDataByKeywordsAndCountries (keywords, countries, options = {}) {
                if(_.isEmpty(countries)) countries = audienceSegmentBuilderHelper.geoByChannel(null, options.channel, $rootScope.Geos.geos);
                let query = ['and', {keywords}];
                if (countries.length) query.push(['or', ...countries.map((country) => ({countries: country.toLowerCase()}))]);
                return getDemographicsDataForPreviewByLogicalStatement(query, null, options);
            }

            function transformDataByTypeToDataByDistSkew (data) {
                let accObj = {distribution: {}, skew: {}};
                for (let prop in data) {
                    const {distribution, skew} = data[prop];
                    if (distribution) accObj.distribution[prop] = distribution;
                    if (skew) accObj.skew[prop] = skew;
                    if (!distribution && !skew) accObj[prop] = data[prop];
                }
                return accObj
            }

            function getGenderSpecificRawDemographicsDataByQuery (query, filterMapping, gender, options = {}) {
                const queryForTheSpecificGender = ['and', query, {genders: gender}];
                const cachePromise = false;
                return getRawDemographicsDataByLogicalStatement(queryForTheSpecificGender, filterMapping, Object.assign({cachePromise}, options));
            }

            // demographics: utility fns

            function roundSegmentRatio (ratio) {
                ratio = +(ratio * 100).toFixed(2);
                return ratio < 0.1 ? Math.max(ratio, 0.01) : parseFloat(ratio.toFixed(1));
            }

            // * data properties transform functions

            const formatDist = (value) => `${Math.round(value)}%`;
            const formatSkew = (value) => value.toFixed(1);
            const sortByLabelsNum = (ageObject) => _.sortBy(ageObject, (o) => +o.label.split('-')[0]);

            function transformAudienceSize (data) {
                const segmentSize = roundSegmentRatio(data['intenders-ratio-in-geo']),
                    population = parseInt(data['segment-size-in-geo'] * 1000000);
                const NUM_OF_TICKS = 15, PERCENTS_ACTUALLY_PER_TICK = 100 / NUM_OF_TICKS,
                    PERCENTS_WANTED_PER_TICK = 1,
                    MIN_TICKS = 4, MIN_TICKS_PERCENTS = 0.5,
                    MIN_TICKS_PERCENTS_ADDITION = MIN_TICKS * PERCENTS_ACTUALLY_PER_TICK;
                const segmentGaugeBarPercents = (segmentSize - Math.min(segmentSize, MIN_TICKS_PERCENTS)) * PERCENTS_ACTUALLY_PER_TICK / PERCENTS_WANTED_PER_TICK + MIN_TICKS_PERCENTS_ADDITION;
                return {segmentSize, population, segmentGaugeBarPercents};
            }

            function transformGender (data) {
                const {male: maleDist, female: femaleDist} = data['gender-distribution'];
                const {male: maleSkew, female: femaleSkew} = data['gender-skew'];
                return {
                    distribution: {
                        male: {value: maleDist, displayValue: formatDist(maleDist * 100)},
                        female: {value: femaleDist, displayValue: formatDist(femaleDist * 100)}
                    },
                    skew: {
                        male: {value: +formatSkew(maleSkew), displayValue: formatSkew(maleSkew)},
                        female: {value: +formatSkew(femaleSkew), displayValue: formatSkew(femaleSkew)}
                    }
                };
            }

            function transformGenderAge (data, query, filterMapping, options) {
                const {male: hasMaleDist, female: hasFemaleDist} = data['gender-distribution'];

                let maleData, femaleData;
                if (hasMaleDist && hasFemaleDist) {
                    [maleData, femaleData] = ['male', 'female'].map((gender) =>
                        getGenderSpecificRawDemographicsDataByQuery(query, filterMapping, gender, options).then((r) => r.status === 'ok' ? r.data : data));
                } else { // if only one gender has data (and not both) then this gender is 100% and no need to make another query to server
                    const emptyAgeObj = _.fromPairs(_.map(data['age-distribution'], (v, k) => [k, 0]));
                    const emptyAgeData = Object.assign({}, data, {'age-distribution': emptyAgeObj, 'age-skew': emptyAgeObj});
                    [maleData, femaleData] = hasMaleDist ? [data, emptyAgeData] : [emptyAgeData, data];
                }

                return $q.all([maleData, femaleData]).then(([maleData, femaleData]) => {
                    const maleDistributionValues = Object.values(maleData['age-distribution']).map((p) => p * 100 * data['gender-distribution']['male']);
                    const femaleDistributionValues = Object.values(femaleData['age-distribution']).map((p) => p * 100 * data['gender-distribution']['female']);
                    const bothNormalizedDist = common.roundPercents(maleDistributionValues.concat(femaleDistributionValues));

                    const ages = Object.keys(data['age-distribution']);
                    const dist = ages.map((age, i) => {
                        const male = bothNormalizedDist[i], female = bothNormalizedDist[i + ages.length];
                        return {label: age, male: {value: male, displayValue: formatDist(male)}, female: {value: female, displayValue: formatDist(female)}};
                    });
                    const skew = ages.map((age) => {
                        const male = formatSkew(maleData['age-skew'][age] * data['gender-skew'].male),
                            female = formatSkew(femaleData['age-skew'][age] * data['gender-skew'].female);
                        return {label: age, male: {value: +male, displayValue: male}, female: {value: +female, displayValue: female}};
                    });

                    return {distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew)};
                });
            }

            function transformAge (data) {
                const distValues = common.roundPercents(Object.values(data['age-distribution']).map((v) => v * 100));
                const dist = Object.keys(data['age-distribution']).map((age, i) => ({
                    label: age, value: distValues[i], displayValue: formatDist(distValues[i])
                }));
                const skew = _.map(data['age-skew'], (value, label) => ({
                    label, value: +formatSkew(value), displayValue: formatSkew(value)
                }));
                return {distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew)};
            }

            function transformIncome (data) {
                const allowedIncomes = ['0-25k', '25-50k', '50-75k', '75-100k', '100-150k', '150-200k', '200k+'];
                return {
                    distribution: sortByLabelsNum(_.map(
                        _.pick(data['income-distribution'], allowedIncomes),
                        (value, label) => ({label: label.toUpperCase(), value: value * 100, displayValue: formatDist(value * 100)})
                    )),
                    skew: sortByLabelsNum(_.map(
                        _.pick(data['income-skew'], allowedIncomes),
                        (value, label) => ({label: label.toUpperCase(), value: +formatSkew(value), displayValue: formatSkew(value)})
                    ))
                };
            }

            function transformEthnicity (data) {
                let {"race-distribution": ethnicityDist, "race-skew": ethnicitySkew} = data;

                const raceMapping = {
                    white: "Caucasian", asian: "Asian American", hispanic: "Hispanic", black: "African American",
                    chinese: "Chinese", indian: "Indian", malay: "Malay", other: "Others"
                };
                const ethnicities = Object.keys(ethnicityDist).map((key) => raceMapping[key]);
                const distValues = common.roundPercents(Object.values(ethnicityDist).map((v) => v * 100));
                const skewValues = Object.values(ethnicitySkew).map(formatSkew);

                ethnicityDist = _.zip(ethnicities, distValues).map(([label, value]) => ({label, value: +value, displayValue: formatDist(value)}));
                ethnicitySkew = _.zip(ethnicities, skewValues).map(([label, value]) => ({label, value: +value, displayValue: value}));

                ethnicityDist = _.orderBy(ethnicityDist, 'value', 'desc');
                const ethnicitySkewByLabel = _.keyBy(ethnicitySkew, (o) => o.label);
                ethnicitySkew = _.compact(Object.values(raceMapping).map((l) => ethnicitySkewByLabel[l])); // order like in +raceMapping+. alphabetically in the future.
                return {distribution: ethnicityDist, skew: ethnicitySkew};
            }

            function fixAgeLegal (data, dataType = 'distribution') {
                const key = `age-${dataType}`;
                if (data && data[key]) data[key] = Object.assign({'13-17': data[key]['12-17']}, _.omit(data[key], ['2-11', '12-17']));
                return data
            }

            function formatDataToPercent (data, labelIsKey = true) {
                const valSum = _.sum(Object.values(data));
                const percentValues = common.roundPercents(Object.values(data).map((val) => (val === 0) ? val : (val * 100 / valSum)));
                return Object.keys(data).map((label, i) => {
                    const valsObject = {value: percentValues[i], displayValue: formatDist(percentValues[i])};
                    return labelIsKey ? Object.assign({label: label}, valsObject) : {[label]: valsObject};
                });
            }

            // insights: service methods

            function getRawInterestsDataV1 (reqData) {
                const reqDefaultsBySearchType = {
                    "wiki": {"wiki-data-entities": 10},
                    "td": {"added-topics-number": 1400, "ensure-topics": 40},
                    "frequent-keywords": {"ensure-topics": 40, "ensure-topics2": 1, "added-topics-number": 1000},
                    "xw": {"ensure-topics": 40, "ensure-topics2": 1, "added-topics-number": 1400},
                    "gsw": {"ensure-topics": 40, "ensure-topics2": 1, "added-topics-number": 1400},
                    "tv": {"added-topics-number": 1400},
                    "genres": {"added-topics-number": 1400},
                    "networks": {"added-topics-number": 1400}
                };
                reqData = Object.assign({}, reqDefaultsBySearchType[reqData['search-type']], reqData);

                return $http({
                    url: AUDIENCE_INSIGHTS_URL_V1,
                    method: 'POST',
                    cache: true,
                    data: reqData
                }).then((res) => {
                    if (!res) return false;
                    if (res.status !== 200) return;

                    let data = angular.copy(res.data);

                    // fix age legal
                    if (res.data['users-sample']) res.data['users-sample'].forEach((user) => {if (user.age === '12-17') user.age = '13-17'});
                    for (let dataType of ['distribution', 'skew'])
                        data = fixAgeLegal(data, dataType);

                    // data size errors
                    if (data.status === 'insufficient data' || data.status === 'segment too wide') return data;

                    // make "words"/"brands" uniform as "words"
                    if (reqData.isSports) data.words = data.brands;

                    // make "segment-proportion-heuristic"/"intenders-ratio-in-geo" uniform as "intenders-ratio-in-geo" and normalize its value
                    data['intenders-ratio-in-geo'] = roundSegmentRatio(data['segment-proportion-heuristic'] || data['intenders-ratio-in-geo']);

                    return data;
                });
            }

            function getRawInterestsDataV2 (searchType, reqData, uriSuffix = '') {
                return $http({
                    url: AUDIENCE_INSIGHTS_URL_V2 + searchType + uriSuffix,
                    method: 'POST',
                    cache: true,
                    data: reqData
                }).then((res) => {
                    if (!res) return false;
                    if (res.status !== 200) return;

                    const body = angular.copy(res.data);
                    if (body.status === 'ok') {
                        // fix age legal
                        if (body.data['users-sample']) body.data['users-sample'].forEach((user) => {if (user.age === '12-17') user.age = '13-17'});
                        for (let dataType of ['distribution', 'skew'])
                            body.data = fixAgeLegal(body.data, dataType);

                        // make "words"/"brands" uniform as "words"
                        if (reqData.isSports) body.data.words = body.data.brands;

                        // make "segment-proportion-heuristic"/"intenders-ratio-in-geo" uniform as "intenders-ratio-in-geo" and normalize its value
                        if (body.data['segment-proportion-heuristic'] || body.data['intenders-ratio-in-geo'])
                            body.data['intenders-ratio-in-geo'] = roundSegmentRatio(body.data['segment-proportion-heuristic'] || body.data['intenders-ratio-in-geo']);
                    }
                    return body.data;
                });
            }

            function getInterestsDataV1 (reqData, {preventCaching = false, minKl = 1.3, fetchTextPhrase = true} = {}) {
                const reqCacheKey = JSON.stringify(reqData);
                if (reqCacheKey === lastReqCacheKey) return lastReqPromise;

                const segmentPropertiesCacheKey = JSON.stringify(_.omit(reqData, 'search-type'));
                if (segmentPropertiesCacheKey !== segmentPropertiesReqs['cache']) segmentPropertiesReqs = {cache: segmentPropertiesCacheKey};
                if (segmentPropertiesReqs[reqData['search-type']]) return segmentPropertiesReqs[reqData['search-type']];

                const promise = getRawInterestsDataV1(reqData).then((data) => {
                        if (!data) return data; // false if no res, undefined if data size error, Object if OK
                        const convertKIdToPhrase = fetchTextPhrase && !['new-topics', 'td', 'tv', 'genres', 'networks', 'pnws'].includes(reqData['search-type']) && !reqData['only-phrase-id'];
                        return (convertKIdToPhrase ? getPhrasesByPhrasesIds((data.words || data.phrases || []).concat(data.brands || []), null, minKl) : $q.resolve())
                            .then(() => Object.assign(data, {words: formatAndFilterAudienceDataWords(data.words || data.phrases || [], convertKIdToPhrase)}));
                    }, responseFailed(true)
                );

                if (!preventCaching) {
                    lastReqCacheKey = reqCacheKey;
                    lastReqPromise = promise;
                    if (['xw', 'td', 'frequent-keywords'].includes(reqData['search-type']))
                        segmentPropertiesReqs[reqData['search-type']] = promise;
                }
                return promise;
            }

            function getInterestsDataV2 (searchType, reqData, {preventCaching = false, minKl = 1.3, fetchTextPhrase = true} = {}, uriSuffix = '') {
                const reqCacheKey = JSON.stringify(reqData);
                if (reqCacheKey === lastReqCacheKey) return lastReqPromise;

                const segmentPropertiesCacheKey = JSON.stringify(reqData);
                if (segmentPropertiesCacheKey !== segmentPropertiesReqs['cache']) segmentPropertiesReqs = {cache: segmentPropertiesCacheKey};
                if (segmentPropertiesReqs[searchType]) return segmentPropertiesReqs[searchType];

                const promise = getRawInterestsDataV2(searchType, reqData, uriSuffix).then((data) => {
                        if (!data) return data; // false if no res, undefined if data size error, Object if OK
                        const convertKIdToPhrase = fetchTextPhrase && !['topics', 'domains', 'tv'].includes(searchType);
                        return (convertKIdToPhrase ? getPhrasesByPhrasesIds((data.words || data.phrases || []).concat(data.brands || []), null, minKl) : $q.resolve())
                            .then(() => Object.assign(data, {words: formatAndFilterAudienceDataWords(data.words || data.interests || [], convertKIdToPhrase)}));
                    }, responseFailed(true)
                );

                if (!preventCaching) {
                    lastReqCacheKey = reqCacheKey;
                    lastReqPromise = promise;
                    if (['keywords', 'td', 'frequent-keywords'].includes(searchType))
                        segmentPropertiesReqs[searchType] = promise;
                }

                return promise;
            }

            function getSegmentInterestsData (segment, searchType, channel, extraParams, opts) {
                return getInterestsDataV1(getSegmentParamsV1(segment, searchType, channel, extraParams), opts);
            }

            function getSegmentInterestsDataByIds (segment, searchType, channel, interestIds, extraParams, extraOpts) {
                const params = Object.assign(getSegmentParamsV2(segment, searchType, channel, extraParams), {ids: interestIds});
                const opts = Object.assign({minKl: 0, fetchTextPhrase: false}, extraOpts);
                return getInterestsDataV2(searchType, params, opts);
            }

            function getSegmentAdvancedInterestsDataByQueries (segment, searchType, channel, advanceInterest, extraParams, extraOpts) {
                const params = Object.assign(getSegmentParamsV2(segment, searchType, channel, extraParams), {queries: advanceInterest});
                const opts = Object.assign({minKl: 0, fetchTextPhrase: false}, extraOpts);
                return getInterestsDataV2(searchType, params, opts, '/advanced');
            }

            function getTvInfo(segment, channel, withNetworks) {
                const promises = [];
                promises.push(getSegmentInterestsData(segment, 'tv', channel).then(res => {
                    const shows = res.words;
                    if (_.isEmpty(shows)) return _.extend(res, {status: ((res.status === 'ok' || !_.has(res, 'status')) ? 'noData' : res.status)});
                    newTvService.getGenres();
                    return tvService.getShowsByids(shows.map(show => show['phrase-id'])).then(shows => {
                        res.words.forEach(word => {
                            const show = shows[word['phrase-id']];
                            if (show) Object.assign(word, _.pick(show, ['title', 'image', 'genre', 'summary', 'network']));
                        });
                        return res.words.filter((word) => word.title);
                    });
                }));
                promises.push(getSegmentInterestsData(segment, 'genres', channel).then(res => {
                    const genres = res.words;
                    if (_.isEmpty(genres)) return _.extend(res, {status: ((res.status === 'ok' || !_.has(res, 'status')) ? 'noData' : res.status)});
                    return _.map(genres, genre => _.extend(genre, {title: genre.phrase}));
                }));
                if (withNetworks) {
                    promises.push(getSegmentInterestsData(segment, channel === 'articles' ? 'networks' : 'pnws', channel).then(res => {
                        const networks = res.words;
                        if (_.isEmpty(networks)) return _.extend(res, {status: ((res.status === 'ok' || !_.has(res, 'status')) ? 'noData' : res.status)});
                        if (channel !== 'linear_tv') return _.map(networks, network => _.extend(network, {title: network.phrase}));
                        return tvService.getNielsenNetworksMap().then(networksMap => {
                            networks.forEach(network => {
                                const networkLabel = networksMap[network['phrase-id']];
                                if (networkLabel) Object.assign(network, { title: networkLabel });
                            });
                            return networks.filter((network) => network.title);
                        });
                    }));
                }
                return $q.all(promises).then(([shows, genres, networks]) => {
                    const resulWithStatus = _.find(_.compact([shows, genres, networks]), type => _.has(type, 'status'));
                    return (_.isEmpty(resulWithStatus)) ? _.omitBy({shows, genres, networks}, _.isUndefined) : resulWithStatus;
                });
            }

            function getPhrasesIndexInLifestyle (segment, phrases, channel) {
                if (segment && phrases && phrases.length)
                    return getSegmentInterestsData(segment, "xw", channel, {
                        'words-sample-size': audienceSkewService.DEFAULTS['words-sample-size'],
                        'added-topics-number': 0,
                        'only-phrase-id': true,
                        'pre-chosen-words': phrases
                    }).then((audienceData) => {
                        return audienceData.words.reduce((phraseToCompIndex, phrase) => {
                            phraseToCompIndex[(phrase["phrase-id"]) + ""] = phrase["uniqueness-index"];
                            return phraseToCompIndex;
                        }, {})
                    });
                else
                    return $q.resolve();
            }

            function getSegmentPromise (segmentType, segment, channel) {
                return segmentPropertiesReqs[segmentType] || getSegmentInterestsData(segment, segmentType, channel)
            }

            function getSegmentIds(segment, channel, sampleSize = null) {
                const isBidStream = channel === 'articles';
                const isIP = channel === 'smart_tv';
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, channel, $rootScope.Geos.geos, null, isBidStream);
                const filterMapping = audienceSegmentBuilderHelper.convertAudienceSegmentToFilterMapping(segment, channel);
                const params = {
                    filter1: logicalStatement,
                    ...(filterMapping && {'filter-mapping': filterMapping}),
                    ...isIP && { fields: ['ip'] },
                    ...sampleSize && { 'sample-size': sampleSize}
                };
                const promise = AudienceService.getSegmentIds(params);

                return promise.then((res) => {
                    if (!res) return false;
                    if (res.status && res.status !== 200) return;

                    return _.map(res.sample, obj => (isIP && obj.hasOwnProperty('ip')) ? obj['ip'] : obj['_id']);
                });
            }

            function createAudienceTargetTaxonomy(segmentId, channel, marketId) {
                const promise = AudienceService.createAudienceTargetTaxonomy(segmentId, channel, marketId);
                return promise.then(res => {
                    if (!res) return false;

                    return res.status;
                })
            }

            function createAudienceTargetUserList(segmentId, channel, ids) {
                const promise = AudienceService.createAudienceTargetUserList(segmentId, channel, ids);
                return promise.then(res => {
                    if (!res) return false;

                    return res.status;
                })
            }

            function getAudienceTaxonomyCategory(segmentId) {
                const promise = AudienceService.getAudienceTaxonomyCategory(segmentId);
                return promise.then(res => {
                    if (!res) return false;

                    return res.taxonomy_category_id;
                })
            }

            // insights: utility fns

            function getSegmentParamsV1 (segment, searchType, channel, extraParams) {
                const isTv = TV_SEARCH_TYPES.includes(searchType);
                const DEFAULT_PARAMS = {'users-sample-size': audienceSkewService.DEFAULTS['users-sample-size'], 'words-sample-size': 1000};
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, channel, $rootScope.Geos.geos, isTv);
                const filterMapping = audienceSegmentBuilderHelper.convertAudienceSegmentToFilterMapping(segment, channel);
                const referenceGroup = getReferenceGroup(segment, isTv, channel);
                const referenceGroupInObj = referenceGroup && {filter2: referenceGroup};
                return Object.assign(DEFAULT_PARAMS, {filter1: logicalStatement, 'search-type': searchType, ...(filterMapping && {'filter-mapping': filterMapping})}, referenceGroupInObj, extraParams);
            }

            function getSegmentParamsV2 (segment, searchType, channel, extraParams) {
                const isTv = TV_SEARCH_TYPES.includes(searchType);
                const DEFAULT_PARAMS = {'users-sample-size': audienceSkewService.DEFAULTS['users-sample-size']};
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, channel, $rootScope.Geos.geos, isTv);
                const referenceGroup = getReferenceGroup(segment, isTv, channel);
                const referenceGroupInObj = referenceGroup && {"reference-segment-filter": referenceGroup};
                return Object.assign(DEFAULT_PARAMS, {"segment-filter": logicalStatement}, referenceGroupInObj, extraParams);
            }

            function formatAndFilterAudienceDataWords (words, convertKIdToPhrase = false) {
                words.forEach((word) => {
                    if (convertKIdToPhrase) {
                        const wordsArr = (word['phrase'] || '').toLowerCase().split(' ').sort();
                        word['longest-term-check'] = {words_arr: wordsArr, num_of_words: wordsArr.length};
                    } else {
                        word['phrase'] = word['id'] || word['phrase-id'];
                    }
                    // transform to percents
                    word['interest-portion'] = word['interest-portion'] * 100;
                    word['segment-portion'] = word['segment-portion'] * 100;
                });

                words = words.filter((word) => word['phrase'] && word['topic'] !== 'Sensitive Content');
                if (convertKIdToPhrase) words = deleteContainedPhrases(words);

                return words;
            }

            function getPhrasesByPhrasesIds (phrases, brands, minKl = 1.3) {
                if (_.isEmpty(phrases) && _.isEmpty(brands))
                    return $q.resolve();
                return keywordsService.get_kwds_by_ids(phrases.concat(brands || []).map((p) => p['id'] || p['phrase-id']), minKl)
                    .then((res) => { phrases.forEach((p) => {p['phrase'] = res[p['id'] || p['phrase-id']]}) });
            }

            function getReferenceGroup (segment, isTv, channel) {
                const demographics = segment.filter((segment) => segment.type === "demographics")[0];
                const isFirstParty = _.some(segment, {type: '1st party'});
                const geos = audienceSegmentBuilderHelper.geoByChannel(demographics && demographics.geo, channel, $rootScope.Geos.geos, isFirstParty);
                const demoRefGroup = geos && geos.length ? ["or", ...geos.map((geo) => ({countries: geo}))] : undefined;
                const tvRefGroup = isTv && !TV_CHANNELS.includes(channel) ? {"any-tv": "yes"} : undefined;
                const refGroup = _.compact([demoRefGroup, tvRefGroup]);
                if (refGroup && refGroup.length) return ["and", ...refGroup];
            }

            function deleteContainedPhrases (phrases) {

                function markIfContain (a1, a2) {
                    let i = 0, j = 0;
                    while (i < a1['words_arr'].length && j < a2['words_arr'].length) {
                        let compare = a1['words_arr'][i].localeCompare(a2['words_arr'][j]);
                        if (compare === 1) return;
                        if (compare === 0) j++;
                        i++;
                    }
                    if (j < a2['words_arr'].length) return;
                    a2['contained'] = true;
                }

                const MIN_DEST_BETWEEN_SIMILAR_TERMS = 20;
                if (phrases.length <= MIN_DEST_BETWEEN_SIMILAR_TERMS) return phrases;
                for (let i = 0; i < phrases.length; i++) {
                    let lastIndex = Math.min(i + MIN_DEST_BETWEEN_SIMILAR_TERMS, phrases.length);
                    for (let j = i + 1; j < lastIndex; j++) {
                        let phrase1 = phrases[i]['longest-term-check'];
                        let phrase2 = phrases[j]['longest-term-check'];
                        if (phrase1['num_of_words'] !== phrase2['num_of_words']) continue;
                        phrase1['num_of_words'] > phrase2['num_of_words'] ? markIfContain(phrase1, phrase2) : markIfContain(phrase2, phrase1);
                    }
                }
                return phrases.filter((phrase) => !phrase['longest-term-check']['contained']);
            }

            // user history: service method

            function getUserHistory (segment, userId, channel) {
                const segmentPropertiesPromise = getSegmentProperties(segment, channel);
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, channel, $rootScope.Geos.geos);
                return segmentPropertiesPromise.then(function (segmentProperties) {
                    return $http({
                        url: AUDIENCE_USER_HISTORY_URL,
                        method: 'POST',
                        cache: true,
                        data: {"highlight-filters": segmentProperties, "filter1": logicalStatement, "user-id": userId, "allow-porn": false}
                    }).then(responseSuccess, responseFailed(true));
                })
            }

            function getSegmentProperties (segment, channel) {
                const searchesSearchType = TV_CHANNELS.includes(channel) ? 'tv' : _.some(segment, {type: '1st party'}) ? QUERY_NAMES.searchesBidStream : QUERY_NAMES.searches;
                const searchesPromise = getSegmentPromise(searchesSearchType, segment, channel);
                const domainsPromise = getSegmentPromise(TV_CHANNELS.includes(channel) ? 'tv' : QUERY_NAMES.websites, segment, channel);
                return $q.all([searchesPromise, domainsPromise]).then(([searches, domains]) => ({
                    searches: getSegmentMostCommonKeywords(searches), domains: getSegmentMostCommonKeywords(domains)
                }));
            }

            // user history: utility fns

            function getSegmentMostCommonKeywords (data) {
                if (!data || !data.words) return [];
                const topByCompositionIndex = _.sortBy(data.words, 'uniqueness-index').reverse().slice(0, 30);
                return _.uniq(topByCompositionIndex.concat(topByCompositionIndex).map((word) => word['id'] || word['phrase-id']));
            }

            // other service methods and utility fns

            const REQUEST_STATUS_CANCEL = -1;

            function isRequestCancelled (data) {
                return data === REQUEST_STATUS_CANCEL;
            }

            function responseSuccess (res) {
                return res ? res.data : false;
            }

            function responseFailed (showErrorMsg) {
                return function (err) {
                    if (err && err.config && err.config.timeout && err.config.timeout.$$state && err.config.timeout.$$state.value === "AMOBEE_CANCEL_OK")
                        return REQUEST_STATUS_CANCEL;

                    if (false && showErrorMsg) errorMgmt.widgetServiceError('Audience', err);

                    return false
                }
            }
        }]
    );