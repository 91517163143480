'use strict';

module.exports = {

    //trends
    trends_web_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', tooltip: 'Phrase', ellipsis: true, flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'reach', title: 'Estimated Volume', tooltip: 'Estimated Volume'},
            {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'},
            {id: 't', title: 'Topic', tooltip: 'Topic', ellipsis: true},
            {id: 'specifity', title: 'Specificity score', tooltip: 'Specificity score'}
        ],
        sortCB: 'mixpanelTrackSort',
        largeHeader: true
    },

    trends_sg_telco_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', tooltip: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'reach', title: 'Estimated Volume', tooltip: 'Estimated Volume'},
            {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'},
            {id: 't', title: 'Topic', tooltip: 'Topic', ellipsis: true},
            {id: 'specifity', title: 'Specificity score', tooltip: 'Specificity score'}
        ],
        largeHeader: true
    },

    trends_au_telco_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', tooltip: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'reach', title: 'Estimated Volume', tooltip: 'Estimated Volume'},
            {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'},
            {id: 't', title: 'Topic', tooltip: 'Topic', ellipsis: true},
            {id: 'specifity', title: 'Specificity score', tooltip: 'Specificity score'}
        ],
        largeHeader: true
    },

    trends_social_phrases: {
        columns:[

                {id: 'phrase', title: 'Phrase', tooltip: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
                {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
                {id: 'relevancy', title: 'Relevancy Score', tooltip: 'Relevancy Score'},
                {id: 'mentions', title: 'Mutual Mentions', tooltip: 'Mutual Mentions'},
                {id: 'rt_count', title: 'Retweets', tooltip: 'Retweets'},
                {id: 'mentions_per_day', title: 'Estimated Volume', tooltip: 'Estimated Volume'},
                {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'},
                {id: 'specifity', title: 'Specificity score', tooltip: 'Specificity score'},
                {id: 'top_topic', title: 'Topic', tooltip: 'Topic', ellipsis: true},
                {id: 'net_sent', title: 'Net Sentiment', tooltip: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
            ],
            largeHeader: true
        },

    trends_social_hashtags: {
        columns:[
            {id: 'hashtag', title: 'Hashtag', tooltip: 'Hashtag', flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'mentions', title: 'Mutual Mentions', tooltip: 'Mutual Mentions'},
            {id: 'rt_count', title: 'Retweets', tooltip: 'Retweets'},
            {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'},
            {id: 'net_sent', title: 'Net Sentiment', tooltip: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
        ],
        largeHeader: true
    },

    trends_social_influencers: {
        columns:[
            {id: 'handle', title: 'Handle', tooltip: 'Handle', flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'mentions', title: 'Mutual Mentions', tooltip: 'Mutual Mentions'},
            {id: 'retweets', title: 'Retweets', tooltip: 'Retweets'},
            {id: 'followers', title: 'Followers', tooltip: 'Followers'},
            {id: 'klout_score', title: 'Klout Score', tooltip: 'Klout Score'},
            {id: 'net_sent', title: 'Net Sentiment', tooltip: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
        ],
        largeHeader: true
    },

    trends_social_audience:  {
        columns: [
            {id: 'handle', title: 'Handle', tooltip: 'Handle', flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'seed', title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'mentions', title: 'Mutual Mentions', tooltip: 'Mutual Mentions'},
            {id: 'retweets', title: 'Retweets', tooltip: 'Retweets'},
            {id: 'followers', title: 'Followers', tooltip: 'Followers'},
            {id: 'klout_score', title: 'Klout Score', tooltip: 'Klout Score'},
            {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
        ],
        largeHeader: true
    },

    trends_social_emojis:  {
        columns: [
            {id: 'id', title: 'Emoji', tooltip: 'Emoji', template: 'partials/emoji.partial.html'},
            {id: 'seed', search:true, title: 'Seed', tooltip: 'Seed', ellipsis: true},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'mentions', title: 'Mutual Mentions', tooltip: 'Mutual Mentions'},
            {id: 'retweets', title: 'Retweets', tooltip: 'Retweets'},
            {id: 'net_sent', title: 'Net Sentiment', tooltip: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},
            {id: 'eid', title: 'Name', tooltip: 'Name', ellipsis: true}
        ],
        largeHeader: true
    },

    trends_facebook_interests:{
        columns:[
            {id: 'id', title: 'Interest', tooltip: 'Interest', flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'estimated_reach', title: 'Estimated reach', tooltip: 'Estimated reach'},
            {id: 'facebook_category', title: 'Facebook category', tooltip: 'Facebook category', ellipsis: true},
            {id: 'relevancy', title: 'Relevancy Score', tooltip: 'Relevancy Score'},
            {id: 'velocity', title: 'Velocity', tooltip: 'Velocity'}
        ],
        largeHeader: true
    },

    trends_facebook_pages:{
        columns:[
            {id: 'name', title: 'Name', tooltip: 'Name', flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'score', title: 'Total Score', tooltip: 'Total Score'},
            {id: 'shares', title: 'Shares', tooltip: 'Shares'},
            {id: 'likes', title: 'Likes', tooltip: 'Likes'},
            {id: 'comments', title: 'Comments', tooltip: 'Comments', ellipsis: true},
            {id: 'top_topic', title: 'Topic', tooltip: 'Topic', ellipsis: true}
        ],
        largeHeader: true
    },

    //audience interests
    audience_interests_web_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', tooltip: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', tooltip: 'Audience Name', flex:'0-7', ellipsis: true},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'Consumption In Audience', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'Audience Portion in Consumption', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'Audience Skew', flex:'0-7'},
            {id: 'score', title: 'Significance Score', tooltip: 'Significance Score'},
            {id: 'topic', title: 'Topic', tooltip: 'Topic', flex:'0-7', ellipsis: true}
        ],
        order: 'interest-portion',
        reverse: true,
        largeHeader: true
    },

    audience_interests_data_spark_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', flex:'0-7'},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'Consumption In Audience', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'Audience Portion in Consumption', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', flex:'0-7'},
            {id: 'score', title: 'Significance Score'},
            {id: 'topic', title: 'Topic', flex:'0-7'}
        ],
        order: 'interest-portion',
        reverse: true
    },

    audience_interests_au_telco_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', flex:'0-7'},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'Consumption In Audience', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'Audience Portion in Consumption', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', flex:'0-7'},
            {id: 'score', title: 'Significance Score'},
            {id: 'topic', title: 'Topic', flex:'0-7'}
        ],
        largeHeader: true
    },

    audience_interests_smart_tv_phrases: {
        columns: [
            {id: 'phrase', title: 'Phrase', tooltip: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', tooltip: 'Audience Name', flex:'0-7', ellipsis: true},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'Consumption In Audience', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'Audience Portion in Consumption', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'Audience Skew', flex:'0-7'},
            {id: 'score', title: 'Significance Score', tooltip: 'Significance Score'},
            {id: 'topic', title: 'Topic', tooltip: 'Topic', flex:'0-7', ellipsis: true}
        ],
        order: 'interest-portion',
        reverse: true,
        largeHeader: true
    },


    //inventory_lists
    inventory_lists_web_websites: {
        columns: [
            {id: 'phrase', title: 'Website', tooltip: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', tooltip: 'Audience Name', flex:'0-7', ellipsis: true},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'How popular the interest is among the audience. 100% means everyone in the audience visits the website', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'How unique the interest is to the audience. 100% means only this audience visits the website', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'The extent to which the interest is over-indexed within the audience', flex:'0-7'},
            {id: 'score', title: 'Significance Score', tooltip: 'Significance Score'},
            {id: 'topic', title: 'Topic', tooltip: 'Topic', flex:'0-7', ellipsis: true}
        ],
        order: 'interest-portion',
        reverse: true,
        largeHeader: true
    },

    inventory_lists_data_spark_websites: {
        columns: [
            {id: 'phrase', title: 'Website', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', flex:'0-7'},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'How popular the interest is among the audience. 100% means everyone in the audience visits the website', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'How unique the interest is to the audience. 100% means only this audience visits the website', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'The extent to which the interest is over-indexed within the audience', flex:'0-7'},
            {id: 'score', title: 'Significance Score'},
            {id: 'topic', title: 'Topic', flex:'0-7'}
        ],
        order: 'interest-portion',
        reverse: true
    },

    inventory_lists_au_telco_websites: {
        columns: [
            {id: 'phrase', title: 'Website', tooltip: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
            {id: 'audience_name', title: 'Audience Name', tooltip: 'Audience Name', flex:'0-7', ellipsis: true},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'How popular the interest is among the audience. 100% means everyone in the audience visits the website', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'How unique the interest is to the audience. 100% means only this audience visits the website', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'The extent to which the interest is over-indexed within the audience', flex:'0-7'},
            {id: 'score', title: 'Significance Score', tooltip: 'Significance Score'},
            {id: 'topic', title: 'Topic', tooltip: 'Topic', flex:'0-7', ellipsis: true}

        ],
        largeHeader: true
    },

    inventory_lists_smart_tv_websites: {
        columns: [
            {id: 'phrase', title: 'Website', tooltip: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
            {id: 'audience_name', title: 'Audience Name', tooltip: 'Audience Name', flex:'0-7', ellipsis: true},
            {id: 'interest-portion', title: 'Consumption In Audience', tooltip: 'How popular the interest is among the audience. 100% means everyone in the audience visits the website', template: 'partials/interest-portion.partial.html'},
            {id: 'segment-portion', title: 'Audience Portion in Consumption', tooltip: 'How unique the interest is to the audience. 100% means only this audience visits the website', flex:'1-5', template: 'partials/segment-portion.partial.html'},
            {id: 'uniqueness-index', title: 'Audience Skew', tooltip: 'The extent to which the interest is over-indexed within the audience', flex:'0-7'},
            {id: 'score', title: 'Significance Score', tooltip: 'Significance Score'},
            {id: 'topic', title: 'Topic', tooltip: 'Topic', flex:'0-7', ellipsis: true}
        ],
        order: 'interest-portion',
        reverse: true,
        largeHeader: true
    },



    //for modal edit target
    trends: {
        title: 'items',
        dataArray: [],
        order: 'name',
        loading: true,
        largeHeader: true
    },

    dynamic: {
        title: 'items',
        dataArray: [],
        order: 'name',
        loading: true,
        largeHeader: true
    }

};
