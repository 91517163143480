var $ = require("jquery"),
    dotdotdot = require("jquery.dotdotdot"),
    validate = require('./validate'),
    dom = require('./dom'),
    date = require('./date'),
    logger = require('./logger'),
    pluralize = require("pluralize");

// Language types validators from validate.js
var is = validate.is;
var isNumber = validate.isNumber;
var isArray = validate.isArray;
var isString = validate.isString;

// General
function getNumber(n, d) {
    if (isNumber(n)) {
        return n;
    } else if (isString(n) && !isNaN(n)) {
        try {
            var parsed = parseInt(n);
            if (!isNaN(parsed)) {
                return parsed;
            }
        } catch (e) {}
    }

    return isNumber(d) ? d : null;
}

function convertKey(key) {
    var p = '__';

    function e(c) {
        return p + c.charCodeAt(0) + p;
    }

    // TODO Add validation that all characters are valid to be a variable name
    // https://mothereff.in/js-variables#%EF%BE%A0%E1%85%A0%E1%85%9F
    // https://mathiasbynens.be/notes/javascript-identifiers
    // https://github.com/mathiasbynens/mothereff.in/tree/master/js-variables

    /*
     An identifier must start with $, _, or any character in the Unicode categories “Uppercase letter (Lu)”,
     “Lowercase letter (Ll)”, “Titlecase letter (Lt)”, “Modifier letter (Lm)”,
     “Other letter (Lo)”, or “Letter number (Nl)”.
     The rest of the string can contain the same characters,
     plus any U+200C zero width non-joiner characters,
     U+200D zero width joiner characters,
     and characters in the Unicode categories “Non-spacing mark (Mn)”, “
     Spacing combining mark (Mc)”, “Decimal digit number (Nd)”, or “Connector punctuation (Pc)”.
     */
    var valid = key.replace(/ /g, e(' '));
    valid = valid.replace(/#/g, e('#'));
    valid = valid.replace(/@/g, e('@'));
    valid = valid.replace(/&/g, e('&'));
    valid = valid.replace(/\./g, e('.'));
    valid = valid.replace(/:/g, e(':'));
    return valid;
}

module.exports = {
    EMPTY_DATA_DEFAULT_MESSAGE: '',

    // validate.js
    is: is,
    isNumber: isNumber,
    getNumber: getNumber,
    isArray: isArray,
    isString: isString,
    isDate: validate.isDate,
    isElement: validate.isElement,
    isNull: validate.isNull,

    // date.js
    getTimeframe: date.getTimeframe,
    getHoursLabel: date.getHoursLabel,
    getUTCHoursLabel: date.getUTCHoursLabel,
    parseDateHourMinute: date.parseDateHourMinute,
    parseDate: date.parseDate,
    setDatedValueByIdAndKey: date.setDatedValueByIdAndKey,
    getMonthShortLabel: date.getMonthShortLabel,
    addMapItemByDate: date.addMapItemByDate,
    getDateKey: date.getDateKey,
    getTimeframeLabel: date.getTimeframeLabel,
    getMapItemByDate: date.getMapItemByDate,
    isMoreThanMonth: date.isMoreThanMonth,
    hasDatesOlderThanOneMonth: date.hasDatesOlderThanOneMonth,
    getDateRange: date.getDateRange,
    setDatedValueById: function (map, _date, minutes, value, id, df) {
        date.setDatedValueByIdAndKey(map, _date, minutes, value, id, null, df);
    },
    calcOffsetDays: date.calcOffsetDays,
    getFullWeeksTimeframe: date.getFullWeeksTimeframe,
    isTimeframeForWeekly: date.isTimeframeForWeekly,

    // dom.js
    removeElement: dom.removeElement,
    removeElementByClass: dom.removeElementByClass,
    getElementWidth: dom.getElementWidth,
    getElementHeight: dom.getElementHeight,
    setElementHeight: dom.setElementHeight,
    setElementProperty: dom.setElementProperty,
    setElementFont: dom.setElementFont,
    cleanElement: function (id, show) {
        dom.setElementVisible(id, show, true);
    },

    // logger
    getServerError: logger.getServerError,
    logUpdate: logger.logUpdate,
    log: logger.log,

    cutText: function (text, max, suffix) {
        if (isString(text) && isNumber(max) && text.length > max) {
            return text.substring(0, max - 2) + (isString(suffix) ? suffix : '..');
        }
        return text;
    },
    titleize: function (str) {
        return str ? _.map(str.split(' '), _.upperFirst).join(' ') : str;
    },
    roundPercents: function (percents) {
        if (!_.sum(percents)) return percents;
        var sortedByDecimal = [];
        for (var i = 0; i < percents.length; i++) {
            var percentWithIndex = {};
            percentWithIndex[percents[i]] = i;
            sortedByDecimal.push(percentWithIndex);
        }
        sortedByDecimal.sort(function (a, b) {
            var aDecimal = Object.keys(a)[0];
            var bDecimal = Object.keys(b)[0];
            return aDecimal - Math.floor(aDecimal) > bDecimal - Math.floor(bDecimal) ? -1 : 1
        });
        var indexOfPercentsSortedByDecimal = sortedByDecimal.map(function (d) {
            return d[Object.keys(d)[0]]
        });
        percents = percents.map(function (n) {
            return Math.floor(n)
        });
        var missingTo100 = 100 - percents.reduce(function (a, b) {
                return a + b;
            });
        for (var j = 0; j < missingTo100; j++) {
            percents[indexOfPercentsSortedByDecimal[j]] += 1;
        }
        return percents;
    },

    roundUpToNearestPowerOf10: function(pr) {
      if (pr < 0) throw new RangeError("Parameter must be bigger than 0");
      if (pr < 10) return 10;
      let n = Math.floor(pr);
      let roundPower = Math.pow(10, n.toString().length - 1);
      return Math.ceil(n/roundPower) * roundPower;
    },

    sortByNumeric: function compare(array, field, ascending) {
        if (isArray(array) && array.length > 0) {
            array.sort(function (a, b) {
                if (a[field] < b[field])
                    return ascending ? -1 : 1;
                if (a[field] > b[field])
                    return ascending ? 1 : -1;
                return 0;
            });
        }
    },
    getKey: function (id, name) {
        var key;
        if (isNumber(id) && id > 0) {
            key = id.toString();
        } else if (isString(id)) {
            key = convertKey(id);
        } else if (isString(name)) {
            key = convertKey(name);
        } else {
            key = (new Date()).getTime().toString();
        }
        return key;
    },
    getValidContextParameters: function (values, types) {
        if (is(values)) {
            var parameters = {
                timeframe: isArray(values.timeframe) ? JSON.parse(JSON.stringify(values.timeframe)) : [],
                topics: isArray(values.topics) ? JSON.parse(JSON.stringify(values.topics)) : [],
                geo: isArray(values.geo) ? JSON.parse(JSON.stringify(values.geo)) : [],
                sub_geos: isArray(values.sub_geos) ?
                    values.sub_geos.filter((sg) => sg.selectable)
                        .map ((sg) => ({id: sg.id, type: sg.type}))  : [],
                active: values.active,
                terms: []
            };
            if (isArray(values.terms)) {
                _.each(values.terms, function (entry) {
                    if (!is(types) || (isString(entry.type) && types[entry.type])) {
                        parameters.terms.push({
                            class: entry.class,
                            id: entry.id,
                            text: entry.text,
                            type: entry.type
                        });
                    }
                });
            }
            return parameters;
        } else {
            return {};
        }
    },

    isSocialBooleanLogic: function (term) {
        if (term.type !== 'booleanLogic' && term.type !== 'programBL') return false;

        var types = _([term.required, term.included, term.excluded]).flattenDeep().compact().map('type').uniq().value();
        return _.without(types, 'post', 'hashTag', 'mention').length == 0;
    },

    validateNonPhrases: function (terms, channel, notificator, customMsg) {
        var msgBody = customMsg || 'Sorry, @mentions, @posts and #hashtags are supported only for social channel';
        var self = this;

        var mentions = terms.filter(function (t) {
            return ['mention','hashTag','post'].indexOf(t.type) !== -1 || self.isSocialBooleanLogic(t);
        });

        if (mentions.length > 0 && !(channel == 'tweets' || channel == 'facebook')) {
            notificator.notify({body: msgBody});
            return true;
        }
        return false;
    },
    validateNonPosts: function (terms, notificator, customMessage) {
        var msgBody = customMessage || 'Sorry, @posts are not supported. Please search for phrases, #hashtags or @mentions';
        var mentions = terms.map(function (t) {
            return t.type
        }).filter(function (t) {
            return t === 'post'
        });
        if (mentions.length > 0) {
            notificator.notify({body: msgBody});
            return true;
        }
        return false;
    },
    selectBooleanLogics: function (terms) {
        return _.filter(terms, function (term) {
            return term.type == 'booleanLogic' || term.type == 'programBL';
        });
    },
    rejectBooleanLogics: function (terms) {
        return _.reject(terms, function (term) {
            return term.type == 'booleanLogic' || term.type == 'programBL';
        });
    },
    getProgramName: function (name) {
        return name.slice(-' interests'.length) == ' interests' ? 'my interests' : name;
    },
    generateUUID: function () {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    getTrend: function (trends, key) {
        var trend = null;
        var checkText = false;
        var compareKey = key;

        if (isString(key)) {
            if (isNumber(key) && !isUUID(key)) {
                compareKey = parseInt(key);
            } else if (!isUUID(key)) {
                checkText = true;
            }
        }

        _.each(trends, function (entry) {
            var entryId = entry.id;
            if (!isNumber(entryId)) {
                entryId = convertKey(entryId);
            }
            if (entryId == compareKey || (checkText && entry.text == compareKey)) {
                trend = entry;
            }
        });

        return trend;

        function isUUID(uuid) {
            var re = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            return re.test(uuid);
        }
    },

    getChannelIcon: function (channel) {
        var icon;
        switch (channel) {
            case 'articles':
                icon = 'icon-Article';
                break;
            case 'tweets':
                icon = 'icon-Twitter';
                break;
            case 'videos':
                icon = 'icon-Video_icon';
                break;
            default:
                icon = '';
        }

        return icon;
    },
    
    getChannels: function ($state, context, objectType) {
        var page = $state.current.name.toLowerCase(),
            channels = [];

        if (page.includes("discovery.grid")) {
            channels = [(context.current._gridChannels_mold._value || {value: "articles"}).value];
        } else if (page.includes("discovery.streams")) {
            channels = (context.current._channels_mold._value || {}).map(function (c) {
                return c.value
            });
        } else if (page.includes("discovery.bubbles")) {
            channels = [(context.current._bubblesChannels_mold._value || {value: "articles"}).value];
        }else if(page.includes("insights")){
          let mold = context.current._insightsChannels_mold._value || {value: "articles", label: "All"};
          if (page.includes("timing") && !_.includes(["articles", "tweets", "sg_telco"], mold.value)) {
            channels = ["tweets"];
          } else if ((page.includes("landscape") && mold.label.indexOf('sg_telco') == -1 ) || mold.label==='All') {
            channels = ["all"];
          }else if ((page.includes("landscape") && mold.label.indexOf('sg_telco') == 1 )|| mold.label != 'All') {
            channels = [mold.value];
          } else {
            channels = [mold.value];
          }

        }else if(page.includes("audience")){

            let audienceChannel = context.current.audience_app.current_channel;
            channels = [audienceChannel.value];    
        }

        return channels;
        
    },

    isAllChannelsSelected: function ($state, context, $rootScope) {
         var page = $state.current.name.toLowerCase(),
             channels = [];
             if (page.includes("streams")) {
                //dummy_parent is a stab for a node with childs. EX: social --> Twitter, Facebook, instagram.

               var streamsChannels =  _.chain($rootScope.streamsChannelsFilter).reduce((res, channel) => {
                    return res.concat(channel.children ? channel.children : channel);
                }, [])

               streamsChannels = streamsChannels.reject('custom').reject(['exclude_from_all', 'true']).map('value').value();

               var selectedChannels = _.without(this.getChannels($state, context), 'dummy_parent');
               return streamsChannels.length == selectedChannels.length;

            }else{
                //for future other pages, for now return false
                return false;
            }
    },
    getPage: function ($state) {
        //example: discovery.grid
        var page = $state.current.name.split(".");
        page = page.slice(0, 2);
        return page.join(".");
    },
    getTermId: function (term) {
        return isNumber(term.id) && term.id != -1 ? term.id : term.text;
    },
    getWeekDays: function () {
        return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    },
    getTruncatedWeekDays: function () {
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    },
    getHeatmapHours: function () {
        var hour_numbers = [12].concat(_.range(1, 12));
        return _.flatten(_.map(['am', 'pm'], function (denom) {
            return _.map(hour_numbers, function (num) {
                return num.toString() + denom
            })
        }));
    },
    getHeatmapHourNumbers: function () {
        var hour_numbers = [12].concat(_.range(1, 12));
        return hour_numbers.concat(hour_numbers);
    },
    getTextWidth: function (text, font) {
        // re-use canvas object for better performance
        var canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    },
    outputWithPercent: function (value) {
        return parseFloat(value).toFixed(2) + '%';
    },
    average: function (array) {
        return array.reduce(function (mem, val) {
            return mem + (val / array.length);
        }, 0.0);
    },

    arrayToSentence: function(arr, {limit = null, seperator = ', ', lastSeperator = ' and ', itemType = ''} = {}) {
      if (arr.length === 0) {
        return "";
      }
      if (arr.length === 1) {
        return arr[0];
      }

      let join = function(a, seperator, lastSeperator) {
        return a.slice(0, -1).join(seperator) + lastSeperator + a[a.length - 1];
      };

      let size = arr.length;
      if (limit && limit > 0) {
        let x = size - limit;
        let upToLimit = arr.slice(0, limit);
        if (x > 0) {
          let sentence = `${arr.slice(0, limit).join(seperator)} and ${x} more`;
          if (itemType) {
            sentence += " " + pluralize(itemType, x);
          }
          return sentence;
        } else {
          return join(upToLimit, seperator, lastSeperator);
        }
      } else {
        return join(arr, seperator, lastSeperator);
      }
    },

    getAudience: function (values, channels) {
        channels = _.castArray(channels || values.channel);
        return _.isEqual(channels, ['sg_telco']) ? values.sgTelcoAudience : values.audience;
    },

    getSource: function(channel) {
      var sources = {'tweets': 'social',
                     'facebook': 'social',
                     "instagram": "social",
                     "sg_telco" : "sg_telco",
                     "au_telco" : "au_telco",
                     "web" : "articles",
                     "videos" : "articles" };
      return sources[channel] || channel;
    },

    hasAudienceValuesSelected: function(audience, values) {
      return _.intersection(values, _.flatten(audience)).length > 0;
    },

    validateMinDate: function(timeframe, minDate, rootScope) {
      if(typeof minDate != 'string' || _.isNumber(timeframe[0])) return;
      const format = "DD_MM_YY_HH_mm";
      let from_date = moment(timeframe[0], format).startOf('hour');
      let to_date = moment(timeframe[1], format).startOf('hour');
      let min_date_moment = moment(minDate, format).startOf('hour');
      if (from_date < min_date_moment) {
        var diff_months = to_date.diff(from_date, 'months');
        var diff_days = to_date.diff(from_date.add(diff_months, 'month'), 'days');
        var notification_message = "No data exists for the selected timeframe. The timeframe's start date has been updated.";
        timeframe[0] = min_date_moment.format(format);
        if (to_date < min_date_moment) {
          to_date = _.min([min_date_moment.add({'month': diff_months, 'day': diff_days}), moment().startOf('hour')]);
          notification_message = "No data exists for the selected timeframe. The timeframe's start date and end date have been updated.";
        }
        timeframe[1] = to_date.format(format);
        rootScope.$broadcast('timeframe-update', timeframe, false, notification_message);
      }
    },

    //Determine whether doUpdate in widgets was called because timeframe was changed due to minimun date (need to stop doUpdate)
    isUpdateFromMinDate: function(changedVals, min_date, TIMES) {
      return _.isString(min_date) && changedVals && changedVals.timeframe && _.isString(changedVals.timeframe[0]) &&
        moment(changedVals.timeframe[0], TIMES.FORMAT) < moment(min_date, TIMES.FORMAT)
    },

    getAvailableContext: function(channelFilter,abiPermissions){
        var context = {};
        _.each(channelFilter, function(data){
            if(abiPermissions.hasPermission(data.permission)){
                context = data;
                return false;
            }
        });
        return context;
    },

    isNotBelongToPermissionGroup: function(col,val){
        var result = _.find(col,{value: val});
        return result === undefined;
    }
};
