import mixpanel from 'mixpanel-browser';

// eslint-disable-next-line no-undef
const isDisabled = () => !IS_PRODUCTION;

export const initialize = (authInfo) => {
  if (isDisabled()) return;

  mixpanel.init('3b9c0e987bfe8dbfb18467ca8adad53b');
  const userEmail = authInfo.email;
  mixpanel.register({ user: userEmail });
  mixpanel.identify(userEmail);
  mixpanel.people.set({
    '$email': userEmail,
    'Username': authInfo.name,
    'Organization': authInfo.account.name
  });
};

export const track = (name, props) => {
  if (isDisabled()) return;
  mixpanel.track(name, props);
};
