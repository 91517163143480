var d3 = require("d3"),
    c = require("infra/utils/common"),
    $ = require("jquery");

var PAD = {
    left: 50,
    right: 20,
    top: 20,
    bottom: 30
};
var HOVER_PAD = 0;
var HOVER_OVERLAY = -2;
var HOVER_HEIGHT = 18;
var HOVER_WIDTH = 42;
var HOVER_TEXT_PAD = 13;
var HOVER_Y = -7;
var SHOW_DATE = true;

var DEFAULT_INTERPOLATION = 'monotone'; // 'cardinal'
var MIN_X_TICK_SPACE = 80;
var MAX_X_TICK_SPACE = 130;
var AXIS_COLOR = '#81878C';
var Y_AXIS_LINES = 10;
var Y_AXIS_MINIMUM_LINES = 5;
var Y_AXIS_DEFAULT_MAX = 103;
var DEFAULT_Y_AXIS = false;

d3.selection.prototype.moveToFront = function() {
    return this.each(function(){
        this.parentNode.appendChild(this);
    });
};

function StackedChart(containing_object, id, entries, line, configuration) {
    var previous = null;
    var self = this;
    this.id = id;
    this.containing_object = containing_object || {};
    this.initialized = false;
    this.fill = line ? 'line' : 'area';
    this.configuration = c.is(configuration) ? configuration : {};
    this.minutes = 'minutes' == this.configuration.type;
    this.range = [];
    this.wide = false;
    this.entries = entries;
    this.letterClicked = '';

    Object.defineProperty(this, "dateFormat", {
        set: function(value) {
            this.parseDate = d3.time.format(value).parse;
            this.toStringDate = d3.time.format(value);
        }
    });

    _.each(this.entries, function(entry, i) {
        entry.previous = previous;
        previous = entry;
    });

    this.clean = function() {
        this.infoMap = {};
        this.currentInfo = null;
        this.currentDate = null;
        this.delta = 0;
        this.move = null;
        this.range = [];
        if (c.is(this.svg)) {
            this.svg.selectAll(".line, .area, g.y-axis, .stacked-chart-circle, .stacked-chart-text, .stacked-chart-line").remove();
        }
        if (c.is(this.info)) {
            this.info.selectAll("*").remove();
        }
    };

    this.init = function() {
        if (!this.initialized) {
            this.initialized = true;
            this.clean();
            this.dateFormat = this.minutes ? "%Y-%m-%dT%H:%M:%S.%LZ" : "%d-%m-%Y";
            var root = $(document.getElementById(this.id));
            var self = this;
            root.mouseleave(function() {
                if (c.is(self.currentInfo))
                    self.currentInfo.style("opacity", 1e-6);
            });
            this.container = d3.select(document.getElementById(this.id));
            this.svg = this.container.append("svg")
                .attr("height", "100%")
                .attr("id","svg-container");
            this.container.select("svg")
                .attr("style","position:absolute;");
            this.svg = this.svg.append("g")
                .attr("id", "canvas");
            this.svg.append("g")
                .attr("class", "x-axis axis");
            this.domain = this.svg.append("g")
                .attr("stroke", "none")
                .attr("class", "y-axis axis");
            var gy = this.svg.select("g.y-axis.axis");
            gy.select("path.domain").attr("id", "domain");
            this.info = this.svg.append("g")
                .attr("id", "info");
            return false;
        }
        return true;
    };

    this.draw = function(data, range, max, wide) {
        this.init();
        this.clean();
        this.wide = wide;
        if (c.is(data) && c.isArray(range) && c.isArray(data.series) && c.is(data.term)) {
            var self = this;
            this.range = range;
            this.updateLayout(range, max, data.midnight);
            this.addLines(data);
            self.addInfo(data);
            setTimeout( function() {
                self.addInfoTooltips();
            }, 0 );
            if (self.configuration.agg_examples_mode) {
                self.addCircles(data.examples);
            }
        }
    };

    this.updateLayout = function(range, max, minutes) {
        var self = this;
        var root = $(document.getElementById(this.id));
        this.width = root.width() - PAD.left - PAD.right;
        this.height = root.height() - PAD.top - PAD.bottom;
        this.svg.attr("transform", "translate(" + PAD.left + "," + PAD.top + ")");
        this.xScale = d3.time.scale()
            .range([0, this.width]);
        this.yScale = d3.scale.linear()
            .range([this.height, HOVER_PAD]);
        this.svg.select("g.x-axis")
            .attr("transform", "translate(0," + this.height + ")");
        this.svg.append("g").attr("class", "y-axis axis");

        var ticks = self.wide ? ((this.width / MAX_X_TICK_SPACE) | 0) : ((this.width / MIN_X_TICK_SPACE) | 0);
        ticks = (ticks > range.length) ? range.length : ticks;
        var usedDates = [];
        var xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient("bottom")
            .ticks(ticks)
            .tickSize(0)
            .tickPadding(10)
            .tickFormat(function (d, index) {
                if (c.isString(self.configuration.type) && self.minutes) {
                    if (self.wide) {
                        return (d.getMonth() + 1) + '/' + d.getDate() + ' ' + c.getHoursLabel(d, /*minutes*/true);
                    }
                    return c.getHoursLabel(d);
                } else {
                    var formatted = (d.getMonth() + 1) + '/' + d.getDate();
                    if(usedDates.indexOf(formatted) == -1){
                        usedDates.push(formatted);
                        return formatted;
                    }
                }
                return '';
            });

        this.xScale.domain(d3.extent(range));
        var g = this.svg.selectAll("g.x-axis")
            .call(xAxis);
        var m = Y_AXIS_DEFAULT_MAX;
        if (c.isNumber(max) && max > 0) {
            if (self.configuration.force_max || max <= 100) {
                m = max + (max/100)*3;
            }
        }
        this.yScale.domain([HOVER_PAD, m]);
        var yAxis = d3.svg.axis()
            .scale(this.yScale)
            .orient("left")
            .ticks(self.height > 285 ? Y_AXIS_LINES : Y_AXIS_MINIMUM_LINES)
            .tickFormat(function (d) {
                if (d >= 10000) {
                    return d/1000 + 'K';
                }
                return (d == 0) ? '' : d;
            })
            .tickSize(-this.width);
        this.svg.selectAll("g.y-axis")
            .call(yAxis);
        if (!DEFAULT_Y_AXIS) {
            this.svg.selectAll("g.y-axis text")
                .attr("y", -10)
                .attr("x", -11);
            this.svg.selectAll("g.y-axis .tick line")
                .attr("x1", -10)
                .attr("y1", 0);
            this.svg.selectAll("g.y-axis .tick").append("svg:line")
                .attr("x1", function (d) {
                    return (d == 0) ? 0 : -2;
                })
                .attr("y1", 0)
                .attr("x2", function (d) {
                    return (d == 0) ? 0 : -30;
                })
                .attr("y2", 0)
                .style("stroke", AXIS_COLOR)
                .attr("class", "small-ticks");
        }
    };

    this.addLines = function(data) {
        var self = this;
        var path = this.svg.selectAll("path.line").data([data]);
        _.each(this.entries, function(entry, i) {
            self.addLine(path, data, entry);
        });
    };

    this.addInfo = function(data) {
        if (c.isArray(data.series)) {
            var self = this, px = 0, cx = 0;
            var max = self.xScale(data.series[data.series.length - 1].date);
            _.each(data.series, function (tick, j) {
                if (c.isDate(tick.date)) {
                    cx = self.xScale(tick.date);
                    max = (cx > max) ? cx : max;
                    self.delta = px > 0 ? (self.minutes ? (cx - px) : (px - cx)) + self.delta : self.delta;
                    c.addMapItemByDate(self.infoMap, self.getInfo(self.entries, tick, cx, max), tick.date, self.minutes);
                    px = cx;
                }
            });
            this.delta = (this.delta/(data.series.length - 1))/2;
        } else this.delta = 0;
    };

    this.addLine = function(path, data, entry) {
        var f, self = this;
        if (self.fill == 'line') {
            f = d3.svg.line().interpolate(DEFAULT_INTERPOLATION)
                .x(function(d) {
                    return self.xScale(d.date);
                })
                .y(function(d) {
                    return self.yScale(d[entry.key]);
                });
        } else {
            f = d3.svg.area().interpolate(DEFAULT_INTERPOLATION)
                .x(function(d) {
                    return self.xScale(d.date);
                })
                .y0(function(d) {
                    if (c.is(d.previous)) {
                        return self.yScale(d[d.previous.key]);
                    }
                    return self.height;
                })
                .y1(function(d) {
                    return self.yScale(d[entry.key]);
                });
        }
        path.enter().append("path")
            .attr("class", self.fill + ' ' + entry.class)
            .attr("id", function(d) {
                return 'line-' + entry.key + '-' + d.term.id;
            })
            .attr("d", function(d) {
                return f(d.series);
            })
            .attr("tkey", function(d) {
                return entry.key + '-' + d.term.id;
            });
    };

    this.getInfoPosition = function(entries, x, max) {
        var width = (SHOW_DATE ? entries.length + 1 : entries.length) * HOVER_WIDTH;
        var p = x - width / 2 + (SHOW_DATE ? HOVER_WIDTH/2 : 0);
        if (p < 0) {
            return 0;
        } else if ((p + width) > max) {
            return (max - width);
        }
        return p;
    };

    this.getInfo = function(entries, tick, cx, max) {
        var self = this;
        var dateKey = c.getDateKey(tick.date, self.minutes, '-', false);
        var infoGroup = self.info.append("g")
            .attr("id", 'info-group-' + dateKey)
            .attr("dkey", dateKey)
            .style("opacity", 1e-6);
        infoGroup.append("line")
            .attr("stroke", "#cacaca")
            .attr("x1", cx)
            .attr("x2", cx)
            .attr("stroke-width", 1)
            .attr("y1", 0)
            .attr("y2", self.height);
        var i = 0, s = 0, ls = 0;
        var p = this.getInfoPosition(entries, cx, max);
        _.each(entries, function(entry) {
            var t = 0 + entry.units;
            if (tick.value > 0) {
                t = Math.round(tick[entry.source]*10)/10 + entry.units;
            }
            s = HOVER_WIDTH * i;
            ls = (t.length * 3) / 2;
            infoGroup.append("rect")
                .attr("x", p + s)
                .attr("y", HOVER_OVERLAY - HOVER_HEIGHT)
                .attr("width", HOVER_WIDTH)
                .attr("height", HOVER_HEIGHT)
                .attr("class", entry.class);
            var text = infoGroup.append("text")
                .attr("x", p + s + HOVER_TEXT_PAD - ls)
                .attr("y", HOVER_Y)
                .attr("class", "hover-text")
                .text(t);
            i++;
        });
        if (SHOW_DATE) {
            s = HOVER_WIDTH * i;
            infoGroup.append("rect")
                .attr("x", p)
                .attr("y", HOVER_OVERLAY - HOVER_HEIGHT)
                .attr("width", HOVER_WIDTH)
                .attr("height", HOVER_HEIGHT)
                .attr("class", "unknown-stack"); // hover-date
            ls = tick.date.getDate() > 9 ? 9 : 6;
            var description = self.minutes ? c.getHoursLabel(tick.date) :
                (c.getMonthShortLabel(tick.date.getMonth()) + ' ' + tick.date.getDate());
            var text = infoGroup.append("text")
                .attr("x", p + s + HOVER_TEXT_PAD - ls)
                .attr("y", HOVER_Y)
                .attr("class", "hover-date-text")
                .text(description);
            i++;
        }
        return infoGroup;
    };

    this.findDateItem = function(date, later) {
        var item = c.getMapItemByDate(this.infoMap, date, this.minutes, false);
        if (!c.is(item)) {
            var p, n;
            _.each (this.range, function(entry, index) {
                if (date > entry) {
                    p = (p && p > entry) ? p : entry;
                } else {
                    n = (n && n < entry) ? n : entry;
                }
            });
            p = c.is(p) ? p : n;
            n = c.is(n) ? n : p;
            if (c.is(n) && c.is(p)) {
                var d = (((date.getTime() - p.getTime()) > (n.getTime() - date.getTime())) ? n : p);
                item = c.getMapItemByDate(this.infoMap, d, this.minutes, false);
            }
        }
        return item;
    };

    this.updateTooltip = function(infoGroup) {
        if (c.is(infoGroup)) {
            if (infoGroup != this.currentInfo) {
                if (c.is(this.currentInfo))
                    this.currentInfo.style("opacity", 1e-6);
                infoGroup.style("opacity", 1);
                this.info.moveToFront();
                infoGroup.moveToFront();
                this.currentInfo = infoGroup;
            } else infoGroup.style("opacity", 1);
            return true;
        }
        return false;
    };

    this.addInfoTooltips = function() {
        var self = this;
        setTimeout( function() {
            self.container.on("mousemove", function(e) {
                try {
                    var fx = d3.mouse(this)[0] - PAD.left;
                    var date = self.xScale.invert(fx + self.delta/2);
                    var key = self.container.attr("key");
                    var dateInfoGroup = self.findDateItem(date, (fx - self.move) > 0);
                    var dateKey = dateInfoGroup.attr('dkey');

                    self.updateTooltip(dateInfoGroup);
                    self.move = fx;
                    self.moveCircleToFront(dateKey, key)
                } catch (e) {}
            });
        }, 0 );
    };

    this.addCircles = function(data) {
        var self = this;

        if(c.isArray(data)) {
            _.each(data, function (entry, i) {
                var key = c.getKey(entry.trend_id, entry.trend_text);
                var dateKey = c.getDateKey(entry.date, false, '-', false);
                var cx = self.xScale(entry.date);
                var cy = self.yScale(entry.value) - 20;
                self.svg.append("circle")
                    .attr("class", "stacked-chart-circle")
                    .attr("id", 'stacked-chart-circle-' + key + '-' + dateKey)
                    .attr("pdate", dateKey)
                    .attr("cx", cx)
                    .attr("cy", cy)
                    .attr("letter", entry.letter)
                    .attr("key", key)
                    .attr("sentiment", entry.sentiment)
                    .on("mouseover", function () {
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        self.emitEvent("chartCircleMouseover", currentLetter);
                        self.moveCircleToFront(currentLetter);
                    }).on("mouseout", function () {
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        if (currentLetter != self.letterClicked) {
                            self.highlightSwitchIdValues(element, false, "circle", "text");
                        }
                        self.emitEvent("chartCircleMouseout", currentLetter);
                    }).on("click", function () {
                        self.highlightCircleFromLetter(self.letterClicked, false);
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        self.letterClicked = currentLetter;
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        self.moveCircleToFront(currentLetter);
                        self.emitEvent("chartCircleClick", {letter: currentLetter, sentiment: element.attr("sentiment")});
                        d3.event.stopPropagation();
                    });

                self.svg.append("text")
                    .attr("class", "stacked-chart-text")
                    .attr("id", 'stacked-chart-text-' + key + '-' + dateKey)
                    .attr("pdate", dateKey)
                    .attr("x", cx)
                    .attr("y", cy)
                    .attr("letter", entry.letter)
                    .attr("key", key)
                    .attr("sentiment", entry.sentiment)
                    .text(entry.letter)
                    .on("mouseover", function () {
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        self.highlightSwitchIdValues(element, true, "text", "circle");
                        self.emitEvent("chartCircleMouseover", currentLetter);
                        self.moveCircleToFront(currentLetter);
                    }).on("mouseout", function () {
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        if (currentLetter != self.letterClicked) {
                            self.highlightSwitchIdValues(element, false, "text", "circle");
                        }
                        self.emitEvent("chartCircleMouseout", currentLetter);
                    }).on("click", function () {
                        self.highlightCircleFromLetter(self.letterClicked, false);
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        self.letterClicked = currentLetter;
                        self.highlightSwitchIdValues(element, true, "text", "circle");
                        self.moveCircleToFront(currentLetter);
                        self.emitEvent("chartCircleClick", {letter: currentLetter, sentiment: element.attr("sentiment")});
                        d3.event.stopPropagation();
                    });

                self.svg.append("line")
                    .attr("class","stacked-chart-line")
                    .attr("x1", cx)
                    .attr("x2", cx)
                    .attr("y1", cy + 5)
                    .attr("y2", cy + 20)
                    .attr("stroke", "#cacaca")
                    .attr("stroke-width", 1);

                if(cy < 10) {
                    d3.selectAll("#" + self.id + " #info-group-" + dateKey + " rect").attr("y", cy + 15);
                    d3.selectAll("#" + self.id + " #info-group-" + dateKey + " text").attr("y", cy + 28);
                }

                if (self.letterClicked) {
                    self.highlightCircleFromLetter(self.letterClicked, true);
                }
            });

            d3.selectAll(".stacked-chart-circle, .stacked-chart-text").moveToFront();
        }
    };

    this.resetHighlightedCircle = function() {
        this.highlightCircleFromLetter(this.letterClicked, false);
        this.letterClicked = '';
    };

    this.moveCircleToFront = function (dateKey, key) {
        this.getStackedChartElement("circle", dateKey, key).moveToFront();
        this.getStackedChartElement("text", dateKey, key).moveToFront();
    };

    this.highlightSwitchIdValues = function(element, highlightOn, searchValue, replaceValue) {
        element.classed("highlighted", highlightOn);
        var elementId = element.attr("id").replace(searchValue, replaceValue);
        d3.select("#" + elementId).classed("highlighted", highlightOn);
    };

    this.highlightCircleFromLetter = function(letter, highlightOn) {
        if(letter) {
            d3.select(".stacked-chart-circle[letter=" + letter + "]").classed("highlighted", highlightOn);
            d3.select(".stacked-chart-text[letter=" + letter + "]").classed("highlighted", highlightOn);
        }
    };

    this.getStackedChartElement = function(type, dateKey, key){
        return d3.select(".stacked-chart-" + type + "[pdate=\"" + dateKey + "\"][key=\"" + key + "\"]");
    };

    this.emitEvent = function(eventType, data) {
        if (self.containing_object.hasOwnProperty('emitEventForChartCircle') && typeof self.containing_object.emitEventForChartCircle == 'function'){
            self.containing_object.emitEventForChartCircle(eventType, data);
        }
    };
}

module.exports = StackedChart;
