import { isEmpty } from 'lodash';

export function createPromiseEvaluatedToComponentProps(audienceSegment, channel, userId, audienceDistributionService, isRequestCancelledService, trackTooNarrow) {
  if (isEmpty(audienceSegment)) {
    return Promise.resolve({ hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: false, summary: 'All Population' });
  }

  return audienceDistributionService(audienceSegment, { channel, userId }).then((data) => convertPromiseResultsToComponentProps(data, isRequestCancelledService, trackTooNarrow));
}

export function convertPromiseResultsToComponentProps(data, isRequestCancelledService, trackTooNarrow) {
  const MIN_AUDIENCE_SIZE = 1000;

  if (!data) return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: true }; 

  if (isRequestCancelledService(data)) return false; // cancel
  if (data.status === 'ok') return {
    hasData: true, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: false, ageDist: data.distribution.age, genderDist: data.distribution.gender, populationDist: data.audienceSize.population < MIN_AUDIENCE_SIZE ? MIN_AUDIENCE_SIZE : data.audienceSize.population
  };

  switch (data.error.reason) {
    case 'no_results':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'selectionTooNarrow':
      trackTooNarrow();
      return { hasData: false, isTooWide: false, isTooNarrow: true, isEntitiesLimit: false, isError: false };
    case 'selectionTooWide':
      return { hasData: false, isTooWide: true, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'too_many_entities':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: true, isError: false };  
    default: return { hasData: false };           
  }
}
