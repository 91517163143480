import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {

  logError: (errorInfo) => ({
    url: `${HOST}/log_react_js_error`,
    payload: errorInfo
  })
  
};
