import { SEGMENT_MAP, getSegmentValuesSummary } from '../../data/audience-segment-builder-helper';

const audienceExplorerModule = angular.module(__filename, [
    require('../../widgets/audience-demographics-widget/audience-demographics-widget').name,
    require('../../widgets/audience-tv-widget/audience-tv-widget').name,
    require('../../widgets/audience-table-view-widget/audience-table-view-widget').name,
    require('../../widgets/audience-interests-widget/audience-interests-widget').name,
    require('../../widgets/audience-linkedin-interests-widget/audience-linkedin-interests-widget').name,
    require('../../widgets/user-history-widget/user-history-widget').name,
    require('../../widgets/user-examples-dropdown-widget/user-examples-dropdown-widget').name,
    require("../../infra/mixpanel/mixpanel-audience").name,
    require("../../common/am-tabs.drv/am-tabs.drv").name
]);

audienceExplorerModule.stateConfig = [{
    name: "audience-explorer",
    url: "/audience-explorer",
    template: require("./audience-explorer.html"),
    display: "Explore Audience",
    controller: audienceExplorerController,
    data: {
      permissions: ['audience']
    }
}];

audienceExplorerController.$inject = ["$scope", "$state", "abiPermissions", "mixpanelAudience", "context", "filtersPartition"];

function audienceExplorerController($scope, $state, abiPermissions, mixpanelAudience, context, filtersPartition) {
    const SG_CHANNELS = ['snbb', 'data_spark'];
    const channel = context.current.audience_app.current_channel.value;
    $scope.channel = channel;
    
    const debugUser = $scope.$root.user.userType === 'debug';
    const showTvTab = !SG_CHANNELS.includes(channel) && channel !== 'au_telco' && abiPermissions.hasPermission(['tv']);
    const showInterestsTab = abiPermissions.hasPermission(['interests']) && channel !== 'linear_tv';
    // each channel support other types of data
    if(channel === "linkedin")
        $scope.tabs = [{label: "Interests", value: "linkedin_interests"}];
    else
        $scope.tabs = _.compact([
            abiPermissions.hasPermission(['demographics']) && {label: "Demographics", value: "demographics"},
            debugUser && {label: "Websites", value: "domain"},
            debugUser && {label: "Keywords", value: "keywords"},
            debugUser && {label: "Consumed Phrases", value: "web"},
            showInterestsTab && {label: "Interests", value: "wiki"},
            showTvTab && {label: "TV Shows", value: "tv"},
            abiPermissions.hasPermission(['timeline']) && {label: "Timeline", value: "timeline"}
        ]);
  
    $scope.selectedTab = $scope.tabs[0];
    
    $scope.navToPrevTab = () => {
      $scope.selectedTab = $scope.prevTab || $scope.tabs[0];
      $scope.$digest();
    };

    $scope.navToAudienceBuilder = () => {
        $state.go('audience-builder');
    }

    $scope.onSelectTab = function (selectedTab) {
        $scope.prevTab = $scope.selectedTab;
        $scope.selectedTab = selectedTab;
        
        $scope.$digest();
        
        mixpanelAudience.trackPageView(selectedTab.label);
    };

    $scope.trackRefine = function () {
        mixpanelAudience.trackRefine();
    };

    $scope.audienceInfoTooltipContent = function() {
        const channel = (context.current.audience_app || {}).current_channel;
        const audienceName = (context.current.audience_app[channel.value] || {}).audience_name;
        const audienceSegment = (context.current.audience_app[channel.value] || {}).audience_segment;
        const divWrapper = (content) => `<div style="line-height: 19px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${content}</div>`
        let tooltipContent =  _.isEmpty(audienceName) ? '' : divWrapper(`<strong>Audience Name:</strong> ${audienceName}`);
        tooltipContent += channel.label === 'All' ? '' : divWrapper(`<strong>Channel:</strong> ${channel.label}`);
        audienceSegment.forEach((segment) => {
            const segmentTypeLabel = SEGMENT_MAP[segment['type']]['label'];
            tooltipContent += divWrapper(`<strong>${segmentTypeLabel}:</strong> ${getSegmentValuesSummary(segment, filtersPartition)}`);
        });

        return tooltipContent;
    }

    $scope.$watch('selectedUser', function () {
        if($scope.selectedUser && $scope.selectedTab.value === 'timeline') mixpanelAudience.trackTimelineUserExample($scope.selectedUser);
    });
}

module.exports = audienceExplorerModule;
