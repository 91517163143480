
module.exports = angular.module(__filename, [
    require('./content-driver/content-driver.drv.js').name
]).directive('contentDriversPane', ['directiveBuildHelper', 'TargetsCommon', function (directiveBuildHelper, TargetsCommon) {
    var dataPattern = '(@model as)? (@url is url of)? (@) (@title for)? (_instance_ in @source@) (track by @index)?';
    var Parser = directiveBuildHelper.compile(dataPattern);
    return {
        restrict: "E",
        controllerAs: 'contentCtrl',
        transclude: true,
        replace: 'element',
        template: require('./content-drivers-pane.drv.html'),
        scope: {
            driversTitle: '=?',
            filters: '=?',
            filterType: '=?'
        },
        link: function (scope, iElement, iAttrs, controller) {
            iElement.removeAttr('title');
        },
        controller: ['$scope', '$element', '$attrs', '$transclude', '$rootScope', '$timeout', 'utils', 'TARGET_CONSTS', 'abiPermissions',
            function (scope, element, attr, $transclude, $rootScope, $timeout, utils, TARGET_CONSTS, abiPermissions) {

                scope.hasPermission = abiPermissions.hasPermission;

                if(scope.$parent.contentDriversTargets){
                    scope.contentDriversTab = "target_drawer";
                    open();
                }else{
                    scope.contentDriversTab = "content_drivers";
                }

                scope.$on('openContentDrivers', function (event, tab) {
                    open(tab);
                });

                scope.$on('closeContentDrivers', function () {
                    close();
                });

                scope.filterNonDynamic = function(cell){
                    return !cell.dynamic;
                };

                scope.showTargetLoading = ()=>{
                    return scope.$parent.targetLoading && 
                        scope.$parent.currentTarget.target_type==
                        TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE;
                }

                scope.isSupportForecast = ()=>{
                    return TargetsCommon.TargetData.isSupportForecast(scope.$parent.currentTarget);
                }

                _.extend(this, {
                    clickTab: clickTab,
                    close: close,
                    removeItem: removeItem,
                    removeFolder: removeFolder,
                    openCloseFolder: openCloseFolder,
                    toggleByFilter: toggleByFilter,
                    getDisplay: getDisplay,
                    removeAllFromTarget: removeAllFromTarget,
                    removeAllFromTargetCB: removeAllFromTargetCB
                });

                function open(tab) {
                    utils.triggerResize(element);

                    if(tab)
                        scope.contentDriversTab = tab;

                    if (!element[0].classList.contains('ng-hide'))
                        $(".main-content-page").addClass("content-drivers-open");

                    scope.targetFolders = scope.$parent.targetFolders;
                    scope.targetFoldersLength = scope.$parent.targetFoldersLength;
                    scope.contentDriversOpen = true;
                    scope.$parent.contentDriversOpen = true;
                }

                function clickTab(tab){

                    scope.contentDriversTab=tab;
  
                    if(!scope.contentDriversOpen){
                        open();
                        scope.$emit('contentDriversPaneOpenByClick');
                        TargetsCommon.mixpanelTargets.trackContentDriversPaneOpen();
                    }
                }

                function close() {
                    scope.contentDriversOpen = false;
                    scope.$parent.contentDriversOpen = false;
                    utils.triggerResize();
                    $(".main-content-page").removeClass("content-drivers-open");
                }

                function openCloseFolder(key){
                    if(scope.targetFoldersLength>1){
                        scope.targetFolders[key].open = !scope.targetFolders[key].open;
                    }
                }

                function toggleByFilter(filter) {
                    scope.$emit("contentDriversPaneToggled", filter);
                }

                function getDisplay(cell, key, tooltip){

                    let display = '';

                    if(cell.manual){
                        display = cell.text; //icon handled in view
                    }
                    else if(key=='phrases' || key=='websites'){
                        display = cell.phrase;

                    }else if(key=='hashtags'){
                        display = cell.hashtag;

                    }else if(key=='influencers' || key=='audience'){
                        display = cell.handle;

                    }else if(key=='pages'){
                        display = cell.name;

                    }else if(key=='interests'){
                        display = cell.id;

                    }else if(key=='emojis'){
                        display = '';//handled in view
                    }

                    let MAX_DISPLAY_LENGTH = 30;
                    if(display.length > MAX_DISPLAY_LENGTH && !tooltip){
                        display = display.slice(0, MAX_DISPLAY_LENGTH)+"...";
                    }

                    return display;
                }

                function removeItem(item, dataType){
                    removeItemCB([item], dataType);
                }

                function removeAllFromTarget(){
                    TargetsCommon.removeAllFromTarget(scope.$parent.currentTarget, scope.contentCtrl);
                }

                function removeAllFromTargetCB(){
                    TargetsCommon.removeAllFromTargetCB(scope.$parent.currentTarget, scope);       
                    scope.removingAll = false;  
                }

                function removeFolder(dataType){
                    removeItemCB(scope.$parent.currentTarget.results[dataType], dataType);
                }

                function removeItemCB(items, dataType){
                    TargetsCommon.removeFromTarget(scope.$parent.currentTarget, dataType, items, scope);
                }
            }
        ]
    }
}]);
