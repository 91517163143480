import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, flatMap, groupBy, map } from 'lodash';
import './CommercialsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import WindowEventListener from '../../../../../common/WindowEventListener/WindowEventListener';

const CommercialsModal = ({ isOpen, modalTitle, onSubmit, onCancel, commercialsInput, commercialsMetadataPromise, channel }) => {

  const SEGMENT_TYPE = channel === 'smart_tv' ? 'smartTvCommercials' : 'linearTvCommercials';
  const RESPONSE_ATTRIBUTES = channel === 'smart_tv' ? ['brands', 'creatives', 'brand_to_creatives'] : ['brand_parents', 'brands', 'creatives', 'brand_parent_to_brands', 'brand_to_creatives'];
  const [brandParentToBrands, setBrandParentToBrands] = useState({});
  const [brandToCreatives, setBrandToCreatives] = useState({});
  const [allBrands, setAllBrands] = useState([]);
  const [allCreatives, setAllCreatives] = useState([]);
  const [brandParentValues, setBrandParentValues] = useState([]);
  const [brandValues, setBrandValues] = useState([]);
  const [creativeValues, setCreativeValues] = useState([]);
  const [selectedBrandParents, setSelectedBrandParents] = useState(commercialsInput.brandParents || []);
  const [selectedBrands, setSelectedBrands] = useState(commercialsInput.brands || []);
  const [selectedCreatives, setSelectedCreatives] = useState(commercialsInput.creatives || []);
  const [showSpinner, setShowSpinner] = useState(true);
  const [modalMaxHeight, setModalMaxHeight] = useState('491px');

  const setModalMaxHeights = () => {
    setModalMaxHeight(window.innerHeight > 900 ? '491px' : '412px');
  };

  useEffect(() => {
    setModalMaxHeights();
  });

  const fetchCommercialsMetadata = async () => {
    const res = await commercialsMetadataPromise;
    const isDataLoadSuccessfully = RESPONSE_ATTRIBUTES.every((property) => res[property]);
    if (!isDataLoadSuccessfully) throw new Error('One or more of the promise properties are missing (brand_to_creatives, brand_to_parents, creative_to_brands, parent_to_brands)');
    setBrandParentValues(res.brand_parents);
    setBrandValues(res.brands);
    setCreativeValues(res.creatives);
    setAllBrands(res.brands);
    setAllCreatives(res.creatives);
    setBrandParentToBrands(res.brand_parent_to_brands);
    setBrandToCreatives(res.brand_to_creatives);

    if (channel === 'linear_tv' && selectedBrandParents.length === 0 || channel === 'smart_tv' && selectedBrands.length === 0) { 
      setShowSpinner(false); 
      return;
    }
    const selectedCreativeValues = new Set(flatMap(commercialsInput.brands, (brand) => res.brand_to_creatives[brand.value]));
    const creatives = res.creatives.filter((creative) => [...selectedCreativeValues].includes(creative.value));
    const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({ label, value: map(values, 'value') }));
    setCreativeValues(mergedCreatives);

    if (channel === 'smart_tv') { 
      setShowSpinner(false);
      return;
    }
    const selectedBrandsValues = new Set(flatMap(commercialsInput.brandParents, (parent) => res.brand_parent_to_brands[parent.value]));
    const brands = res.brands.filter((brand) => [...selectedBrandsValues].includes(brand.value));
    setBrandValues(brands);
    setShowSpinner(false);
  };

  useEffect(() => {
    fetchCommercialsMetadata();
  }, [commercialsMetadataPromise]);

  const handleSubmit = useCallback(
    () => {
      onSubmit({ ...commercialsInput, type: SEGMENT_TYPE, channel, ...(channel === 'linear_tv' && { brandParents: selectedBrandParents }), brands: selectedBrands, creatives: selectedCreatives });
    },
    [selectedCreatives, selectedBrands, selectedBrandParents, brandParentValues, brandValues, creativeValues],
  );

  const handleCancel = useCallback(
    () => {
      onCancel(SEGMENT_TYPE);
    },
    [],
  );

  const onParentsSelect = useCallback(
    (parents) => {
      const selectedBrandsValues = [...new Set(flatMap(parents, (parent) => brandParentToBrands[parent.value]))];
      const brands = allBrands.filter((brand) => selectedBrandsValues.includes(brand.value));
      setSelectedBrandParents(parents);
      setBrandValues(brands);
      onBrandsSelect(brands);
    },
    [allBrands, brandParentToBrands, brandToCreatives],
  );

  const onBrandsSelect = useCallback(
    (brands) => {
      const selectedCreativesValueMemory = createSelctedCreativesMemory(brands);
      const creatives = allCreatives.filter((creative) => selectedCreativesValueMemory[creative.value]);
      const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({ label, value: map(values, 'value') }));
      setSelectedBrands(brands);
      setCreativeValues(mergedCreatives);
      onCreativesSelect(mergedCreatives);
    },
    [allCreatives, brandToCreatives],
  );

  const onCreativesSelect = useCallback(
    (creatives) => {
      setSelectedCreatives(creatives);
    },
    [],
  );

  const createSelctedCreativesMemory = useCallback(
    (brands) => {
      const selectedCreativeValueMemory = {};
      brands.forEach((brand) => {
        brandToCreatives[brand.value].forEach((creativeValue) => selectedCreativeValueMemory[creativeValue] = 1);
      });
      return selectedCreativeValueMemory;
    },
    [brandToCreatives],
  );

  const parentsSummaryTextBuilder = (selectedValues, values) => {
    if (!selectedValues.length || selectedValues.length === values.length) return 'Select parent brands';
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return 'Select';
    if (selectedValues.length === 1) return selectedValues[0]['label'];
    return selectedValues.map((value) => value.label).join(', ');
  };

  const brandsSummaryTextBuilder = (selectedValues, values) => {
    if (!selectedValues.length || selectedValues.length === values.length) return 'All brands';
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return 'Select';
    if (selectedValues.length === 1) return selectedValues[0]['label'];
    return selectedValues.map((value) => value.label).join(', ');
  };

  const creativesSummaryTextBuilder = (selectedValues, values) => {
    if (!selectedValues.length || selectedValues.length === values.length) return 'All creatives';
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return 'Select';
    if (selectedValues.length === 1) return selectedValues[0]['label'];
    return selectedValues.map((value) => value.label).join(', ');
  };

  return (
    <div className="commercials-modal-component">
      <WindowEventListener events="resize" eventHandlerFunction={ setModalMaxHeights } />
      <ModalWithConfirmationButtons
        width="800px"
        maxHeightBeforeScroll={ modalMaxHeight }
        modalTitle={ modalTitle }
        isOpen={ isOpen }
        onSubmit={ handleSubmit }
        onCancel={ handleCancel }
        showSpinner={ showSpinner }
        isSubmitDisabled={ isEmpty(selectedCreatives) }
        isDisabledEnterKeyPress={ isEmpty(selectedCreatives) }>
        <div className="commercials-modal-content">
          { channel === 'linear_tv' && <div className="commercials-modal-row">
            <div className="dropdown-label">Parent brand:</div>
            <Dropdown
              shouldCloseOnClickOutside={ false }
              selectedValues={ selectedBrandParents }
              isMulti={ true }
              isSearchable={ true }
              summaryTextBuilder={ parentsSummaryTextBuilder }
              onSelect={ onParentsSelect }
              values={ brandParentValues }
              menuMaxHeight={ 128 }>
            </Dropdown>
          </div> }
          <div className="commercials-modal-row">
            <div className="dropdown-label">Brand:</div>
            <Dropdown
              shouldCloseOnClickOutside={ false }
              selectedValues={ selectedBrands }
              isMulti={ true }
              isSearchable={ true }
              isDisabled={ channel === 'linear_tv' ? isEmpty(selectedBrandParents) : false }
              summaryTextBuilder={ brandsSummaryTextBuilder }
              onSelect={ onBrandsSelect }
              showSelectAllOptions={ channel === 'linear_tv' }
              values={ brandValues }
              menuMaxHeight={ 92 }
              isOpen={ isEmpty(selectedBrandParents) ? false : undefined }>
            </Dropdown>
          </div>
          <div className="commercials-modal-row">
            <div className="dropdown-label">Creative:</div>
            <Dropdown
              shouldCloseOnClickOutside={ false }
              selectedValues={ selectedCreatives }
              isMulti={ true }
              isSearchable={ true }
              isDisabled={ isEmpty(selectedBrands) }
              summaryTextBuilder={ creativesSummaryTextBuilder }
              onSelect={ onCreativesSelect }
              showSelectAllOptions={ true }
              values={ creativeValues }
              menuMaxHeight={ 92 }
              isOpen={ isEmpty(selectedBrands) || (channel === 'linear_tv' && isEmpty(selectedBrandParents)) ? false : undefined }>
            </Dropdown>
          </div>
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

CommercialsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  commercialsMetadataPromise: PropTypes.object,
  commercialsInput: PropTypes.object,
  channel: PropTypes.string
};

CommercialsModal.defaultProps = {
  commercialsInput: {},
  channel: ''
};

export default CommercialsModal;
