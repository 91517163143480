import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import classNames from 'classnames';
import './GenderDistribution.scss';

const genderIconHeightByRatio = (currentValue, femaleValue, maleValue) => `calc(((100% - 130px) / ${femaleValue + maleValue}) * ${currentValue})`;

const GenderDistribution = ({ femaleValue, femaleDisplayValue, femaleTooltip, maleValue, maleDisplayValue, maleTooltip }) => (
  <div className="gender-distribution-component">
    <div className={ classNames('gender-wrapper', { 'highlighted': maleValue > femaleValue }) }>
      <Tooltip content={ maleTooltip }>
        <Icon
          iconId="male-detailed"
          className={ classNames('gender-icon', { 'disabled': maleValue === 0 }) }
          height={ genderIconHeightByRatio(maleValue, femaleValue, maleValue) }>
        </Icon>
      </Tooltip>
      <div className="percent-label">{ maleDisplayValue }</div>
      <div className="gender-label">Male</div>
    </div>
    <div className={ classNames('gender-wrapper', { 'highlighted': femaleValue > maleValue }) }>
      <Tooltip content={ femaleTooltip }>
        <Icon
          iconId="female-detailed"
          className={ classNames('gender-icon', { 'disabled': femaleValue === 0 }) }
          height={ genderIconHeightByRatio(femaleValue, femaleValue, maleValue) }>
        </Icon>
      </Tooltip>
      <div className="percent-label">{ femaleDisplayValue }</div>
      <div className="gender-label">Female</div>
    </div>
  </div>
);

GenderDistribution.propTypes = {
  femaleValue: PropTypes.number.isRequired,
  femaleDisplayValue: PropTypes.string.isRequired,
  femaleTooltip: PropTypes.string,
  maleValue: PropTypes.number.isRequired,
  maleDisplayValue: PropTypes.string.isRequired,
  maleTooltip: PropTypes.string
};

GenderDistribution.defaultProps = {
  femaleTooltip: null,
  maleTooltip: null
};

export default GenderDistribution;
