"use strict";

const common = require("infra/utils/common");
const exportHelper = require("infra/utils/export");
const mixpanel = require("infra/mixpanel/mixpanel-discovery");

module.exports = angular.module(__filename, []).service('discoveryStreamService', ['streamsService', 'Excel', 'context', '$state', '$rootScope', 'util', 'abiPermissions', 'mixpanelDiscovery', 'topicsTree',
    function(streamsService, Excel, context, $state, $rootScope, util, abiPermissions, mixpanelDiscovery, topicsTree) {

        return {getWorkbook};

        function getWorkbook() {
            const current = context.current;
            const amount = 1000;

            let channels = [], programSources = [], sourcesMap = {};
            if(current.showCustomSources) {
                let allSources = context.program.program_sources;
                programSources = current.channels.length > 0 ? _.intersectionBy(allSources, current.channels, 'id') : allSources;
                sourcesMap = _(programSources).keyBy('name').mapValues(ps => _.map(ps.sources, 'id')).value();
            } else {
                channels = streamsService.getChannels(current.channels);
            }

            const channel_sources = _.groupBy(channels, common.getSource);
            const shouldSendAudience = Object.keys(channel_sources).length === 1 && ['articles', 'sg_telco'].includes(Object.keys(channel_sources)[0]);
            const hasSgTelcoAudiencePermission = abiPermissions.hasPermission('sg telco audience filters');
            let audience = [];
            if(shouldSendAudience && ((channels.length > 1) || channels[0] !== 'sg_telco' || hasSgTelcoAudiencePermission)) {
                audience = common.getAudience(current, channels);
            }

            const queryConfig = {
                timeframe: current.discovery_timeframe,
                phrases: util.getTerms(current.terms, false),
                boolean_logic_phrases: util.getTerms(current.terms, true),
                terms_ids: util.getPhrasesToIdMap(current.terms),
                excluded_phrases: null,
                channels: channels,
                article_types: _.map(current.articleType, 'value'),
                topics: _.map(current.topics, 'id'),
                geos: _.map(current.geo, 'id'),
                sub_geos: current.discoverySubGeos,
                language: current._language_mold.getLanguage($state, context).value,
                audience: _.map(audience, 'value'),
                sensitive_content: context.program.sensitive_content,
                blend: false,
                test_mode: $rootScope.user.userType === 'debug',
                trending_topics: context.program.trending_topics,
                audience_mappings: context.program.audience_mappings,
                program_sources: _(programSources).map('sources').flatten().groupBy('channel').mapValues(v => _.map(v, 'id')).value()
            };

            const ids_only = false, no_scroll = true;
            const additional_fields = ['view_date', 'main_topic', 'sentiment.comments'];
            return streamsService.streams(null, queryConfig, amount, ids_only, no_scroll, additional_fields).promise.then(function(streamData) {
                const workbook = Excel.builder.createWorkbook();
                const t = Excel.formater(workbook);

                const generatedDate = moment().format('YYYY-MM-DD HH:mm');

                /* Summery page */
                const summarySheet = workbook.createWorksheet({name: 'Summary'});

                const geos = $rootScope.Geos.geosForChannel(current.geo, $state, context);
                const sub_geos = context.current.discoveryAllSubGeosSelected ? 'all' :
                                  $rootScope.Geos.indexedSubGeosTreeHelper($rootScope.subGeosTree, (geos[0] || {}).id)
                                                 .contextSubGeosHelper($rootScope.Geos.subGeosForChannel(current.discoverySubGeos, $state, context))
                                                 .getOnlyTopCheckedSubGeos();
                const topics = topicsTree.isAllTopics(current.topics) ? null : current.topics;

                let grid = [
                    [t('Generated At:', 'bold'), generatedDate],
                    [t('Content From:', 'bold'), queryConfig.timeframe.join(' ')],
                    [t('Program:', 'bold'), context.program.name],
                    [t('Seeds:', 'bold'), t(_.or(common.rejectBooleanLogics(current.terms), 'None'), 'display')],
                    [t('Refine interests:', 'bold'), t(_.or(common.selectBooleanLogics(current.terms), 'None'), 'display')],
                    [t('Selected Topics:', 'bold'), t(_.or(topics, 'all'), 'name')],
                    [t('Selected Audience:', 'bold'), _.or(common.titleize(_(audience).map('summary').compact().join(', ')), 'all')]];

                if(_.isEqual(queryConfig.channels, ["articles"]) &&
                    abiPermissions.hasPermission('first party segments') &&
                    !_.isEmpty(context.current.firstPartyAudience)) {
                    grid.push([t('1st Party Segments: ', 'bold'),
                              t(_.map(context.current.firstPartyAudience, 'summary').join(', '), 'summary')]);
                }

                grid = _.concat(grid, [
                    [t('Geo:', 'bold'), t(_.or(geos, 'all'), 'label')],
                    [t('Sub Geo:', 'bold'), t(_.or(sub_geos, 'all'), 'name')],
                    [t('Language:', 'bold'), t(_.or(queryConfig.language, 'all'), 'label')],
                    [t('Article types:', 'bold'), t(_.or(current.articleType, 'all'), 'label')]]);

                const bls = util.getTerms(common.selectBooleanLogics(current.terms), true);
                if(!_.isEmpty(bls)) {
                    const boolSummary = exportHelper.getBooleansLoginSummary(bls, {});
                    grid.push([]); // new line
                    grid.push([t('Refine interests:', 'bold')].concat(boolSummary.title));
                    grid = grid.concat(boolSummary.data);
                }

                summarySheet.setColumns([{width: 20}, {width: 20}]);
                summarySheet.setData(grid);
                workbook.addWorksheet(summarySheet);

                const pagesMap = {
                    article: {
                        columns: {
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            URL: {key: 'url', width: 20, type: 'text'},
                            Domain: {key: 'domain', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'date'},
                            "Last Viewed": {key: 'view_date', width: 20, type: 'fullDate'},
                            Shares: {key: 'shares', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'},
                            "Indexed Consumption": {key: 'indexed_consumption', width: 20, type: 'numeric'}
                        },
                        name: 'Web'
                    },
                    video: {
                        columns: {
                            "Video URL": {key: 'url', width: 20, type: 'text'},
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            Provider: {key: 'provider', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'date'},
                            "Last Viewed": {key: 'view_date', width: 20, type: 'fullDate'},
                            Views: {key: 'views', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Dislikes: {key: 'dislikes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'}
                        },
                        name: 'Video'
                    },
                    tweet: {
                        columns: {
                            "User Name": {key: 'user_display_name', width: 20, type: 'text'},
                            Followers: {key: 'followers', width: 20, type: 'text'},
                            "@handle": {key: 'user', width: 20, type: 'mention'},
                            "Verified User": {key: 'verified', width: 20, type: 'text'},
                            Tweet: {key: 'desc', width: 20, type: 'text'},
                            "Tweet Date": {key: 'published', width: 20, type: 'fullDate'},
                            "Last Retweet Date": {key: 'view_date', width: 20, type: 'fullDate'},
                            Retweets: {key: 'retweets', width: 20, type: 'text'},
                            Topic: {key: 'main_topic', width: 20, type: 'topic'},
                            Link: {key: 'url', width: 20, type: 'text'},
                            Hashtags: {key: 'hashtags', width: 20, type: 'simple_array'},
                            Mentions: {key: 'mentions', width: 20, type: 'simple_array'}
                        },
                        name: 'Twitter'
                    },
                    facebook: {
                        columns: {
                            "User Name": {key: 'user_display_name', width: 20, type: 'text'},
                            "Post Message": {key: 'desc', width: 20, type: 'text'},
                            "Post Date": {key: 'published', width: 20, type: 'fullDate'},
                            Likes: {key: 'fb_likes', width: 20, type: 'text'},
                            Comments: {key: 'fb_comments', width: 20, type: 'text'},
                            Shares: {key: 'fb_shares', width: 20, type: 'text'},
                            Topic: {key: 'main_topic', width: 20, type: 'topic'},
                            "Post URL": {key: 'url', width: 20, type: 'text'},
                            "Page URL": {key: 'page', width: 20, type: 'text'},
                            Link: {key: 'link', width: 20, type: 'text'},
                            "Link Title": {key: 'link_title', width: 20, type: 'text'},
                            "Link Description": {key: 'link_description', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'}
                        },
                        name: 'Facebook'
                    },
                    instagram: {
                        columns: {
                            "User Name": {key: 'user_display_name', width: 20, type: 'text'},
                            "Post Message": {key: 'desc', width: 20, type: 'text'},
                            "Post Date": {key: 'published', width: 20, type: 'fullDate'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            Topic: {key: 'main_topic', width: 20, type: 'topic'},
                            "Post URL": {key: 'url', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'}
                        },
                        name: 'Instagram'
                    },
                    sg_telco: {
                        columns: {
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            URL: {key: 'url', width: 20, type: 'text'},
                            Domain: {key: 'domain', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'date'},
                            "Last Viewed": {key: 'view_date', width: 20, type: 'fullDate'},
                            Shares: {key: 'shares', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'},
                            "Indexed Consumption": {key: 'indexed_consumption', width: 20, type: 'numeric'}

                        },
                        name: 'Web - SG Telco'
                    },
                    au_telco: {
                        columns: {
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            URL: {key: 'url', width: 20, type: 'text'},
                            Domain: {key: 'domain', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'date'},
                            "Last Viewed": {key: 'view_date', width: 20, type: 'fullDate'},
                            Shares: {key: 'shares', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'},
                            "Indexed Consumption": {key: 'indexed_consumption', width: 20, type: 'numeric'}
                        },
                        name: 'AU Telco'
                    },
                    customSources: {
                        columns: {
                            Channel: {key: 'type', width: 20, type: 'text'},
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            URL: {key: 'url', width: 20, type: 'text'},
                            Author: {key: 'user', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'fullDate'},
                            Shares: {key: 'shares', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            Views: {key: 'views', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'},
                            Topic: {key: 'main_topic', width: 20, type: 'topic'}
                        },
                        name: 'Custom Sources'
                    },
                    customSourcesWithoutSentiment: {
                        columns: {
                            Channel: {key: 'type', width: 20, type: 'text'},
                            Title: {key: 'title', width: 20, type: 'text'},
                            Description: {key: 'desc', width: 20, type: 'text'},
                            URL: {key: 'url', width: 20, type: 'text'},
                            Author: {key: 'user', width: 20, type: 'text'},
                            "Publish Date": {key: 'published', width: 20, type: 'fullDate'},
                            Shares: {key: 'shares', width: 20, type: 'text'},
                            Likes: {key: 'likes', width: 20, type: 'text'},
                            Comments: {key: 'comments', width: 20, type: 'text'},
                            Views: {key: 'views', width: 20, type: 'text'},
                            "Interest Score": {key: 'interest', width: 20, type: 'text'},
                            Topic: {key: 'main_topic', width: 20, type: 'topic'}
                        },
                        name: 'Custom Sources w/o Sentiment'
                    }
                };

                if(queryConfig.sort_by === 'interest') {
                    pagesMap.tweet.columns["Interest Score"] = {key: 'interest', width: 20, type: 'text'};
                }

                const commentsSentiments = {
                    "Comments Sentiment - Positive": {key: 'sentiment_comments.pos', width: 30, type: 'percent'},
                    "Comments Sentiment - Negative": {key: 'sentiment_comments.neg', width: 30, type: 'percent'},
                    "Comments Sentiment - Neutral": {key: 'sentiment_comments.neu', width: 30, type: 'percent'}
                };

                addIfPermission('comments sentiment', commentsSentiments, pagesMap.instagram.columns, pagesMap.facebook.columns, pagesMap.customSources.columns);

                if(current.showCustomSources) {
                    streamData.docs.forEach(doc => doc.source_name = _.findKey(sourcesMap, a => a.includes(doc.source_id)));
                    streamData = _.groupBy(streamData.docs, 'source_name');
                    Object.keys(pagesMap).forEach(function(sourceColumn) {
                        let data = streamData[sourceColumn];
                        if(data) {
                            let columns = data[0].type == 'facebook' ? pagesMap.customSources.columns : pagesMap.customSourcesWithoutSentiment.columns;
                            Excel.addTableSheet(workbook, data.map(buildSourceDoc), columns, {name: sourceColumn});
                        }
                    });
                } else {
                    streamData = _.groupBy(streamData.docs, 'type');
                    Object.keys(pagesMap).forEach(function(sourceColumn) {
                        let data = streamData[sourceColumn];
                        if(data) {
                            data = processChannelData(data, sourceColumn);
                            Excel.addTableSheet(workbook, data, pagesMap[sourceColumn].columns, {name: pagesMap[sourceColumn].name});
                        }
                    });
                }

                workbook.fileName = `Stream ${generatedDate}.xlsx`;
                mixpanelDiscovery.trackExport();
                return workbook;
            });
        }

        function addIfPermission(permission, columnsToAdd, ...pageColumns) {
            if(abiPermissions.hasPermission(permission)) {
                pageColumns.forEach(col => Object.assign(col, columnsToAdd));
            }
        }

        function processChannelData(docs, type) {
            switch(type) {
                case 'article':
                case 'sg_telco':
                case 'au_telco':
                    const maxVolume = Math.max(...docs.map(doc => doc.volume));
                    return docs.map(function(doc) {
                        return Object.assign(doc, {indexed_consumption: 100.0 * doc.volume / maxVolume})
                    });
                default:
                    return docs
            }
        }

        function buildSourceDoc(doc) {
            switch(doc.type) {
                case 'facebook':
                    return facebookDoc(doc);
                case 'tweet':
                    return tweetDoc(doc);
                case 'video':
                    return videoDoc(doc);
                case 'pinterest':
                    return pinterestDoc(doc);
                default:
                    console.log(doc.type + ' not supported');
                    return {};
            }
        }

        function facebookDoc(doc) {
            return Object.assign(
                _.pick(doc, ['type', 'desc', 'url', 'published', 'interest', 'main_topic', 'sentiment_comments']),
                {title: dox.link_title, user: doc.user_display_name, shares: doc.fb_shares, likes: doc.fb_likes, comments: doc.fb_comments, views: null}
            );
        }

        function tweetDoc(doc) {
            return Object.assign(
                _.pick(doc, ['type', 'desc', 'url', 'user', 'published', 'interest', 'main_topic']),
                {title: null, shares: null, likes: null, comments: doc.retweets, views: null}
            );
        }

        function videoDoc(doc) {
            return Object.assign(
                _.pick(doc, ['title', 'desc', 'url', 'published', 'likes', 'comments', 'views', 'interest', 'main_topic']),
                {type: doc.provider, shares: null});
        }

        function pinterestDoc(doc) {
            return Object.assign(
                _.pick(doc, ['type', 'desc', 'url', 'published', 'interest', 'main_topic', 'likes', 'comments']),
                {title: doc.link_title, user: doc.user_display_name, shares: doc.repins, views: null}
            );
        }
    }
]);
