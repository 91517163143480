"use strict";
import config from 'infra/config';

module.exports = require("angular").module(__filename, [])
    .service("tvService", ['$http', function ($http) {

        const GET_SHOWS_BY_IDS_URL = config.USER_MGMT_API + '/tv/shows_by_ids';
        const GET_NETWORKS_URL = config.USER_MGMT_API + '/tv/networks';
        const GET_SHOWS_URL = config.USER_MGMT_API + '/tv/shows';
        const GET_NIELSEN_NETWORKS_MAP_URL = config.USER_MGMT_API + '/tv/nielsen_networks_map';

        return {
            getShowsByids: getShowsByids,
            getNetworks: getNetworks,
            getShows: getShows,
            getNielsenNetworksMap: getNielsenNetworksMap
        };

        function getShowsByids(ids) {
            return $http.post(GET_SHOWS_BY_IDS_URL, {'ids': ids}).then((res)=>{
                return res.data;
            })
        }

        function getNetworks(channel) {
            return $http.get(`${GET_NETWORKS_URL}/${channel}`);
        }

        function getShows(channel) {
            return $http.get(`${GET_SHOWS_URL}/${channel}`);
        }

        function getNielsenNetworksMap() {
            return $http.get(GET_NIELSEN_NETWORKS_MAP_URL).then((res) => res.data);
        }
    }]
);
