const BaseWidget = require("../base_widget");
UserExamplesDropdownWidgetController.$inject = ['audienceInsightsService', 'context'];
const TV_CHANNELS = ['linear_tv', 'smart_tv'];

function UserExamplesDropdownWidgetController(audienceInsightsService, context) {
    if (this.$scope.channel == "linkedin") return;
    
    const self = this;
    const segment = context.current.audience_app[self.$scope.channel].audience_segment,
        targetRatio = context.current.audience_segment_target_ratio,
        targetRatioObj = targetRatio ? {'wanted-intenders-ratio': targetRatio} : undefined;
    const searchType = TV_CHANNELS.includes(this.$scope.channel) ? 'tv' : 'xw';
    audienceInsightsService.getSegmentInterestsData(segment, searchType, self.$scope.channel, targetRatioObj).then(function(data) {
        if(!data || _.isEmpty(data['users-sample'])) self.$scope.errorOccurred = data.status;
        self.$scope.userExamples = addDisplayLabelToUser(data['users-sample']);
        self.$scope.selectedUser = self.$scope.userExamples[0]
    })
}

function addDisplayLabelToUser(usersData) {
    return _.map(usersData, function(user, index) {
        return Object.assign(user, {exampleId: (index + 1), display: 'Example #' + (index + 1) + ' - ' + user.gender + ' ' + user.age})
    })
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name
])
    .directive("userExamplesDropdownWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./user-examples-dropdown-widget.html"),
        scope: {
            selectedUser: "=",
            errorOccurred: "=",
            channel: "="
        },
        controller: UserExamplesDropdownWidgetController
    })]);