'use strict';
import './brand-intelligence.scss';

//brand-intelligence 3rd party dependencies
import angular from 'angular';
import ngAnimate from 'angular-animate';
import angularUiRouter from 'angular-ui-router';
import angularCache from 'angular-cache';
import 'angular-inview';
import 'angular-scroll';
import '@iamadamjowett/angular-click-outside';
import 're-tree';
import 'ng-device-detector';
moment_round.monkey(moment); //extend moment with moment-round functions

//brand-intelligence pages
import resetPwd from './pages/reset_password/reset_password';
import dashboard from './pages/dashboard/dashboard';
import insights from './pages/insights/insights';
import discovery from './pages/discovery/discovery';
import warroom from './pages/warroom/warroom';
import settings from './pages/settings/settings';
import alerts from './pages/alerts/alerts';
import alertsV2 from './pages/alerts-v2/alerts-v2';
import audienceBuilder from './pages/audience-builder/audience-builder';
import audienceExplorer from './pages/audience-explorer/audience-explorer';
import helpCenter from './pages/help-center/help-center';
import config from 'infra/config';
import 'common/lodash.srv.js';
import nv from "nvd3";

//react
import { react2angular } from 'react2angular';
import SingleSelectToggle from 'react/components/common/SingleSelectToggle/SingleSelectToggle';
import Dropdown from 'react/components/common/Dropdown/Dropdown/Dropdown';
import SideMenuDropdown from 'react/components/common/Dropdown/SideMenuDropdown/SideMenuDropdown';
import ClickableLabel from './react/components/common/ClickableLabel/ClickableLabel';
import ClickableIcon from './react/components/common/ClickableIcon/ClickableIcon';
import Accordion from 'react/components/common/Accordion/Accordion';
import BarChart from 'react/components/common/BarChart/BarChart';
import SubmitButton from 'react/components/common/SubmitButton/SubmitButton';
import TabSelector from 'react/components/common/TabSelector/TabSelector';
import ConfirmationModal from 'react/components/common/Modal/ConfirmationModal/ConfirmationModal';
import ChannelFilterSideMenu from 'react/components/common/SideMenu/ChannelFilterSideMenu/ChannelFilterSideMenu';
import {setStateService} from 'react/middleware/AngularExportedMiddleware';
var HttpClient = require('./react/infra/HttpClient');

const biDependencies = [
  ngAnimate,
  angularUiRouter,
  angularCache,
  'angular-inview',
  'duScroll',
  'angular-click-outside',
  'reTree',
  'ng.deviceDetector',

  require("infra/authentication/authentication-config").name,
  require('infra/app-constants').name,
  require('infra/input-directives').name,
  require("infra/user").name,
  require("infra/state-helper").name,
  require('common/program-selector.drv/program-selector.drv').name,
  require("infra/topbar/topbar").name,
  require("infra/menu/menu.js").name,
  require("infra/context/context").name,

  require("infra/notifications_deck/notification_deck").name,
  require("infra/error_handlers/global_http_errors").name,
  require("infra/error_handlers/error_mgmt.service").name,
  require("infra/error_handlers/logging-service").name,

  require("infra/context/filters/timeframe-mold").name,
  require("infra/context/filters/geo-mold").name,
  require("infra/context/filters/general-mold").name,
  require("infra/context/filters/terms-mold").name,
  require("infra/context/filters/boolean-mold").name,
  require("infra/context/filters/string-mold").name,
  require("infra/context/filters/audience-mold").name,
  require("infra/context/filters/sgTelcoAudience-mold").name,
  require("infra/context/filters/topics-mold").name,

  require("infra/user_access/abi-permissions").name,
  require("infra/device-detector").name,
  require("infra/cancellable-http").name,
  require('common/input-bar.drv/input-bar.drv.js').name,

  require("pages/settings/settings").name,
  require("pages/dashboard/dashboard").name,
  require("pages/insights/insights").name,
  require("pages/discovery/discovery").name,
  require("pages/warroom/warroom").name,
  require('pages/alerts/alerts').name,
  require('pages/alerts-v2/alerts-v2').name,
  require("pages/audience-builder/audience-builder").name,
  require("pages/audience-explorer/audience-explorer").name,
  require("pages/reset_password/reset_password").name,

  require('common/sly-scrollbar.drv/slyScrollbar.drv.js').name,

  require('common/utils.srv.js').name,
  require('common/filters/slice.fltr.js').name,
  require('common/form-components/input-validators/email-form-validator.directive').name,
  require('common/form-components/input-validators/strong-password.directive').name,
  require('common/datePicker.drv/datePicker.drv.js').name,
  require('common/excelBuilder/excel.srv.js').name,
  require("common/modals/password/forgot-password.modal.service").name,
  require("common/modals/password/set-password.modal.service").name,
  require("common/modals/notification/notification-modal.service").name,
  require("common/am-on-key-press.drv.js").name,
  require('common/tooltip/tooltip.drv.js').name,
  require('common/plus-minus/plus-minus.js').name,
  require('common/dotted-slider/dotted-slider.js').name,
  require("common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv").name,
  require("common/heat-map/heat-map").name,
  require("common/sso/sso-service").name,

  require('data/filters-partition.srv.js').name,
  require("data/geo-service").name,
  require("data/grid-data-layer").name,
  require("data/grid.srv").name,
  require("data/topics-tree.srv").name,
  require("data/insights/base-insights-service").name,
  require("data/dsp-service").name,

  require('common/helper-directives-collection/helper-directive-collection').name,
  require('common/am-input-collection/am-input-collection.js').name,
  require('common/am-toggle-button/am-toggle-button.js').name,
  require('common/am-image-toggle-button/am-image-toggle-button.js').name,
  require('common/am-dropdown-select/am-dropdown-select.js').name,
  require('common/am-dropdown-tree/am-dropdown-tree.js').name,
  require('common/am-linked-dropdown/am-linked-dropdown').name,
  require('common/am-container-collection/am-container-collection').name,
  require('common/empty-screen-message.drv/emptyScreenMessage.js').name,
  require('common/am-table-grid/am-table').name,
  require('common/quick-table/quick-table').name,
  require('common/quick-tree/quick-tree').name,
  require('common/market-context/market-context.js').name,
  require('common/am-autocomplete.drv/am-autocomplete.drv.js').name,
  require('common/content-drivers-pane/content-drivers-pane.drv.js').name,
  require('common/am-consumption-legend.drv/am-consumption-legend.drv.js').name,
  require('common/am-image-toggle-button/am-image-toggle-button').name,
  require('common/audience-filter/audience-filter').name,
  require('infra/mgmt-service-factory').name,
  require('infra/user-details').name
];

const rootModule = angular.module('brand-intelligence', biDependencies)

rootModule
  .component('singleSelectToggle', react2angular(SingleSelectToggle))
  .component('dropdown', react2angular(Dropdown, ['values', 'selectedValue', 'selectedValues', 'promise', 'isMulti', 'isSearchable', 'isDisabled', 'isOpen', 'shouldCloseOnSelect', 'shouldCloseOnClickOutside', 'showSelectAllOptions', 'selectAllOptionsText', 'summaryTextAllSelected', 'summaryTextBuilder', 'onSelect', 'virtualizeThreshold', 'isVirtualizeDisabled', 'menuMaxHeight', 'menuWidth']))
  .component('sideMenuDropdown', react2angular(SideMenuDropdown, ['values', 'selectedValue', 'selectedValues', 'promise', 'isMulti', 'isDisabled', 'isOpen', 'showSelectAllOptions', 'selectAllOptionsText', 'summaryTextAllSelected', 'summaryTextBuilder', 'onSelect']))
  .component('clickableLabel', react2angular(ClickableLabel, ['label', 'rightIconId', 'leftIconId', 'isDisabled', 'tooltip', 'onDisabledTooltip', 'onClick', 'className', 'rightIconStyle', 'leftIconStyle']))
  .component('clickableIcon', react2angular(ClickableIcon, [ 'iconId', 'isDisabled', 'tooltip', 'tooltipOptions', 'onClick', 'className', 'width', 'height' ]))
  .component('accordion', react2angular(Accordion, ['isOpen', 'iconId', 'label', 'onToggle', 'tooltip']))
  .component('barChart', react2angular(BarChart, ['data', 'theme', 'styleOptions', 'shouldHighlightMax', 'referenceLine', 'width', 'height']))
  .component('submitButton', react2angular(SubmitButton, ['label', 'rightIconId', 'leftIconId', 'isDisabled', 'isTransparent', 'onClick']))
  .component('tabSelector', react2angular(TabSelector, ['tabs', 'selectedTab', 'onSelect']))
  .component('confirmationModal', react2angular(ConfirmationModal, ['isOpen', 'modalTitle', 'width', 'height', 'minHeight', 'marginTop', 'onCancel', 'onSubmit', 'isSubmitDisabled', 'isDisabledEnterKeyPress', 'submitLabel', 'cancelLabel', 'showXButton', 'message']))
  .component('channelFilterSideMenu', react2angular(ChannelFilterSideMenu, [ 'isSideMenuOpen', 'isAccordionOpen', 'onAccordionClick', 'channels', 'selectedChannel', 'onChannelChange']));


rootModule.config(["$compileProvider", "stateHelperProvider", "$urlRouterProvider", 'authenticationConfig', 'CacheFactoryProvider', '$windowProvider',
  function ($compileProvider, stateHelperProvider, $urlRouterProvider, authenticationConfig, CacheFactoryProvider, $windowProvider) {
      $compileProvider.debugInfoEnabled(false);
      angular.extend(CacheFactoryProvider.defaults, { maxAge: 4 * 60 * 1000, deleteOnExpire: 'passive' });
      var rootStateConfig = {
          name: "root",
          url: "^",
          abstract: true,
          template: require("./brand-intelligence.html"),
          data: {
            permissions: []
          },
          context: {
              terms: "TermsMold",
              keyTrends: "BooleanMold",
              programSources: "BooleanMold",
              discovery_timeframe: "TimeframeMold",
              topics: "TopicsMold",
              audience: "AudienceMold",
              sgTelcoAudience: "SgTelcoAudienceMold",
              geo: "GeoMold",
              channels: "ChannelsMold",
              bubblesChannels: "BubblesChannelsMold",
              gridChannels: "BubblesChannelsMold",
              insightsChannels: "InsightsChannelsMold",
              articleType: "ArticleTypeMold",
              insightsSubGeos: "GeneralMold",
              discoverySubGeos: "GeneralMold",
              language: "LanguageMold"
          },
          children: [dashboard, insights, discovery, warroom, settings, audienceBuilder, audienceExplorer, alerts, alertsV2, helpCenter]
      };

      stateHelperProvider.state(resetPwd.stateConfig);

      var isInit = true;
      authenticationConfig.doAfterAuthentication.push([ () => {
          if (isInit) {
              stateHelperProvider.state(rootStateConfig, "IgnoreRoot");
              $urlRouterProvider.otherwise(() => {
                  let $window = $windowProvider.$get();
                  $window.location.replace('/404.html');
              });
              isInit = false;
          }
      }]);
  }
]);

rootModule.run(['$templateCache', ($templateCache) => {
    $templateCache.put('partials/score-breakdown.partial.html', require('common/brick.drv/score-breakdown.partial.html'));
    $templateCache.put('partials/domain-delete.partial.html', require('pages/domains/domain-delete.partial.html'));
    $templateCache.put('partials/targets-select.partial.html', require('pages/targets/targets-select.partial.html'));
    $templateCache.put('partials/targets-common.partial.html', require('pages/targets/targets-common.partial.html'));
    $templateCache.put('partials/targets-delete.partial.html', require('pages/targets/targets-delete.partial.html'));
    $templateCache.put('partials/facebook-category.partial.html', require('pages/discovery/facebook-category.partial.html'));
    $templateCache.put('partials/emoji.partial.html', require('pages/discovery/emoji.partial.html'));
    $templateCache.put('partials/net-sentiment.partial.html', require('pages/discovery/net-sentiment.partial.html'));
    $templateCache.put('partials/facebook-interests.partial.html', require('pages/discovery/facebook-interests.partial.html'));
    $templateCache.put('partials/emoji-name.partial.html', require('pages/discovery/emoji-name.partial.html'));
    $templateCache.put('partials/facebook-estimated-reach.partial.html', require('pages/discovery/facebook-estimated-reach.partial.html'));
    $templateCache.put('partials/audience-skew.partial.html', require('pages/discovery/audience-skew.partial.html'));
    $templateCache.put('partials/phrases.partial.html', require('pages/discovery/phrases.partial.html'));
    $templateCache.put('partials/phrase-delete.partial.html', require('pages/discovery/modal/phrase-delete.partial.html'));
    $templateCache.put('partials/phrase-select.partial.html', require('pages/discovery/phrase-select.partial.html'));
    $templateCache.put('partials/sentiment-filter.partial.html', require('pages/alerts/alert-dialog/alerts-filters/sentiment-filter.partial.html'));
    $templateCache.put('partials/consumption-filter.partial.html', require('pages/alerts/alert-dialog/alerts-filters/consumption-filter.partial.html'));
    $templateCache.put('partials/source-actions.partial.html', require('pages/programs/program_settings/partials-sources/source-actions.partial.html'));
    $templateCache.put('partials/source-date.partial.html', require('pages/programs/program_settings/partials-sources/source-date.partial.html'));
    $templateCache.put('partials/source-items.partial.html', require('pages/programs/program_settings/partials-sources/source-items.partial.html'));
    $templateCache.put('partials/interest-date.partial.html', require('pages/programs/program_settings/partials-boolean-logics/interest-date.partial.html'));
    $templateCache.put('partials/interest-name.partial.html', require('pages/programs/program_settings/partials-boolean-logics/interest-name.partial.html'));
    $templateCache.put('partials/interest-actions.partial.html', require('pages/programs/program_settings/partials-boolean-logics/interest-actions.partial.html'));
    $templateCache.put('partials/program-interests.partial.html', require('pages/programs/program_settings/program-interests.partial.html'));
    $templateCache.put('partials/dashboard-interests.partial.html', require('pages/programs/program_settings/dashboard-interests.partial.html'));
    $templateCache.put('partials/program-sources.partial.html', require('pages/programs/program_settings/program-sources.partial.html'));
    $templateCache.put('partials/audience-name.partial.html', require('pages/audience-builder/modals/load-audience/audience-name.partial.html'));
    $templateCache.put('partials/audience-date.partial.html', require('pages/audience-builder/modals/load-audience/audience-date.partial.html'));
    $templateCache.put('partials/audience-date-activated.partial.html', require('pages/audience-builder/modals/load-audience/audience-date-activated.partial.html'));
    $templateCache.put('partials/audience-actions.partial.html', require('pages/audience-builder/modals/load-audience/audience-actions.partial.html'));
    $templateCache.put('partials/segnificance-score.partial.html', require('widgets/audience-table-view-widget/segnificance-score.partial.html'));
    $templateCache.put('partials/composition-index.partial.html', require('widgets/audience-table-view-widget/composition-index.partial.html'));
    $templateCache.put('partials/audience-linkedin-interests-content.partial.html', require('widgets/audience-table-view-widget/audience-linkedin-interests-content.partial.html'));
    $templateCache.put('partials/segment-portion.partial.html', require('widgets/audience-table-view-widget/segment-portion.partial.html'));
    $templateCache.put('partials/audience-domain.partial.html', require('widgets/audience-table-view-widget/audience-domain.partial.html'));
    $templateCache.put('partials/audience-domain-select.partial.html', require('widgets/audience-table-view-widget/audience-domain-select.partial.html'));
    $templateCache.put('partials/interest-portion.partial.html', require('widgets/audience-table-view-widget/interest-portion.partial.html'));
}]);

// Don't remove the openNewVersionModal from the dependencies, as it needed to initate the openNewVersionModal service
rootModule.run(init);
init.$inject = ["context", "user", "userDetails", "$rootScope", '$interval', "utils", "$window", "$http", "filtersPartition", "geoService", "authentication", "$state", "$injector", "openNewVersionModal", "authenticationConfig"];
function init(context, user, userDetails, $rootScope, $interval, utils, $window, $http, filtersPartition, geoService, authentication, $state, $injector, openNewVersionModal, authenticationConfig) {

  $http.defaults.headers.common['Abi-Version'] = ABI_VERSION;
  HttpClient.setCommonHeader('Abi-Version', ABI_VERSION);
  setStateService($state)
  $rootScope.context = context;
  $rootScope.user = user;
  $rootScope.menuOpen = false;
  $rootScope.filterMenuOpen = false;

  $rootScope.kdebug = sessionStorage.kdebug=="true";

  $rootScope.toggleMenu = function () {
      $rootScope.menuOpen = !$rootScope.menuOpen;
      utils.triggerResizeOnTransitionEnd($('menubar'));
  };
  $rootScope.toggleFilterBar = function () {
      $rootScope.filterMenuOpen = !$rootScope.filterMenuOpen;
      utils.triggerResizeOnTransitionEnd($('side-filters-menu'));
  };

  /** for filter menu **/
  $rootScope.audienceFilters = filtersPartition.audience;
  $rootScope.behavioralSegment = filtersPartition.behavioralSegment;
  $rootScope.testSegment = filtersPartition.testSegment;
  $rootScope.levelOfIntent = filtersPartition.levelOfIntent;
  $rootScope.Geos = geoService;
  $rootScope.streamsChannelsFilter = filtersPartition.streamsChannels;
  $rootScope.insightsChannelsFilter = filtersPartition.insightsChannels;
  $rootScope.insightsAssociationsChannelsFilter = filtersPartition.insightsAssociationsChannels;
  $rootScope.insightsTimingChannelsFilter = filtersPartition.insightsTimingChannels;
  $rootScope.insightsReferralsChannelsFilter = filtersPartition.insightsReferralsChannels;
  $rootScope.geoInsightsChannelsFilter = filtersPartition.geoInsightsChannels;
  $rootScope.bubblesChannelsFilter = filtersPartition.bubblesChannel;
  $rootScope.languageFilter = filtersPartition.language;
  $rootScope.gridChannelsFilter = filtersPartition.bubblesChannel;
  $rootScope.audienceChannelsFilter = filtersPartition.audienceChannels;
  $rootScope.sgWebSourceFilter = filtersPartition.sgWebSource;
  $rootScope.filter = filtersPartition;
  $rootScope.topicsDistribution = {};

  authenticationConfig.doAfterAuthentication.push([ () => { //TODO: temporary
      if (($rootScope.user || {}).id === 1029) $rootScope.streamsChannelsFilter = [{label: "Video", value: 'videos', single: 'true'}];
      if (($rootScope.user || {}).email === "test.abi.fb@gmail.com") {
          $rootScope.streamsChannelsFilter = [{"label": "Facebook", "value": 'facebook', 'single': 'true'}];
          $rootScope.bubblesChannelsFilter = [{"label": "Facebook", "value": 'facebook', 'single': 'true'}];
          $rootScope.gridChannelsFilter = [{"label": "Facebook", "value": 'facebook', 'single': 'true'}];
      }
  }]);

  //logout if user is idle for a while
  const MINUTE = 60 * 1000;

  $(window).on('mousedown', _.throttle(()=>{
      localStorage.lastClick = (new Date()).getTime();
      userDetails.send($rootScope.user);
  }, MINUTE * 10));
  
  const checkLastClick = ()=> {
      const now = (new Date()).getTime(); //if idle for 5 hours, logout
      const logout = Number(localStorage.lastClick || 0) + MINUTE * 300;

      if(now > logout){
          localStorage.setItem("lastClick", (new Date()).getTime());
          $window.location.reload();
      }
  }

  $interval(()=>{checkLastClick()}, MINUTE * 30);
  checkLastClick();

  /* handle redirect */
  $rootScope.$on("$stateChangeStart", function (evnt, toState, toParams) {

      if(false==authentication.authenticatePage(toState)){
        sessionStorage['login_message'] = 'Your account is not authorized for this page. <br/>Please contact your account manager today.<br/><br/><br/><a href="/login">Go back to Brand Intelligence</a>';
        $window.location.replace('/login_message');
        return;
      };

      $.each(toState, function(key, val) { utils.findPagehUrl(key, val) });

      var redirect = toState.redirectTo;
      if (redirect) {
          if (angular.isString(redirect)) {
              evnt.preventDefault();
              $state.go(redirect, toParams);  
          }else {
              var newState = $injector.invoke(redirect, null, {toState: toState, toParams: toParams});
              if (newState) {
                  if (angular.isString(newState)) {
                      evnt.preventDefault();
                      $state.go(newState);
                  } else if (newState.state) {
                      evnt.preventDefault();
                      $state.go(newState.state, newState.params);
                  }
              }
          }
      }
  });

  /* handle external */
  $rootScope.$on('$stateChangeStart', function (evnt, toState, toParams) {
      if (toState.external) {
          var target = toState.target || '_blank';
          evnt.preventDefault();
          $window.open(toState.url, target);
      }
  });

  angular.element($window).trigger('resize');

  authentication.authenticate();
}

export default rootModule;
