import { isAudienceTargetVisible } from '../../data/audience-segment-builder-helper';

const angular = require("angular"),
  common = require("infra/utils/common"),
  BaseWidget = require("../base_widget"),
  audienceTableView = require('../../widgets/audience-table-view-widget/audience-table-view-widget'),
  mixpanel = require("../../infra/mixpanel/mixpanel-audience");

AudienceInterestsWidgetController.$inject = ['audienceInsightsService', 'TargetsCommon', 'util', 'TermsMold', 'context', '$state',
    'filtersPartition', 'segmentInterestExportService', 'mixpanelAudience', 'mixpanelService', 'abiPermissions',
  'audienceTableStructure', 'confirmAction', 'notificator', '$timeout', '$q', 'CHANNEL', 'TARGET_CONSTS', 'dspService'];

function AudienceInterestsWidgetController (audienceInsightsService, TargetsCommon, infraUtil, termsMold, context, $state,
                                            filtersPartition, segmentInterestExportService, mixpanelAudience, mixpanelService, abiPermissions,
                                            audienceTableStructure, confirmAction, notificator, $timeout, $q, CHANNEL, TARGET_CONSTS, dspService) {
  // shortcuts
  
  const thisCtrl = this;
  const $scope = this.$scope;
  
  // consts as enum
  
  const TELCO_CHANNELS = ['data_spark', 'au_telco'];
  const VIEW_TYPES_NAMES = $scope.VIEW_TYPES_NAMES = {bars: 'bars', chart: 'bars', table: 'table'};
  const ALL_INTERESTS = $scope.ALL_INTERESTS = 'All Interests';
  
  // data querying utils
  
  const audienceChannel = context.current.audience_app.current_channel.value;
  const segment = context.current.audience_app[audienceChannel].audience_segment;
  const isFirstParty = _.some(segment, {type: '1st party'});
  $scope.audienceIsFirstParty = isFirstParty;
  $scope.hasPermission = abiPermissions.hasPermission;
  $scope.showTargets = () => audienceChannel !== 'linear_tv' && abiPermissions.hasPermission('activate targets');
  thisCtrl.all_ages = filtersPartition.age.map((a) => a.summary);
  
  // services and utility modules for view
  
  const audienceTables = audienceTableStructure.tableStructure;
  thisCtrl.mixpanelAudience = mixpanelAudience;
  thisCtrl.infraUtil = infraUtil;
  thisCtrl.audienceInsightsService = audienceInsightsService;
  thisCtrl.segmentInterestExportService = segmentInterestExportService;
  $scope.termsMold = new termsMold();
  
  // ref to dom
  
  thisCtrl.exportButton = angular.element(document.querySelector('.export'))[0];
  
  // info for view elements construction
  
  const includeSearches = abiPermissions.hasPermission(['search']) && $scope.showTargets() && !TELCO_CHANNELS.includes(audienceChannel) && audienceChannel !== 'smart_tv';
  $scope.interestTypes = _.compact([
    {value: 'websites', label: 'Websites', plural: 'websites', singular: 'website'},
    {value: 'phrases', label: 'Phrases', plural: 'phrases', singular: 'phrase'},
    includeSearches && {value: isFirstParty ? 'searchesBidStream' : 'searches', label: 'Searches', plural: 'searches', singular: 'search term'}
  ]);
  $scope.interestTypesMap = _.keyBy($scope.interestTypes, 'value');
  $scope.$watch('interestType', () => { $scope.interestTypeInfo = $scope.interestTypesMap[$scope.interestType] });
  
  $scope.viewTypes = [
    {value: 'bars', icon: 'icon-bar-chart', label: ''},
    {value: 'table', icon: 'icon-table', label: ''}
  ];
  
  $scope.sortTypes = [
    {
      value: 'portion',
      label: 'Consumption',
      tooltip: {
        text: 'Sort by how popular the interest is among the audience',
        amToolTip: 'top center to bottom right',
        classes: 'no-width-limit-tooltip'
      }
    },
    {
      value: 'composition',
      label: 'Skew',
      tooltip: {
        text: 'Sort by the extent to which the interest is over-indexed within the audience',
        amToolTip: 'top center to bottom right',
        classes: 'no-width-limit-tooltip'
      }
    }
  ];
  // name mapping
  const sortTypeToTopicKey = {portion: "percents", composition: "value"};
  // table setting
  $scope.query = audienceTables[audienceChannel];
  // mixpanel
  $scope.query.sortCB = 'mixpanelTrackSort';
  $scope.mixpanelTrackSort = (params) => {
    mixpanelAudience.trackSort(params);
  };
  // default view option
  $scope.viewType = VIEW_TYPES_NAMES.chart;
  $scope.lastViewType = VIEW_TYPES_NAMES.chart;
  
  // targets handling
  $scope.tab = $scope.interestTypes[0].value;
  $scope.tabs = [$scope.tab];
  $scope.currentTarget = {};
  $scope.targets = [];
  $scope.contentDriversTargets = false;
  $scope.navBackModal = { isOpen: false };

  //audience target
  $scope.channel = audienceChannel;
  $scope.audienceSegment = segment;
  $scope.isNewAudienceTarget = context.current.audience_app[audienceChannel].audience_newTarget;
  $scope.audienceId = context.current.audience_app[audienceChannel].audience_id;
  $scope.audienceName = context.current.audience_app[audienceChannel].audience_name;
  $scope.isNewAudienceTarget = context.current.audience_app[audienceChannel].audience_newTarget;
  $scope.isAudienceTargetDisabled = context.current.audience_app[audienceChannel].audience_targetDisabled;
  $scope.audienceTargetDisabledTooltipText = context.current.audience_app[audienceChannel].audience_targetDisabledTooltipText;
  $scope.getSegmentIds = audienceInsightsService.getSegmentIds;
  $scope.createAudienceTargetTaxonomy = audienceInsightsService.createAudienceTargetTaxonomy;
  $scope.createAudienceTargetUserList = audienceInsightsService.createAudienceTargetUserList;
  $scope.notificator = notificator;

  $scope.isAudienceTargetVisible = function() {
    const currentChannel = ((context.current.audience_app || {}).current_channel || {}).value;
    return isAudienceTargetVisible(currentChannel, abiPermissions.hasPermission('audience activation'));
  }

  $scope.updateIsNewAudienceTarget = function(value) {
    context.current.audience_app[$scope.channel].audience_newTarget = $scope.isNewAudienceTarget = value;
  }

  //Will be used in the future when we will support more markets
  //if ($scope.isAudienceTargetVisible($scope.channel)) $scope.marketsPromise = dspService.getMarkets('label', 'value', true);
  
  if($scope.showTargets()){
    TargetsCommon.getTargets({scope: $scope, $state: $state, tab: $scope.tab});
  }

  const updateTargetsHandlerWatch = $scope.$root.$on('updateTargets', (event, obj) => {
    TargetsCommon.updateTargetsHandler(obj, $scope);
  });
  
  $scope.$on('$destroy', () => {
    updateTargetsHandlerWatch(); //clears watch
  });
  
  $scope.$on('remove-all-from-target-cb', () => {
    resetSelection();
  });
  
  $scope.openTargets = () => {
    TargetsCommon.openTargets($scope);
  };
  
  $scope.editTarget = () => {
    if ($scope.currentTarget) TargetsCommon.edit($scope.currentTarget, '', $scope);
  };

  $scope.validateAddToTarget = function() {
    $scope.allowedAddToTarget = TargetsCommon.validateAddToTarget($state, $scope);
    if (!$scope.allowedAddToTarget) {
      const advertiser_id = $scope.currentTarget.advertiser_id;
      notificator.notify({body: `Adding 1st Party audience phrases will change this Target type. This Target is activated to advertiser ${advertiser_id} and can not be changed at the moment.`});
    }
  };

  
  $scope.addToCurrentTarget = () => {
    const currentChannel = context.current.audience_app.current_channel.value,
          audience_id = context.current.audience_app[currentChannel].audience_id;

    $scope.query.selected.forEach((s) => { s.audience_id = audience_id });

    TargetsCommon.addToCurrentTarget($state, $scope);
  };
  
  $scope.createTargetWindow = (interests) => {
    
    /*target params:
    ================
    target_type:      audience_interests | inventory_lists
    channel:          web | data_spark | au_telco
    channel_dispaly:  all | sg telco | au telco

    */

    let channel = common.getChannels($state, context)[0];
    TargetsCommon.createTargetWindow({
      target_type: TargetsCommon.TargetData.getTargetType($state, $scope.tab),
      name: '',
      channel: CHANNEL.name(channel),
      seeds: [],
      query: audienceInsightsService.getSegmentParams(segment, audienceInsightsService.QUERY_NAMES[$scope.tab], channel),
      results: {[$scope.tab]: interests}
    });
  };
  
  $scope.toggleSelected = (row, $event) => {
    TargetsCommon.toggleSelected($scope, row, $event);
    $scope.validateAddToTarget();
  };
  
  $scope.toggleSelectedBar = (bar) => {
    const row = $scope.query.dataArray.find((o) => o.id === bar.id);
    if (!row) return;
    row.selected = bar.selected;
    $scope.toggleSelected(row, null);
  };
  
  $scope.toggleSelectedAllBar = (selectAll, bars) => {
    if (selectAll) {
      const barIds = bars.map((bar) => bar.id);
      $scope.query.selected = $scope.query.dataArray.filter((o) => barIds.includes(o.id));
      $scope.query.selected.forEach((row) => row.selected = true);
    } else {
      $scope.query.dataArray.forEach((row) => row.selected = false);
      $scope.query.selected = [];
    }
    
    TargetsCommon.mixpanelTargets.toggleSelectAll(selectAll);
  };
  
  // interests data
  
  const targetRatio = context.current.audience_segment_target_ratio,
    targetRatioObj = targetRatio ? {'wanted-intenders-ratio': targetRatio} : undefined;
  
  // * topics
  
  const topicsPromise = audienceInsightsService.getSegmentInterestsData(segment, audienceInsightsService.QUERY_NAMES.topics, audienceChannel, targetRatioObj)
    .then(throwForInvalidDataResponse).catch(expelUserIfMissingData)
    .then((data) => data.words || []);
  
  function transformTopicsData (topics) {
    return topics.map((topic) => Object.assign({}, topic, {
      id: topic["phrase-id"],
      percents: topic["interest-portion"],
      percentValue: topic["interest-portion"].toFixed(1),
      value: topic["uniqueness-index"].toFixed(1),
      tooltip: genInterestTooltipStr(topic)
    }));
  }
  
  $scope.topicsPromise = topicsPromise.then(transformTopicsData).then((data) => _.orderBy(data, 'percents', 'desc'));
  $scope.topicsPromise.then((topicsData) => { $scope.topicsData = topicsData });
  
  // * phrases/websites
  
  thisCtrl.topInterestsPromises = {};
  thisCtrl.testedInterestsByIdPromises = {}; // holds promise of tested phrases for delaying other promises until it resolves
  thisCtrl.readyTestedInterestsByIdPromises = {}; // holds only the last resolved tested phrases promise for getting
  
  context.current.audience_app[audienceChannel].audience_interestsToTest = context.current.audience_app[audienceChannel].audience_interestsToTest || {};
  
  // initial interests data retrievals
  
  $scope.interestTypes.map(t => t.value).reduce((promise, type) => {
    return promise.then(() => getTopInterestsDataByType(type).then(() => getPrevTestedInterestsDataByType(type)));
  }, $q.resolve());
  
  function getTopInterestsDataByType(type) {
    return thisCtrl.topInterestsPromises[type] = thisCtrl.topInterestsPromises[type] || audienceInsightsService.getSegmentInterestsData(
      segment, audienceInsightsService.QUERY_NAMES[type], audienceChannel, targetRatioObj
    ).then(throwForInvalidDataResponse).catch(expelUserIfMissingData)
      .then((data) => transformInterestsData(data.words || [], type === 'websites'));
  }
  
  function getPrevTestedInterestsDataByType(type) {
    return thisCtrl.testedInterestsByIdPromises[type] = thisCtrl.testedInterestsByIdPromises[type] || getTopInterestsDataByType(type).then(() => {
      if (!_.isEmpty(context.current.audience_app[audienceChannel].audience_interestsToTest[type]))
        return getAllTestedInterestsByIdByViewAndUpdateCache(
          context.current.audience_app[audienceChannel].audience_interestsToTest[type],
          {type, topicId: ALL_INTERESTS, refInterestsPromise: getPromiseOfInterestsByTypeAndTopic(type, ALL_INTERESTS)}
        );
      else
        return {forChart: [], forTable: []}
    }).then(indexByIdAndMergeByView);
  }
  
  function getPromiseOfInterestsByTypeAndTopic (type, topicId) {
    return getTopInterestsDataByType(type).then((dataByView) => _.mapValues(dataByView, (data) => data[topicId] || []));
  }
  
  // processing and publishing interest data
  
  function transformInterestsData (interests, addUrlsBasedOnInterestText = false) {
    const forChart = interests.map((interest) => ({
      ...interest,
      id: interest['id'] || interest['phrase-id'],
      title: interest['phrase'],
      portion: interest['interest-portion'],
      composition: interest['uniqueness-index'],
      value: interest['interest-portion'].toFixed(1) + '% | ' + interest['uniqueness-index'].toFixed(1),
      url: addUrlsBasedOnInterestText ? 'http://' + interest['phrase'] : undefined,
      tooltip: genInterestTooltipStr(interest)
    }));
    const forTable = interests.map((interest) => ({
      ...interest,
      id: interest['id'] || interest["phrase-id"],
      skewTooltip: genInterestSkewTooltipStr(interest)
    }));
    
    // group by topic for quick sort by user selection
    return _.mapValues({forChart, forTable}, (objs) => Object.assign(_.groupBy(objs, 'topic'), {[ALL_INTERESTS]: objs}));
  }
  
  function sortAndNormalizeInterests ({forChart, forTable}) {
    if (_.isEmpty(forChart) || _.isEmpty(forTable)) return {forChart, forTable};
    forChart.forEach((i) => i.percents = i[$scope.sortType]);
    forChart = _.orderBy(_.orderBy(forChart, $scope.sortType, 'desc'), 'highlighted', 'asc');
    forChart = forChart.slice(0, 100); // chart cannot handle all interests - too slow.
    forChart = thisCtrl.infraUtil.normalize(forChart, 'percents', 'percents');
    return {forChart, forTable};
  }
  
  function allInterestsByTypeAndTopic (type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id) {
    if (topicId === ALL_INTERESTS)
      return getPromiseOfInterestsByTypeAndTopic(type, topicId).then((topInterestsByView) => {
        return getPromiseOfTestedInterestsByType(type).then((testedInterestsByView) => {
          return _.mergeWith(testedInterestsByView, topInterestsByView, (arr1, arr2) => _.uniqBy(arr1.concat(arr2), 'id'));
        });
      });
    return getPromiseOfInterestsByTypeAndTopic(type, topicId);
  }
  
  function publishAllInterestsToView (updateQueryObj, type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id) {
    if(!topicId || !type) return;
    
    // symbolize loading is performed.
    $scope.interests = null;
    $scope.tablesData = null;

    return allInterestsByTypeAndTopic(type, topicId)
      .then(sortAndNormalizeInterests)
      .then(({forChart, forTable}) => {
      // no need for publishing if the interestType is not the current one (if changed while loading)
      if(type !== $scope.interestType) return;

      $scope.interests = forChart;
      $scope.tablesData = forTable;
      if(!forChart.length) $scope.noDataMsg = $scope.getNoDataMsg();
  
      $scope.viewType = thisCtrl.topic && thisCtrl.topic.id === ALL_INTERESTS ? $scope.lastViewType : VIEW_TYPES_NAMES.chart;
      showBars($scope.viewType === VIEW_TYPES_NAMES.chart);
      if (updateQueryObj) $scope.query.show(forTable);
    
      resetSelection();
    
      $timeout(() => { $scope.$digest() }); // rerender view to assure it contains the new data
    });
  }
  
  // error handling
  
  function throwForInvalidDataResponse (data) {
    if (!data) throw 'noData';
    switch (data.status) {
      case 'noData':
        throw 'noData';
      case 'segment too wide':
      case 'tooWide':
        throw 'tooWide';
      case 'insufficient data':
      case 'tooNarrow':
        throw 'tooNarrow';
    }
    if (_.isEmpty(data.words)) throw 'noData';
    
    return data;
  }
  
  const tooWideMsg = 'The audience you have selected is too wide for Interests analysis. Please refine your audience criteria.';
  const tooNarrowMsg = 'The audience you have selected is too narrow for Interests analysis. Please refine your audience criteria.';
  
  function expelUserIfMissingData (reason) {
    switch (reason) {
      case 'tooNarrow':
        openNavBackModal(tooNarrowMsg, 'Audience is too narrow');
        break;
      case 'tooWide':
        openNavBackModal(tooWideMsg, 'Audience is too wide');
        break;
    }
    return $q.reject(reason);
  }
  
  function openNavBackModal(msg, title) {
    $scope.navBackModal = {
        isOpen: true,
        title: title,
        message: msg
    };
  }
  
  // view prep: vars & fns
  
  function showBars (show) {
    let bars = angular.element(document.querySelector('selectable-bar-chart div.scroll-container'));
    show ? bars.show() : bars.hide();
  }
  
  function setTableHead (type) {
    // Dynamically set quick-table properties.
    $scope.query.columns[0].title = type;
    $scope.query.title = getTabType(type);
    $scope.isCheckboxesNeeded = $scope.showTargets();
    switch ($scope.interestType) {
      case 'websites':
        $scope.query.columns[0].template = 'partials/audience-domain-select.partial.html';
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience visits the website';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience visits the website';
        break;
      case 'phrases':
        $scope.query.columns[0].template = 'partials/phrase-select.partial.html';
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';
        $scope.query.doSummary = () => {};
        break;
      case 'searches':
      case 'searchesBidStream':
        $scope.query.columns[0].template = $scope.interestType==='searches' ? 'partials/phrase-select.partial.html' : null;
        $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the search term';
        $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the search term';
        $scope.query.doSummary = () => {};
        break;
    }
  }
  
  const genInterestSkewTooltipStr = ({phrase, "uniqueness-index": skew}) => {
    switch (+skew.toFixed(1)) {
      case 0:
        return `Consumers of ${phrase} are unlikely to be in the target audience`;
      case 1:
        return `Consumers of ${phrase} are as likely to be in the target audience as the average consumer`;
      default:
        const [multiplier, compare] = skew > 1 ? [skew, 'more'] : [1 / skew, 'less'];
        return `Consumers of ${phrase} are ×${multiplier.toFixed(1)} times ${compare} likely to be in the target audience than the average consumer`;
    }
  };
  
  const genInterestConsumptionTooltipStr = ({phrase, "interest-portion": consumption}) => {
    const clientsVerb = $scope.interestType === 'websites' ? 'consumed' : 'are interested in';
    return `${consumption.toFixed(1)}% of the target audience ${clientsVerb} ${phrase}`;
  };
  
  function genInterestTooltipStr (obj) {
    return genInterestConsumptionTooltipStr(obj) + '<br/>' + genInterestSkewTooltipStr(obj);
  }
  
  function resetSelection () {
    if ($scope.query && $scope.query.selected && $scope.query.selected.length) {
      $scope.query.selected = [];
      $scope.query.dataArray.forEach((row) => row.selected = false);
      ($scope.interests||[]).forEach((row) => row.selected = false);
    }
    setTimeout(() => {
      $scope.selectedMax = $scope.viewType === VIEW_TYPES_NAMES.chart ? ($scope.interests || []).length : $scope.query.dataArray.length
    });
    
    const selectAllCheckboxEl = $("#select-all-checkbox");
    if (selectAllCheckboxEl.attr("checked") === "checked") selectAllCheckboxEl.trigger("click");
  }

  function getTabType(tab){
    return tab == 'searches' ? 'phrases' : tab; //same tab for targets
  }

  $scope.getNoDataMsg = (type = $scope.interestType) => {
    return `Sorry, we couldn't find relevant ${type && $scope.interestTypesMap[type].plural} on this topic for your target audience.`
  };
  
  $scope.disableSearches = () => false;
  
  $scope.calcLeft = () => {
    let barTitle = angular.element(document.querySelector('selectable-bar-chart .chart-title'));
    return `${$scope.title ? barTitle.width() + 20 + 20 : 0}px`;
  };
  
  // view data handling events/callbacks
  
  $scope.onTopicChange = (topic) => {
    thisCtrl.topic = topic;
    $scope.title = topic.id;
    return publishAllInterestsToView(true);
  };
  
  $scope.onInterestTypeChange = (type) => {
    setTableHead(type);
    
    if (thisCtrl.topic) publishAllInterestsToView(true);
    $scope.tab = getTabType(type);
    $scope.tabs = [$scope.tab];
     
    if($scope.showTargets()) {
      $scope.contentDriversTargets = true;
      if($scope.tab != $scope.prevTab){
        TargetsCommon.getTargets({scope: $scope, $state: $state, tab: $scope.tab});
      }
    }else{
      $scope.contentDriversTargets = false;
      $scope.$root.$broadcast('closeContentDrivers', "target_drawer");
    }
    $scope.prevTab = $scope.tab;
    
    resetSelection();
  
    mixpanelService.sendToMixpanel('Audience-Builder - Interests - InterestType Changed', {interestType: type});
  };
  
  $scope.openTargetsPane = ()=>{
    if(!$scope.contentDriversOpen){
      $scope.$root.$broadcast('openContentDrivers', 'target_drawer');
    }
  }

  
  $scope.onViewTypeChange = (type) => {
    $scope.lastViewType = type;
    resetSelection();
    if (type === VIEW_TYPES_NAMES.table) $scope.query.show($scope.tablesData);
    showBars(type === VIEW_TYPES_NAMES.chart);
  };
  
  $scope.onSortTypeChange = (type) => {
    if ($scope.topicsData) {
      $scope.topicsData.forEach((i) => i.percents = type === 'portion' ? i.percentValue : i.value);
      thisCtrl.infraUtil.normalize($scope.topicsData, 'percents', 'percents');
      $scope.topicsData = _.orderBy($scope.topicsData, sortTypeToTopicKey[type], 'desc');
    }
    
    publishAllInterestsToView(false);
    
    resetSelection();
  };
  
  // test-an-interest
  
  $scope.newlyAddedInterestsToTest = {}; // by type; bound to the test-an-interest input-bars, to contain its values
  $scope.testedInterestsLoadingPromise = {}; // last promise by type
  
  context.current.audience_app[audienceChannel].audience_interestsToTest =  // searched interests to test saved by type
    context.current.audience_app[audienceChannel].audience_interestsToTest || {};
  
  function getPromiseOfTestedInterestsByType (type = $scope.interestType) {
    return (thisCtrl.readyTestedInterestsByIdPromises[type] || getPrevTestedInterestsDataByType(type))
      .then((testedInterestsCache) => _.mapValues(testedInterestsCache, Object.values));
  }
  
  function getAllTestedInterestsByIdByViewAndUpdateCache (searchResults, {type = $scope.interestType, topicId = thisCtrl.topic && thisCtrl.topic.id, refInterestsPromise = allInterestsByTypeAndTopic(type, topicId)}) {
    return refInterestsPromise.then((refInterestsByView) => {
      // get from api only interests that do not already exist in top interests
      const existingTestedInterests = _.mapValues(refInterestsByView, (interests) => interests.filter((i) => searchResults.some((r) => r.id === i.id)));
      const existingTestedInterestIds = existingTestedInterests.forTable.map((i) => i.id);
      const nonexistingInterestsToTest = searchResults.filter((r) => !existingTestedInterestIds.includes(r.id));
      
      // calc min and max g values in current interests (of type) for score calculation in server; prepare obj for sending
      const refInterestGValues = refInterestsByView.forTable.map((w) => w.g);
      const gInfoObj = {"max-g": Math.max(...refInterestGValues), "min-g": Math.min(...refInterestGValues)};
      const extraParams = {...gInfoObj, ...targetRatioObj};
  
      const {[true]: advancedInterestsToTest, [false]: regularInterestsToTest} = _.groupBy(nonexistingInterestsToTest, (r) => r.type === 'programBL' || r.type === 'booleanLogic');
      const testedRegularInterestsPromise = testRegularInterests(type, regularInterestsToTest, extraParams) || $q.resolve([]);
      const testedAdvancedInterestsPromise = testAdvancedInterests(type, advancedInterestsToTest, extraParams) || $q.resolve([]);
      
      return $q.all([testedRegularInterestsPromise, testedAdvancedInterestsPromise]).then(([testedRegularInterests, testedAdvancedInterests]) => {
        const allTestedInterests = testedRegularInterests.concat(testedAdvancedInterests);
        const newTestedInterestsByTopic = transformInterestsData(allTestedInterests, type === 'websites');
        const newTestedInterests = _.mapValues(newTestedInterestsByTopic, (interests) => interests[topicId]);
        
        // combine new-found interests from api and top existing, and mark as highlighted for view
        const defaultTestedInterestProps = {highlighted: true, deletable: true, highlightMarkTooltip: 'Manually added'};
        const allTestedInterestsByView = _.mergeWith(existingTestedInterests, newTestedInterests, (arr1, arr2) => arr2.concat(arr1 || []));
        return _.mapValues(allTestedInterestsByView, (interests) => interests.map((i) => Object.assign({}, i, defaultTestedInterestProps)));
      });
    });
  }
  
  function testRegularInterests(type, interestsToTest, extraParams = {}) {
    if(_.isEmpty(interestsToTest)) return;
    // mixpanel event
    mixpanelAudience.trackTestingInterests(type, interestsToTest);
    
    const idToTextMap = _.mapValues(_.keyBy(interestsToTest, 'id'), 'text');
    return audienceInsightsService.getSegmentInterestsDataByIds(
      segment, audienceInsightsService.QUERY_NAMES_V2[type], audienceChannel, interestsToTest.map((r) => r.id), extraParams
    ).then(throwForInvalidDataResponse).catch(continueIfNoData)
      .then(({words = []}) => words.map((i) => Object.assign(i, {phrase: idToTextMap[i.id] || i.id})));
  }
  
  function testAdvancedInterests(type, interestsToTest, extraParams = {}) {
    if(_.isEmpty(interestsToTest)) return;
    // mixpanel event
    mixpanelAudience.trackTestingInterests(type, interestsToTest, true);
    
    const idToTextMap = _.mapValues(_.keyBy(interestsToTest, 'id'), 'text');
    const advancedInterestsObjsForReq = interestsToTest.map((o) => ({
      id: o.id, required: (o.required || []).map((r) => r.id), included: (o.included || []).map((i) => i.id), excluded: (o.excluded || []).map((e) => e.id)
    })); // api expects getting only id (which returned back), required (= and), included (= or), excluded (= not); values are ids only
    return audienceInsightsService.getSegmentAdvancedInterestsDataByQueries(
      segment, audienceInsightsService.QUERY_NAMES_V2[type], audienceChannel, advancedInterestsObjsForReq, extraParams
    ).then(throwForInvalidDataResponse).catch(continueIfNoData)
      .then(({words: interests = []}) => {
        const disabledTooltip = "Refined interests are inapplicable for targeting.";
        return interests.map((i) => Object.assign(i, {phrase: idToTextMap[i.id], disabled: true, disabledTooltip}));
      });
  }
  
  const continueIfNoData = (errMsg) => errMsg === 'noData' || errMsg === 'tooNarrow' ? $q.resolve({}) : $q.reject(errMsg);
  
  function indexByIdAndMergeByView (interestsByView, lastInterestsByIdByView) {
    const interestsByIdByView = _.mapValues(interestsByView, (interests) => _.keyBy(interests, 'id'));
    if (!lastInterestsByIdByView) return interestsByIdByView;
    return _.mergeWith(lastInterestsByIdByView, interestsByIdByView, (o1, o2) => Object.assign(o1, o2));
  }
  
  function notifyForMissingInterestsToTest (testedInterestsByView, searchResults) {
    const isAnyTested = testedInterestsByView.forTable && testedInterestsByView.forTable.length;
    const missingInterestsToTest = isAnyTested ? searchResults.filter((r) => !testedInterestsByView.forTable.find((i) => i.id === r.id)) : searchResults;
    
    if (missingInterestsToTest && missingInterestsToTest.length)
      notificator.notify(`Sorry, insufficient data about ${missingInterestsToTest.map((r) => r.text).join(', ')}`);
    
    return testedInterestsByView;
  }
  
  for (let {value: type} of $scope.interestTypes) {
    $scope.$watch(`newlyAddedInterestsToTest.${type}`, (searchResults) => {
      if (_.isEmpty(searchResults)) return;
      
      const pastInterestsToTest = context.current.audience_app[audienceChannel].audience_interestsToTest[type];
      context.current.audience_app[audienceChannel].audience_interestsToTest[type] = (pastInterestsToTest || [])
        .concat(_.differenceBy(searchResults, pastInterestsToTest, 'id'));
      
      thisCtrl.readyTestedInterestsByIdPromises[type] = thisCtrl.testedInterestsByIdPromises[type];
      const testedInterestsPromise = getAllTestedInterestsByIdByViewAndUpdateCache(searchResults, {type, topicId: ALL_INTERESTS});
      
      thisCtrl.testedInterestsByIdPromises[type] = $q.all([testedInterestsPromise, thisCtrl.testedInterestsByIdPromises[$scope.interestType]])
        .then(([testedInterestsByView, lastTestedInterestsByIdByView]) => indexByIdAndMergeByView(testedInterestsByView, lastTestedInterestsByIdByView));
      
      $scope.testedInterestsLoadingPromise[type] = testedInterestsPromise // for showing loading animation in view
        .then((testedInterestsByView) => notifyForMissingInterestsToTest(testedInterestsByView, searchResults))
        .then(() => publishAllInterestsToView(true))
        .then(() => { $scope.newlyAddedInterestsToTest[type] = [] }); // clear input-bar
      
      // mixpanel event
      mixpanelAudience.trackTestingInterests(type, searchResults);
    });
  }
  
  $scope.deleteATestedInterest = (id, interest) => {
    const deleteInterestPromise = thisCtrl.testedInterestsByIdPromises[$scope.interestType].then(testedInterestsById => {
      // remove from tested interests pools
      delete testedInterestsById.forChart[id];
      delete testedInterestsById.forTable[id];
      // remove from 'snapshot'/'context'
      _.remove(context.current.audience_app[audienceChannel].audience_interestsToTest[$scope.interestType], (r) => r.id === id);
      
      return testedInterestsById;
    });
    thisCtrl.testedInterestsByIdPromises[$scope.interestType] = deleteInterestPromise; // chain
    // refresh view
    deleteInterestPromise.then(() => { publishAllInterestsToView(false) });
  };
  
  // export file
  
  $scope.$root.$on("$stateChangeStart", () => {
    thisCtrl.exportButton.hidden = false;
  });
  
  $scope.export = () => {};
  $scope.$root.createExcelWorkbook = $scope.export;
  
  const demographicsPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(segment, {channel: audienceChannel});
  $scope.export = () => {
    const allPhrasesPromise = allInterestsByTypeAndTopic('phrases', ALL_INTERESTS).then(d => d.forTable);
    const allWebsitesPromise = allInterestsByTypeAndTopic('websites', ALL_INTERESTS).then(d => d.forTable);
    const allSearchesPromise = includeSearches ? allInterestsByTypeAndTopic(isFirstParty ? 'searchesBidStream' : 'searches', ALL_INTERESTS).then(d => d.forTable) : $q.resolve();
    $q.all([topicsPromise, allWebsitesPromise, allPhrasesPromise, allSearchesPromise, demographicsPromise])
      .then(([topics, websites, phrases, searches, distribution]) => {
        topics = _.orderBy(topics, 'interest-portion', 'desc');
        let excel = segmentInterestExportService.exportToExcel(topics, websites, phrases, searches, segment, distribution, audienceChannel);
        segmentInterestExportService.downloadExcel(excel);
        mixpanelAudience.trackExport();
      })
      .catch(expelUserIfMissingData);
  };
  $scope.$root.createExcelWorkbook = $scope.export;
}

module.exports = require("angular").module(__filename, [
  require("../../data/audience-insights-service").name,
  require('data/segment-interest-export-service').name,
  require("../../common/topic-selector.drv/topic-selector.drv").name,
  require("widgets/audience-table-view-widget/audience-table-struct").name,
  require("../../common/selectable-bar-chart.drv/selectable-bar-chart.drv").name,
  require('common/modals/confirmation/confirm-action.modal.service').name,
  mixpanel.name
])
  .directive("audienceInterestsWidget", [() => BaseWidget({
    restrict: "E",
    template: require("./audience-interests-widget.html"),
    scope: {
      navToPrevTab: '&',
      navToAudienceBuilder: '&'
    },
    controller: AudienceInterestsWidgetController
  })]);
