import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmationModal.scss';
import BaseModal from '../BaseModal/BaseModal';
import ConfirmationButtons from '../../ConfirmationButtons/ConfirmationButtons';

const ConfirmationModal = ({ isOpen, modalTitle, width, height, minHeight, marginTop, onCancel, onSubmit, isSubmitDisabled, isDisabledEnterKeyPress, submitLabel, cancelLabel, message, children }) => (
  <div className="confirmation-modal-component">
    <BaseModal
      isOpen={ isOpen }
      modalTitle={ modalTitle }
      onEscKeyPress={ onCancel }
      onEnterKeyPress={ onSubmit }
      marginTop={ marginTop }
      width={ width }
      height={ height }
      minHeight={ minHeight }
      isDisabledEnterKeyPress={ isDisabledEnterKeyPress }>
      <div className="confirmation-modal-container">
        { message ? <div className="confirmation-message">{ message }</div> : children }
        <div className="confirmation-buttons">
          <ConfirmationButtons
            submitLabel={ submitLabel } 
            isSubmitDisabled={ isSubmitDisabled }
            onSubmitClick={ onSubmit }
            cancelLabel={ cancelLabel }
            onCancelClick={ onCancel }>
          </ConfirmationButtons>
        </div>
      </div>
    </BaseModal>
  </div>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isDisabledEnterKeyPress: PropTypes.bool,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

ConfirmationModal.defaultProps = {
  width: '485px',
  minHeight: 'fit-content',
  submitLabel: 'Done',
  cancelLabel: 'Cancel',
  isSubmitDisabled: false
};

export default ConfirmationModal;