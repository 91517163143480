import { isFinite } from 'lodash';

export function formatNumberToUnits(number, decimalPlaces = 0) {
  if (!isFinite(number)) return null;

  const units = ['K', 'M', 'B'];
  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (Math.abs(number) >= decimal) {
      return (number / decimal).toFixed(decimalPlaces) + units[i];
    }
  }

  return number.toFixed(0);
}