import React from 'react';
import { isEqual } from 'lodash';
import './BaseDropdown.scss';
import Icon from '../../Icon/Icon';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner/Spinner';
import classNames from 'classnames';
import { convertToArray } from '../../../../utils/ArrayUtils';
import { FixedSizeList as List } from 'react-window';
import onClickOutside from 'react-onclickoutside';
import reactStringReplace from 'react-string-replace';
import WindowEventListener from '../../WindowEventListener/WindowEventListener';

const VirtualizedList = ({ values, isVirtualized, markup, menuHeight }) => {
  if (!isVirtualized) return values.map((value) => markup(value, isVirtualized));
  return (
    <List
      className="list"
      height={ menuHeight }
      itemCount={ values.length }
      itemSize={ 30 }
      width={ '100%' }
      overscanCount={ 3 }>
      { ({ data, index, isScrolling, style }) => (
        markup(values[index], isVirtualized, style)
      ) }
    </List>
  );
};

class BaseDropdown extends React.Component {
  constructor(props) {
    super(props);
    let isLoadingData = false;
    if (this.props.isMulti && !this.props.selectedValues) throw new Error('SelectedValues must be defined when isMulti = true');
    if (!this.props.isMulti && !this.props.selectedValue) throw new Error('SelectedValue must be defined when isMulti = false');
    if (!this.props.isMulti && this.props.showSelectAllOptions) throw new Error('Cannot have showSelectAllOptions with isMulti = false.');
    if (!this.props.values && !this.props.promise) throw new Error('props does not contain values or promise');
    if (!this.props.values && this.props.promise) {
      isLoadingData = true;
      this.props.promise.then((res) => {
        this.setState({ isLoadingData: false, isOpen: this.props.isOpen, filteredValues: res, promiseValues: res  });
      });
    }
    
    this.state = {
      isOpen: this.props.isOpen && !isLoadingData,
      searchString: null,
      filteredValues: this.props.values || [],
      isLoadingData,
      menuContainerHeight: this.props.menuMaxHeight
    };
    
    this.searchRef = this.props.isSearchable && React.createRef();
    this.menuContainerRef = React.createRef();
    this.getValueMarkup = this.getValueMarkup.bind(this);
    this.setMenuContainerHeight = this.setMenuContainerHeight.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) this.setState({ isOpen: this.props.isOpen });
    if (this.props.shouldResizeMenu && this.state.isOpen && prevState.isOpen !== this.state.isOpen) this.setMenuContainerHeight();
    if (prevProps.values !== this.props.values) {
      this.setState({
        searchString: null,
        filteredValues: this.props.values || [],
      });
    }
  }
  
  componentDidMount() {
    if (this.props.shouldResizeMenu && this.state.isOpen) this.setMenuContainerHeight();
  }
  
  setMenuContainerHeight() {
    const menuClientRect = this.menuContainerRef.current.getBoundingClientRect();
    const MIN_BOTTOM_PADDING = 20;
    const availableHeightForMenu = window.innerHeight - menuClientRect.top - MIN_BOTTOM_PADDING;
    const menuMaxHeight = this.props.menuMaxHeight ? Math.min(availableHeightForMenu, this.props.menuMaxHeight) : availableHeightForMenu;
    this.setState({ menuContainerHeight: menuMaxHeight });
  }
  
  getSelectedValuesAsArray() {
    if (!this.props.isMulti) return convertToArray(this.props.selectedValue);
    return this.props.selectedValues;
  }
  
  calcNewSelectedValues(prevSelectedValues, clickedValue) {
    if (!this.props.isMulti) return clickedValue;
    if (this.isSelected(clickedValue)) return prevSelectedValues.filter((selectedValue) => selectedValue['value'] !== clickedValue['value']);
    return prevSelectedValues.concat([clickedValue]);
  }
  
  onSelectValue(value) {
    const selectedValues = this.getSelectedValuesAsArray();
    const isValueDidNotChanged = !this.props.isMulti && selectedValues.length && value['value'] === selectedValues[0]['value'];
    if (isValueDidNotChanged) {
      if (this.props.shouldCloseOnSelect) this.setState({ isOpen: false });
      return;
    }
    
    const newSelectedValues = this.calcNewSelectedValues(selectedValues, value);
    this.props.onSelect(newSelectedValues, selectedValues);
    if (this.props.shouldCloseOnSelect) this.setState({ isOpen: false });
  }
  
  getCurrentValues() {
    return this.props.values || this.state.promiseValues || [];
  }
  
  onSelectAll() {
    const selectedValues = this.getSelectedValuesAsArray();
    const newSelectedValues = selectedValues.length === this.getCurrentValues().length ? [] : this.getCurrentValues();
    this.props.onSelect(newSelectedValues);
  }
  
  onSummaryClick() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }),
      () => (this.searchRef && this.state.isOpen && this.searchRef.current.focus())
    );
  }
  
  onSearch(searchString) {
    const filteredValues = this.filterValuesBySearchString(this.getCurrentValues(), searchString);
    this.setState({ searchString, filteredValues });
  }
  
  isSelected(value) {
    const selectedValues = this.getSelectedValuesAsArray();
    for (const selectedValue of selectedValues) {
      if (isEqual(selectedValue['value'], value['value'])) return true;
    }
    return false;
  }
  
  getSummaryText() {
    const selectedValues = this.getSelectedValuesAsArray();
    if (this.props.summaryTextBuilder) return this.props.summaryTextBuilder(selectedValues, this.getCurrentValues());
    if (!selectedValues.length || selectedValues.length === this.getCurrentValues().length) return this.props.summaryTextAllSelected;
    if (selectedValues.length === 1) return selectedValues[0]['label'];
    return `${selectedValues[0]['label']} and ${selectedValues.length - 1} ${selectedValues.length - 1 > 1 ? 'Others' : 'Other'}`;
  }
  
  filterValuesBySearchString(values, searchString) {
    if (!searchString) return values;
    searchString = searchString.toLowerCase();
    return values.filter((value) => value['label'].toLowerCase().includes(searchString));
  }
  
  getValueMarkup(value, isEllipsis, style) {
    return (
      <div style={ style } onClick={ () => this.onSelectValue(value) } key={ value['value'] } className={ classNames('value', { selected: this.isSelected(value) }) }>
        <div className={ classNames('value-label', { ellipsis: isEllipsis }) }>{ this.getLabel(value) }</div>
        <Icon iconId="v" className="v-icon"></Icon>
      </div>
    );
  }
  
  getLabel(value) {
    if (!this.state.searchString) return value['label'];
    return reactStringReplace(value['label'], this.state.searchString, (match, i) => (
      <span key={ `${value['value']} ${i}` } className="search-result">{ match }</span>
    ));
  }
  
  handleClickOutside() { //handler for onClickOutside HOC
    if (this.props.shouldCloseOnClickOutside) this.setState({ isOpen: false });
  }
  
  render() {
    return (
      <div className={ classNames('base-dropdown-component', this.props.className, this.state.isOpen ? 'open' : 'closed', { disabled: this.props.isDisabled || this.state.isLoadingData }, { 'select-all-enabled': this.props.showSelectAllOptions }) }>
        { this.props.shouldResizeMenu &&
          <WindowEventListener events="resize" eventHandlerFunction={ this.setMenuContainerHeight }/>
        }
        <Spinner show={ this.state.isLoadingData }></Spinner>
        <div onClick={ () => !this.props.isDisabled && this.onSummaryClick() } className="summary">
          <div className="summary-text">{ this.getSummaryText() }</div>
          <Icon iconId="triangle-up" className={ classNames('triangle', this.state.isOpen ? 'up' : 'down') }></Icon>
        </div>
        <div className="menu" style={ { width: this.props.menuWidth } }>
          { this.props.isSearchable &&
            <React.Fragment>
              <div className={ classNames('search-container', { 'has-search-string': this.state.searchString }) }>
                <Icon iconId="search" className="search-icon"></Icon>
                <input className="search-input" onChange={ (event) => this.onSearch(event.target.value) } placeholder="Search" ref={ this.searchRef }></input>
              </div>
              <div className="search-seperator" />
            </React.Fragment>
          }
          { this.props.showSelectAllOptions &&
            <React.Fragment>
              <div onClick={ () => this.onSelectAll() } className="value select-all">
                <div className="value-label">{ this.props.selectAllOptionsText }</div>
              </div>
              <div className="seperator" />
            </React.Fragment>
          }
          <div className="menu-container" ref={ this.menuContainerRef } style={ { maxHeight: this.state.menuContainerHeight } }>
            <VirtualizedList values={ this.state.filteredValues }
              isVirtualized={ !this.props.isVirtualizeDisabled && this.state.filteredValues.length > this.props.virtualizeThreshold }
              markup={ this.getValueMarkup }
              menuHeight={ this.state.menuContainerHeight }/>
          </div>
          { (this.state.searchString && !this.state.filteredValues.length) &&
            <div className="no-search-results-msg">
              0 results found <br/> try a different keyword
            </div>
          }
        </div>
        <div className="menu-triangle"></div>
      </div>
    
    );
  }
}

VirtualizedList.propTypes = {
  values: PropTypes.array,
  isVirtualized: PropTypes.bool,
  markup: PropTypes.func,
  menuHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

BaseDropdown.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  promise: PropTypes.object,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  shouldCloseOnClickOutside: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
  selectAllOptionsText: PropTypes.string,
  summaryTextAllSelected: PropTypes.string,
  summaryTextBuilder: PropTypes.func,
  onSelect: PropTypes.func,
  virtualizeThreshold: PropTypes.number,
  isVirtualizeDisabled: PropTypes.bool,
  menuMaxHeight: PropTypes.number,
  shouldResizeMenu: PropTypes.bool,
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  menuContainerStyle: PropTypes.object
};

BaseDropdown.defaultProps = {
  isMulti: false,
  isSearchable: false,
  shouldCloseOnSelect: false,
  showSelectAllOptions: false,
  virtualizeThreshold: 100,
  isVirtualizeDisabled: false,
  shouldResizeMenu: true,
  selectAllOptionsText: 'Select All',
  summaryTextAllSelected: 'All Selected',
  menuWidth: '100%'
};

export default onClickOutside(BaseDropdown);
