var resetPasswordModule = angular.module(__filename, []);

function resetPwdController($scope, $stateParams, $state, $window, userMgmt, abiNotificationModal) {
    var token = $stateParams.t;

    $scope.pwdConfirmationError = false;
    $scope.beforeUpdate = true;
    $scope.update = validateAndUpdate;

    function validateAndUpdate(form) {
        if (form.password == form.passwordConfirm) {
            updatePwd(form);
        } else {
            $scope.pwdConfirmationError = true;
        }
    }

    function updatePwd(form) {
        $scope.isBusy = true;
        userMgmt.resetLegacyPassword(token, form).then(function (res) {
            $scope.beforeUpdate = false;
            abiNotificationModal.show('good', 'Password updated', 'Feel free to login with your new password').then(function (modal) {
                modal.close.then(function () {
                    $window.location.replace('/login');
                });
            });
        }, function (error) {
            $scope.beforeUpdate = false;
            abiNotificationModal.show('bad', 'Update failed :(', 'This link is no longer valid').then(function (modal) {
                modal.close.then(function () {
                    $window.location.replace('/login');
                });
            });
        });
    }
}

resetPwdController.$inject = ['$scope', '$stateParams', '$state', '$window', 'userMgmt', 'abiNotificationModal'];

resetPasswordModule.stateConfig = {
    name: "reset_password",
    url: "/reset_password",
    template: require("./reset_password.html"),
    params: {t: null},
    controller: resetPwdController,
    data: {
      permissions: []
    }
};

module.exports = resetPasswordModule;
