import React from 'react';
import PropTypes from 'prop-types';
import './ImageToggleButtonSelector.scss';

import ImageToggleButton from '../ImageToggleButton/ImageToggleButton';

const ImageToggleButtonSelector = ({ togglesData, selectedToggle, onToggle, iconId, imageWidth, imageHeight, isDownTriangleVisible }) => {
  const defaultToggleData = { onToggle, iconId, imageWidth, imageHeight, isDownTriangleVisible };

  return (
    <div className="image-toggle-button-selector-component">
      { 
        togglesData.map((obj) => {
          const toggleData = { ...obj, ...defaultToggleData };
          const isSelected = obj.value === selectedToggle;

          return (
            <ImageToggleButton
              key={ toggleData.value }
              { ... toggleData }
              onToggle={ () => toggleData.onToggle(toggleData.value) }
              isSelected={ isSelected } />
          );
        })
      }
    </div>
  );
};

ImageToggleButtonSelector.propTypes = {
  togglesData: PropTypes.array.isRequired,
  selectedToggle: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  iconId: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  isDownTriangleVisible: PropTypes.bool
};

ImageToggleButtonSelector.defaultProps = {
  selectedToggle: null
};

export default ImageToggleButtonSelector;