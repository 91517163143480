import React from 'react';
import './IconValueComparison.scss';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconValueComparison = ({ firstIconId, firstDisplayValue, firstValue, secondIconId, secondDisplayValue, secondValue }) => (
  <div className="icon-value-comparison-component">
    <div className={ classNames('icon-container', 'first', { 'highlight': firstValue > secondValue }) }>
      <Icon iconId={ firstIconId } width="19" height="19"></Icon>
      <span className="icon-label">{ firstDisplayValue }</span>
    </div>
    <div className={ classNames('icon-container', { 'highlight': secondValue > firstValue }) }>
      <Icon iconId={ secondIconId } width="19" height="19"></Icon>
      <span className="icon-label">{ secondDisplayValue }</span>
    </div>
  </div>    
);

IconValueComparison.propTypes = {
  firstIconId: PropTypes.string.isRequired,
  firstDisplayValue: PropTypes.string.isRequired,
  firstValue: PropTypes.number.isRequired,
  secondIconId: PropTypes.string.isRequired,
  secondDisplayValue: PropTypes.string.isRequired,
  secondValue: PropTypes.number.isRequired
};

export default IconValueComparison;
