import config from 'infra/config';
import c from 'infra/utils/common';

var TREND_API = config.SEARCH_API + '/insights',
    BRAND_CONSUMPTION_API = config.SEARCH_API + '/brand_index',
    ASSOCIATION_TREND_LINE = config.SEARCH_API + '/association_trend_line';

module.exports = angular.module(__filename, []).service("consumptionTrendService", ['baseInsightsService', 'util', 'context', '$state', function (baseInsightsService, util, context, $state) {
    var lastTrends = {};

    // API
    return {
        get: getConsumptionTrends,
        getBrandConsumption: getBrandConsumption,
        getAssociationTrendLine: getAssociationTrendLine,
        getValuesByDateTable: getValuesByDateTable,
        getExamples: getExamples
    };

    function getConsumptionTrends(cp, selectedTopic, disableNotifyInsufficient) {
        disableNotifyInsufficient = disableNotifyInsufficient || false;
        var termMap = {};
        var parameters = baseInsightsService.buildParams(termMap, cp);
        if (_.isEmpty(termMap)) return Promise.reject("No Terms");

        if (c.is(selectedTopic) && (c.isNumber(selectedTopic.id) || c.isString(selectedTopic.id))) {
            parameters.super_topic_id = selectedTopic.id;
        }

        parameters.sentiment = cp.sentiment;
        parameters.alert_sentiment = cp.alert_sentiment;
        parameters.alert_change = cp.change;
        parameters.type = cp.type;
        parameters.to_normalize = cp.to_normalize;
        parameters.brand = cp.brand;
        parameters.no_examples = cp.no_examples;
        parameters.examples_only = cp.examples_only;
        parameters.language = context.current._language_mold.getLanguage($state, context).value;
        parameters.hourly_consumption_trends = cp.hourly_consumption_trends;
        if(cp.sg_web_source){
            parameters.sg_web_source = cp.sg_web_source;
        }
        return baseInsightsService.postAsyncData(TREND_API, parameters).cancellableThen(function (response) {
            return buildGraphResults(response.data, termMap, parameters, disableNotifyInsufficient)
        }).cancellableCatch(baseInsightsService.handleError);
    }

    function getBrandConsumption(cp) {
       var termMap = {};
       var parameters = baseInsightsService.buildParams(termMap, cp);
       parameters.brand = cp.brand;
       parameters.to_normalize = cp.to_normalize;

       return baseInsightsService.postAsyncData(BRAND_CONSUMPTION_API, parameters).cancellableThen(function (response) {
         var graph = buildGraphResults(response.data.graph, termMap, parameters);
         return {graph: graph, brandIndex: response.data.brand_index};
       }).cancellableCatch(baseInsightsService.handleError);
    }

    function getAssociationTrendLine(params, associatedSeed) {
        var termMap = {};
        var parameters = baseInsightsService.buildParams(termMap, params);
        parameters.language = context.current._language_mold.getLanguage($state, context).value;
        if (_.isEmpty(termMap)) return Promise.reject("No Terms");

        if (isFinite(associatedSeed.id) || associatedSeed.id == associatedSeed.text.toLowerCase() + '_id') {
            parameters.associated = associatedSeed.text.toLowerCase();
            parameters.kwd_ids[associatedSeed.text.toLowerCase()] = associatedSeed.id;
        } else if (['@', '#'].indexOf(associatedSeed.id[0]) != -1) {
            parameters.associated = associatedSeed.text.toLowerCase();
            parameters.kwd_ids[associatedSeed.text.toLowerCase()] = -1;
        } else {
            parameters.boolean_associated = util.getTerms([associatedSeed], true)[0];
            angular.extend(parameters.kwd_ids, util.getPhrasesToIdMap([associatedSeed]));
        }

        parameters.measure = params.measure;
        parameters.to_normalize = params.to_normalize && params.measure != 'skew';
        return baseInsightsService.postAsyncData(ASSOCIATION_TREND_LINE, parameters).then(function (response) {
            return buildGraphResults(response.data, termMap, parameters);
        }).catch(baseInsightsService.handleError);
    }

    function getValuesByDateTable(terms, format, cellType) {
        var result = [], sources = [], targets = [], map = {};
        var raw = lastTrends;
        if (c.is(raw) && c.isArray(raw.chart) && c.isArray(terms)) {
            var dateCellType = 'date';
            _.each(raw.chart, function (t) {
                if (c.is(t.term) && c.isArray(t.series)) {
                    var id = ['booleanLogic', 'programBL', 'hashTag', 'mention', 'post'].indexOf(t.term.type) != -1 ? t.term.text : t.term.id.toString();
                    sources.push(id);
                    var hasHours = !_.isEmpty(_.find(t.series, function(item){
                        return item.date.getHours() > 0
                    }));
                    dateCellType = hasHours? 'fullDate':'date';
                    _.each(t.series, function (column) {
                        c.setDatedValueById(map, column.date, hasHours, cellType == 'integer' ? parseInt(column.value) : column.value, id);
                    });
                }
            });
            _.each(terms, function (target) {
                if (c.isNumber(target.id) && target.id > 0) {
                    targets.push(target.id.toString());
                } else {
                    targets.push(target.text);
                }
            });
            _.each(map, function (entry, key) {
                var row = [format(key, dateCellType)];
                _.each(targets, function (target) {
                    row.push(format((c.isNull(entry[target]) ? 0 : entry[target]), cellType));
                });
                result.push(row);
            });
        }
        return result;
    }

    function buildGraphResults(response, termMap, parameters, disableNotifyInsufficient) {
        disableNotifyInsufficient = disableNotifyInsufficient || false;
        var result = {}, maxValue = 0, maxUnnormalized = 0, trends = [], averages = [], dates = [], sums = [];
        var foundTerms = {};
        result.examples = response.abi_content_examples;
        delete response.abi_content_examples;

        result.last_closed_time_mark = response.last_closed_time_mark;
        delete response.last_closed_time_mark;

        _.each(response, function (entry, trendName) {
            var term = termMap[trendName] || _.find(termMap, ['id', parseInt(trendName)]);
            var average = 0, values = [];
            if (c.isString(trendName) && c.is(term) && c.isArray(entry['datapoints'])) {
                foundTerms[term.text] = term;
                // remove first point (last date) if it has zero consumption
                if (_.has(result.last_closed_time_mark) && c.getNumber(entry['datapoints'][0].value, 0) == 0) entry['datapoints'].splice(0, 1);
                _.each(entry['datapoints'], function (point) {
                    if (c.isString(point.date)) {
                        var date = c.parseDate(point.date);
                        var v = c.getNumber(point.value, 0);
                        var total = c.getNumber(point.unnormalized_value, 0);
                        dates.push(date);
                        average += v;
                        maxValue = (v > maxValue) ? v : maxValue;
                        maxUnnormalized = (total > maxUnnormalized) ? total : maxUnnormalized;
                        var value = {
                            date: date,
                            total: total,
                            value: v
                        };
                        values.push(value);
                    }
                });
            }

            sums.push({id: term.id, label: term.text, value: Math.round(average), class: term.class});
            average = values.length > 0 ? average / values.length : 0;
            trends.push({term: term, series: values, avg_pos: entry['avg_pos'], avg_neg: entry['avg_neg']});
            averages.push({id: term.id, label: term.text, value: average, class: term.class});
        });

        c.sortByNumeric(averages, 'value', true);
        result.icon = c.getChannelIcon(parameters.channel);
        result.chart = trends;
        result.range = dates;
        result.averages = averages;
        result.sums = sums;
        result.max = maxValue;
        result.maxUnnormalized = maxUnnormalized;
        result.maxChart = parameters.to_normalize ? maxValue : _.max(_.map(sums, 'value'));
        lastTrends = result; // local "cache" for export
        var insufficient = isResponseDataEmpty(result, _.difference(Object.keys(termMap), Object.keys(foundTerms)));
        if (!disableNotifyInsufficient) {
            baseInsightsService.notifyInsufficient(insufficient);
            return insufficient.length == Object.keys(termMap).length ? Promise.reject({}) : result;
        } else {
            return result;
        }
    }

    function getExamples(terms, format, visibleExamples, associated) {

        var examples = c.isArray(visibleExamples) ? visibleExamples : lastTrends.examples;
        var termDisplayByText = [], termDisplayById = [], result = [];
        _.each(terms, function (term) {
            termDisplayByText[term.text] = term.display;
            if (term.id != -1) {
                termDisplayById[term.id] = term.display;
            }
        });

        var hasHours = !_.isEmpty(_.find(examples, function(e){
           return new Date (e.date).getUTCHours() > 0
        }));

        _.each(examples, function (example) {
            var seed = termDisplayByText.hasOwnProperty(example.keyword) ?
                termDisplayByText[example.keyword] :
                termDisplayById.hasOwnProperty(example.keyword) ? termDisplayById[example.keyword] : example.keyword;
            seed += (_.has(associated, 'text')) ? " with " + associated.text : '';
            var date = example.date;
            var cellType = 'date';
            if(hasHours){
                date = moment(date).format('YYYY-MM-DDTHH:mm:SS');
                cellType = 'fullDate';
            }
            else {
                date = example.date.substr(0, 10);
            }
            var row = [format(date , cellType), format(seed, 'text'), format(example.url, 'url')];
            result.push(row);
        });
        return result;
    }

    function isResponseDataEmpty(data, notFound) {
        var insufficient = notFound;
        _.each(data.averages, function (point) {
            if (point.value == 0) {
                insufficient.push(point.label);
            }
        });

        return insufficient;
    }
}]);
