const tmpl = require("./audience-widget.html");
const nv = require("nvd3");
const BaseWidget = require("../base_widget");
const common = require("infra/utils/common");

const GENDER_CHART_SELECTOR = "audience-widget .gender-chart";

function AudienceWidgetCtrl (audienceInsightsService, geoService) {
    this.all_geos_size = geoService.geos.length;
    this.audienceInsightsService = audienceInsightsService;
    this.$scope.responsiveBarChartOptions = {
        xAxisColor: "#2e3236", barWidth: 20, barColor: "#cacaca", labelColor: "#cacaca", labelFont: "14px Helvetica", valueColor: "#cacaca", valueFont: "14px Helvetica",
        highlightedBarColor: "white", highlightedLabelColor: "white", highlightedLabelFont: "14px Helvetica", highlightedValueColor: "white",
        highlightedValueFont: "16px Helvetica Bold"
    };
}

AudienceWidgetCtrl.prototype._doUpdate = function (newValues) {
    const brandKeyword = this.$scope.terms && this.$scope.terms.find(t => t.text === this.$scope.program.brand.name);
    if (!brandKeyword || !brandKeyword.id) return;

    const geo = this.$scope.geos.length === this.all_geos_size ? [] : this.$scope.geos.map(g => g.cc);
    return this.audienceInsightsService.getDemographicsDataByKeywordsAndCountries(brandKeyword.id, geo, {disableNotification: true})
        .then(result => {
            switch (result && result.status) {
                case 'ok':
                    this.$scope.error = false;
                    this.$scope.age = result.distribution.age;
                    const genderObjects = _.map(result.distribution.gender, (o, label) => Object.assign({label}, o));
                    this.drawAudienceGenderChart(genderObjects);
                    resizeGenderChart(this.$scope.svg, this.$scope.slice, this.$scope.text, this.$scope.icon, this.$scope.polyline);
                    break;
                case 'error':
                    const errorMessageByReason = {
                        "selectionTooNarrow": `Sorry, insufficient audience info about "${brandKeyword.text}".`
                    };
                    this.$scope.error = true;
                    this.$scope.errorMessage = errorMessageByReason[result.error.reason];
                    break;
                default:
                    this.$scope.error = true;
                    this.$scope.errorMessage = "No available data.";
                    break;
            }
        });
};

AudienceWidgetCtrl.$inject = ["audienceInsightsService", "geoService"];

AudienceWidgetCtrl.prototype.onResize = function () {
    if (this.$scope.svg) {
        resizeGenderChart(this.$scope.svg, this.$scope.slice, this.$scope.text, this.$scope.icon, this.$scope.polyline);
    }
};

function midAngle (d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
}

AudienceWidgetCtrl.prototype.drawAudienceGenderChart = function (data) {
    d3.select(GENDER_CHART_SELECTOR).select("svg").remove();
    const svg = d3.select(GENDER_CHART_SELECTOR).append("svg").append("g");

    svg.append("g").attr("class", "slices");
    svg.append("g").attr("class", "labels");
    svg.append("g").attr("class", "lines");

    const pie = d3.layout.pie()
        .sort(null)
        .value(d => d.value);

    /* ------- PIE SLICES ------- */
    const slice = svg.select(".slices").selectAll("path.slice")
        .data(pie(data), data.label);

    slice.enter()
        .insert("path")
        .style("fill", '#81878c')
        .attr("class", "slice");

    slice.exit().remove();

    /* ------- TEXT LABELS ------- */

    svg.selectAll(".labels").remove();
    svg.append("g").attr("class", "labels");
    //svg.select(".labels").selectAll("text").remove();
    const text = svg.select(".labels").selectAll("textpercents")
        .data(pie(data), data.label);

    text.enter().append("text")
        .attr("dy", ".4em")
        .style('fill', 'white')
        .text(d => d.data.displayValue);

    text.exit().remove();

    const icon = svg.select(".labels").selectAll("icon")
        .data(pie(data), data.label);

    icon.enter().append("svg:image")
        .attr("xlink:href", d => `./images/widgets/audience-widget/${d.data.label}.svg`);

    icon.exit().remove();

    /* ------- SLICE TO TEXT POLYLINES ------- */

    const polyline = svg.select(".lines").selectAll("polyline").data(pie(data), data.label);

    polyline.enter().append("polyline");

    polyline.exit().remove();

    this.$scope.slice = slice;
    this.$scope.text = text;
    this.$scope.icon = icon;
    this.$scope.polyline = polyline;
    this.$scope.svg = svg;
};

function resizeGenderChart (svg, slice, text, icon, polyline) {
    const chart_elem = $(GENDER_CHART_SELECTOR);
    const width = chart_elem.width(), height = chart_elem.height();
    let radius = height * 0.65;
    if (radius > width / 4) radius = 45;

    const arc = d3.svg.arc()
        .outerRadius(radius * 0.65)
        .innerRadius(radius * 0.52);

    const outerArc = d3.svg.arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

    svg.attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    slice.transition().duration(1000)
        .attrTween("d", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => arc(interpolate(t));
        });

    slice.exit().remove();

    text.style('font-size', '16px').style('font-weight', 'normal').style('font-family', 'Helvetica Bold')
        .transition()
        .attrTween("transform", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => {
                const d2 = interpolate(t);
                const pos = outerArc.centroid(d2);
                pos[0] = (radius + 5) * (midAngle(d2) < Math.PI ? 1 : -1);
                return `translate(${pos})`;
            };
        })
        .styleTween("text-anchor", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => midAngle(interpolate(t)) < Math.PI ? "start" : "end";
        });

    text.exit().remove();

    let icon_size = radius / 4;
    icon_size = icon_size < 20 ? 20 : icon_size;
    svg.selectAll("image").attr("width", icon_size).attr("height", icon_size);

    icon.transition()
        .attrTween("transform", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => {
                const d2 = interpolate(t);
                const factor = (midAngle(d2) < Math.PI ? 1 : -1);
                let pos = outerArc.centroid(d2);
                // pos[0] = radius * factor - (factor > 0 ? -40 : 60);
                pos[0] = radius * factor - (factor > 0 ? -47 : 67 - 20 + icon_size);
                /* - icon_size / 2 */
                pos[1] = pos[1] - icon_size / 2;
                // reduce the man icon 1 pixel down
                if (factor > 0) pos[1] += 1;

                return `translate(${pos})`;
            };
        })
        .styleTween("text-anchor", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => midAngle(interpolate(t)) < Math.PI ? "start" : "end";
        });

    icon.exit().remove();

    polyline.transition()
        .attrTween("points", function (d) {
            this._current = this._current || d;
            const interpolate = d3.interpolate(this._current, d);
            this._current = interpolate(0);
            return t => {
                const d2 = interpolate(t);
                const pos = outerArc.centroid(d2);
                pos[0] = radius * (midAngle(d2) < Math.PI ? 1 : -1);
                return [arc.centroid(d2), outerArc.centroid(d2), pos];
            };
        });

    polyline.exit().remove();
}

module.exports = angular.module(__filename, [
    require("data/audience-insights-service").name,
    require("data/geo-service").name
])
    .directive("audienceWidget", ["audienceInsightsService", "geoService", function (audienceInsightsService, geoService) {
            return BaseWidget({
                restrict: "E",
                template: tmpl,
                scope: {
                    program: "=program",
                    geos: "=geos",
                    terms: "=terms"
                },
                controller: AudienceWidgetCtrl
            });
        }]
    );
