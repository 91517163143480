function BubblesController($rootScope, discoveryExportService, Discovery, Excel, $q, $parse) {
    $rootScope.createExcelWorkbook = discoveryExportService.getDiscoveryExcelWorkbook.bind(null, Discovery, Excel, $q, $parse);
    $rootScope.helpPage = '3_Discovery_Screen';
}

BubblesController.$inject = ["$rootScope", "discoveryExportService", "Discovery", "Excel", "$q", "$parse"];

module.exports.stateConfig = {
    name: "bubbles",
    url: "/bubbles",
    template: require("./bubbles.discovery.html"),
    display: "Discovery",
    data: {
      permissions: ['trending interests']
    },
    controller: BubblesController,
    context: {}
};
