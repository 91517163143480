import * as OktaAuth from '@okta/okta-auth-js';
import Cookies from 'js-cookie'

/**
 * Created by guyB on 11/04/2019
 */
/*
    handles the needed data and action for the sso login
    1: getLoginDataObj: 
       a local storage object that holds the current params:
       {
        "method":"sso", -> sso/normal what login method was used last and will redirect in the next bi entry
       }

    2: isUsingSso: if the user used sso login method
*/
module.exports = angular.module(__filename,[])
    .service('ssoService', ["$window" , function ($window) {

      let oktaServerUrlData = SSO_SERVER;
      let isProduction = IS_PRODUCTION;
      let originArr = document.location.host.split(".");

      //on what env we are hermes.intelligence.amobee.com  vs production intelligence.amobee.com
      if (originArr.length > 3 && isNaN(parseInt(originArr[0]))){
        isProduction = false;
      }

      if (document.location.host === "next.intelligence.amobee.com") {
        isProduction = true;
      }
        
      var oktaEnv = isProduction? "prod" : "dev";

      var authConfig = {
        issuer: oktaServerUrlData[oktaEnv].url + "/oauth2/default",
        clientId: oktaServerUrlData[oktaEnv].clientId,
        redirectUri: (window.location.origin + "/login")
      }
      var authClient = new OktaAuth['default'](authConfig);

        /*API*/
        return {
            authorize: authorize,
            getAccessToken: getAccessToken,
            ensureLoggedIn: ensureLoggedIn,
            getLoginDataObj: getLoginDataObj,
            setLoginDataObj: setLoginDataObj,
            isUsingSso: isUsingSso,
            redirectToLogin: redirectToLogin
        };

        function isUsingSso(){
            let loginObj = getLoginDataObj();
            return loginObj.method && loginObj.method == "sso";
        }

        function getAccessToken() {
         return authClient.tokenManager.get('accessToken');
        };

        function ensureLoggedIn(){
          
          //checks token
          //if no token, redirects to login

          const myPromise = new Promise((resolve, reject) => {

            this.getAccessToken().then((res)=> {
              if(!res || !res.accessToken){
                logout();
                reject(new Error('token expired'));
              }else{
                resolve(res.accessToken);
              } 

            }, (res)=>{
              logout();
              reject(new Error('token error'));
            });

            const logout = ()=>{
              Cookies.set('login_state', true, { expires: 10 / 24 * 60 * 60, path: '/' });            
              this.redirectToLogin();
            }

          });

        }

        function authorize() {
            let randomString = generateRandomString();
            return authClient.token.getWithoutPrompt({
              responseType: ['token', 'id_token'],
              scopes: ['openid', 'email'],
              state: randomString,
              nonce: randomString
            })
        };

        function generateRandomString(length = 40) {
            const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let array = new Uint8Array(length);
            window.crypto.getRandomValues(array);
            array = array.map(x => validChars.charCodeAt(x % validChars.length));
            return String.fromCharCode.apply(null, array);
        };

        function getLoginDataObj(){
            let loginObj = localStorage.getItem('login_data');
            return loginObj? JSON.parse(loginObj) : {};
        } 

        function setLoginDataObj(obj){
            localStorage.setItem('login_data', JSON.stringify(obj));
        }

        //this is what determins if a hard or soft logout will be made
        function redirectToLogin(isHardLogout){
            if(isHardLogout){
                sessionStorage['login_message'] = 'You have been logged out of the Amobee Platform.<br/><br/><br/><a href="/login">Go to sign in</a>'+
                '<style>.welcome{display:none}</style>'+

                //add the iframe other apps use to logout
                '<iframe id="logoutFrames" src="https://ssomgr-api'+
                (location.host == "intelligence.amobee.com" ? "" : '.qa-iad') +
                '.amobee.com/web/logout?app=APP_BRAND_INTELLIGENCE" '+
                'style="display: none;"></iframe>';

                //will clear okta session, tokens and local params
                $window.location.replace('/junction?logOut=true');
            }else{
                $window.location.replace('/login');
            }
        }
    }]
);
