import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import './SingleSelectToggle.scss';

const SingleSelectToggle = ({ values, selectedValue, onToggle, disabled }) => {
  let selectedValueFound = false;
  
  values.forEach((value) => {
    
    if (undefined == value.label && undefined == value.icon) {
      throw new Error("Single Select Toggle 'values' needs 'label' or 'icon' for each cell");
    }

    if (value.value == selectedValue) {
      selectedValueFound = true;
    }

  });

  if (selectedValueFound == false) {
    throw new Error("Single Select Toggle 'values' needs a cell with value==props.selectedValue");
  }

  return ( 
    <div className="single-select-toggle-component"> 
      {
        values.map((value, i) => <Tooltip 
          content={ value.tooltip } 
          key={ 'tooltip' + i }>

          <span className={ classNames('toggle-button', { 
            'selected': selectedValue == value.value, 
            'disabled': disabled }) }
   
          onClick={ () => {
            if (!disabled) {
              onToggle(value.value);
            }
          } }
            
          key={ 'button' + i }>
            { value && value.icon && <Icon iconId={ value.icon || '' } className="icon"/> }
            { value && value.icon && value.label && <span className="label-icon-margin"/> }
            { value && value.label && <span className="with-text">{ value.label }</span> }
          </span>
 
        </Tooltip>)
      }
    </div>
  );
};

SingleSelectToggle.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired
  })).isRequired,
  selectedValue: PropTypes.any.isRequired,
  onToggle:      PropTypes.any.isRequired,
  disabled:      PropTypes.bool
};

SingleSelectToggle.defaultProps = {
  disabled: false
};

export default SingleSelectToggle;
