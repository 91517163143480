module.exports = angular.module(__filename, [
    require('common/am-range-slider.drv/am-range-slider.drv').name
]).service('linkedinDemographicsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(demographics, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./linkedin-demographics-modal.html'),
            inputs: { demographics: demographics, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params },
            controller: ['$timeout', '$scope', 'close', 'filtersPartition', 'demographics', 'isNewSegment', 'disableGeo', 'channel', 'params', LinkedinDemographicsModalCtrl]
        });
    }

    function LinkedinDemographicsModalCtrl($timeout, $scope, close, filtersPartition, demographics, isNewSegment, disableGeo, channel, params) {
        const ALL_COUNTRIES = { label: 'All Geos', value: 'all' };
        const ALL_STATES = { label: 'All States', value: 'all' };
        const ALL_REGIONS = { label: 'All Areas', value: 'all' };
        const ALL_GENDERS = filtersPartition.linkedinBothGender;
        const GENDER_CONFIG = { "urn:li:gender:MALE": { icon: 'icon-male' }, "urn:li:gender:FEMALE": { icon: 'icon-Female' }, "both": { icon: 'icon-male_female' } };
        const US = { label: "United States", value: "urn:li:country:us" };
        let unfiltered_regions;

        $scope.isNewSegment = isNewSegment;
        $scope.disableGeo = disableGeo;
        $scope.close = close;
        $scope.demographics = _.extend({ gender: null, age: null, income: null, children: null, country: [], state: [], regions: [] }, demographics);  
        $scope.promise = params.linkedinMetaDataPromise;
        $scope.isDataTreesLoaded = false;

        $scope.isUs = () => {
            return _.isEqual($scope.demographics.country, [US]);
        }

        params.linkedinMetaDataPromise.then(d => {
            let data = _.cloneDeep(d);
            unfiltered_regions = data.regions;

            $scope.genders = data.genders;
            $scope.ages = data.ages;
            $scope.countries = data.countries;
            $scope.us_states = data.us_states;
            $scope.regions_by_state = data.regions_by_state;
            $scope.regions = [];

            $scope.genders.push(ALL_GENDERS);
            $scope.genders.forEach(gender => Object.assign(gender, GENDER_CONFIG[gender.value]));
            $scope.iconToLabelMap = {};
            $scope.genders.forEach(gender => { $scope.iconToLabelMap[gender.icon] = gender.label });
            $scope.demographics = _.extend({ gender: null, age: null, income: null, children: null, country: [], state: [], regions: [] }, demographics);

            $scope.dataTrees = {
                geoDataTree: {
                checkedArray: _.map($scope.demographics.country, (country) => ({ id: country.value, name: country.label})),
                children: _.map($scope.countries, (country) => ({ id: country.value, name: country.label})),
                allSelectedLabel: 'All Geos',
                maxSummaryItems: 1,
                showSearch: true
                },
                stateDataTree: {
                    checkedArray: _.map($scope.demographics.state, (state) => ({ id: state.value, name: state.label})),
                    children: _.map($scope.us_states, (state) => ({ id: state.value, name: state.label})),
                    allSelectedLabel: 'All States',
                    maxSummaryItems: 1,
                    showSearch: true
                },
                regionDataTree: {
                    checkedArray: _.map($scope.demographics.regions, (region) => ({ id: region.value, name: region.label})),
                    children: _.map($scope.regions, (state) => ({ id: state.value, name: state.label})),
                    allSelectedLabel: 'All Areas',
                    maxSummaryItems: 1,
                    showSearch: true
                }
            };

            $scope.isDataTreesLoaded = true;
            $scope.dataTrees.geoDataTree.saveChecked = (checkedArray) => {
                $scope.demographics.country = _.map(checkedArray, (item) => ({ value: item.id, label: item.name}));
            };
            $scope.dataTrees.stateDataTree.saveChecked = (checkedArray) => {
                $scope.demographics.state = _.map(checkedArray, (item) => ({ value: item.id, label: item.name}));
            };
            $scope.dataTrees.regionDataTree.saveChecked = (checkedArray) => {
                $scope.demographics.regions = _.map(checkedArray, (item) => ({ value: item.id, label: item.name}));
            };
        });

        $scope.$watch('isDataTreesLoaded', () => {
            if ($scope.isDataTreesLoaded) {
                _.each(['geoDataTree', 'stateDataTree', 'regionDataTree'], (tree) => {
                    $scope.dataTrees[tree].show();
                })
            } 
        });

        $scope.$watch("demographics.country", function (newCountry, oldCountry) {
            if (!newCountry || $scope.isUs()) return;
            $scope.demographics.state = [];
            if (!_.isEmpty($scope.dataTrees)) {
                $scope.dataTrees.stateDataTree.checkedArray = [];
                $scope.dataTrees.stateDataTree.show();
            }
            $scope.disableRegion = true;   
        });

        $scope.$watch("demographics.state", function (newState, oldState) {
            if (!newState || !oldState) return;
            if (!_.isEmpty($scope.demographics.state) && newState.length ===1 ) {
                let relevant_regions = $scope.regions_by_state[_.first(newState).value];
                $scope.regions = relevant_regions ? _.filter(unfiltered_regions, region => relevant_regions.includes(region.value)) : [];
                $scope.disableRegion = relevant_regions ? false : true;
            } else {
                $scope.regions = [];
                $scope.demographics.regions = [];
                $scope.disableRegion = true;
            }

            if (!_.isEmpty($scope.dataTrees)) {
                $scope.dataTrees.regionDataTree.children = _.map($scope.regions, (region) => ({ id: region.value, name: region.label}));
                $scope.dataTrees.regionDataTree.checkedArray = [];
                $scope.dataTrees.regionDataTree.show();
            }
        });

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.demographics))
        };

        function removeEmptyFilters(demographics) {
            let results = {};
            if ((demographics.gender || []).length > 0) results.gender = demographics.gender;
            if ((demographics.age || []).length > 0 && (demographics.age || []).length < $scope.ages.length) results.age = demographics.age;
            
            if (!_.isEmpty(demographics.country) && !_.isEqual(demographics.country.length, $scope.countries.length)) {
                results.country = demographics.country;
            }

            if (!_.isEmpty(demographics.state) && !_.isEqual(demographics.state.length, $scope.us_states.length)) {
                results.state = demographics.state;
            }

            if (!_.isEmpty(demographics.regions) && !_.isEqual(demographics.regions.length, $scope.regions.length)) {
                results.regions = demographics.regions;
            }
            
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters($scope.demographics), { type: "linkedinDemographics", operand: this.demographics.operand }));
        }
    }
}]);
