import React from 'react';
import PropTypes from 'prop-types';
import { noop, isEqual } from 'lodash';

const PromiseStateEnum = { LOADING: 'loading', LOADED: 'loaded', ERROR: 'error' };

class WidgetPromiseStateManager extends React.Component {

  constructor(props) {
    super(props);
    this._isMounted = true;

    this.state = {
      promiseState: PromiseStateEnum.LOADING,
      data: null,
    };
  }

  componentDidMount() {
    this.fetchDataAndHandlePromise(this.props.dataServiceParams);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (isEqual(this.props.dataServiceParams, prevProps.dataServiceParams)) return;
    if (this.state.promiseState === PromiseStateEnum.LOADED) this.props.onDataUnloaded();
    this.setState({ promiseState: PromiseStateEnum.LOADING }, () => {
      this.fetchDataAndHandlePromise(this.props.dataServiceParams);
    });
  }

  async fetchDataAndHandlePromise(dataServiceParams) {
    try {
      const promise = this.props.dataService.apply(null, dataServiceParams);
      const data = await promise;
      if (!isEqual(dataServiceParams, this.props.dataServiceParams) || !this._isMounted) return;
      this.setState({ promiseState: PromiseStateEnum.LOADED, data });
      this.props.onDataLoaded(data);
    } catch (error) {
      if (!isEqual(dataServiceParams, this.props.dataServiceParams) || !this._isMounted) return;
      this.props.onServiceError(error);
      this.setState({ promiseState: PromiseStateEnum.ERROR, data: null });
    }
  }

  render() {
    const isPromiseStillLoading = this.state.promiseState === PromiseStateEnum.LOADING;  

    if (this.state.data) return this.props.onRender(this.state.data, isPromiseStillLoading); 
    if (this.state.promiseState === PromiseStateEnum.ERROR || this.state.isChildrenHasJsError) return this.props.errorRender();

    return this.props.emptyStateRender(isPromiseStillLoading);
  }
}   

WidgetPromiseStateManager.propTypes = {
  dataService: PropTypes.func.isRequired,
  dataServiceParams: PropTypes.array.isRequired,
  onServiceError: PropTypes.func,
  onRender: PropTypes.func.isRequired,
  errorRender: PropTypes.func.isRequired,
  emptyStateRender:  PropTypes.func.isRequired,
  onDataLoaded: PropTypes.func,
  onDataUnloaded: PropTypes.func
};
  
WidgetPromiseStateManager.defaultProps = {
  onDataLoaded: noop,
  onDataUnloaded: noop,
  onServiceError: noop
};

export default WidgetPromiseStateManager;