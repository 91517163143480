import React from 'react';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import PropTypes from 'prop-types';
import './Dropdown.scss';

const Dropdown = (props) => <BaseDropdown { ...props } />; 

Dropdown.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  promise: PropTypes.object,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool, 
  shouldCloseOnClickOutside: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
  selectAllOptionsText: PropTypes.string,
  summaryTextAllSelected: PropTypes.string, 
  summaryTextBuilder: PropTypes.func,
  onSelect: PropTypes.func,
  virtualizeThreshold: PropTypes.number,
  isVirtualizeDisabled: PropTypes.bool,
  menuMaxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Dropdown.defaultProps = {
  className: 'dropdown-component',
  shouldCloseOnClickOutside: true,
  menuMaxHeight: 200
};

export default Dropdown;
