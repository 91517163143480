var mixPanel = require('./mixpanel-base');

module.exports = angular.module(__filename, [
    mixPanel.name
]).service('mixpanelTargets', ['$state', '$window', 'context', 'dspService', 'mixpanelService', function ($state, $window, context, dspService, mixpanelService) {

    var appName = 'Targets';
    var widgetName = mixpanelService.extractWidget($state.current);
    var snapshot = context.current;

    var generatedDate = moment().format('YYYY-MM-DD HH:mm');
    var manualPhrases = []; 
    var limitTopics = [];
    var limitTopicsFlag, dynamicTrendsFlag, ActivatedToFlag, selectAllPhrasesFlag, excludeFlag = false;

    //tracking functions
    function trackNewTarget(params) {
        mixpanelService.sendToMixpanel('Target - New Target', targetsProps(params));
    }

    function trackDeleteTarget(deleteTarget) {
        var props = targetsProps(deleteTarget),
        deleteProps = {
            activated_to: deleteTarget.activated_to ? deleteTarget.activated_to[0] : null,
            deleteDate: generatedDate
        }

        mixpanelService.sendToMixpanel('Target - Delete Target', Object.assign(props, deleteProps));
    }

    function trackSelectAll(currentTarget) {
        if(selectAllPhrasesFlag && (currentTarget && currentTarget.name)){
            mixpanelService.sendToMixpanel('Audience-Explorer - Interests - Phrases - Select all', targetsProps(currentTarget));
            selectAllPhrasesFlag = false;
        }   
    }

    function trackContentDriversPaneOpen() {
        mixpanelService.trackContentDrive();
    }




    //utils functions
    function targetsProps(target) {
        var props = {
            'appName': appName,
            'widget': widgetName,
            'target name': target.name
        }

        if(target.id){
             return Object.assign(props, {'target id': target.id});

        }
        return props;
    }

    
    //for each toggle button, this will save the state 
    //to be checked when the done button is clickd (see next function: trackUpdatedData)
    function collectUpdatedTargetData(action, data) {

        switch(action) {
            case 'Add Phrase':{
                manualPhrases = manualPhrases.concat(data);
                break;
            }
            case 'Limit Topics':{
                limitTopicsFlag = data;
                break;
            }
            case 'Dynamic Trends':{
                dynamicTrendsFlag = data;
                break;
            }
            case 'Activated To':{
                ActivatedToFlag = data;
                break;
            }case 'Exclude Btn':{
                excludeFlag = data;
                break;
            }
            default:
               
        }
    }

    function trackUpdatedData(scope){
        
        var updatedTarget = scope.currentTarget;
        var checkedArray = scope.dataTrees && scope.dataTrees.manualTopicsTree ? 
                                scope.dataTrees.manualTopicsTree.checkedArray : [];
        var props = targetsProps(updatedTarget);

        if(manualPhrases.length > 0){
             mixpanelService.sendToMixpanel('Target - Manually add phrase', Object.assign(props, {'added phrases': manualPhrases.length}));
        }

        if(limitTopicsFlag || !compareArrays(limitTopics, updatedTarget.topics)){
            var limitTopicsArr = _.map(checkedArray, item => (
                `${item.name}`
            ));

            mixpanelService.sendToMixpanel('Target - Limit Topics', Object.assign(props, {'topics': limitTopicsArr}));
        }

        if(dynamicTrendsFlag){
            mixpanelService.sendToMixpanel('Target - Dynamically add Trends', props);
        }

        if(excludeFlag){
            mixpanelService.sendToMixpanel('Target - Exclude btn', Object.assign(props, {'Exclude': excludeFlag}));
        }

        if(ActivatedToFlag){

            var activatedTo = updatedTarget.activated_to[0];

            if(updatedTarget.activated_to[0] == "turn"){
                dspService.getMarketFromId(updatedTarget.market_id, {isAllMarketsSupported: true}).then(function(market) {
                  let marketName = market.name;

                  activatedTo = "DSP";
                  mixpanelService.sendToMixpanel('Target - Market', Object.assign(props, {'Activate to': activatedTo, 'market ID': updatedTarget.market_id, 'market name': marketName}));
                  mixpanelService.sendToMixpanel('Target - Activate to', Object.assign(props, {'Activate to': activatedTo, 'market ID': updatedTarget.market_id, 'market name': marketName}));
                });
            }else{
                var activatedToObj = activatedTo != undefined? {'Activate to': activatedTo}  : {'Not Activated': 'true'};
                mixpanelService.sendToMixpanel('Target - Activate to', Object.assign(props, activatedToObj));
            }
        }
        
    }

    function trackKeyTrends(updatedTarget){
        var props = targetsProps(updatedTarget);
        mixpanelService.sendToMixpanel('Target - Dynamically add Trends, Key trends changed', props);
    }

    function initLimitTopics(target){
        limitTopics = target.topics;
    }

    function resetUpdatedData(){
        limitTopics = manualPhrases = [];
        limitTopicsFlag = dynamicTrendsFlag = ActivatedToFlag = excludeFlag = false;
    }


    function compareArrays(arr1, arr2){
        return  $(arr1).not(arr2).length === 0 && $(arr2).not(arr1).length === 0
    }

    function toggleSelectAll(isSelected) {
       selectAllPhrasesFlag = isSelected;
    }

   

    return {
        trackNewTarget: trackNewTarget,
        trackDeleteTarget: trackDeleteTarget,
        collectUpdatedTargetData: collectUpdatedTargetData,
        trackUpdatedData: trackUpdatedData,
        trackKeyTrends: trackKeyTrends,
        trackContentDriversPaneOpen: trackContentDriversPaneOpen,
        resetUpdatedData: resetUpdatedData,
        initLimitTopics: initLimitTopics,
        toggleSelectAll: toggleSelectAll,
        trackSelectAll: trackSelectAll
    }
}]);
