const BaseMapComponent = require('../base-map-component');

class SingaporeMapComponent extends BaseMapComponent {
    constructor() {
      super('singapore');
      this.mapSizes = {
          subzone: 'sg_subzones',
          planning: 'sg_planning-areas',
          regions: 'sg_regions',
          electoral_sub_regions: 'sg_sub-electoral-divisions'
      };

      this.mapOptions = {
        scaleFactor: 68,
        center: [ 103.901251, 1.367712 ],
        maxScale: 10,
        heightOffset: 2.5
      };
    }

    $onInit() {
      this.setMapNameFromScale(this.mapScale);
    };

    $onChanges(changesObj) {
      if (changesObj.mapScale) {
        this.setMapNameFromScale(changesObj.mapScale.currentValue);
      }

      if (changesObj.popupData) {
        this.popupTemplate = this.getPopupFunction(changesObj.popupData.currentValue);
      }

      if (changesObj.selectedRegions) {
        this.selectedRegions = changesObj.selectedRegions.currentValue;
      }
    };
}

module.exports = angular.module(__filename, [
  require('common/map-component/map-component').name
])
  .component('singaporeMapComponent', {
      template: require('../base-map-component.html'),
      bindings: {
        data: '<',
        mapScale: '<',
        popupData: '<',
        allRegionsSelected: '<',
        terms: '<',
        selectedRegions: '<'
      },
      controller: SingaporeMapComponent,
  })
