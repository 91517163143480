var c = require('infra/utils/common');

const FIRST_PARTY_PERMISSION = 'create first party segments';

angular.module(__filename, [
    require('./edit-segment.js').name
  ]
).controller('FirstPartySegmentsController',
  ['$scope', 'audienceInsightsService', 'programService', 'ModalService', 'confirmAction', 'mixpanelSettings', 'util',
  function($scope, audienceInsightsService, programService, ModalService, confirmAction, mixpanelSettings, util) {
    $scope.$root.helpPage = '7_Settings_Screen';
    populate();

    mixpanelSettings.trackPageView('first_party');

    function populate() {
      audienceInsightsService.getFirstPartyDataByUser($scope.$root.user.id).then(function (data) {
        $scope.segments = data;
        $scope.gotData = true;
      });
      programService.userProgramsList($scope.$root.user.id).then(function (data) {
        let segments = _.reject(data, util.isMyInterestsProgram);
        $scope.user_programs = _.map(segments, function (p) {
          let program = _.clone(p);
          program.text = program.name;
          return program;
        });
      })
    }

    $scope.deleteSegment = function (id, segment_value) {
      var idMap = _.map($scope.segments, 'id');
      var selectedIndex = idMap.indexOf(id);

      var confirmationMsg = "Are you sure you want to delete the first party segment " + $scope.segments[selectedIndex].name + "?";
      var txtConfirm = 'Yes';
      var txtCancel = 'No';

      confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel).then(function (modal) {
        modal.close.then(function (confirmation) {
          if (confirmation) executeDelete(id, segment_value);
        });
      });

      function executeDelete(id, segment_value) {
        audienceInsightsService.deleteFirstPartySegment(id, segment_value).then(function () {
          $scope.segments.splice(selectedIndex, 1);
        });
      }
    };

    $scope.editSegment = function (segment) {
      OpenEditSegmentModal({newSegment: !segment,
                            segment: segment || {},
                            userPrograms: $scope.user_programs,
                            allSegments: segment ? _.reject($scope.segments, {name: segment.name}) : $scope.segments});
    };

    function OpenEditSegmentModal(inputs) {
      ModalService.showModal({
        template: require('./edit-segment.html'),
        inputs: inputs,
        controller: 'editSegmentController'
      }).then(function (modal) {
        modal.close.then(function (result) {
          if (result != undefined && result.reload) {
            populate();
          }
        });
      });
    }
  }
]).stateConfig = {
  name: 'first_party',
  url: '/first_party',
  template: require('./first_party.html'),
  controller: 'FirstPartySegmentsController',
  data: {
    permissions: [FIRST_PARTY_PERMISSION]
  },
  redirectTo: ['abiPermissions', function(abiPermissions) {
    if (!abiPermissions.hasPermission(FIRST_PARTY_PERMISSION)) {
      return 'settings.programs';
    }
  }],
  display: '1st Party Segments'
};

module.exports = angular.module(__filename);