import { orderBy } from 'lodash';

const getDataView = ({ data, columns, sortByColumn, isSortAsc, searchString }) => {
    
  const isRowContainsSearchString = (rowData, searchableColumns, searchString) => {

    for (const searchableColumn of searchableColumns) {
      if ((rowData[searchableColumn] + '').toLowerCase().includes(searchString)) {
        return true;
      }
    }
    return false;
  };

  const searchableColumns = columns.filter((col) => col.isSearchable != false).map((col) => col.value);

  //sort
  let dataView = orderBy(data, 
    [sortByColumn], 
    [isSortAsc ? 'asc' : 'desc']);

  //search
  if (searchString) {
    dataView = dataView.filter((rowData) =>
      isRowContainsSearchString(
        rowData, 
        searchableColumns, 
        searchString)
    );
  }

  return dataView;
};

export default getDataView; 
