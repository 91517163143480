import 'angular-modal-service';
var queries = {};

queries.domainsList = {
  title: 'domains',
  dataArray: [],
  columns: [
          {id: 'name', title: 'Domain', tooltip: 'Domain', ellipsis: true, search: true, alwaysShown:true,  template: 'partials/domain-delete.partial.html'},
          {id: 'added_by', title: 'Added by', tooltip: 'Added by', ellipsis: true, flex:'0-3', alwaysShown:true},
          {id: 'created_at', title: 'Date added', ellipsis: true, tooltip: 'Date added', flex:'0-7', search: true, displayValue: "date_added_formated"},
          {id: 'reason', title: 'Reason', tooltip: 'Reason', ellipsis: true, search: true, search: true, flex:'0-5'},
          ],

  crud: false,
  fixedSize: false,
  order: 'created_at',
  reverse: true,
  excel: false
};


angular.module(__filename, [
    'angularModalService',
    require("./domain-edit").name,
    require("../../infra/mixpanel/mixpanel-settings").name

]).controller('blacklistDomainsController', ['$scope', 'ModalService', 'notificator', '$timeout', 'confirmAction',
                                             'mgmtServiceFactory', 'mixpanelSettings', 'util',
    function ($scope, ModalService, notificator, $timeout, confirmAction, mgmtServiceFactory, mixpanelSettings, util) {
        $scope.queries=queries;

        var service = mgmtServiceFactory.createService('domain');
        $scope.updateDomain = updateDomain;
        $scope.deleteDomain = deleteDomain;
        $scope.animateSize = util.animateElementBloat;
        $scope.gotData = false;
        getDomains();
        mixpanelSettings.trackPageView('blacklist domains');

        function dateFormat(d){
            return moment(d).format("MMM DD, YYYY (HH:mm:ss A)");
        } 

        function getDomains() {
            $scope.loading = true;
            service.list().then(function (data) {
              $scope.domains = data;
              
              $.each($scope.domains, function(i, domain){
                  domain.date_added_formated = dateFormat(domain.created_at);
                });

              $scope.gotData = true;
            }).finally(function() {
              $scope.queries.domainsList.show($scope.domains);  
              $scope.loading = false;
            });
        }

        function updateDomain(domain) {
            if (domain == undefined) {
                openEditModal();
            } else {
                service.get(domain.id).then(openEditModal)
            }
        }

        function deleteDomain(domain) {
            var confirmationMsg = 'Are you sure you want to delete the domain ' + domain.name + '?';
            confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) executeDelete(domain.id);
                });
            });

            function executeDelete(domainId) {
                service.delete(domainId).then(function (data) {
                    $scope.domains = $scope.domains.filter(function (obj) {
                        return obj.id != domainId;
                    });
                    notificator.success({body: data.name + ' was deleted successfully'});
                    $scope.queries.domainsList.show($scope.domains);
                });
            }
        }

        function openEditModal(domain) {
            ModalService.showModal({
                template: require('./domain-dialog.html'),
                inputs: {
                    domain: domain,
                    domains: $scope.domains,
                    service: service
                },
                controller: 'domainEditController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result != undefined && result.reload) getDomains();
                });
            });
        }
    }
]).stateConfig = {
    name: "domains",
    url: "/domains",
    data: {
      permissions: [],
      roles: ['super admin']
    },
    template: require("./domains.html"),
    display: "Settings",
    controller: 'blacklistDomainsController'
};

module.exports = angular.module(__filename);
