import React from 'react';
import './FirstPartyModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import RadioButton from '../../../../../common/RadioButton/RadioButton';
import Spinner from '../../../../../common/Spinner/Spinner';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class FirstPartyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFirstParty: props.firstPartyInput,
      firstParties: [],
      isLoadingData: true
    };
    
    this.segmentType = '1st party';
    this.handleFirstPartySelect = this.handleFirstPartySelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.loadFirstParyData();
  }

  loadFirstParyData() {
    if (!this.props.firstPartyPromise) return;

    this.props.firstPartyPromise.then((data) => {
      this.setState({ firstParties: data, isLoadingData: false });
    });
  }

  handleFirstPartySelect(value) {
    this.setState({ selectedFirstParty: value });
  }

  handleSubmit() {
    this.props.onSubmit({ ...this.props.firstPartyInput, type: this.segmentType, ...this.state.selectedFirstParty });
  }

  handleCancel() {
    this.props.onCancel(this.segmentType);
  }

  render() {
    const { modalTitle, isOpen } = this.props;

    return (
      <div className="first-party-modal-component">
        <ModalWithConfirmationButtons
          width="800px"
          modalTitle={ modalTitle }
          isOpen={ isOpen }
          isSubmitDisabled={ isEmpty(this.state.selectedFirstParty) }
          isDisabledEnterKeyPress={ isEmpty(this.state.selectedFirstParty) }
          onSubmit={ this.handleSubmit }
          onCancel={ this.handleCancel } >
          <Spinner show={ this.state.isLoadingData }></Spinner>
          <div className="first-party-modal-content">
            <RadioButton values={ this.state.firstParties } selectedValue={ this.state.selectedFirstParty } onSelect={ this.handleFirstPartySelect } numColumns={ 2 } />
          </div>
        </ModalWithConfirmationButtons>
      </div>
    );
  }
}

FirstPartyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  firstPartyInput: PropTypes.object,
  firstPartyPromise: PropTypes.object
};

FirstPartyModal.defaultProps = {
  firstPartyInput: {}
};

export default FirstPartyModal;
