/*
common target functions and event handlers
to use:
=======
1. include this on every page that has targets
2. include targets-common-partial.html
3. if page needs, include targets-delete-partial.html
4. call getTargets
5. handle updateTargets event
6. page needs to define functions like createTargetWindow 
7. if page has contet drivers (right target pane)
     * include entire content-drivers files and logic
     * scope.contentDriversTargets = true;
*/

import config from 'infra/config';
import c from 'infra/utils/common';

module.exports = angular.module(__filename, [require("data/target-data").name, require("infra/mixpanel/mixpanel-targets").name])
    .service('TargetsCommon', ['$http', 'context', 'CHANNEL', 'TARGET_CONSTS', 'MARKET_CONTEXT', 'TargetData', 'notificator', 'ModalService', 'mixpanelTargets', 'abiPermissions',
        function ($http, context, CHANNEL, TARGET_CONSTS, MARKET_CONTEXT, TargetData, notificator, ModalService, mixpanelTargets, abiPermissions) {

            function updateSelectedWithFirstParty(state, scope, selected) {
              let target_type = TargetData.getTargetType(state, scope.tab);
              if ([TARGET_CONSTS.DEFAULT_TARGET_TYPE, TARGET_CONSTS.AUDIENCE_TARGET_TYPE].includes(target_type)) {
                let first_party_active = Boolean(scope.trendsIsFirstParty || scope.audienceIsFirstParty);
                _.each(selected, (q) => q.first_party = first_party_active);
              }
            }

            return {

                HOSTS: {
                    [TARGET_CONSTS.DEFAULT_TARGET_TYPE]:         config.USER_MGMT_API+'/targets',
                    [TARGET_CONSTS.AUDIENCE_TARGET_TYPE]:        config.USER_MGMT_API+'/targets',
                    [TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE]: config.USER_MGMT_API+'/inventory_lists'
                },

                loadingGetTargets: false,
                TargetData: TargetData,
                mixpanelTargets: mixpanelTargets,

                targetURL: function(target = {target_type: TARGET_CONSTS.DEFAULT_TARGET_TYPE}){
                    return this.HOSTS[target.target_type] + (target._id ? '/'+target._id : '');
                },

                getTargets: function(params){

                    //so it wont get called while still loading
                    if(this.loadingGetTargets){
                        return;
                    }
                    this.loadingGetTargets = true;

                    let target_type = TargetData.getTargetType(params.$state, params.tab);
                    let url = this.HOSTS[target_type] + '/all';

                    if(undefined == params.all){ //only targets from current program+channel+target_type
                        let channel = c.getChannels(params.$state, context)[0],
                            p_id = context.current.p_id,
                            page = params.$state.current.name.toLowerCase();

                        url += '?program_id='+p_id+'&channel='+CHANNEL.name(channel)+'&target_type='+target_type;
                    }

                    let targetsPromise = new Promise((resolve,reject)=> {
                        $http.get(url).then((res)=> {
                            resolve(res.data);
                         }, (err)=>{
                            console.log(err);
                            notificator.error({body: 'Faild to fetch targets'});
                            resolve([]);
                        });
                    });

                    let marketsPromise = this.getMarkets();

                    Promise.all([targetsPromise, marketsPromise]).then(data => { 
                        let targets = data[0],
                            markets = data[1];

                        TargetData.markets = $.extend(true, {}, markets);

                        this.initTargets(targets);
                        params.scope.targets = Array.from(targets);  

                        this.updateTargetsHandler({action: 'update-target-list', target_type: target_type}, params.scope);
                        if(undefined != params.cb){
                            params.cb();
                        }

                        this.loadingGetTargets = false;
                    });
                },

                getMarkets: function(){
                    return new Promise((resolve,reject)=> {
                        if (TargetData.markets){
                            return resolve(TargetData.markets);
                        }
                        $http.get(config.USER_MGMT_API + '/dsp/markets')
                            .then(res=> {
                                let markets = res.data.reduce(
                                    (r,market)=>{
                                        r[market.marketId]=market.name;
                                        return r;
                                    },{});

                                resolve(markets);
                            },(res)=>{
                                console.log(res);
                                resolve([]);
                            });
                        })
                },

                openTargets: function(scope){

                    ModalService.showModal({
                        template: require('../../pages/targets/targets-modal.html'),
                        controller: require('../../pages/targets/targets-modal.js'),
                        controllerAs: 'targetsController',
                        inputs: {
                            parent: scope
                        }
                    }).then(function (modal) {
                        modal.close.then(function () {
                        });
                    });
                },

                hasAssignedObjects: function(target) {
                  return this.getAssignedObjects(target).then(
                     (res) => { return res.data.assigned_concepts.length + res.data.assigned_line_items.length > 0 },
                     (err) => { return false }
                  );
                },

                getAssignedObjects: function(target) {
                  const url = this.targetURL(target) + "/assigned_objects";
                  return $http.get(url);
                },

                initTargets: function(targets){
                    var that = this;
                    $.each(targets, function(i, t){
                      that.TargetData.targetToUI({target: t});
                    });
                },

                showUpdatedList: function($scope, targets){
                  //show list and moves scroll
                  $scope.queries.targetlist.saveScroll();
                  $scope.queries.targetlist.show(targets);
                  $scope.queries.targetlist.loadScroll();
                },


                edit: function(target, tab, $scope){

                  if (target.target_type == TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE){
                    //target already loaded in targetToCurrentTarget
                    this.TargetData.targetToUI({target: target, action: 'edit-target', context: context});
                    this.openEditDialog(target, tab, $scope);
                    return;
                  }

                  $scope.targetLoading = true;

                  $http.get(this.targetURL(target)).then((res)=>{
                    this.TargetData.targetToUI({target: res.data, action: 'edit-target', context: context});

                    $scope.editTargetOpened = true;
                    $scope.targetLoading = false;

                    this.openEditDialog(res.data, tab, $scope);

                  },(res)=>{
                    console.log(res);
                    let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error fetching target. '"+target.name+"'";
                    notificator.error({body: error});
                  });
                },


                openEditDialog(target, tab, $scope){
                    ModalService.showModal({
                        template: require('../discovery/modal/modalEditTarget.html'),
                        controller: require('../discovery/modal/modalEditTarget.js'),
                        controllerAs: 'editTargetCtrl',
                        inputs: {
                          currentTarget: target,
                          parentTab : tab,
                          dataType: ''
                        }
                    }).then(function (modal) {
                         modal.close.then(function () {
                            $scope.editTargetOpened = false;
                        });
                    });
                },


                deleteConfirm: function($scope){
                    var id = $scope.deleteTarget._id, r = $scope.$root,
                        name = $scope.deleteTarget.name;

                    let deleteTarget = $.extend(true, {}, $scope.deleteTarget);

                    $scope.deleteInProgress = true;
                    $scope.targetLoading = true;
                    $http.delete(this.targetURL(deleteTarget)).then(function(){
                        $scope.deleteTarget=null;
                        $scope.deleteInProgress = false;
                        r.$broadcast('updateTargets', {action: 'remove-target', _id: id});
                        notificator.success({body: 'Target "' + name + '" deleted'});
                        mixpanelTargets.trackDeleteTarget(deleteTarget);
                        $scope.targetLoading = false;
                    
                    }, (res)=>{
                        console.log(res);
                        let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error deleting target '"+$scope.currentTarget.name+"'";
                        notificator.error({body: error});
                        $scope.targetLoading = false;
                        $scope.deleteInProgress = false;
                        $scope.deleteTarget = null;
                    });
                },

                createTargetWindow: function(target){
                    
                    ModalService.showModal({
                        template: require('../../pages/targets/create-target.html'),
                        controller: require('../../pages/targets/create-target.js'),
                        controllerAs: 'createTargetCtrl',
                        inputs: {
                            params: target
                        }
                    });
                },


                validateAddToTarget: function(state, scope) {
                  const selected = $.extend(true, [], scope.query.selected);
                  updateSelectedWithFirstParty(state, scope, selected);
                  const is_first_party = _.some(selected, {'first_party' : true});
                  const cant_add_first_party = ((scope.currentTarget.audience_type == "general_audience") &&
                                               scope.currentTarget.activated_to.includes("turn") &&
                                               (scope.currentTarget.advertiser_id == undefined || scope.currentTarget.advertiser_id == MARKET_CONTEXT.ALL_ADVERTISERS_ID) &&
                                               is_first_party);
                  return !cant_add_first_party;
                },

                addToCurrentTarget: function (state, scope) {
                    //add to current target or create new target with these phrases

                    var selected = $.extend(true, [], scope.query.selected);

                    updateSelectedWithFirstParty(state, scope, selected);

                    if (selected && selected.length == 0) {
                        return
                    }

                    if(context.current.language){
                        const lan = context.current.language.value;
                        selected.forEach((phrase)=>phrase.lan = lan);
                    }

                    if (!scope.currentTarget.name) {
                        scope.createTargetWindow(selected);
                    } else {
                        //check if phrase already exists in target
                        var alreadyExists = '', i, j,
                            res = scope.currentTarget.results && scope.currentTarget.results[scope.tab] ?
                                    scope.currentTarget.results[scope.tab] : [],
                            len = res.length,
                            lenSelected = selected.length;

                        for (i = 0; i < lenSelected; i++) {
                            var phrase = selected[i];
                            for (j = 0; j < len; j++) {
                                if (res[j].id == phrase.id && !res[j].dynamic) {
                                    alreadyExists += phrase[scope.query.columns[0].id] + " , ";
                                    phrase.alreadyExists = true;
                                }
                            }
                        }

                        if (alreadyExists) {
                            notificator.notify({body: alreadyExists.substring(0, alreadyExists.length - 3) + " already exists in this target"});
                        }

                        selected = selected.filter(function (phrase) {
                            return !phrase.alreadyExists;
                        });

                        if (!selected.length) {
                            return;
                        }

                        //add to results
                        if(!scope.currentTarget.results){
                            scope.currentTarget.results = {};
                        }
                        if(!scope.currentTarget.results[scope.tab]){
                            scope.currentTarget.results[scope.tab] = [];
                        }
                        scope.currentTarget.results[scope.tab] =
                            scope.currentTarget.results[scope.tab].concat(selected);

                        this.TargetData.targetToServer({target: scope.currentTarget});

                        let url = this.targetURL(scope.currentTarget);

                        scope.targetLoading = true;
                        $http.put(url, scope.currentTarget).then((res)=>{

                            mixpanelTargets.trackSelectAll(scope.currentTarget);
                            this.updateTargetsHandler({
                                target: res.data,
                                action: 'update-target',
                                resultsAlreadySet: true}, scope);

                            notificator.success({
                                body: selected.length + ' '+this.TargetData.typePlural(scope.tab, selected.length)+' added to target "' + scope.currentTarget.name + '"'
                            }) 

                            this.clearSelection(scope); 
                            scope.targetLoading = false;
                        }, (res)=>{
                            console.log(res);
                            let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing from target. '"+$scope.currentTarget.name+"'";
                            notificator.error({body: error});
                        });


                    }
                },

                removeFromTarget: function(currentTarget, tab, words, scope) {
                    
                    let url = this.targetURL(currentTarget);
                    
                    words.forEach((word)=>{
                        currentTarget.results[tab] = (currentTarget.results[tab]||[]).filter(res=>res.id !== word.id);
                    });

                    this.TargetData.targetToServer({target: currentTarget});

                    scope.$parent.targetLoading = true;
                    $http.put(url, currentTarget).then((res)=>{
                        
                        scope.$root.$broadcast('updateTargets', {
                            target: res.data,
                            action: 'update-target',
                            resultsAlreadySet: true
                        });
  
                        notificator.success({
                            body: words.length + ' '+this.TargetData.typePlural(tab, words.length)+' removed from target "' + currentTarget.name + '"'
                        }) 
                        scope.$parent.targetLoading = false;  
                    }, (res)=>{
                        console.log(res);
                        let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing from target. '"+$scope.currentTarget.name+"'";
                        notificator.error({body: error});
                    });

                },

                removeAllFromTarget: function(currentTarget, scope){
                    ModalService.showModal({
                        template: require('../../pages/targets/remove-all-from-target.html'),
                        controller: require('../../pages/targets/remove-all-from-target.js'),
                        controllerAs: 'removeAllFromTargetCtrl',
                        inputs: {
                            target: currentTarget, 
                            parentScope: scope
                        }
                    });
                },

                removeAllFromTargetCB: function(currentTarget, scope){
                    
                    let url = this.targetURL(currentTarget);
                    
                    Object.keys(currentTarget.results).forEach((k)=>currentTarget.results[k] = []);

                    let tab = this.targetTabName(currentTarget);

                    this.TargetData.targetToServer({target: currentTarget});

                    let rootScope = scope.$root;

                    scope.targetLoading = true;
                    $http.put(url, currentTarget).then((res)=>{
                        
                        rootScope.$broadcast('updateTargets', {
                            target: res.data,
                            action: 'update-target',
                            resultsAlreadySet: true
                        });
                        
                        setTimeout(()=>rootScope.$broadcast('remove-all-from-target-cb'));

                        notificator.success({
                            body: 'All '+tab+' removed from target "' + currentTarget.name + '"'
                        });

                        scope.targetLoading = false;
                    }, (res)=>{

                        console.log(res);
                        let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : "Error removing all from target. '"+$scope.currentTarget.name+"'";
                        notificator.error({body: error});
                    });
                },

                targetTabName: function(target){
                    return Object.keys(target.results||{}).length==1 ? 
                            Object.keys(target.results)[0] : 'concepts';
                },

                targetInContext: (target_type, channel)=>{
                    return "target_" + target_type + "_" + channel;
                },

                updateTargetsHandler: function (params, scope) {
                    //main handler of all update events
                    //once it updates, sends 'updateTargetsCB'

                    params = params || {};

                    var oldId = scope.currentTarget._id,
                        channel = CHANNEL.name(context.current.gridChannels);

                    if (params.action === "remove-target") {
                        
                        function otherId(row){return row._id !== params._id;}
                        scope.targets = scope.targets.filter(otherId);

                        if(params._id === scope.currentTarget._id){
                            let target_type = scope.currentTarget.target_type;
                            scope.currentTarget = {};
                            context.current[this.targetInContext(target_type, channel)] = '';
                        }

                        params.targets = scope.targets.slice(0);

                    } else if (params.action === "update-target") {

                        this.TargetData.targetToUI({target: params.target});
 
                        let ind = scope.targets.findIndex(t => t._id===params.target._id);
                        
                        scope.targets[ind]  = $.extend(true, {}, params.target);

                        //dont set results if resultsAlreadySet
                        //it helps performance and avoids target phrases blink

                        //update only when changed
                        Object.keys(params.target).forEach((key)=> {
                            if(key !== 'results' || !params.resultsAlreadySet){
                                scope.currentTarget[key] = params.target[key];
                            }
                        });
                                                
                        params.targets = Array.from(scope.targets);

                    }else if (params.action === 'create-target') {

                        this.TargetData.targetToUI({target: params.target});
                        scope.currentTarget = $.extend(true, {}, params.target);
                        scope.targets.push(params.target);
                        context.current[this.targetInContext(params.target.target_type, channel)] = scope.currentTarget._id; 

                        this.clearSelection(scope);

                    } else if (params.action === 'switch-target') {
                        scope.targetLoading = true;
                        scope.currentTarget = $.extend(true, {}, params.target);
                        context.current[this.targetInContext(params.target.target_type, channel)] = scope.currentTarget._id; 

                        //later calls openContentDrivers

                    } else if(params.action === 'update-target-list'){
                      scope.currentTarget = {}; //reset target
                    }

                    //default: get target from context id
                    if(!scope.currentTarget.name){
                        let target_type = params.target_type || TARGET_CONSTS.DEFAULT_TARGET_TYPE;
                        let _id = context.current[this.targetInContext(target_type, channel)];
                        let i = scope.targets.findIndex(t => t._id===_id);
                        scope.currentTarget = i>=0 ? scope.targets[i] : {};
                    }

                    scope.currentTargetLoaded = true;

                    let url = this.targetURL(scope.currentTarget);
                    TargetData.targetToCurrentTarget(scope, url, params.action);

                    //update all listernes that targets changed
                    scope.$root.$broadcast('updateTargetsCB', params);
                    
                }, //end of updateTargetsHandler 

                

                toggleSelected: function (scope, row, $event) {
                    //shift+click checks/uncheckes all checked

                    if($event){
                        var index = scope.query.viewArray.indexOf(row),
                            prev = scope.prevIndexSelected == undefined ? index : scope.prevIndexSelected,

                            start = Math.min(index, prev),
                            end = Math.max(index, prev);

                        if ($event.shiftKey) {
                            for (var i = start; i <= end; i++) {
                                scope.query.viewArray[i].selected = row.selected;
                            }
                        }

                        scope.prevIndexSelected = index;
                    }

                    scope.query.selected = (scope.query.dataArray || []).filter(function (row) {
                        return row.selected;
                    });
                },

                clearSelection: function(scope){

                    if (scope.contentDriversTargets) {
                        scope.query.selected = [];
                        scope.query.dataArray.forEach(function (cell) {
                            cell.selected = false;
                        });
                        scope.selectAll = false;
                        scope.$root.$broadcast('openContentDrivers', "target_drawer");
                    }
                },

                hasPermission: function (permission) {
                    return abiPermissions.hasPermission(permission);
                }

            }
        }
    ]
);
