var programs = require('../programs/programs.js'),
    first_party = require('../first_party/first_party.js'),
    users = require('../users/users.js'),
    phrases = require('../phrases/phrases.js'),
    organizations = require('../organizations/organizations.js'),
    domains = require('../domains/domains.js'),
    targets = require('../targets/targets-settings.js'),
    mixpanel = require("../../infra/mixpanel/mixpanel-settings");

var settingsModule = angular.module(__filename, [
    programs.name,
    first_party.name,
    users.name,
    organizations.name,
    mixpanel.name,
    domains.name,
    targets.name
]);

SettingsController.$inject = ["$scope", "$state", "$window", "abiPermissions", "mixpanelSettings"];
function SettingsController($scope, $state, $window, abiPermissions, mixpanelSettings) {

    $scope.tabRoles = settingsModule.stateConfig.children.reduce(
        (tabs, tab) => {
            tabs[tabName(tab.name)] = tab.data.roles || [];
            return tabs;
    }, {});
    

    $scope.clickTab = (name) => {
        if($scope.setSelectedTabRoles(name)){;
            $state.go('^.'+name);
        }
    }

    $scope.setSelectedTabRoles =(name) =>{
        $scope.selectedTabRoles = $scope.tabRoles[tabName(name)];
        if(false == abiPermissions.hasRoles($scope, $scope.selectedTabRoles)){
            sessionStorage['login_message'] = 'Your account is not authorized for this page. <br/>Please contact your account manager today.<br/>';            
            $window.location.replace('/login_message');
        };
        return true;
    }

    $scope.$on("$stateChangeStart", function (evnt, toState, toParams) {
        $scope.setSelectedTabRoles(toState.name);
    });

    $scope.setSelectedTabRoles($state.current.name);


    function tabName(name){
        return name.replace('settings.','');
    }


}

settingsModule.stateConfig = {
    name: 'settings',
    url: '/settings',
    template: require('./settings.html'),
    controller: SettingsController,
    display: 'Settings',
    family: 'settings',
    redirectTo: 'settings.programs',
    children: [
        programs.stateConfig,
        first_party.stateConfig,
        users.stateConfig,
        phrases.stateConfig,
        organizations.stateConfig,
        targets.stateConfig,
        domains.stateConfig
    ],
    data: {
      permissions: []
    }
};

module.exports = settingsModule;
