const BaseWidget = require("../base_widget"),
    exportService = require('data/segment-interest-export-service'),
    mixpanel = require('../../infra/mixpanel/mixpanel-audience');


AudienceTableViewWidgetController.$inject = ['audienceInsightsService', 'context', '$scope', 'filtersPartition', 'audienceTableStructure', 'segmentInterestExportService', 'mixpanelAudience'];

function AudienceTableViewWidgetController(audienceInsightsService, context, $scope, filtersPartition, audienceTableStructure, exportService, mixpanelAudience) {
    const self = this;
    const audienceTables = audienceTableStructure.tableStructure;
    self.mixpanelAudience = mixpanelAudience;
    self.all_ages = filtersPartition.age.map(a => a.summary);

    self.searchTypes = {
        web: 'xw',
        keywords: 'keywords',
        domain: 'td',
        sports: 'xw'
    };

    $scope.searchType = self.searchTypes[$scope.selected];
    $scope.query = audienceTables[$scope.selected];

    const additionalParams = $scope.selected === 'sports' ? {isSports: true} : null;
    let currentChannel = context.current.audience_app.current_channel.value
    let audienceSegment = context.current.audience_app[currentChannel].audience_segment;

    $scope.loadingPromise = audienceInsightsService.getSegmentInterestsData(audienceSegment, $scope.searchType, currentChannel, additionalParams);
    const distributionPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(audienceSegment, {channel: currentChannel});

    $scope.export = function() {
        return $scope.loadingPromise.then(function(data) {
            distributionPromise.then(function(distribution) {
                const excel = exportService.exportToExcel(
                    null,
                    $scope.searchType === 'td' && data.words,
                    $scope.searchType === 'xw' && data.words,
                    null,
                    audienceSegment,
                    distribution
                );
                exportService.downloadExcel(excel);
                mixpanelAudience.trackExport();
            })
        })
    };

    $scope.$root.createExcelWorkbook = $scope.export;

    $scope.loadingPromise.then(function(data) {
        const scope = self.$scope;
        const words = data['words'] || [];
        scope.source = words;

        self.$timeout(() => {
            scope.query.show(words);
        });
    });
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name,
    require("./audience-table-struct").name,
    mixpanel.name
]).directive("audienceTableViewWidget", [() => BaseWidget({
    restrict: "E",
    template: require("./audience-table-view-widget.html"),
    scope: {selected: "@"},
    controller: AudienceTableViewWidgetController
})]);
