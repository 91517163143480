import config from 'infra/config';

module.exports = {
    TWITTER_PUB_API: "https://publish.twitter.com",
    ELASTIC_API: config.SEARCH_API + "/tweets_by_ids",

    DATA_FACTOR: 9.7,
    GEO_SINGAPORE: 702,
    NO_FACTOR_GROUP: 'pt_',
    blacklist_name: 'cola_superbowl',
    queries: {},
    graph: {},

    colors : {'total': '#cacaca',
    'pos':'rgb(88, 208, 142)', 'neu': 'rgb(45, 117, 225)', 'neg': 'rgb(209, 92, 92)'},


    selectedGroupId: null, //common for warroom tabs
    geo: 'all',

    smallWidth: 1280,
    filtersWidth: [180,236],

  	getSize: function(){

  		var MARGIN = 3;
      var filtersMargin = this.filtersShown() ?
  				this.filtersSmall() ? (this.filtersWidth[0]+MARGIN) : 
            (this.filtersWidth[1]+MARGIN)
  			: 0;

  		if($("menubar").hasClass("open")){
  			filtersMargin += Math.round($("menubar").width()*0.75);
  		}

    	return {wid: $(window).width() - filtersMargin,
             	hi: $(window).height()};
  	},

  	filtersShown: function(){
  		return $("side-filters-menu").is(":visible") && $("side-filters-menu").css("margin-right") == "0px";
  	},

  	filtersSmall: function(){
  		return $(window).width() < this.smallWidth+80;
  	},

	groupFactor: function(group){
	    //dont factor groups that start with "pt_"

	    if(this.geo==this.GEO_SINGAPORE){
	    	return 1;
	    }else if(group && group.name){
	      return group.name.toLowerCase().indexOf(this.NO_FACTOR_GROUP) === 0 ? 1 : this.DATA_FACTOR;
	    }else{
	      return 1;
	    }
	},

	width: function(elm, width){
		width=Math.round(width);
		elm.css({"width": width+"px", "min-width": width+"px"});
	},

	height: function(elm, height){
		height=Math.round(height);
		elm.css({"height": height+"px", "min-height": height+"px"});
	},

	encodeCSV : function(str){
      return str.replace(/,/g, " ").replace(/(\r\n|\n|\r)/gm," ");
    },

	getBrandID: function() {
	    return ("brand:" + window.brandId);
	},

	getUserID: function() {
	      return ("user:" + window.userId);
	},

	getUserBrand: function() {
	    return (getUserID() + "|" + getBrandID());
	}
}
