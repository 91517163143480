import React from 'react';
import './RadioButton.scss';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const isSelected = (value, selectedValue) => (selectedValue && selectedValue.value == value.value);
const numRows = (numValues, numColumns) => Math.ceil(numValues / numColumns);

const RadioButton = ({ values, selectedValue, onSelect, numColumns }) => (
  <div className="radio-button-component" style={ { gridTemplateRows: `repeat(${numRows(values.length, numColumns)}, 1fr)` } }>
    {
      values.map((value, i) => (
        <div className="option-wrapper" key={ i }>
          <div className={ classNames('option', { 'selected-value': isSelected(value, selectedValue) }) } onClick={ () =>  onSelect(isSelected(value, selectedValue) ? null : value) }>
            <Icon className="option-icon" iconId={  isSelected(value, selectedValue) ? 'radio-on' : 'radio-off' }></Icon>
            <span className="option-label">{ value.label }</span>
          </div>
        </div>
      ))
    }
  </div>
);

RadioButton.propTypes = {
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  numColumns: PropTypes.number
};

export default RadioButton;
