import { isEmpty, difference } from 'lodash';

export function executeBeaconRequest(request) {
  validate(request);
  const payload = request.payload && JSON.stringify(request.payload);
  navigator.sendBeacon(request.url, payload);
}

function validate(request) {
  if (!request) throw Error('executeBeaconRequest: missing request argument');
  if (!request.url) throw Error("executeBeaconRequest: missing request's url");
  const unknownRequestKeys = difference(Object.keys(request), ['url', 'payload']);
  if (!isEmpty(unknownRequestKeys)) throw Error(`executeBeaconRequest: unknown request keys: ${unknownRequestKeys.join(', ')}`);
}