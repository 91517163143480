var d3 = require("d3"),
    barChar = require("nvd3"),
    $ = require("jquery");

var isNumber = angular.isNumber;
var DEFAULT_PRECISION = 1,
    DEFAULT_FORCED_PRECISION = 0,
    TOP_PADDING = 34;

module.exports = angular.module(__filename, [])
    .directive('amBarChart', ['$timeout', '$parse', '$filter', function ($timeout, $parse, $filter) {
        return {
            restrict: 'EA',
            controllerAs: 'barCtrl',
            scope: {
                showTooltip: '=',
                data: '=',
                caption: '@',
                addIcons: '=?',
                resize: '=?',
                object: '=?',
                x: '&', /*expression that can included scope and data variable*/
                y: '&',
                max: '=',
                precision: "=",
                factor: '=',
                domain: '&',
                pad: '=',
                barHeight: '@',
                fitLabel: '='
            },
            template: require('./bar-chart.html'),
            link: function (scope, element, attr, ctrl) {
                var $svg = element.find('svg');
                var $caption = element.find('.caption');
                var boundingClient = element[0].getBoundingClientRect();
                var MARGIN = 50,
                    BAR_WIDTH = 50,
                    HEIGHT = null,
                    BAR_HEIGHT = null;
                var max = 100;

                var length = $parse('data.length');
                var bars_width = function () {
                    return BAR_WIDTH * (length(scope) || 1);
                };
                var directive_width = function () {
                    return bars_width() + 2 * MARGIN;
                };

                /**CHART**/
                var chart = barChar.models.discreteBarChart()
                        .x(function (d) {
                            return scope.x(d);
                        })
                        .y(function (d) {
                            return scope.y(d);
                        })
                        .staggerLabels(false)
                        
                        .showValues(true)
                        .showYAxis(false)
                        .showXAxis(false)
                        .valueFormat(function (d) {
                            if (isNumber(scope.factor) && scope.factor != 0) {
                                return $filter('formatNumber')(d / scope.factor, scope.precision);
                            }
                            return $filter('formatNumber')(d, scope.precision);
                        })
                        .margin({left: 0, right: 0})

                function update() {
                    chart.tooltip.enabled(scope.showTooltip);
                    chart.tooltip.contentGenerator(function(d) {
                        var value = (d.data.original / 100).toFixed(2);
                        var moreOrLess = (value >= 1) ? 'more' : 'less';
                        if (value < 1 && value > 0) value = (1 / value).toFixed(2);
                        return "<span>"
                         + d.data.display + " is x" + value + " times "
                         + moreOrLess + " likely to be consumed in "
                         + scope.caption +
                         " than in all the channels combined.</span>";
                    });
                    
                    if (attr.max && scope.data && scope.data.length > 0) {
                        if (scope.data[0] && isNumber(scope.data[0][attr.max])) {
                            max = scope.data[0][attr.max];
                        }
                    }
                    /**ELEMENT**/
                    var w = directive_width();
                    element.width(w);
                    element.css("maxWidth", w + "px");
                    var boundingRect = element[0].getBoundingClientRect();
                    HEIGHT = boundingRect.height;
                    BAR_HEIGHT = HEIGHT - $caption.outerHeight(true);
                    if (isNumber(scope.pad) && !isNaN(scope.pad))
                        BAR_HEIGHT -= scope.pad;
                    else
                        BAR_HEIGHT -= TOP_PADDING;
                    $svg.height(BAR_HEIGHT);
                    /**BAR IN CHART**/
                    chart.discretebar
                        .rectClass(function (d, i, j) {
                            return d.class;
                        })
                        .xRange([0, bars_width()])
                        .yDomain(scope.domain() || [0, max])
                        .yRange([scope.barHeight || BAR_HEIGHT - 25, 0])
                        .margin({left: MARGIN, right: MARGIN});
                    d3.select($svg[0])
                        .datum([{values: scope.data}])
                        .call(chart);

                    // Redrawing the charts so the space between them will be larger.
                    resizeBarChart(100);
                    resizeBarChart(2000);
                    chart.duration(0)
                    chart.update();
                }

                function resizeBarChart(timeout) {
                  $timeout(function() {
                    d3.selectAll(".nv-bar > rect")
                    .attr("width", 20)
                    .attr("x", 9)
                  }, timeout);
                }

                /** Handle Data Changed **/
                attr.$observe('caption', function (nv, ol) {
                    if (nv && scope.fitLabel) {
                        var len = nv.length;
                        var extraLen = 2 * length(scope);
                        var font_size = d3.scale.linear().range([16, 15]).domain([8 + extraLen, 15 + extraLen]);
                        $caption.css('font-size', font_size(len) + 'px');
                    }
                });

                update();
                scope.$watch('data', function (nv, ov) {
                    if (!angular.isArray(nv)) return;
                    update();
                });

                if (scope.resize !== false) {
                  window.addEventListener('resize', update);
                  scope.$on('$destroy', function () {
                    window.removeEventListener('resize', update);
                  });
                }
            },
            controller:['$scope',function($scope) {
                _.extend(this,{
                   sendEvent:sendEvent
                });

                function sendEvent(objectType,object){
                    $scope.$emit('barChart' + objectType + 'Clicked',object);
                }
            }]
        }
    }]
);
