/**
 * Created by pery on 06/07/2015.
 * DropDown directive
 */
module.exports = function postLinkOneFromMany(scope, element, attr, ctrls, transcludeFn) {
    /*option list define in am-input-collection file*/
    var ctrl = ctrls[0];
    var inputCtrl = scope.inputCtrl;
    

    scope.thereIsInternalEdit = !!attr.onEdit;
    scope.thereIsCreateNew = !!attr.onNew;

    scope.selectingValue = selectingValue;
    
    if (scope.returingArray == void 0)
        inputCtrl.returingArray = false;

    function selectingValue(option) {
        var options = scope.inputCtrl.options;
        scope.setAll(options, 'selected', false);
        if(!option){
            option = options[options.length-1];
        }
        option.selected = true;

        ctrl.$setViewValue([option]);
    }

    ctrl.$render = function () {
        //scope.selected = inputCtrl.modelValue[0];
        scope.selected = ctrl.$viewValue[0];
    };

    /*************************
     *  Internal Edit
     ************************/
    scope.fireEdit = function (option, $event) {
        attr.onEdit && scope.onEdit({
            $value: option.model,
            $event: $event,
            $editor: inputCtrl.editor
        });
        inputCtrl.editor.closeOption($event);
    };

    scope.fireNew = function ($event, newName) {
        attr.onNew && scope.onNew({
            $event: $event,
            $newName: newName,
            $editor: inputCtrl.editor
        });
        inputCtrl.editor.closeOption($event);

        inputCtrl.states.new.stage1();
    };

    scope.openNew = function ($event) {
        inputCtrl.states.new.stage2();
        $event.stopPropagation();
    };

    /** ------------- register to handled 'open' class --------------- **/
    element.on('click', inputCtrl.editor.toggleOption);
    element.on('dblclick', function ($event) {
        $event.preventDefault();
        return false;
    });
    /* prevent selection on dblclick*/
    element.on('selectstart', function () {
        return false;
    });
};
