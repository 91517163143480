const api = "https://sap.intelligence.amobee.com"

module.exports =function (location){
	return {
        USER_MGMT_API: api,
        AUTHENTICATION_API: api,
        SNAPSHOT_API: api,
        TRENDLINE_API: api,
        DISCOVERY_API: api,
        SEARCH_API: api,
        AUDIENCE_PROFILER_API: "//audience-profiler-ui." + location,
        TWITTER_API: api,
        REALTIME_API: api,
        LOGGING_API: api,
        KEYWORDS_SUGGESTIONS_API: api,
        //switch to api gateway after verification
        FIRST_PARTY_UPLOADER: "https://first-party-uploader.intelligence.amobee.com"
    };
}