import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

class ErrorBoundary extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, componentStack) {
    this.setState({ error: { error, componentStack } });
    this.props.onError(error, componentStack);
  }
  
  render() {
    if (this.state.error) return this.props.fallbackComponent(this.state.error.error, this.state.error.componentStack);
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  onError: PropTypes.func,
  fallbackComponent: PropTypes.func
};
    
ErrorBoundary.defaultProps = {
  onError: noop,
  fallbackComponent: () => null
};

export default ErrorBoundary;