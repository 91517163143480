import 'angular-masonry';
import 'infinite-scroll';

const tmpl = require("./streams-widget.html"),
    streamsService = require("data/streams-service"),
    geoService = require("data/geo-service"),
    brick = require("common/brick.drv/brick.drv"),
    BaseWidget = require("../base_widget"),
    mixpanel = require('infra/mixpanel/mixpanel-discovery'),
    c = require("infra/utils/common"),
    $ = require("jquery");

function StreamsWidgetCtrl(streamsService, topicsTree, geoService, $timeout, $rootScope, notificator, $interval, util,
                           mixpanelDiscovery, abiPermissions, $state, TIMES, $location) {
    const self = this;

    this.streamsService = streamsService;
    this.topicsTree = topicsTree;
    this.geoService = geoService;
    this.$timeout = $timeout;
    this.notificator = notificator;
    this.$interval = $interval;
    this.util = util;
    this.TIMES = TIMES;
    this.$location = $location;
    this.abiPermissions = abiPermissions;
    this.promise = null;
    this.newItemsAvailCheckScheduledPromise = null;
    this.scrollBarContainer = $('#streams-results');
    this.streamsSeparator = $('#streams-separator');
    this.displayed_notifications = {};

    this.$scope.sortBy = 'interest';
    this.$scope.hasEmptyTerms = false;

    this.$scope.firstLoad = true;
    this.resetSearch();

    this.$scope.fetchMoreItems = function() {
        self.fetchMoreItems();
    };
    mixpanelDiscovery.trackPageView('streams');
    const refreshViewListener = $rootScope.$on('refresh-view', event => self.refresh());

    this.$scope.refresh = function() {
        self.refresh();
    };

    this.$scope.deleteBrick = function(brickId) {
        return function() {
            const index = _.findIndex(self.$scope.bricks, {id: brickId});
            if(index != -1) {
                self.$scope.bricks.splice(index, 1);
                self.$scope.deletedBricks.push(index);
            }
        }
    };

    this.$scope.$on('$destroy', function() {
        refreshViewListener();
        self.cancelNewItemsAvailCheck();
        self.deleteScroll();
    });

    $rootScope.$on('resizeTransitionEnd', function() {
        $rootScope.$broadcast('masonry.reload');
    });

    this.scrollBarContainer.scroll(this.showSeparatorOnScroll.bind(this));

    this.$scope.isShiftDown =()=>{
       return this.$scope.$parent.$parent.shiftDown;
    }

    this.$scope.markBrick = (id)=>{
        
        if(this.$scope.isShiftDown()){
            $("#"+id+" .brick").toggleClass("shift-marked");
            document.getSelection().removeAllRanges(); //unmark text after shift
        }
    }

    this.$scope.trackBrick = (brickUrl, $event) =>{
        if(this.$scope.isShiftDown()){
          $event.preventDefault();
          return;
        }

        const snapshot = $rootScope.context.current;
        mixpanelDiscovery.trackStream(brickUrl);
    };

    this.$scope.brickRollover = function(brickData) {
        const title = brickData['link_title'];
        const desc = brickData['link_description'];
        let result = '';
        if(title && desc) {
            result = title + '. ' + desc + ' ';
        } else if(title || desc) {
            result = (title || desc) + ' ';
        }

        return result;
    }
}

StreamsWidgetCtrl.prototype.showSeparatorOnScroll = function() {
    if(this.scrollBarContainer.scrollTop() == 0) {
        this.hideScrollBar.bind(this)();
    } else {
        if(this.previousScrollOnTop) {
            this.streamsSeparator.show();
        }
        this.previousScrollOnTop = false;
    }
};

StreamsWidgetCtrl.prototype.hideScrollBar = function() {
    this.streamsSeparator.hide();
    this.previousScrollOnTop = true;
};

StreamsWidgetCtrl.prototype.buildParams = function() {
    const $scope = this.$scope;

    $scope.lan = $scope.context.current._language_mold.getLanguage($scope.$state, $scope.context).value;

    let channels = [], sources = [];
    if($scope.showCustomSources) {
        const allSources = $scope.context.program.program_sources;
        const programSources = $scope.channels.length > 0 ? _.intersectionBy(allSources, $scope.channels, 'id') : allSources;
        sources = _.flatMap(programSources, (s) => s.sources);
    } else {
        channels = this.streamsService.getChannels($scope.channels);
    }

    const channel_sources = _.groupBy(channels, c.getSource);
    const shouldSendAudience = _.size(channel_sources) == 1 && 
        _(['articles', 'sg_telco']).includes(_(channel_sources).keys().first()) &&
        $scope.$parent.$parent.showAudienceFilter();
    
    const hasSgTelcoAudiencePermission = this.abiPermissions.hasPermission('sg telco audience filters');
    let audience = [];
    if(shouldSendAudience && (channels.length > 1 || channels[0] !== 'sg_telco' || hasSgTelcoAudiencePermission)) {
        audience = c.getAudience($scope, channels);
    }

    let params = {
        timeframe: $scope.timeframe,
        phrases: this.util.getTerms($scope.terms, false),
        boolean_logic_phrases: this.util.getTerms($scope.terms, true),
        terms_ids: this.util.getPhrasesToIdMap($scope.terms),
        excluded_phrases: null,
        channels: channels,
        article_types: _.map($scope.articleType, 'value'),
        topics: this.topicsTree.getIds($scope.topics),
        geos: this.geoService.objToIdArray($scope.geo),
        sub_geos: $scope.subGeos,
        audience: this.util.removeFullySelectedAudienceSegments(_.compact(_.map(audience, 'value'))),
        sensitive_content: this.topicsTree.isSensitive,
        blend: true,
        language: $scope.lan,
        sort_by: $scope.hasEmptyTerms ? 'interest' : $scope.sortBy,
        test_mode: this.abiPermissions.hasPermission(['score breakdown']),
        trending_topics: $scope.context.program.trending_topics,
        audience_mappings: $scope.context.program.program_audience_mappings,
        program_sources: _.mapValues(_.groupBy(sources, 'channel'), val => _.map(val, 'id'))
    };

    if (_.isEqual(channels, ['articles']) && this.abiPermissions.hasPermission('first party segments')) {
        params.user_first_party_audience = _.map($scope.userFirstPartyAudience, "value");
    }

    return params;
};

StreamsWidgetCtrl.prototype.fetchMoreItems = function() {
    if(this.$scope.is_last_scroll) return;

    this.$scope.isBusy = !this.$scope.firstLoad;
    const self = this;
    const $scope = this.$scope;

    const params = this.buildParams();
    if($scope.page == 1) {
        this.streamsPromise = this.streamsService.streams(null, params);
        this.scheduleNewItemsAvailCheck();
    } else {
        this.streamsPromise = this.streamsService.streamsNextPage($scope.scroll_ids, $scope.page, params);
    }

    return this.streamsPromise.promise.then(function(result) {
        $scope.firstLoad = false;
        if(result) {
            if(self.$scope.page == 1) {
                self.$scope.$root.topicsDistribution = result.topicsDistribution;

                if(_.isEmpty(result.docs)) self.notifyInsufficientContent();
            }
            self.handleNewBricks(result);
        } else {
            // Error occurred
            self.$scope.isBusy = false;
            self.$scope.is_last_scroll = true;
        }
    });
};

StreamsWidgetCtrl.prototype.cancelNewItemsAvailCheck = function() {
    if(!angular.isDefined(this.newItemsAvailCheckScheduledPromise)) return;
    this.$timeout.cancel(this.newItemsAvailCheckScheduledPromise);
    this.newItemsAvailCheckScheduledPromise = null;
    if(this.hasMoreItemsPromise) {
        this.hasMoreItemsPromise.cancel();
    }
};

StreamsWidgetCtrl.prototype.notifyWhenSocialNotSelected = function() {
    if(!this.$scope.channels) return; // happens when clicking on streams url - the scope is not initialized yet, maybe because of the duplicate calls bug

    const self = this;
    const mappingField = this.$scope.showCustomSources ? 'type' : 'value';
    const channels = _.map(this.$scope.channels, mappingField);
    if(channels.length == 0 || channels.indexOf('tweets') > -1 || channels.indexOf('instagram') > -1 || channels.indexOf('facebook') > -1) return;

    const social_terms = _(this.$scope.terms).filter(function(term) {
        return _.includes(['post', 'hashTag', 'mention'], term.type) || self.util.isSocialBooleanLogic(term);
    }).map('text').value();
    if(social_terms.length == 0) return;

    const message = 'Sorry, @mentions, @posts and #hashtags are supported only for Twitter, Instagram or Facebook channels ('
                  + _(social_terms).map(self.util.getTermDisplayShort).join() + ')';
    if(!self.displayed_notifications[message]) {
        self.displayed_notifications[message] = true;
        self.notificator.notify({body: message});
        self.$interval(function() {
            delete self.displayed_notifications[message];
        }, 3000, 1);
    }
};

StreamsWidgetCtrl.prototype.notifyInsufficientContent = function() {
    const self = this;
    let msg = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";
    msg = _.isEmpty(self.$scope.terms) ? msg.replace(" about <seed>", "") :
        msg.replace("<seed>", self.$scope.terms.map(function(term) {
            return self.util.getTermDisplayShort(term.text);
        }).join(", "));
    self.notificator.notify({body: msg});
};

StreamsWidgetCtrl.prototype.scheduleNewItemsAvailCheck = function() {
    this.cancelNewItemsAvailCheck();
    const self = this;

    this.newItemsAvailCheckScheduledPromise = this.$timeout(function() {
        if (!self.$scope.scroll_ids) return self.scheduleNewItemsAvailCheck();
       
        const params = self.buildParams();
        params.test_mode = false;

        const curBrickIds = self.$scope.deletedBricks.concat(_.map(self.$scope.bricks, 'id'));
        self.hasMoreItemsPromise = self.streamsService.hasMoreItems(self.$scope.scroll_ids, params, curBrickIds, self.$scope.is_last_scroll);
        self.hasMoreItemsPromise.promise.then(res => res ? self.$scope.isMoreItemsAvail = true : self.scheduleNewItemsAvailCheck());
    }, 60 * 2000);
};

StreamsWidgetCtrl.prototype.refresh = function() {
    this.resetSearch();
    this.fetchMoreItems();
};

StreamsWidgetCtrl.prototype.deleteScroll = function() {
    if(this.$scope.scroll_ids) {
        this.streamsService.streamsDeleteScroll(this.$scope.scroll_ids, this.$scope.lan);
        this.$scope.scroll_ids = null;
    }
};

StreamsWidgetCtrl.prototype.resetSearch = function() {
    if(this.streamsPromise && !this.streamsPromise.promise.$$state.status) {
        this.streamsPromise.cancel();
    }
    this.deleteScroll();
    this.cancelNewItemsAvailCheck();
    this.hideScrollBar.bind(this)();
    this.$scope.bricks = [];
    this.$scope.deletedBricks = [];
    this.$scope.page = 1;
    this.$scope.is_last_scroll = false;
    this.$scope.isMoreItemsAvail = false;
    this.$scope.isBusy = false;
    this.$scope.hasEmptyTerms = this.$scope.terms.length == 0;
    this.$scope.$root.topicsDistribution = null;
};

StreamsWidgetCtrl.prototype.handleNewBricks = function(result) {
    const self = this;
    const new_bricks = result.docs;
    this.$scope.scroll_ids = result.scroll_ids;

    const scroll_ids = Object.values(this.$scope.scroll_ids);
    const hasRegularScrolls = scroll_ids.find((scroll) => scroll.type != "raw") != undefined;
    const hasRawScrollsThatIsNotLastPage = scroll_ids.find((scroll) => scroll.type == "raw" && scroll.is_last_page == false) != undefined;
    this.$scope.is_last_scroll = !hasRegularScrolls && !hasRawScrollsThatIsNotLastPage;
    
    const duplicateArticleIds = _.intersection(_.map(this.$scope.bricks, 'id'), _.map(new_bricks, 'id'));
    const newBricksWithoutDuplicates = new_bricks.filter((article) => !duplicateArticleIds.includes(article.id));

    this.$scope.bricks = this.$scope.bricks.concat(newBricksWithoutDuplicates);
    this.$scope.page += 1;
    // Hide bricks until rendered (wait 2 cycles: 2 $timeouts). then set deletion transition
    this.$timeout(function() {
        self.$timeout(function() {
            const all_bricks_elements = $('.masonry-brick');
            all_bricks_elements.css("opacity", 1);
            self.$scope.isBusy = false;
            self.$timeout(function() {
                all_bricks_elements.addClass("no-transition");
            }, 1000);
        }, 0);
    }, 0)
};

StreamsWidgetCtrl.prototype._doUpdate = function(newVals, oldVals) {
    this.$scope.hasEmptyTerms = false;
    this.$scope.firstLoad = true;
    if (this.$location.path() == "/discovery/streams") { //Don't set timeframe vars if we're stepping out of widget
        let times = ["1W", "1M", "3M"];
        if (!this.$scope.context.current.showCustomSources) {
            times = ["1D", "3D"].concat(times);
            if (this.$scope.channels.length > 0 &&
                _.every(this.$scope.channels, (ch) => !_.includes(["sg_telco", "au_telco"], ch.value))) {
                times = ["1H"].concat(times);
            } else if (_.isEqual(this.$scope.timeframe, [1, "hour"])) {
                let num_days = 3;
                if (_.isEmpty(this.$scope.channels) ||
                    _.some(this.$scope.channels, (ch) => !_.includes(["sg_telco", "au_telco"], ch.value))) {
                    num_days = 1;
                }
                this.$scope.$root.$broadcast('timeframe-update', [num_days, "day"]);
            }
        }
        let min_date = this.$scope.context.current.bubblesShowCustomSources ? [45, 'days'] : this.TIMES.DISCOVERY_OFFSET;
        this.$scope.$root.$broadcast('timeframe-vars-update', times, min_date, false);
    }
    this.resetSearch();
    let promise = this.fetchMoreItems();
    this.notifyWhenSocialNotSelected();
    return promise;
};

StreamsWidgetCtrl.$inject = ["streamsService", "topicsTree", "geoService", "$timeout", "$rootScope", "notificator",
                             "$interval", "util", "mixpanelDiscovery", "abiPermissions", "$state", "TIMES", "$location"];

module.exports = angular.module(__filename, [
    brick.name,
    'infinite-scroll',
    'wu.masonry',
    streamsService.name,
    geoService.name,
    mixpanel.name
]).directive("streamsWidget", [function() {
    return BaseWidget({
        restrict: "AE",
        template: tmpl,
        scope: {
            timeframe: "=",
            terms: "=",
            topics: "=",
            geo: "=",
            subGeos: "=",
            audience: "=",
            sgTelcoAudience: "=",
            userFirstPartyAudience: "=",
            channels: "=",
            articleType: "=",
            showCustomSources: "=",
            language: "=",
            sortBy: "@"
        },
        controller: StreamsWidgetCtrl
    });
}]);
