var methods = {
    is: _.isObject,
    isObject: _.isPlainObject,
    isNumber: _.isFinite,
    isElement: _.isElement,
    isArray: function (a) {
        return _.isArray(a) && !_.isEmpty(a)
    },
    isString: function (s) {
        return _.isString(s) && !_.isEmpty(s)
    },
    isNull: function (v) {
        return _.isNull(v) || _.isUndefined(v)
    },
    isDate: function (d) {
        if (_.isDate(d)) {
            return !isNaN(d.getTime())
        }
        return false;
    }
};

module.exports = methods;
