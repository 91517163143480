import React from 'react';
import PropTypes from 'prop-types';
import './ModalWithConfirmationButtons.scss';
import BaseModal from '../BaseModal/BaseModal';
import ConfirmationButtons from '../../ConfirmationButtons/ConfirmationButtons';

const ModalWithConfirmationButtons = ({ isOpen, modalTitle, width, height, minHeight, marginTop, onCancel, onSubmit, isSubmitDisabled, isCancelDisabled, isDisabledEnterKeyPress, isYAutoScrollEnabled, submitLabel, cancelLabel, showSpinner, children, hasScrollContainer, maxHeightBeforeScroll }) => (
  <div className="modal-with-confirmation-buttons-component">
    <BaseModal
      isOpen={ isOpen }
      modalTitle={ modalTitle }
      onEscKeyPress={ onCancel }
      onEnterKeyPress={ onSubmit }
      onXClick={ onCancel }
      isXDisabled={ isCancelDisabled }
      marginTop={ marginTop }
      width={ width }
      height={ height }
      minHeight={ minHeight }
      isDisabledEnterKeyPress={ isDisabledEnterKeyPress }
      showSpinner={ showSpinner }>
      <div className="modal-container" style={ { maxHeight: maxHeightBeforeScroll } }>
        { hasScrollContainer ? (<div className="modal-scroll-container" style={ { 'overflowY': isYAutoScrollEnabled && 'auto' } }>
          { children }
        </div>) : children }
        <div className="confirmation-buttons">
          <ConfirmationButtons
            submitLabel={ submitLabel } 
            isSubmitDisabled={ isSubmitDisabled }
            isCancelDisabled={ isCancelDisabled }
            onSubmitClick={ onSubmit }
            cancelLabel={ cancelLabel }
            onCancelClick={ onCancel }>
          </ConfirmationButtons>
        </div>
      </div>
    </BaseModal>
  </div>
);

ModalWithConfirmationButtons.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  isDisabledEnterKeyPress: PropTypes.bool,
  isYAutoScrollEnabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hasScrollContainer: PropTypes.bool,
  showSpinner: PropTypes.bool,
  children: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  maxHeightBeforeScroll: PropTypes.string
};

ModalWithConfirmationButtons.defaultProps = {
  submitLabel: 'Done',
  cancelLabel: 'Cancel',
  isSubmitDisabled: false,
  hasScrollContainer: true,
  isCancelDisabled: false,
  isYAutoScrollEnabled: true
};

export default ModalWithConfirmationButtons;