import { react2angular } from 'react2angular';
import GenderDistribution from 'react/components/common/GenderDistribution/GenderDistribution';
import { isAudienceTargetVisible } from '../../data/audience-segment-builder-helper';

const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    common = require("infra/utils/common");

AudienceDemographicsWidgetController.$inject = ['audienceInsightsService', 'context', 'dspService', 'notificator', 'abiPermissions'];

function AudienceDemographicsWidgetController(audienceInsightsService, context, dspService, notificator, abiPermissions) {
    const SG_CHANNELS = ['snbb', 'data_spark'];
    const $scope = this.$scope;
    const dataTypesToggleValues = [
        {
            value: 'distribution',
            label: 'Distribution',
            tooltip: {
                text: 'How your audience is distributed between demographic segments',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        },
        {
            value: 'skew',
            label: 'Skew',
            tooltip: {
                text: 'The extent to which the demographic segment is over-indexed within the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        }
    ];
    const MIN_AUDIENCE_SIZE = 1000;    
    $scope.ethnicityBarChartOptions = {labelFont: '13px Helvetica', highlightedLabelFont: '13px Helvetica'}; // todo remove after responsive-bar-chart colliding labels problem is fixed

    this.audienceInsightsService = audienceInsightsService;
    this.channel = context.current.audience_app.current_channel.value;
    this.segment = context.current.audience_app[this.channel].audience_segment;
    $scope.channel = this.channel;
    $scope.audienceSegment = this.segment;
    $scope.isNewAudienceTarget = context.current.audience_app[this.channel].audience_newTarget;
    $scope.audienceId = context.current.audience_app[this.channel].audience_id;
    $scope.audienceName = context.current.audience_app[this.channel].audience_name;
    $scope.isNewAudienceTarget = context.current.audience_app[this.channel].audience_newTarget;
    $scope.isAudienceTargetDisabled = context.current.audience_app[this.channel].audience_targetDisabled;
    $scope.audienceTargetDisabledTooltipText = context.current.audience_app[this.channel].audience_targetDisabledTooltipText;
    $scope.getSegmentIds = audienceInsightsService.getSegmentIds;
    $scope.createAudienceTargetTaxonomy = audienceInsightsService.createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = audienceInsightsService.createAudienceTargetUserList;
    $scope.notificator = notificator;

    let segmentDemographics = this.segment.find((segment) => segment.type === "demographics");
    $scope.geo = segmentDemographics && segmentDemographics.geo && segmentDemographics.geo[0];
    $scope.isUSA = this.channel === 'smart_tv' || this.channel === 'linear_tv' || $scope.geo && $scope.geo.cc === 'US';
    $scope.isSgChannel = SG_CHANNELS.includes(this.channel);

    let exportButton = angular.element(document.querySelector('.export'))[0];
    exportButton.hidden = true;
    $scope.$on('$destroy', () => { exportButton.hidden = false; });

    $scope.dataTypesToggleValues = dataTypesToggleValues;

    $scope.data = {distribution: {}, skew: {}};

    $scope.isAudienceTargetVisible = function() {
        const currentChannel = ((context.current.audience_app || {}).current_channel || {}).value;
        return isAudienceTargetVisible(currentChannel, abiPermissions.hasPermission('audience activation'));
    };

    $scope.updateIsNewAudienceTarget = function(value) {
        context.current.audience_app[$scope.channel].audience_newTarget = $scope.isNewAudienceTarget = value;
    };

    $scope.shouldShowEthnicityBox = function() {
        return $scope.isUSA || ($scope.isSgChannel && abiPermissions.hasPermission("sg telco ethnicity"));
    };

    //Will be used in the future when we will support more markets
    //if ($scope.isAudienceTargetVisible($scope.channel)) $scope.marketsPromise = dspService.getMarkets('label', 'value', true);

    const audienceDemographicsDataPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(this.segment, {channel: this.channel})
        .then((data) => {
                if(!data) return;
                switch(data.status) {
                    case 'ok':
                        // insert skew tooltips
                        _.each(data.skew.gender, (o, gender) => {
                            o.tooltip = skewTooltip(`${_.capitalize(gender)}s`, o.value)
                        });
                        data.skew.genderAge.forEach((o) => {
                            o.male.tooltip = skewTooltip(`Males of age ${o.label}`, o.male.value);
                            o.female.tooltip = skewTooltip(`Females of age ${o.label}`, o.female.value);
                        });
                        data.skew.ethnicity.forEach((o) => {
                            let ethnicity_label = o.label.charAt(o.label.length - 1) === 's' ? o.label : `${o.label}s`;
                            o.tooltip = skewTooltip(ethnicity_label, o.value);
                        });
                        data.skew.income.forEach((o) => {
                            o.tooltip = skewTooltip(`Residents of households with an income of ${o.label} USD`, o.value);
                        });

                        $scope.data = data;
                        if ($scope.data.audienceSize.population < MIN_AUDIENCE_SIZE) $scope.data.audienceSize.population = MIN_AUDIENCE_SIZE;
                        break;
                    case 'error':
                        switch(data.error.reason) {
                            case "selectionTooWide":
                                $scope.too_wide = true;
                                break;
                            case "selectionTooNarrow":
                                $scope.too_narrow = true;
                                break;
                        }
                        break;
                }
            });
  
    $scope.isAudienceDemographicsLoading = true;
    audienceDemographicsDataPromise.then(() => { $scope.isAudienceDemographicsLoading = false });
    $scope.loadingPromise = audienceDemographicsDataPromise;

    function skewTooltip(topicPlural, value) {
        switch(value) {
            case 1:
                return `${topicPlural} are as likely to be in the target audience as the average consumer`;
            case 0:
                return `${topicPlural} are unlikely to be in the target audience`;
            default:
                const [multiplier, comparePhrase] = value > 1 ? [value, 'more'] : [1 / value, 'less'];
                return `${topicPlural} are ×${multiplier.toFixed(1)} times ${comparePhrase} likely to be in the target audience than the average consumer`;
        }
    }
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name,
    require('common/segment-size.drv/segment-size.drv').name,
    require('common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv').name,
    require('common/age-gender-chart.drv/age-gender-chart.drv').name,
    require('common/am-donut-chart.drv/am-donut-chart.drv').name
])
    .directive("audienceDemographicsWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-demographics-widget.html"),
        scope: {},
        controller: AudienceDemographicsWidgetController
    })])
    .component('genderDistribution', react2angular(GenderDistribution, ['femaleValue', 'femaleDisplayValue', 'femaleTooltip', 'maleValue', 'maleDisplayValue', 'maleTooltip']));
