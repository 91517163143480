import { isAudienceTargetVisible } from '../../data/audience-segment-builder-helper';

const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    dotdotdot = require("jquery.dotdotdot");

AudienceTvWidgetController.$inject = ['audienceInsightsService', 'context', 'audienceTableStructure', 'util', 'tvExportService', 'dspService', 'notificator', 'abiPermissions'];

function AudienceTvWidgetController(audienceInsightsService, context, audienceTableStructure, util, exportService, dspService, notificator, abiPermissions) {
    let $scope = this.$scope;
    this.channel = context.current.audience_app.current_channel.value;
    this.segment = context.current.audience_app[this.channel].audience_segment;
    $scope.channel = this.channel;
    $scope.audienceSegment = this.segment;
    $scope.isNewAudienceTarget = context.current.audience_app[this.channel].audience_newTarget;
    $scope.audienceId = context.current.audience_app[this.channel].audience_id;
    $scope.audienceName = context.current.audience_app[this.channel].audience_name;
    $scope.isNewAudienceTarget = context.current.audience_app[this.channel].audience_newTarget;
    $scope.isAudienceTargetDisabled = context.current.audience_app[this.channel].audience_targetDisabled;
    $scope.audienceTargetDisabledTooltipText = context.current.audience_app[this.channel].audience_targetDisabledTooltipText;
    $scope.getSegmentIds = audienceInsightsService.getSegmentIds;
    $scope.createAudienceTargetTaxonomy = audienceInsightsService.createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = audienceInsightsService.createAudienceTargetUserList;
    $scope.notificator = notificator;

    const audienceTables = audienceTableStructure.tableStructure;
    const demographicsPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(this.segment, {channel: context.current.audience_app.current_channel.value});
    const tooWideMsg = 'The audience you have selected is too wide for TV shows analysis. Please refine your audience criteria.';
    const tooNarrowMsg = 'The audience you have selected is too narrow for TV shows analysis. Please refine your audience criteria.';
    const outputTypes = [
        {
            value: 'bars',
            icon: 'icon-stream',
            label: ''
        },
        {
            value: 'table',
            icon: 'icon-table',
            label: ''
        }
    ];
    const sortByTypes = [
        {
            value: 'interest-portion',
            label: 'Consumption',
            tooltip: {
                text: 'Sort by how popular the TV show is among the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        },
        {
            value: 'skew-score',
            label: 'Skew',
            tooltip: {
                text: 'Sort by the extent to which the TV show is over-indexed within the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        }
    ];
    const tableSearchTypes = [
        {
            value: 'tv',
            label: 'TV Shows'
        },
        {
            value: 'genres',
            label: 'Genres'
        }
    ];
    const isDebugUser = $scope.$root.user.userType === 'debug';
    const shouldShowNetwork = this.channel === 'linear_tv' || this.channel === 'smart_tv' || (isDebugUser && this.channel === 'articles')
    if (shouldShowNetwork) tableSearchTypes.push({value: 'networks', label: 'Networks'});

    $scope.outputTypes = outputTypes;
    $scope.sortByTypes = sortByTypes;
    $scope.sortBy = 'interest-portion';
    $scope.scoreView = 'interest-portion';
    $scope.selectedView = 'bars';
    $scope.query = _.cloneDeep(audienceTables.tv);
    $scope.tableSearchTypes = tableSearchTypes;
    $scope.tableData = {};
    $scope.navBackModal = { isOpen: false };
    $scope.tvType = this.channel === 'articles' ? 'Online' : 'TV';

    $scope.onSortChange = (type, sortTVOnly = true) => {
        if(_.isEmpty($scope.tableData)) return;
        const sortKeys = sortTVOnly ? ['tv'] : ['tv', 'genres', 'networks'];
        _.each(sortKeys, val => {
            $scope.tableData[val] = _.orderBy($scope.tableData[val], type, 'desc');
        });
    };

    $scope.onSearchTypeChange = (type) => {
        if (_.isEmpty($scope.tableData[type])) return;
        _.extend($scope.query, audienceTables[type]);
        $scope.query.title = type;
        $scope.$watch('::query', (q) => { if(q) q.show($scope.tableData[type]) });
    };

    $scope.onOutputViewsChange = (type) => {
        if(type === 'bars') refreshTvShowImageView();
    };

    // Temp fix performance issue of rendering too many shows
    $scope.getTopShows = () => (($scope.tableData.tv || []).slice(0,50));

    $scope.export = () => {
        return Promise.all([$scope.loadingPromise, demographicsPromise]).then(([data, demographicsData]) => {
            let excel = exportService.exportToExcel(data, demographicsData, this.segment, shouldShowNetwork, this.channel);
            return exportService.downloadExcel(excel);
        });
    };
    $scope.$root.createExcelWorkbook = $scope.export;

    let tvPromise = audienceInsightsService.getTvInfo(this.segment, this.channel, shouldShowNetwork).then(data => {
        if(!data) throw 'noData';
        if(data.status === 'noData') throw 'noData';
        if(data.status === 'segment too wide') throw 'tooWide';
        if(data.status === 'insufficient data') throw 'tooNarrow';

        return data;
    });
    $scope.loadingPromise = tvPromise.then(handleResponseData).catch(handleMissingData);

    $scope.isAudienceTargetVisible = function() {
        const currentChannel = ((context.current.audience_app || {}).current_channel || {}).value;
        return isAudienceTargetVisible(currentChannel, abiPermissions.hasPermission('audience activation'));
    };

    $scope.updateIsNewAudienceTarget = function(value) {
        context.current.audience_app[$scope.channel].audience_newTarget = $scope.isNewAudienceTarget = value;
    };

    //Will be used in the future when we will support more markets
    //if ($scope.isAudienceTargetVisible($scope.channel)) $scope.marketsPromise = dspService.getMarkets('label', 'value', true);

    function normalizeDataScores(data, correctTooltip = false) {
        _.each(data, item => {
            item['skew-score'] = item['uniqueness-index'];
            if (correctTooltip) item['phrase'] = item['title']; // For correct tooltip lookup
        });
        util.normalize(data, 'uniqueness-index', 'skew-score');
        util.normalize(data, 'interest-portion', 'interest-portion-normalized');
    }

    function handleResponseData(data) {
        normalizeDataScores(data.shows, true);
        normalizeDataScores(data.genres);
        normalizeDataScores(data.networks);
        
        $scope.$watch('::query', (q) => { if(q) q.show(data.shows) });
        $scope.tableData.tv = data.shows;
        $scope.tableData.genres = data.genres;
        $scope.tableData.networks = data.networks;
        $scope.onSortChange($scope.sortBy, false);

        return $scope.tableData;
    }

    function openNavBackModal(msg, title) {
        $scope.showNoDataBG = true;
        $scope.navBackModal = {
            isOpen: true,
            title: title,
            message: msg
        };
    }

    function handleMissingData(reason) {
        switch(reason) {
            case 'noData':
            case 'tooNarrow':
                openNavBackModal(tooNarrowMsg, 'Audience is too narrow');
                break;
            case 'tooWide':
                openNavBackModal(tooWideMsg, 'Audience is too wide');
                break;
        }
    }

    function refreshTvShowImageView() {
        angular.element(document).find('tv-show-info .dotdotdot').each(() => {
            $(this).dotdotdot();
        })
    }
}

module.exports = require("angular").module(__filename, [
    require("data/audience-insights-service").name,
    require('data/tv-export-service').name,
    require("common/tv-show-info.drv/tv-show-info.drv").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
])
    .directive("audienceTvWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-tv-widget.html"),
        scope: {
            navToPrevTab: '&',
            navToAudienceBuilder: '&'
        },
        controller: AudienceTvWidgetController
    })]);
