import config from '../../infra/config';

const HOST = config.AUDIENCE_PROFILER_API;

export default {
  getSegmentIds: (audience) => ({
    url: `${HOST}/api/1/get-segment-ids`,
    payload: { ...audience }
  })
};
