import React, { useState, useCallback } from 'react';
import './AudienceTarget.scss';

import PropTypes from 'prop-types';
import ClickableLabel from '../../../../common/ClickableLabel/ClickableLabel';
import SubmitButton from '../../../../common/SubmitButton/SubmitButton';
import AudienceTargetModal from '../Modals/AudienceTargetModal/AudienceTargetModal';

const AudienceTarget = ({ marketsPromise, audienceId, audienceName, audienceSegment, isAudienceTargetDisabled, isAudienceTargetVisible, isNewAudienceTarget, channel, getSegmentIds, createAudienceTargetTaxonomy, createAudienceTargetUserList, notificator, updateIsNewAudienceTarget, isClickableLabel, audienceTargetDisabledTooltipText }) => {
  const [isOpenAudienceTargetModal, setIsOpenAudienceTargetModal] = useState(false);
  const [isTargetModalProcessing, setIsTargetModalProcessing] = useState(false);
  const audienceTargetModalSubmitHandler = useCallback(
    (selectedMarket) => {
      setIsOpenAudienceTargetModal(false);
      onTargetAudienceSubmit(selectedMarket);
    },
    [isOpenAudienceTargetModal],
  );

  const audienceTargetModalCancelHandler = useCallback(
    () => {
      setIsOpenAudienceTargetModal(false);
    },
    [isOpenAudienceTargetModal]
  );

  const onTargetAudienceSubmit = useCallback(
    async (selectedMarket) => {
      const operationText = isNewAudienceTarget ? 'create' : 'update';
      const errorMsg = `Something went wrong... We couldn't ${operationText} the audience "${audienceName}" in DMP. Please try again later.`;
      const successMsg = `The audience "${audienceName}" will be ${operationText}d in DMP within 48 hours.`;

      try {
        setIsTargetModalProcessing(true);
        let audienceTargetTaxonomyResult;
        if (isNewAudienceTarget) {
          audienceTargetTaxonomyResult = await createAudienceTargetTaxonomy(audienceId, channel, (selectedMarket || {}).value);
          (audienceTargetTaxonomyResult === 'ok') ? updateIsNewAudienceTarget(false) : notificator.error({ body: errorMsg });
        }
        setIsTargetModalProcessing(false);
        if (!isNewAudienceTarget || audienceTargetTaxonomyResult === 'ok') {
          notificator.success({ body: successMsg });
          const segmentIds = await getSegmentIds(audienceSegment, channel);
          await createAudienceTargetUserList(audienceId, channel, segmentIds);
        }
      } catch (error) {
        setIsTargetModalProcessing(false);
        notificator.error({ body: errorMsg });
      }
    },
    [isNewAudienceTarget, audienceName, audienceId, audienceSegment, channel, notificator]
  );

  const getAudienceTargetClickable = () => {
    const commonProps = {
      className: 'target-audience',
      label: 'Target Audience',
      leftIconId: 'target',
      isDisabled: isAudienceTargetDisabled,
      onClick: () => setIsOpenAudienceTargetModal(true),
      onDisabledTooltip: audienceTargetDisabledTooltipText
    };

    return isClickableLabel ? <ClickableLabel { ...commonProps } /> : <SubmitButton { ...commonProps } />;
  };

  return (
    <React.Fragment>
      <div className="audience-target-component">
        { isAudienceTargetVisible && getAudienceTargetClickable() }
      </div>

      { isAudienceTargetVisible &&
      <AudienceTargetModal
        isOpen={ isOpenAudienceTargetModal }
        onSubmit={ audienceTargetModalSubmitHandler }
        onCancel={ audienceTargetModalCancelHandler }
        //marketsPromise={ marketsPromise } //Will be used in the future when we will support more markets
        audienceName={ audienceName }
        isNewAudienceTarget={ isNewAudienceTarget }
        isProcessing={ isTargetModalProcessing }>
      </AudienceTargetModal>
      }
    </React.Fragment>
  );
};

AudienceTarget.propTypes = {
  marketsPromise: PropTypes.object,
  audienceId: PropTypes.string,
  audienceName: PropTypes.string.isRequired,
  audienceSegment: PropTypes.array.isRequired,
  isAudienceTargetDisabled: PropTypes.bool.isRequired,
  isAudienceTargetVisible: PropTypes.bool.isRequired,
  isNewAudienceTarget: PropTypes.bool,
  channel: PropTypes.string.isRequired,
  getSegmentIds: PropTypes.func.isRequired,
  createAudienceTargetTaxonomy: PropTypes.func.isRequired,
  createAudienceTargetUserList: PropTypes.func.isRequired,
  notificator: PropTypes.object.isRequired,
  updateIsNewAudienceTarget: PropTypes.func.isRequired,
  isClickableLabel: PropTypes.bool,
  audienceTargetDisabledTooltipText: PropTypes.string
};

AudienceTarget.defaultProps = {
  isClickableLabel: true
};

export default AudienceTarget;
