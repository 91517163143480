import React from 'react';
import './AudiencePreview.scss';

import IconValueComparison from '../../../../common/IconValueComparison/IconValueComparison';
import { BarChart } from '../../../../common/BarChart/BarChart';
import { formatNumberToUnits } from '../../../../../utils/NumberUtils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SubmitButton from '../../../../common/SubmitButton/SubmitButton';
import Icon from '../../../../common/Icon/Icon';

const AudiencePreview = ({ hasData, populationDist, genderDist, ageDist, isError, isTooNarrow, isTooWide, isEntitiesLimit, onExploreAudience }) => (
  <div className="audience-preview-component">
    <div className="summary">
      { hasData &&
        <div className="inline-container">
          <span className={ classNames('audience-summary-title', 'highlighted') }>Audience Preview</span>
          <span className={ classNames('population-value', 'highlighted') }>{ formatNumberToUnits(populationDist, 1) }</span>
          <span className="population-users">Users</span>
          <span className="audience-separator" />
          <span className="gender-comparison">
            { genderDist && 
              <IconValueComparison
                firstIconId="male"
                firstDisplayValue={ genderDist.male.displayValue }
                firstValue={ genderDist.male.value }
                secondIconId="female"
                secondDisplayValue={ genderDist.female.displayValue }
                secondValue={ genderDist.female.value }>
              </IconValueComparison> 
            }
          </span>
          <span className="audience-separator" />
          <div className="age-chart">
            <BarChart data={ ageDist } theme="preview" />
          </div>
        </div>
      }
      { isError ?
        <span className="audience-summary-title">Audience Preview:</span> :
        <AudiencePreviewErrorMessage { ...{ isTooNarrow, isTooWide, isEntitiesLimit } } />
      }
    </div>
      
    <div className="explore-button-container">
      <SubmitButton
        label="Explore Audience"
        isDisabled={ !hasData }
        isLarge={ true }
        onClick={ onExploreAudience }>
      </SubmitButton>
    </div>
  </div>
);

const AudiencePreviewErrorMessage = ({ isTooNarrow, isTooWide, isEntitiesLimit }) => {
  if (!isTooNarrow && !isTooWide && !isEntitiesLimit) return null;

  const tooNarrowMessage = isTooNarrow && 'The audience you have selected is too narrow, please expand your audience criteria';
  const tooWideMessage = isTooWide && 'The audience you have selected is too broad, please refine your criteria';
  const entitiesLimitMessage = isEntitiesLimit && 'Too many entities selected, please refine your criteria';
  
  return (
    <div className="audience-preview-error-message">
      <Icon iconId="exclamation-in-circle" className="exclamation-icon"></Icon>
      { tooNarrowMessage || tooWideMessage || entitiesLimitMessage }
    </div>
  );
};

AudiencePreview.propTypes = {
  hasData: PropTypes.bool,
  populationDist: PropTypes.number,
  genderDist: PropTypes.shape({
    male: PropTypes.shape({
      value: PropTypes.number,
      displayValue: PropTypes.string
    }),
    female: PropTypes.shape({
      value: PropTypes.number,
      displayValue: PropTypes.string
    }),
  }),
  ageDist: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    displayValue: PropTypes.string
  })),
  isError: PropTypes.bool,
  isTooNarrow: PropTypes.bool,
  isTooWide: PropTypes.bool,
  isEntitiesLimit: PropTypes.bool,
  onExploreAudience: PropTypes.func
};

AudiencePreviewErrorMessage.propTypes = {
  isTooNarrow: PropTypes.bool,
  isTooWide: PropTypes.bool,
  isEntitiesLimit: PropTypes.bool
};

export default AudiencePreview;
