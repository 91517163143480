import config from 'infra/config';

var img = {
    width: 1820,
    height: 140
}

module.exports = {

    user:null,
    scope: null,
    context: null,
    notificator: null,
    showEditThemeModal: false,
    showResetThemeModal: false,
    FILE_MAX_SIZE: 2048000,
    editThemeDefaultState: {
        //lables
        logo_upload: "add",
        cover_upload: "add",
        upload_text_default: "PNG file",
        logo_name : "PNG file, max size: 2MB",
        banner_name : "PNG file, max size: 2MB, recommended: 1820X140 px.",
        placeholders: {
             logo_upload: "PNG file, max size: 2MB",
             cover_upload: "PNG file, max size: 2MB, recommended: 1820X140 px.",
        },
        //params
        brand_name : "Amobee",
        theme_form_valid: false,
        errFile: false,
        input_fields: ["logo_upload", "cover_upload"],
        fields_names: {
            "logo_upload"  : "input_logo_image",
            "cover_upload" : "input_cover_image"
        },
        reset: {
            confirm_message: "Resetting the theme will delete the logo, cover image, and brand title.",
            cancel_btn: "Cancel",
            confirm_btn: "Reset"
        },
        defaults: {
            banner_path: "https://s3.amazonaws.com/bi-warroom-themes/default_banner.png",
            logo_path: "https://s3.amazonaws.com/bi-warroom-themes/Ampbee_logo.png",
            brand_name: "Amobee",
            reset: true
        },
        messages: {
            imageSize: "ideal dimensions for image are \n "+img.width+" pixels X "+img.height+" pixels",
            imageMaxSize: "Max image size 2MB \n",
            errFileSize: "File size should be less then 2MB",
            errFileDimentions: "File dimensions should be "+img.width+" pixels X "+img.height+" pixels"
        }
        
    },
    uploadUrl: config.USER_MGMT_API + '/programs/',




    init: function(scope, http, user, context, notificator){
        var that = this;

        that.context = context;
        that.notificator = notificator;
        that.scope = scope;
        that.scope.user = user;
        that.scope.editThemeDefaultState = that.getDefaultParams();
        that.http = http;

        that.initThemeModalParams(that.scope);
        that.getTheme(that.scope);
    },


    initThemeModalParams: function(scope){
        var that = this;

        that.scope = scope? scope : that.scope;

        that.uploadLogoExplain = that.scope.editThemeDefaultState.messages.imageMaxSize; 
        that.uploadCoverExplain = that.scope.editThemeDefaultState.messages.imageMaxSize + that.scope.editThemeDefaultState.messages.imageSize;
     
        that.errFileSize = [];
        that.errFileDimentions = [];
        that.showResetThemeModal = false;
        that.showRestThemeBtn = that.hasUserProgramTheme();
        
        var fieldsObj = that.scope.editThemeDefaultState;
        that.input_logo_image = fieldsObj.logo_name;
        that.input_cover_image = fieldsObj.banner_name;
        that.edit_brand_name = fieldsObj.brand_name;


        that.addInputsChangelistener();

        that.fillThemeModalParams();
    },

    
    resetField: function(parentId){
        var that = this;
        var inputId = that.scope.editThemeDefaultState.fields_names[parentId];

        that.updateInputVal(parentId, true);
        that.updateInputClass(parentId, true);
        that.enableSaveButton($.extend( true, {}, that.scope.editThemeParams)); //18/07/2017
    },



    fillThemeModalParams: function(){
        var that = this;

         if(that.hasUserProgramTheme() && that.scope.editThemeParams){          
            var edp = that.scope.editThemeParams;
                that.edit_brand_name = edp.brand_name;
         }

         that.refreshThemeForm();
    },


     //opens the modal
    openEditThemeModal: function(){
        var that = this;

        that.showEditThemeModal = true;

        //if canceled and opened again:
        that.initThemeModalParams();

        that.refreshThemeForm();
    },



    closeEditThemeModal: function(){
        var that = this;

        that.showEditThemeModal = false;
        that.resetFormFields();
    },



    getTheme: function(){
        var that = this;
        var p = that.getSelectedUserProgramById(); //context.program does not hold the program_theme
        that.scope.editThemeParams = that.getDefaultParams().defaults; 

        if(p.program_theme){
          that.scope.editThemeParams = {
              banner_path: p.program_theme.banner_path,
              logo_path: p.program_theme.logo_path,
              brand_name: p.program_theme.brand_name,
              banner_name: p.program_theme.banner_name,
              logo_name: p.program_theme.logo_name
          }
        }

        that.setTheme(that.scope.editThemeParams);
    },



    setTheme: function(editThemeParams){
        var that = this;
        var df = that.getDefaultParams().defaults,
            etp = editThemeParams? editThemeParams : that.scope.editThemeParams,
            banner_path = etp.banner_path? etp.banner_path : df.banner_path,
            logo_path = etp.logo_path? etp.logo_path : df.logo_path,
            brand_name = etp.brand_name;

        var gradient = $(".theme_container").css('backgroundImage');
        gradient = gradient.substring(0, gradient.indexOf(", url"));
        $(".theme_container").css('backgroundImage', gradient + ', url(' + banner_path + ')');
        $(".theme_logo_img").attr("src",logo_path);
        $(".theme_txt").text( brand_name );     
        that.edit_brand_name = brand_name;


        if(etp.logo_path == null){//this is needed to reset the logo in view!
          that.scope.editThemeParams.logo_path = null;
        }
    },


    saveTheme: function(){
        var that = this;
        that.scope.editThemeParams.banner_path = (that.input_cover_image !== that.scope.editThemeDefaultState.placeholders["cover_upload"])? that.input_cover_image: "" ;
        that.scope.editThemeParams.logo_path = (that.input_logo_image !== that.scope.editThemeDefaultState.placeholders["logo_upload"])? that.input_logo_image : "" ;//see why this causes the loading missing image error - without this there is a directory and not an image path !!!
        that.scope.editThemeParams.brand_name = that.edit_brand_name;
        that.scope.editThemeParams.reset = false;

        that.prepareUploadImages();
        that.showEditThemeModal = false;
    },

  
    //uploads the images to memory in  order to send the base64 to the server
    prepareUploadImages: function(){
        var that = this;
        var files = that.scope.editThemeDefaultState.input_fields;

        that.scope.editThemeParams.base64Images = {};

        function readFile(index, resolve) {
                var reader = new FileReader();
                var file = that.scope.editThemeParams[files[index]];
                reader.onloadend = function(e) {  
                    var bin = e.target.result.replace(/^data:image\/(png|jpg);base64,/, "");
                    that.scope.editThemeParams.base64Images[files[index]] = bin;
                     resolve("file " + index + "loaded"); 
                }
                reader.readAsDataURL(file);
        }
       
        that.editThemePromises = [];

        $.each(files, function (i, group) {
            if(that.scope.editThemeParams[files[i]]){
                that.editThemePromises.push(
                    new Promise(function (resolve, reject) {
                        readFile(i, resolve);
                    })
                )
            }
        });

        Promise.all(that.editThemePromises).then(
            function (){  
                that.sendFormToServer();  
            } 
        );
    },

  

  sendFormToServer: function(){
    var that = this;
    var pId = that.context.program.id;
    var uploadUrl = that.uploadUrl + pId + '/upload_theme';

    function setUserProgramTheme(editThemeParams){
        var index = that.getSelectedUserProgramIndex();

        if(index != null){
            if(that.hasUserProgramTheme()){
                var upt = that.scope.user.programs[index].program_theme;
                upt.banner_name = editThemeParams.banner_name;
                upt.banner_path = editThemeParams.banner_path;
                upt.logo_name = editThemeParams.logo_name;
                upt.logo_path = editThemeParams.logo_path;
                upt.brand_name = editThemeParams.brand_name;
            }else{
                that.scope.user.programs[index].program_theme = {
                    "banner_name" : editThemeParams.banner_name,
                    "banner_path" : editThemeParams.banner_path,
                    "logo_name" : editThemeParams.logo_name,
                    "logo_path" : editThemeParams.logo_path,
                    "brand_name" : editThemeParams.brand_name
                }
            }
        
        }
    };


    function resetUserProgramTheme(){
        if(!that.hasUserProgramTheme()){ return }

        var index = that.getSelectedUserProgramIndex();

        if(index != null){
            that.scope.user.programs[index].program_theme = null;
        }
    };



    //ACTUALY SEND THE JSON TO THE SERVER (THE S3 UPLOAD WILL BE DONE FROM THE SERVER)
    that.http.post(uploadUrl, that.scope.editThemeParams).then(function (responseMsg) {

            //uploead successfull
            if(responseMsg.data !== "" && responseMsg.data && !responseMsg.data.upload_error){
                if(responseMsg.data.reset){
                    that.scope.editThemeParams.reset = false;
                    that.notificator.notify("program theme has been reset");
                    resetUserProgramTheme();
                    that.setTheme(that.getDefaultParams().defaults);
                }else{
                    that.scope.editThemeParams.banner_path = responseMsg.data.banner_path;
                    that.scope.editThemeParams.logo_path = responseMsg.data.logo_path;
                    that.scope.editThemeParams.brand_name = responseMsg.data.brand_name;

                    that.scope.editThemeParams.banner_name = responseMsg.data.banner_name;
                    that.scope.editThemeParams.logo_name = responseMsg.data.logo_name;

                    setUserProgramTheme(that.scope.editThemeParams);
                    that.setTheme(that.scope.editThemeParams);
                    that.notificator.notify("program theme saved");
                }
                that.resetFormFields();
            }else{
                that.notificator.error("program theme save error - please try again");
            }
    });

  },



  ///// UTILS /////////

  //attach change event listener to the form uploads fields
  addInputsChangelistener: function(){
    var that = this;

    if(that.scope.editThemeParams && that.scope.editThemeParams.hasInputsChangelistener){return;}

        $('#logo_upload .file-upload-input').bind('change', function() {
            that.updateInputVal("logo_upload");
        });

        $('#cover_upload .file-upload-input').bind('change', function() {
            that.updateInputVal("cover_upload");
        });

    if(that.scope.editThemeParams){
        that.scope.editThemeParams.hasInputsChangelistener = true;
    }
       
  },


  //fills the logo_upload, cover_upload lables with the input file value
  updateInputVal: function(parentId, reset){
        var that = this;

        var inputId = that.scope.editThemeDefaultState.fields_names[parentId],
            file = $("#" + parentId + " .file-upload-input")[0],
            files = file.files;

        if(files && files.length > 0 && !reset){
            //size validation:
            that.validateForm(parentId, files[0]);
            that.updateInputClass(parentId, false);
        }else{
            that.updateInputClass(parentId, true);
        }


        if(reset){
            $("#"+inputId).val(that.scope.editThemeDefaultState.placeholders[parentId]);
        }

        that.refreshThemeForm();
  },




  //also from keyup in edit_brand_name input text
  enableSaveButton: function(editThemeParams){
    var that = this;

    var isUpdate, formValid = false;
    var etp = editThemeParams? editThemeParams: that.scope.editThemeParams;
    var updatedParams = {};

    //default state (first time):
    if(!etp){
        if(that.edit_brand_name !== that.scope.editThemeDefaultState.brand_name ||
           that.input_logo_image !== that.scope.editThemeDefaultState.logo_name ||
           that.input_cover_image !== that.scope.editThemeDefaultState.banner_name){
            formValid = true;
        }
    }else{
        formValid = (that.edit_brand_name !== etp.brand_name ||
                     that.input_logo_image !== that.scope.editThemeDefaultState.logo_name ||
                     that.input_cover_image !== that.scope.editThemeDefaultState.banner_name);
    }

    updatedParams.brand_name = (that.edit_brand_name !== etp.brand_name);
    updatedParams.logo_name = (that.input_logo_image !== etp.logo_name);
    updatedParams.banner_name = (that.input_cover_image !== etp.banner_name);

    that.scope.editThemeDefaultState.theme_form_valid = !that.scope.errFile? formValid : false;
  },


    //files is actually files[0]
    validateForm: function(parentId, files){
        var that = this;
        that.errFileSize = []
        that.errFileDimentions = [];
        that.errFileSize[parentId] = false
        that.errFileDimentions[parentId] = false;
        that.scope.errFile = false

          //files is actually files[0]
          function enableInputField(parentId, files){
            var inputId = that.scope.editThemeDefaultState.fields_names[parentId];

            that.errFileSize[parentId] = false;
            that.errFileDimentions[parentId] = false;
            that.scope.editThemeParams[parentId] = files;
            $("#"+inputId).val(files.name);
            that[inputId] = files.name;
            that.enableSaveButton();
         };


         if(files.size > that.FILE_MAX_SIZE){
            that.errFileSize[parentId] = true;
            that.scope.errFile = true;
            that.scope.editThemeDefaultState.theme_form_valid = false;
            that.refreshThemeForm();
         }else{
             enableInputField(parentId, files);
         }

    },


    resetFormFields: function(){
        var that = this;

        that.updateInputVal("logo_upload",true);
        that.updateInputVal("cover_upload",true);

        that.scope.editThemeDefaultState.theme_form_valid = false;
        that.scope.errFile = false;
    },



    openConfirmResetTheme: function(){
        var that = this;

        if(!that.hasUserProgramTheme()){return}
        
        that.showEditThemeModal = false;
        that.showResetThemeModal = true;
    },


    confirmResetTheme: function(){
        var that = this;
        
        that.showResetThemeModal = false;
        that.showEditThemeModal = false;

        that.resetTheme();
    },



    resetTheme: function(){
        var that = this;

        that.resetFormFields();

        that.edit_brand_name = that.scope.editThemeDefaultState.brand_name;
        that.scope.editThemeDefaultState.theme_form_valid = false;
        that.scope.errFile = false;
        that.scope.editThemeParams = that.getDefaultParams().defaults;
        that.scope.editThemeParams.reset = true; //for the server to know it is a reset request
  
        if(that.hasUserProgramTheme()){
            that.sendFormToServer();
        }
        
    },


    
    updateInputClass: function(parentId, reset){
        var that = this;
        var inputId = that.scope.editThemeDefaultState.fields_names[parentId];

        if(reset){
            $("#"+inputId).addClass("placeholder");
        }else{
            $("#"+inputId).removeClass("placeholder");
        }

        that.refreshThemeForm();
    },


    //refreshes the form (error message and icon replace)
    refreshThemeForm: function(parentId, reset){
        var that = this;

        setTimeout(function(){
            that.scope.$apply();
        },75);
    },


    getSelectedUserProgramById: function(){
        var that = this;
        var returnObj = {};

        if(that.scope.user.programs){
           that.scope.user.programs.forEach(function (item, i) {
                if(item.id == that.context.program.id){
                  returnObj = item;
                }
                 
            });
        }
        return returnObj;  
    },


    getSelectedUserProgramIndex: function(){
        var that = this;
        var index = null;

        if(that.scope.user.programs){
           that.scope.user.programs.forEach(function (item, i) {
                if(item.id == that.context.program.id){
                  index = i;
                }  
            });
        }
        return index;  
    },


    getDefaultParams: function(){
        var that = this;
        return $.extend( true, {}, that.editThemeDefaultState ); 
    },

    hasUserProgramTheme: function(){
        var that = this;
        var index = that.getSelectedUserProgramIndex();
        return (index!=null && that.scope.user.programs[index].program_theme)? true : false;
    },

};