module.exports = angular.module(__filename, []).service('audienceMgmt', ['mgmtServiceFactory', function (mgmtServiceFactory) {
    var service = mgmtServiceFactory.createService('audience');

    return {
        list: audienceList,
        create: service.create,
        update: service.update,
        delete: deleteAudience
    };

    // Filter audience by channel
    function audienceList(channel) {
        return service.list().then(function (data) {
            return (channel ? data.filter(audience => audience.channel == channel || (channel == 'articles' && !audience.channel)) : []);
        });
    }

    function deleteAudience(audienceId, isAudienceActivated = false) {
        return service.customRequest('DELETE', "/" + audienceId + "/" + isAudienceActivated);
    }
}]);