import React from 'react';
import './SubmitButton.scss';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubmitButton = ({ label, rightIconId, leftIconId,  isDisabled, isTransparent, isLarge, onClick, tooltip, onDisabledTooltip }) => (
  <div className={ classNames('submit-button-component', { disabled: isDisabled }, { transparent: isTransparent }, { 'is-large': isLarge }) } 
    onClick={ () => !isDisabled && onClick() }>
    <Tooltip content={ isDisabled ? onDisabledTooltip : tooltip }>
      <div className="submit-button-content">
        { leftIconId && <Icon iconId={ leftIconId } className="icon left-icon" ></Icon> }
        <span>{ label }</span>
        { rightIconId && <Icon iconId={ rightIconId } className="icon right-icon"></Icon> }
      </div>
    </Tooltip>
  </div>
);

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  rightIconId: PropTypes.string,
  leftIconId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  onDisabledTooltip: PropTypes.string
}; 

SubmitButton.defaultProps = {
  isDisabled: false,
  isTransparent: false,
  isLarge: false
};

export default SubmitButton;