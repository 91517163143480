import React from 'react';
import PropTypes from 'prop-types';
import './TabSelector.scss';
import classNames from 'classnames';

function Tab({ label, isSelected, onClick }) {
  return (
    <div className={ classNames('tab', { selected: isSelected }) } onClick={ onClick }>
      <span className="label">{ label }</span>
    </div>
  );
}

Tab.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};

Tab.defaultProps = {
  isSelected: false
};

function TabSelector({ tabs, selectedTab, onSelect }) {
  return (
    <div className="tab-selector-component">
      { tabs.map((tab) => (
        <Tab label={ tab.label }
          isSelected={ tab.value === selectedTab.value }
          onClick={ () => onSelect(tab) }
          key={ tab.value.toString() }/>
      )) }
    </div>
  );
}

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
  })).isRequired,
  selectedTab: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default TabSelector;
