var d3 = require("d3"),
    c = require("infra/utils/common"),
    labeler = require('../../3rdparty/d3-labeler'),
    add_tooltip = require('../tooltip/tooltip.js');

module.exports = angular.module(__filename, []).component('scatterChart', {
    bindings: {
        ticksRange: '<',
        data: '<',
        labelX: "<?",
        labelY: "<?",
        dragCirclesMode: "<?",
        tickRangeLabels: "<?",
        editIconX: "<?",
        editIconY: "<?"
    },
    controller: ["$scope", "$element", "$window", "$compile", function($scope, $element, $window, $compile) {
        const TARGET_BACKGROUND = "/images/images/target_benchmark.svg";
        const MARGIN = { label_padding: 8, graph_padding: 60 };
        const TICK_BUFFER = 0.045;
        const POINT_RADIUS = 8;
        const DEFAULT_TICK_RANGE_LABELS = ['Low', 'High'];
        const MIN_EDGE_LENGTH = 400;
        var self = this;
        var window = angular.element($window);
        self.labelArray = [];
        self.anchorArray = [];

        self.$onInit = function() {
            initChart();
            updateChartSize();
        };

        self.sendEditEventForAxis = function(axis) {
            $scope.$emit('editCustomAxis', axis);
        };

        self.$onChanges = function(changesObj) {
            if (changesObj.ticksRange && !changesObj.ticksRange.isFirstChange()) {
                updateChartXYTicks();
            }
            if (changesObj.data && !changesObj.data.isFirstChange()) {
                updateUI();
            }
            if (((changesObj.labelX && !changesObj.labelX.isFirstChange()) || changesObj.tickRangeLabels) && self.edgeLength) {
                addAxisLabels('x');
            }
            if (((changesObj.labelY && !changesObj.labelY.isFirstChange()) || changesObj.tickRangeLabels) && self.edgeLength) {
                addAxisLabels('y');
            }
        };


        window.bind('resize', resizeChart);
        $scope.$on('$destroy', function() {
            window.unbind('resize', resizeChart);
        });

        function resizeChart() {
            updateChartSize();
            updateUI();
            if (self.labelX) {
                addAxisLabels('x');
            }
            if (self.labelY) {
                addAxisLabels('y');
            }
        }

        function initChart() {
            self.svg = d3.select($element[0]).append('svg');
            self.chartG = self.svg.append('g');
            self.svgBackground = self.chartG.append('svg:image').attr('xlink:href', TARGET_BACKGROUND);
            self.xAxisG = self.chartG.append('g').attr('class', 'x-axis');
            self.yAxisG = self.chartG.append('g').attr('class', 'y-axis');
            self.pointsG = self.chartG.append('g').attr('class', 'points');
            self.axisLabelsG = self.svg.append('g').attr('class', 'axis-labels');
            self.xAxisLabelsG = self.axisLabelsG.append('g').attr('class', 'x-axis-labels');
            self.yAxisLabelsG = self.axisLabelsG.append('g').attr('class', 'y-axis-labels');
            self.x = d3.scale.linear();
            self.y = d3.scale.linear();
        }

        function updateChartSize() {
            var parentWidth = $element.parent().width();
            var parentHeight = $element.parent().height();
            self.edgeLength = (parentWidth < parentHeight) ? parentWidth : parentHeight;
            self.edgeLength = self.edgeLength - MARGIN.graph_padding;
            self.edgeLength = Math.max(self.edgeLength, MIN_EDGE_LENGTH);
            self.svg.attr({width: self.edgeLength + MARGIN.graph_padding, height: self.edgeLength + MARGIN.graph_padding})
                .style('width', self.edgeLength + MARGIN.graph_padding)
                .style('height', self.edgeLength + MARGIN.graph_padding);
            self.chartG.attr('transform', 'translate(' + MARGIN.graph_padding / 2 + ',' + MARGIN.graph_padding / 2 + ')');
            self.svgBackground.attr({width: self.edgeLength, height: self.edgeLength});
            self.pointsG.attr('transform', 'translate(' + 0 + ',' + 0 + ')');
            self.x.range([0, self.edgeLength]);
            self.y.range([self.edgeLength, 0]);
            self.xAxisG.attr('transform', 'translate(' + [ 0, self.y.range()[0]] + ')');
            self.yAxisG.attr('transform', 'translate(' + [ self.x.range()[0], 0] + ')');
        }

        function updateChartXYTicks() {
            if (self.ticksRange.x.length == 2 && self.ticksRange.y.length == 2) {
                var xTickRange = [self.ticksRange.x[0] - self.ticksRange.x[1] * TICK_BUFFER, self.ticksRange.x[1] + self.ticksRange.x[1] * TICK_BUFFER];
                var yTickRange = [self.ticksRange.y[0] - self.ticksRange.y[1] * TICK_BUFFER, self.ticksRange.y[1] + self.ticksRange.y[1] * TICK_BUFFER];

                self.xAxis = d3.svg.axis().scale(self.x).orient('bottom');
                self.yAxis = d3.svg.axis().scale(self.y).orient('left');
                self.x.domain(xTickRange);
                self.y.domain(yTickRange);
                self.xAxisG.call(self.xAxis);
                self.yAxisG.call(self.yAxis);
            }
        }

        function clearChartData() {
          self.labelArray = [];
          self.anchorArray = [];
          self.leaderLines = [];
        }

        function updateUI() {
            if ($element.is(':visible')) {
                updateChartSize();
                updateChartData();
                drawPoints();
                drawLabels();
            } else {
                setTimeout(updateUI);
            }
        }

        function updateChartData() {
            clearChartData();
            self.data.forEach(function(d) {
                self.anchorArray.push({x: self.x(d.x_value), y: self.y(d.y_value), r: POINT_RADIUS * 2.0, class: d.class});
                self.labelArray.push({x: self.x(d.x_value), y: self.y(d.y_value) + POINT_RADIUS, id: d.id, name: d.label, class: d.class, width: 0.0, height: 0.0});
            });
        }

        function drawPoints() {
            var pointGroup = self.pointsG.selectAll('.point').data(self.anchorArray, function (d) {
                //class is the 'key' we use (to let D3 know the uniqueness of points)
                return d.class;
            }).attr('cx', function(d) {
                return d.x;
            }).attr('cy', function(d) {
                return d.y;
            });

            var circleElement = pointGroup.enter().append('circle')
                .attr('class', function (d) {
                    return 'point ' + d.class;
                })
                .attr('term-class', function(d) {
                    return d.class;
                })
                .attr('cx', function(d) {
                    return d.x;
                })
                .attr('cy', function(d) {
                    return d.y;
                })
                .on('mouseover', function (d) {
                    d3.select(this).moveToFront();
                    d3.select('.point-label[term-class=' + d.class + ']').moveToFront();
                    drawLeaderLine(self.anchorArray.indexOf(d));
                })
                .on('mouseout', function(d) {
                    removeLeaderLine(self.anchorArray.indexOf(d));
                });

            if (self.dragCirclesMode) {
                callDragOnCircle(circleElement);
            }

            pointGroup.exit().remove();
        }

        function callDragOnCircle(circle) {
            // from here its developer version addition
            circle.call(d3.behavior.drag()
                .origin(function(d) { return d; })
                .on("dragstart", function(d) {
                    d3.event.sourceEvent.stopPropagation();
                })
                .on("drag", function(d) {
                    var element = d3.select(this);
                    var posxNew = (element.attr("cx") * 1.0) + d3.event.dx;
                    var posyNew = (element.attr("cy") * 1.0) + d3.event.dy;
                    var newxVal = self.x.invert(posxNew);
                    var newyVal = self.y.invert(posyNew);
                    if (newxVal >= self.x.domain()[0] && newxVal <= self.x.domain()[1] && newyVal >= self.y.domain()[0] && newyVal <= self.y.domain()[1]) {
                        d3.select(this).attr("cx", posxNew).attr("cy", posyNew);
                        d.x_value = newxVal;
                        d.y_value = newyVal;
                    }
                }).on("dragend", function(d){
                    drawLabels();
                }));
        }

        function drawLeaderLine(index) {
            function calculateLabelPoint(index) {
                var distanceFromAnchor = function(point) {
                    return Math.sqrt(Math.pow(anc.x - point.x, 2) + Math.pow(anc.y - point.y, 2));
                };
                var candidates = [];
                var currLabel = self.labelArray[index];
                var anc = self.anchorArray[index];
                var labelHeight = currLabel.height * (13.0 / 20.0) * (1 / 1.2);
                var labelWidth = currLabel.width * (1 / 1.2);
                if (anc.x < currLabel.x) {
                    candidates.push({x: currLabel.x, y: currLabel.y - labelHeight  / 2});
                } else if (anc.x > currLabel.x + labelWidth) {
                    candidates.push({x: currLabel.x + labelWidth, y: currLabel.y - labelHeight / 2});
                }

                if (anc.y > currLabel.y) {
                    candidates.push({x: currLabel.x + labelWidth / 2, y: currLabel.y});
                } else if (anc.y < currLabel.y - labelHeight) {
                    candidates.push({x: currLabel.x + labelWidth / 2, y: currLabel.y - labelHeight});
                }

                return candidates.sort(function(a,b) {
                    return distanceFromAnchor(a) - distanceFromAnchor(b);
                })[0];
            }
            var labelPoint =  calculateLabelPoint(index);
            var circlePoint = self.anchorArray[index];

            self.leaderLines.push({x1: circlePoint.x, y1: circlePoint.y, x2: labelPoint.x, y2: labelPoint.y, index: index, class: self.labelArray[index].class });

            refreshLeaderLines();
        }

        function refreshLeaderLines() {
            self.linkGroup = self.pointsG
                .selectAll('.point-label-link')
                .data(self.leaderLines);

            self.linkGroup.enter()
                .append("line")
                .attr('class', function (d) {
                    return 'point-label-link ' + d.class;
                })
                .attr("x1", function(d) { return d.x1; })
                .attr("y1", function(d) { return d.y1; })
                .attr("x2", function(d) { return d.x2; })
                .attr("y2", function(d) { return d.y2; })
                .attr("stroke-width", 1.2)
                .attr("stroke", "white");

            self.linkGroup.exit().remove();

        }

        function removeLeaderLine(index) {
            var indexToRemove = self.leaderLines.findIndex(function(line) {
                return line.index !== index;
            });
            self.leaderLines.splice(indexToRemove);
            refreshLeaderLines();
        }

        function drawLabels() {
          var labelGroup = self.pointsG.selectAll('.point-label').data(self.labelArray, function (d) {
            //name is the 'key' we use (to let D3 know the uniqueness of labels)
            return d.name;
          });
          self.labelGroup = labelGroup;

          labelGroup.enter().append('text')
          .attr('class', 'point-label')
          .attr('term-class', function(d) {
            return d.class;
          })
          .text(function (d) {
            return d.name;
          })
          .attr('x', function(d) {
            return d.x;
          })
          .attr('y', function(d) {
            return d.y;
          })
          .on('mouseover', function (d) {
            d3.select(this).moveToFront();
            d3.select('.point[term-class=' + d.class + ']').moveToFront();
            drawLeaderLine(self.labelArray.indexOf(d));
          })
          .on('mouseout', function(d, i) {
            removeLeaderLine(self.labelArray.indexOf(d));
          });

          var index = 0;
          labelGroup.each(function() {
            self.labelArray[index].width = this.getBBox().width * 1.2;
            self.labelArray[index].height = this.getBBox().height * 1.2;

            if (self.labelArray[index].x + self.labelArray[index].width > self.edgeLength) {
              self.labelArray[index].x -= 2.0 * POINT_RADIUS + self.labelArray[index].width;
            } else {
              self.labelArray[index].x += 2.0 * POINT_RADIUS;
            }

            index += 1;
          });

          //rebuild labels positions
          d3.labeler()
          .label(self.labelArray)
          .anchor(self.anchorArray)
          .width(self.edgeLength)
          .height(self.edgeLength)
          .start(1000);

          self.labelGroup
          .attr('x', function(d) { return (d.x); })
          .attr('y', function(d) { return (d.y); });

          labelGroup.exit().remove();
        }

        function addAxisLabels(axis) {
            var labelGroup;
            var labelRanges = {};
            if (axis == 'x') {
                labelGroup = self.xAxisLabelsG;
                labelGroup.selectAll('*').remove();
                if (!_.isEmpty(self.labelX)) {
                    var tooltipPosition = {my: 'bottom center', at: 'top center'};
                    var paddingTop = self.edgeLength + MARGIN.graph_padding / 2 + MARGIN.label_padding;
                    labelRanges['low'] = [MARGIN.graph_padding / 2, paddingTop];
                    labelRanges['center'] = labelRanges['low'];
                    labelRanges['tooltip_target'] = [self.edgeLength / 2 + MARGIN.graph_padding / 2, paddingTop];
                    labelRanges['high'] = [self.edgeLength + MARGIN.graph_padding / 2 , paddingTop];
                    appendLabelsToGroup(axis, labelGroup, self.labelX, tooltipPosition, labelRanges, 0, self.tickRangeLabels['x'], self.editIconX);
                }
            }
            else if (axis == 'y') {
                labelGroup = self.yAxisLabelsG;
                labelGroup.selectAll('*').remove();
                if (!_.isEmpty(self.labelY)) {
                    var tooltipPosition = {my: 'right center', at: 'top center'};
                    var paddingLeft = MARGIN.graph_padding / 2 - MARGIN.label_padding;
                    labelRanges['low'] = [paddingLeft, self.edgeLength + MARGIN.graph_padding / 2];
                    labelRanges['center'] = [0, self.edgeLength + MARGIN.graph_padding / 2];
                    labelRanges['tooltip_target'] = [0, self.edgeLength / 2 + MARGIN.graph_padding / 2];
                    labelRanges['high'] = [paddingLeft, MARGIN.graph_padding / 2];
                    appendLabelsToGroup(axis, labelGroup, self.labelY, tooltipPosition, labelRanges, -90, self.tickRangeLabels['y'], self.editIconY);
                }
            }
        }

        function isEllipsisActive(element) {
            return element[0].scrollWidth > element[0].offsetWidth;
        }

        function appendLabelsToGroup(axis, labelGroup, centerLabels, tooltipPosition, labelRanges, rotateAngle, tickRangeLabels, addEditIcon) {
            tickRangeLabels = c.isArray(tickRangeLabels) && tickRangeLabels.length >= 2 ? tickRangeLabels : DEFAULT_TICK_RANGE_LABELS;
            labelGroup.append('text')
                .attr('class', 'low-axis-label')
                .text(tickRangeLabels[0])
                .attr('transform', 'translate(' + labelRanges['low'] + ')rotate(' + rotateAngle + ')');

            labelGroup.append('text')
                .attr('class', 'high-axis-label')
                .text(tickRangeLabels[1])
                .attr('transform', 'translate(' + labelRanges['high'] + ')rotate(' + rotateAngle + ')');

            var spanElement1 = '';
            var spanElement2 = '';
            var tooltipTitle = '';
            if (centerLabels[0]) {
                tooltipTitle += centerLabels[0];
                if (addEditIcon) {
                    spanElement1 += '<i ng-click="$ctrl.sendEditEventForAxis(\'' + axis + '\')" class="axis-edit icon-edit_icon" ' +
                        'title="Edit" am-tooltip="top center to bottom center"></i>';
                }
                spanElement1 += '<span>' + centerLabels[0] + '</span>';
            }

            if (centerLabels[1]) {
                tooltipTitle += centerLabels[1];
                spanElement2 = '<span class="center-axis-label">' + centerLabels[1] + '</span>';
            }

            labelGroup.append('foreignObject')
                .attr('width', self.edgeLength)
                .attr('height', 20)
                .attr('transform', 'translate(' + labelRanges['center'] + ')rotate(' + rotateAngle + ')')
                .html('<div class="center-axis-label-wrapper">' + spanElement1 + spanElement2 + '</div>');

            var tooltipElement = $(labelGroup[0]).find('.center-axis-label-wrapper');
            if (isEllipsisActive(tooltipElement)) {
                labelGroup.append('g')
                    .attr('class', 'center-axis-label-target')
                    .attr('transform', 'translate(' + labelRanges['tooltip_target'] + ')');
                tooltipPosition.target = $(labelGroup[0]).find('.center-axis-label-target');
                tooltipElement.attr('title', tooltipTitle);
                add_tooltip(tooltipElement, 'info', {
                    position: tooltipPosition,
                    style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}
                });
            }

            $compile(tooltipElement)($scope);
        }

    }]
});
