module.exports = angular.module(__filename, []).component('amLinkedDropdown', {
    template: require('./am-linked-dropdown.html'),
    bindings: {
        options: '<',
        selected: '=',
        onChange: '&'
    },
    controller: function () {
        var self = this;

        this.$onInit = function () {
            self.open = false;
            self.selected = self.selected || self.options[0];
        };

        this.toggleOpen = function () {
            self.open = !self.open;
        };

        this.close = function () {
            self.open = false;
        };

        this.change = function (selected) {
            self.close();
            self.selected = selected;
            self.onChange({selected: self.selected});
        }
    }
});