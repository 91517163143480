let silentReAuthenticationService;
let logoutAuthenticationService;
let showContactUsService;
let stateService;
let openNewVersionModalService;
let displayErrorBannerIfNeededService;

export const getSilentReAuthenticationService = () => silentReAuthenticationService;
export const logout = (isHardLogout) => logoutAuthenticationService(isHardLogout);
export const showContactUs = () => showContactUsService();
export const openNewVersionModal = () => openNewVersionModalService();
export const redirectToLoginPage = () => window.location.replace('/login');
export const openHelpPage = (page) => window.open(`http://help.intelligence.amobee.com/The_Dashboard.html#t=${page}.htm`, '_blank');
export const goToState = (state, params) => ( stateService.go(state, params));
// ALON TODO: add test for this added function
export const getStateName = () => ( stateService && (stateService.current.displayTab || stateService.current.display) );
export const setSilentReAuthenticationService = (angularSilentReAuthenticationService) => (silentReAuthenticationService = angularSilentReAuthenticationService);
export const setLogoutAuthenticationService = (angularLogoutAuthenticationService) => (logoutAuthenticationService = angularLogoutAuthenticationService);
export const setShowContactUsService = (angularShowContactUsService) => (showContactUsService = angularShowContactUsService);
export const setStateService = (angularStateService) => (stateService = angularStateService);
export const setOpenNewVersionModalService = (angularOpenNewVersionModalService) => (openNewVersionModalService = angularOpenNewVersionModalService);
export const setDisplayErrorBannerIfNeededService = (angularDisplayErrorBannerIfNeededService) => (displayErrorBannerIfNeededService = angularDisplayErrorBannerIfNeededService);
export const displayErrorBannerIfNeeded = (error) => (displayErrorBannerIfNeededService(error));
