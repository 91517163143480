let conf;
let apigateway = require('infra/api-gateway');
let host = window.location.host;
const protocol = "https://"
//This value will be changed on final Kong deployment
const api_gw_prefix = "sap";
const domain = "intelligence.amobee.com";
const dot = ".";

const apigateway_environments = ["intelligence.amobee.com", "next.intelligence.amobee.com"]

if (IS_PRODUCTION) {
    conf = apigateway_environments.includes(host) ? apigateway(host) : integrationLocation();
} else {
    conf = require('devConfig');
}

function getTeam(){
    return protocol + api_gw_prefix + dot + host.split(dot)[0] + dot + domain;
}

//Use this when Kong integration server will be ready
function getLocationGW(prefix){
    return IS_GATEWAY === true ? getTeam() : "//"+prefix+"."+host;
}

function getLocation(prefix){
    return "//"+prefix+"."+host;
}

function integrationLocation(){
	return {
        USER_MGMT_API: getLocation("user-mgmt" ),
        AUTHENTICATION_API: getLocation("authentication"),
        SNAPSHOT_API: getLocation("snapshot"),
        TRENDLINE_API: getLocation("consumption"),
        DISCOVERY_API: getLocation("discovery"),
        SEARCH_API: getLocation("abi-content-search"),
        AUDIENCE_PROFILER_API: getLocation("audience-profiler-ui"),
        TWITTER_API: getLocation("twitter-api"),
        REALTIME_API: getLocation("abi-realtime"),
        LOGGING_API: getLocation("user-mgmt"),
        KEYWORDS_SUGGESTIONS_API: getLocation("kw-suggest"),
        FIRST_PARTY_UPLOADER: getLocation("first-party-uploader")
    };
}
export default conf;
