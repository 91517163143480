const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    audienceTableView = require('../../widgets/audience-table-view-widget/audience-table-view-widget')

AudienceLinkedinInterestsWidgetController.$inject = ['audienceInsightsService', 'util', 'context', '$state', 'filtersPartition', 'audienceTableStructure', 'segmentInterestExportService', 'mixpanelAudience', 'audienceLinkedinInterestsExportService'];

function AudienceLinkedinInterestsWidgetController(audienceInsightsService, infraUtil, context, $state, filtersPartition, audienceTableStructure, segmentInterestExportService, mixpanelAudience, audienceLinkedinInterestsExportService) {
    const self = this;
    const audienceTables = audienceTableStructure.tableStructure;
    const $scope = self.$scope;
    const channel = context.current.audience_app.current_channel.value;
    const segment = context.current.audience_app[channel].audience_segment;
    const promise = audienceInsightsService.getLinkedinTopContentForAudience(segment, context.current.u_id);
    const tooltips = {
        domains_value: "Domains", urls_value: "URL's", topics_value: "LinkedIn Topics",
        domains_other: "domain", urls_other: "content", topics_other: "LinkedIn Topic"
    }
    let oldInterest = 'domains';
    let data;

    self.exportButton = angular.element(document.querySelector('.export'))[0];
    self.segmentInterestExportService = segmentInterestExportService;
    self.audienceLinkedinInterestsExportService = audienceLinkedinInterestsExportService;
    self.mixpanelAudience = mixpanelAudience;

    $scope.interestTypes = [{value: 'domains', label: 'Websites'}, {value: 'topics', label: 'Phrases'}, {value: 'urls', label: 'Content'}];
    $scope.outputTypes = [{value: 'table', icon: 'icon-table', label: ''}];
    $scope.selectedView = "table";
    $scope.query = audienceTables.linkedin;
    $scope.disableInterestTypes = true;

    promise.then(d => {
        Object.keys(d).forEach(key=> d[key] = d[key].map((row) => ({...row, engagementPercentageDisplay: row.engagementPercentage && (row.engagementPercentage.toFixed(2) + '%'), impressionPercentageDisplay: row.impressionPercentage && (row.impressionPercentage.toFixed(2) + '%')})))
        data = d
        $scope.disableInterestTypes = false;
        $scope.onInterestTypeChange($scope.interestType);
    });

    $scope.onInterestTypeChange = type => {
        if (!data) return;

        $scope.query.columns[0].title = $scope.interestTypes.find(interest => interest.value == type).label;
        $scope.query.title = _.toLower($scope.query.columns[0].title);
        if (type == "topics") {
            $scope.query.columns[0].template = null;
        } else {
            $scope.query.columns[0].template = (type == "urls" ? 'partials/audience-linkedin-interests-content.partial.html' : "partials/audience-domain.partial.html");
        }
        changeTooltip(type);
        $scope.query.show(data[type]);
    };

    $scope.$root.$on("$stateChangeStart", () => {
        self.exportButton.hidden = false;
    });

    $scope.export = () => {
        if (!data) return;

        let excel = audienceLinkedinInterestsExportService.exportToExcel(data, segment);
        audienceLinkedinInterestsExportService.downloadExcel(excel);
    };

    $scope.$root.createExcelWorkbook = $scope.export;

    function changeTooltip(interest) {
        $scope.query.columns = $scope.query.columns.map(col => {
            let property = col.id == "value" ? "value" : "other";
            return Object.assign({}, col, {tooltip: col.tooltip.replace(tooltips[`${oldInterest}_${property}`], tooltips[`${interest}_${property}`])});
        });

        oldInterest = interest;
    }
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name,
    require("widgets/audience-table-view-widget/audience-table-struct").name,
    require('../../data/audience-linkedin-interests-export-service').name,
])
    .directive("audienceLinkedinInterestsWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-linkedin-interests-widget.html"),
        scope: {},
        controller: AudienceLinkedinInterestsWidgetController
    })]);
