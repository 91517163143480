import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmationButtons.scss';
import ClickableLabel from '../ClickableLabel/ClickableLabel';
import SubmitButton from '../SubmitButton/SubmitButton';

const ConfirmationButtons = ({ cancelLabel, onCancelClick, isCancelDisabled, submitLabel, onSubmitClick, isSubmitDisabled }) => (
  <div className="confirmation-buttons-component">
    <ClickableLabel className="cancel-label" label={ cancelLabel } onClick={ onCancelClick } isDisabled={ isCancelDisabled }></ClickableLabel>
    <SubmitButton label={ submitLabel } onClick={ onSubmitClick } isDisabled={ isSubmitDisabled }></SubmitButton>
  </div>
);

ConfirmationButtons.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isCancelDisabled: PropTypes.bool,
  submitLabel: PropTypes.string.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
};

ConfirmationButtons.defaultProps = {
  isCancelDisabled: false,
  isSubmitDisabled: false
};

export default ConfirmationButtons;