module.exports = require("angular").module(__filename, [])
    .directive("audienceFilter", ["abiPermissions", "audienceInsightsService", "filtersPartition", function(abiPermissions, audienceInsightsService, filtersPartition) {
        return {
            restrict: 'E',
            scope: {
                context: '=',
                debounceGroup: '<',
                audienceSummaryOrder: '<',
                filter: '<',
                channels: '<'
            },
            template: require('./audience-filter.html'),
            link: function ($scope, $element, $attrs, $ctrl) {

                $scope.refreshAudienceSummary = 0;
                // temp fix for old snapshots, after changing label from 12-17 to 13-17
                $scope.context.current.audience.forEach(function (a) { if (a.summary == "12-17") a.summary ="13-17"});

                var refreshAudienceSummary = function () {
                    $scope.refreshAudienceSummary++;
                };

                $scope.determineMold = function () {
                    if($scope.isSgTelco()){
                        $scope.mold = $scope.context.current._sgTelcoAudience_mold;
                        if(!abiPermissions.hasPermission("sg telco ethnicity")){
                            _.remove($scope.mold._value, (eth) => _.find(filtersPartition.sgEthnicity, {value: eth.value}));
                        }
                        $scope.showFirstPartyAudienceForArticles = false;
                    } else {
                        $scope.mold = $scope.context.current._audience_mold;
                        $scope.showFirstPartyAudienceForArticles = abiPermissions.hasPermission("first party segments");
                    }
                };

                $scope.hideOrShowEthnicity = function () {
                    $scope.showEthnicityForArticles = (!$scope.channels || _.isEmpty(_.difference($scope.channels, ['articles', 'videos']))) && $scope.isGeoUsSelected();
                };

                $scope.refreshAudienceFilterDisplay = function () {
                    $scope.channels = _.castArray($scope.channels);
                    $scope.determineMold();
                    $scope.refreshFirstPartyAudience();
                    refreshAudienceSummary();
                    $scope.hideOrShowEthnicity();
                    $scope.showChildren = $scope.showIncome = _.isEmpty(_.difference($scope.channels, ['articles', 'videos']));
                    $scope.showSgTelcoUserOriginData = $scope.isSgTelco();
                    $scope.showSgTelcoEthnicity = abiPermissions.hasPermission("sg telco ethnicity");
                };

                $scope.refreshFirstPartyAudience = function() {
                    audienceInsightsService.getFirstPartyDataByProgram($scope.context.program.id).then(function(res) {
                        $scope.userFirstPartyAudiences = _.map(res, function (segment) {
                            segment.label = segment.summary = segment.name;
                            segment.value = segment.segment_value;
                            return segment;
                        });
                        $scope.noFirstPartySegments = _.isEmpty($scope.userFirstPartyAudiences);
                        var nonexisting_context_segments =
                            _.map(_.filter($scope.context.current.firstPartyAudience,
                                          (segment) => _.every($scope.userFirstPartyAudiences, (s) => s.segment_value != segment.segment_value)), 'segment_value');
                        if(!_.isEmpty(nonexisting_context_segments)){
                            $scope.context.current.firstPartyAudience =
                              _.reject($scope.context.current.firstPartyAudience,
                                      (s) => _.includes(nonexisting_context_segments, s.segment_value));
                        }
                    });
                };

                $scope.isGeoUsSelected = () => $scope.context.current.geo.length == 0 ||
                                               _.some($scope.context.current.geo, {cc: "US"});

                $scope.getSelectedForSummary = function () {
                    let selected = $scope.mold._value;
                    if (!$scope.isSgTelco() && abiPermissions.hasPermission("first party segments")) {
                        selected = _.concat(selected, $scope.context.current.firstPartyAudience);
                    }
                    return selected;
                };

                $scope.isSgTelco = () => _.isEqual($scope.channels, ['sg_telco']);

                $scope.refreshAudienceFilterDisplay();

                $scope.$watch('context.current.geo', $scope.hideOrShowEthnicity);
                $scope.$watch('channels', $scope.refreshAudienceFilterDisplay);
                $scope.$watch('context.program', $scope.refreshAudienceFilterDisplay, true);
                $scope.$watch('context.current.audience', refreshAudienceSummary);
                $scope.$watch('context.current.sgTelcoAudience', refreshAudienceSummary);
                $scope.$watch('context.current.firstPartyAudience',refreshAudienceSummary);
                $scope.$watch('userFirstPartyAudiences',$scope.determineMold);
            }
        };
    }]);
