import 'angular-modal-service';

angular.module(__filename, [
    'angularModalService',
    require('data/settings/user-mgmt').name,
    require('data/settings/users-export-service.js').name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    require('./user-dialog/user-dialog').name,
    require('./bulk-user-dialog/bulk-user-dialog').name,
    require("../../infra/mixpanel/mixpanel-settings").name
]).controller('UsersController', ['$scope', '$filter', 'ssoService', 'userMgmt', 'mgmtServiceFactory', '$timeout', 'ModalService',
                                  'usersExportService', 'notificator', 'confirmAction', 'geoService', '$q',
                                  'mixpanelSettings', 'util',
    function ($scope, $filter, ssoService, userInterface, mgmtServiceFactory, $timeout, ModalService, usersExportService,
              notificator, confirmAction, geoService, $q, mixpanelSettings, util) {

        var orgService = mgmtServiceFactory.createService('organization');

        ssoService.ensureLoggedIn();

        $scope.filtered = [];
        function populate() {
            $scope.gotData = false;
            $scope.selected = [];
            $scope.allSelected = false;
            userInterface.list().then(function (data) {
                $scope.users = data;
                $scope.filterUsers();
                $scope.gotData = true;
            });
        }
        mixpanelSettings.trackPageView('users');
        populate();

        $scope.editUser = editUserModal;
        $scope.editBulkUsers = editBulkUsersModals;
        $scope.deleteUser = deleteUser;
        $scope.animateSize = util.animateElementBloat;
        $scope.canEditUser = canEditUser;
        $scope.search = search;
        $scope.toggleSelected = toggleSelected;
        $scope.toggleSelectedAll = toggleSelectedAll;

        $scope.export = function (users) {
            if (!users) users = $scope.filtered;
            var currentUser = $scope.$root.user;
            var optionsPromise = $scope.getOptions(currentUser.account.id)
            var geosPromise = geoService.getGeosForExport();

            $q.all([optionsPromise, geosPromise]).then(function(values){
              var excel = usersExportService.exportUsersToExcel(users, values[0], values[1]);
              usersExportService.downloadExcel(excel);
            });

        };

        $scope.getOptions = function(id) {
          return orgService.customRequest('GET', '/' + id + '/options');
        };

        $scope.$root.createExcelWorkbook = $scope.export;

        $scope.parseQuery = function(query) {
          if (!query) {
            return {};
          }
          var items = {};
          query.toLowerCase().split(",").forEach(function(part) {
            var negative = false;
            var hasFn = true;
            part = part.trim();
            if (part[0] == "-") {
              negative = true;
              part = _.trimStart(part, "-");
            }
            var [fn, key] = part.split(":");
            if (key == undefined) {
              hasFn = false;
              key = fn;
              fn = "freetext";
            }
            key = key.trim();
            var item = {value: key, negative: negative};
            if (_.has(items, fn)) {
              if (_.isPlainObject(items[fn]) || items[fn] === null) {
                items[fn] = [items[fn]];
              }
              items[fn].push(item);
            } else {
              items[fn] = item;
            }
          });

          return items;
        }

        function basic_search(q, user) {
          return user.first_name.toLowerCase().indexOf(q) != -1
            || user.last_name.toLowerCase().indexOf(q) != -1
            || user.email.toLowerCase().indexOf(q) != -1
            || user.account.name.toLowerCase().indexOf(q) != -1
            || user.user_type.name.toLowerCase().indexOf(q) != -1;
        };

        function search(user) {
            var results = [];
            user.highlights = {};
            var results = _.map($scope.parsedQueriesArray, function(entry) {
              var [fn, item] = _.entries(entry)[0];
              var key = item.value;
              var negative = item.negative;
              var found = false;
              switch(fn) {
                case "":
                  return true;
                case "is":
                  switch(key) {
                    case "selected":
                      found = user.selected;
                      break;
                    default:
                      found = basic_search(key, user);
                      break;
                  }
                  break;
                case "firstname":
                  fn = "first_name"
                  found = user.first_name.toLowerCase().indexOf(key) != -1
                  break;
                case "lastname":
                  fn = "last_name"
                  found = user.last_name.toLowerCase().indexOf(key) != -1
                  break;
                case "email":
                  found = user.email.toLowerCase().indexOf(key) != -1
                  break;
                case "org":
                case "organization":
                  fn = "organization";
                  found = user.account.name.toLowerCase().indexOf(key) != -1
                  break;
                case "type":
                  found = user.user_type.name.toLowerCase().indexOf(key) != -1;
                  break;
                case "permission":
                case "permissions":
                  fn = "permission";
                  found = _(user.permissions).map(function(c) { return c.name.toLowerCase(); }).includes(key.toLowerCase());
                  break;
                case "geo":
                case "geos":
                  fn = "geo";
                  found = _(user.countries).map(function(c) { return c.label.toLowerCase(); }).includes(key.toLowerCase());
                  break;
                case "freetext":
                default:
                  fn = "freetext";
                  found = basic_search(key, user);
                  break;
              }
              found = negative ? !found : found;
              if (found) {
                var value = user.highlights[fn];
                if (value) {
                  user.highlights[fn] = _.castArray(value).concat(key);
                } else {
                  user.highlights[fn] = key;
                }
              }
              return found;
            });
            return _.every(results);
        }

        $scope.filterUsers = function(query = "") {
          var filtered = $filter('filter')($scope.users, $scope.search);
          filtered = $filter('orderBy')(filtered, [$scope.reverse ? '-' + $scope.predicate : $scope.predicate, 'last_name'])
          $scope.filtered = filtered
        };

        $scope.getHighlights = function(user, highlight) {
          return _.filter(_.castArray(user.highlights[highlight]).concat(user.highlights["freetext"]));
        };

        function flattenQ(h) {
          var output = Object.keys(h).map(function(key) {
            var value = h[key];
            if (_.isArray(value)) {
              return _.map(value, v => ({[key]: v}));
            } else {
              return {[key]: value};
            }
          });
          return _.flatten(output);
        };

        $scope.onSearch = function(query = "") {
          $scope.parsedQueries = $scope.parseQuery(query);
          $scope.parsedQueriesArray = flattenQ($scope.parsedQueries);
          $scope.filterUsers(query);
        };

        $scope.predicate = 'last_name';
        $scope.reverse = false;
        $scope.order = function (predicate) {
            $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
            $scope.predicate = predicate;
        };

        function idsMapper(array) {
            return array.map(function (x) {
                return x.id.toString();
            });
        }


        function toggleSelected(row, $event) {

            //shit+click checks/uncheckes all checked
            var index = $scope.filtered.indexOf(row),
                prev = $scope.prevIndexSelected == undefined ? index : $scope.prevIndexSelected,

                start = Math.min(index, prev),
                end   = Math.max(index, prev);

            if ($event.shiftKey) {
                for(var i = start; i<=end; i++) {
                    $scope.filtered[i].selected = row.selected;
                }
            }

            $scope.prevIndexSelected = index;

            $scope.selected = ($scope.users || []).filter(function(row) {
                return row.selected;
            });

            $scope.allSelected = (_.countBy($scope.filtered, canEditUser).true === $scope.selected.length);
        }

        function toggleSelectedAll($value, $event) {
          _.each($scope.filtered, function(user) {
            if (canEditUser(user)) {
              user.selected = $value;
            }
          });

          $scope.selected = ($scope.users || []).filter(function(row) {
            return row.selected;
          });
        }

        function canEditUser(user) {
            var currentUser = $scope.$root.user;
            if (currentUser.userType.match(/super admin/i)) return true;

            var userType = user.user_type.name;
            return !userType.match(/super admin/i) && !userType.match(/support/i);
        }

        function deleteUser(id) {
            var index = idsMapper($scope.users).indexOf(id.toString());
            var confirmationMsg = 'Are you sure you want to delete the user ' + $scope.users[index].email + '?';
            confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) {
                      executeDelete(id);
                      _.remove($scope.selected, user => (user.id === id));
                    }
                });
            });

            function executeDelete(id) {
                userInterface.deleteUser(id).then(function () {
                    $scope.users.splice(index, 1);
                });
            }
        }

        function editUserModal(user) {
          ModalService.showModal({
            template: require('./user-dialog/user-dialog.html'),
            inputs: {
              user: user
            },
            controller: 'editUserModalController'
          }).then(function (modal) {
            modal.close.then(function (result) {
              if (result && result.reload) populate();
            });
          });
        }

        function editBulkUsersModals() {

          var sameOrg = _.every($scope.selected, function(user) { return user.account.id == $scope.selected[0].account.id });

          if (!sameOrg) {
            notificator.error({body: "Sorry, group edit is only supported for users under the same organization"});
            return;
          }

          if ($scope.selected.length == 1) {
            editUserModal($scope.selected[0]);
          } else {
            ModalService.showModal({
              template: require('./bulk-user-dialog/bulk-user-dialog.html'),
              inputs: {
                users: $scope.selected
              },
              controller: 'editBulkUsersModalsController'
            }).then(function(modal) {
              modal.close.then(function(result) {
                if (result && result.reload) populate();
              });
            });
          }
        }
    }
]).stateConfig = {
    name: "users",
    url: "/users",
    template: require('./users_index.html'),
    controller: "UsersController",
    display: "Settings",
    data: {
      permissions: [],
      roles: ['super admin', 'admin']
    }
};

module.exports = angular.module(__filename);
