import { react2angular } from 'react2angular';
import common from "infra/utils/common";
import { validateUserAuthentication } from '../../data/audience-linkedin-helper';
import LifestyleModal from 'react/components/apps/audience/AudienceBuilder/Modals/LifestyleModal/LifestyleModal';
import FirstPartyModal from 'react/components/apps/audience/AudienceBuilder/Modals/FirstPartyModal/FirstPartyModal';
import CommercialsModal from 'react/components/apps/audience/AudienceBuilder/Modals/CommercialsModal/CommercialsModal';
import AudiencePreviewWidget from 'react/components/apps/audience/AudienceBuilder/AudiencePreviewWidget/AudiencePreviewWidget';
import SegmentsImageToggleButtonSelector from 'react/components/apps/audience/AudienceBuilder/SegmentsImageToggleButtonSelector/SegmentsImageToggleButtonSelector.js';
import AudienceTarget from 'react/components/apps/audience/AudienceBuilder/AudienceTarget/AudienceTarget';
import add_tooltip from 'common/tooltip/tooltip.js';
import { SEGMENT_MAP, getSegmentValuesSummary, isAudienceTargetVisible } from '../../data/audience-segment-builder-helper';

const audienceBuilderModule = angular.module(__filename, [
    require("./modals/demographics-modal").name,
    require("./modals/interests-modal").name,
    require("./modals/websites-modal").name,
    require("./modals/tv-shows-modal").name,
    require("./modals/linkedin/linkedin-demographics-modal").name,
    require("./modals/linkedin/linkedin-industries-modal").name,
    require("./modals/linkedin/linkedin-companies-modal").name,
    require("./modals/linkedin/linkedin-jobs-modal").name,
    require("./modals/linear-tv/linear-tv-demographics-modal").name,
    require("./modals/smart-tv/smart-tv-demographics-modal").name,
    require("./modals/load-audience/load-audience-modal").name,
    require("../../widgets/audience-preview-widget/audience-preview-widget").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
    require("data/audience-mgmt").name,
    require("infra/mixpanel/mixpanel-audience").name,
    require("./modals/apps-modal").name,
]);

audienceBuilderModule
    .component('audiencePreview', react2angular(AudiencePreviewWidget, ['hasData', 'populationDist', 'genderDist', 'ageDist', 'isError', 'isTooNarrow', 'isTooWide', 'isEntitiesLimit', 'onExploreAudience']))
    .component('lifestyleModal', react2angular(LifestyleModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'lifestyleInput', 'lifestyles']))
    .component('firstPartyModal', react2angular(FirstPartyModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'firstPartyInput', 'firstPartyPromise']))
    .component('segmentsImageToggleButtonSelector', react2angular(SegmentsImageToggleButtonSelector, ['segmentsData', 'selectedSegment', 'onToggle']))
    .component('commercialsModal', react2angular(CommercialsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'commercialsInput', 'commercialsMetadataPromise', 'channel']))
    .component('audienceTarget', react2angular(AudienceTarget, ['marketsPromise', 'audienceId', 'audienceName', 'audienceSegment', 'isAudienceTargetDisabled', 'isAudienceTargetVisible', 'isNewAudienceTarget', 'channel', 'getSegmentIds', 'createAudienceTargetTaxonomy', 'createAudienceTargetUserList', 'notificator', 'updateIsNewAudienceTarget', 'isClickableLabel', 'audienceTargetDisabledTooltipText']));

audienceBuilderModule.stateConfig = [{
    name: "audience-builder",
    url: "/audience-builder",
    params: { tokenStatus: null, channel: null },
    template: require("./audience-builder.html"),
    display: "Build Audience",
    data: {
      permissions: ['audience']
    },
    context: {
        audience_segment: "GeneralMold"
    },
    controller: audienceBuilderController
}];

audienceBuilderController.$inject = ['$scope', 'context', 'audienceMgmt', 'filtersPartition', 'demographicsModal',
    'interestsModal', 'websitesModal', 'loadAudienceModal', 'notificator', 'confirmAction', 'mixpanelAudience',
    'audienceInsightsService', 'linkedinDemographicsModal', 'linkedinJobsModal', 'linkedinCompaniesModal',
    'linkedinIndustriesModal', 'abiPermissions', 'tvShowsModal', 'linearTvDemographicsModal', 'smartTvDemographicsModal',
    '$stateParams', 'errorMgmt', '$window', '$rootScope', 'appsModal', 'dspService'];

const regularSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "1st party", label: "1st Party", image: "./images/pages/audience-builder/images/1st_party_img.jpg", permission: 'first party segments'}
];

const sgSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png", isDisabled: true, tagLabel: 'Coming soon', debugOnly: true}
];

const linearTvSegmentTypes = [
    {value: "linearTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "linearTvCommercials", label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"}
];

const smartTvSegmentTypes = [
    {value: "smartTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "smartTvCommercials", label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"}
];

const auTelcoSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png", debugOnly: true},
    {value: "apps", label: "Apps", image: "./images/pages/audience-builder/images/apps_img.png", isDisabled: true, tagLabel: 'Coming soon', debugOnly: true}
];

const linkedinSegmentTypes = [
    {value: "linkedinDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "linkedinIndustries", label: "Industries", image: "./images/pages/audience-builder/images/industries_img.jpg"},
    {value: "linkedinCompanies", label: "Companies", image: "./images/pages/audience-builder/images/companies_img.jpg"},
    {value: "linkedinJobs", label: "Jobs", image: "./images/pages/audience-builder/images/jobs_img.jpg"}
];

const bidStreamInscapeSegmentTypes = [
    {value: "smartTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
];

const channelToSegmentTypes = {
    linkedin: linkedinSegmentTypes,
    data_spark: sgSegmentTypes,
    snbb: sgSegmentTypes,
    au_telco: auTelcoSegmentTypes,
    articles: regularSegmentTypes,
    linear_tv: linearTvSegmentTypes,
    smart_tv: smartTvSegmentTypes
};

function audienceBuilderController($scope, context, audienceMgmt, filtersPartition, demographicsModal,
    interestsModal, websitesModal, loadAudienceModal, notificator, confirmAction, mixpanelAudience,
    audienceInsightsService, linkedinDemographicsModal, linkedinJobsModal, linkedinCompaniesModal,
    linkedinIndustriesModal, abiPermissions, tvShowsModal, linearTvDemographicsModal, smartTvDemographicsModal,
    $stateParams, errorMgmt, $window, $rootScope, appsModal, dspService) {

    let current_channel;
    let linkedinMetaDataPromise, tvGenresMetaDataPromise;
    let originalAudienceName = '';
    const DEMOGRAPHICS = ['demographics', 'linkedinDemographics', 'linearTvDemographicsModal', 'smartTvDemographicsModal'];
    const debugUser = $scope.$root.user.userType === 'debug';
    const comingSoonLifestyle = $scope.$root.user.email.toLowerCase() === 'christian.sullivan@optus.com.au';
    const SEGMENT_MODAL_MAP = {
        demographics: demographicsModal, interests: interestsModal, websites: websitesModal,
        linkedinDemographics: linkedinDemographicsModal, linkedinJobs: linkedinJobsModal, linkedinCompanies: linkedinCompaniesModal, 
        linkedinIndustries: linkedinIndustriesModal, tvShows: tvShowsModal, linearTvDemographics: linearTvDemographicsModal,
        smartTvDemographics: smartTvDemographicsModal, apps: appsModal
    };
    const tvChannelToTvShowsMetaDataPromise = {}; // { articles: tvShowsMetaDataPromise, linear_tv: linearTvShowsMetaDataPromise, smart_tv: smartTvShowsMetaDataPromise}
    const tvChannelToTvNetworksMetaDataPromise = {}; // { articles: tvNetworksMetaDataPromise, linear_tv: linearTvNetworksMetaDataPromise, smart_tv: smartTvNetworksMetaDataPromise}

    $scope.isOpenChannelSideMenuFilter = false;
    $scope.SEGMENT_MAP = SEGMENT_MAP;
    $scope.logicalOperands = [{value: 'and', label: 'ALL of the attributes below'}, {value: 'or', label: 'ANY of the attributes below'}];
    mixpanelAudience.trackPageView('audience builder');
    validateChannelContext();
    validateFirstPartySegmentContext();
    loadFromContext();
    changeSegmentTypes(current_channel);
    disableEnableLinkedinSegments();

    $scope.getSegmentIds = audienceInsightsService.getSegmentIds;
    $scope.createAudienceTargetTaxonomy = audienceInsightsService.createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = audienceInsightsService.createAudienceTargetUserList;
    $scope.notificator = notificator;
    $scope.filtersPartition = filtersPartition;
    $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);

    $scope.reactSegmentModals = {
        lifestyle: {
            isOpen: false,
            newTitle: "Add a lifestyle segment",
            editTitle: "Modify segment's lifestyle",
            lifestyles: getLifestyles((context.current.audience_app || {}).current_channel || {}, debugUser)
        },
        '1st party': {
            isOpen: false,
            newTitle: "Add a 1st party segment",
            editTitle: "Modify segment's 1st party"
        },
        'linearTvCommercials': {
            isOpen: false,
            newTitle: "Add a segment based on Linear TV commercial viewership",
            editTitle: "Modify segment's TV commercial viewership"
        },
        'smartTvCommercials': {
            isOpen: false,
            newTitle: "Add a segment based on Smart TV commercial viewership",
            editTitle: "Modify segment's TV commercial viewership"
        }
    };

    $scope.modalCancelHandler = (type) => {
        $scope.segmentType = null;
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);
        updatedReactSegmentModals[type].isOpen = false;
        updatedReactSegmentModals.input = {};
        $scope.reactSegmentModals = updatedReactSegmentModals;
        $scope.$digest();
    };

    $scope.modalSubmitHandler = (values) => {
        $scope.segmentType = null;
        const segmentType = _.get(values, 'type');
        if (!segmentType) return;
        const segmentIndex = values.index;
        const updatedAudienceSegment = _.cloneDeep($scope.audience_segment);
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);

        if (_.isNumber(segmentIndex) && segmentIndex >= 0) {
            //TODO: needs to find a way to check if segment with object || array parameters got updated
            const updatedSegment = {...updatedAudienceSegment[segmentIndex], ...values};
            updatedAudienceSegment.splice(segmentIndex, 1, updatedSegment);
            $scope.audience_segment = updatedAudienceSegment;
            refreshContext();
        } else {
            const operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
            const newSegment = { operand, ...values };
            updatedAudienceSegment.push(newSegment);
            $scope.audience_segment = updatedAudienceSegment;
            if (current_channel === 'linkedin') disableEnableLinkedinSegments(segmentType);
            refreshContext();
        }

        updatedReactSegmentModals[segmentType].isOpen = false;
        $scope.reactSegmentModals = updatedReactSegmentModals;
        $scope.$digest();
    };

    function getLifestyles(currentChannel, debugUser) {
        const lifestyles = filtersPartition.behavioralSegment.filter((segment) => segment.debugOnly ? debugUser : true);

        if (currentChannel.value === 'au_telco' && debugUser) {
            const auLifestyles = filtersPartition.auTelcoBehavioralSegment.filter((segment) => segment.debugOnly ? debugUser : true);
            return _.orderBy([ ...auLifestyles, ...lifestyles], 'label', 'asc');
        }

        if (currentChannel.value === 'data_spark' && debugUser) {
            const sgLifestyles = filtersPartition.sgTelcoBehavioralSegment.filter((segment) => segment.debugOnly ? debugUser : true);
            return _.orderBy([ ...sgLifestyles, ...lifestyles], 'label', 'asc');
        }

        return lifestyles;
    }

    function updateModalLifestyles(channel) {
        if (!_.has($scope.reactSegmentModals, 'lifestyle.lifestyles')) return;
        $scope.reactSegmentModals.lifestyle.lifestyles = getLifestyles(channel, debugUser);
    }

    function updateModalLifestyles(channel) {
        if (!_.has($scope.reactSegmentModals, 'lifestyle.lifestyles')) return;
        $scope.reactSegmentModals.lifestyle.lifestyles = getLifestyles(channel, debugUser);
    }

    function validateChannelContext () {
        $scope.audienceChannelsFilter = _.filter($scope.$root.audienceChannelsFilter, channel => (debugUser || !channel.debugOnly));
        if (!context.current.audience_app || !_.includes(_.map($scope.audienceChannelsFilter, 'value'), (context.current.audience_app.current_channel || {}).value)
          || (context.current.audience_app.current_channel.permission != null && !abiPermissions.hasPermission(context.current.audience_app.current_channel.permission))) {
            context.current.audience_app = { current_channel: common.getAvailableContext($scope.audienceChannelsFilter, abiPermissions) };
        }
    }

    function loadFromContext () {
        current_channel = context.current.audience_app.current_channel.value;
        if (!context.current.audience_app[current_channel]) context.current.audience_app[current_channel] = {};
        let channel_audience = context.current.audience_app[current_channel];

        $scope.audience_segment = channel_audience.audience_segment || [];
        $scope.audience_name = channel_audience.audience_name || '';
        $scope.audience_id = channel_audience.audience_id || '';
        $scope.advancedSegmentation = channel_audience.audience_advancedSegmentation || false;
        $scope.isNewAudienceTarget = channel_audience.audience_newTarget || true;
        if (_.isEmpty(channel_audience.audience_logicalOperand)) {
            $scope.logicalOperand = $scope.logicalOperands[0];
        } else {
            $scope.logicalOperand = _.find($scope.logicalOperands, {value: channel_audience.audience_logicalOperand.value});
        }
        if (!$scope.advancedSegmentation) _.each($scope.audience_segment, (s) => s.operand = $scope.logicalOperand);
    }

    $scope.onLogicalOperandChange = function (selectedOperand) {
        $scope.logicalOperand = selectedOperand;
        let newAudienceSegment = _.cloneDeep($scope.audience_segment)
        _.each(newAudienceSegment, (segment) => segment.operand = selectedOperand);
        $scope.audience_segment = newAudienceSegment;
        refreshContext();
    };

    $scope.onToggleAdvancedSegmentation = function (on) {
        if (!on) {
            var advancedOperands = _($scope.audience_segment).map('operand.value').uniq().value();
            if (advancedOperands.length == 1) {
                var operand = _.find($scope.logicalOperands, {value: advancedOperands[0] || 'and'});
                $scope.logicalOperand = operand ? operand : $scope.logicalOperand;
            }
            let newAudienceSegment = _.cloneDeep($scope.audience_segment)
            _.each(newAudienceSegment, (segment) => segment.operand = $scope.logicalOperand);
            $scope.audience_segment = newAudienceSegment;
            refreshContext();
        }
        mixpanelAudience.trackAdvancedSegmentation();
    };

    $scope.loadAudience = function () {
        getConfirmation(true, function () {
            loadAudienceModal.showModal(current_channel, $scope.isAudienceTargetVisible(current_channel)).then(function (modal) {
                modal.close.then(function (value) {
                    // When the user uses browse back btn, there is no "segments" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "value" and segments" attribute.
                    if (value && !_.isEmpty(value.segments)) {
                        $scope.audience_id = value.id;
                        $scope.audience_segment = _.cloneDeep(value.segments);
                        $scope.audience_name = value.name;
                        originalAudienceName = value.name;
                        $scope.advancedSegmentation = value.advancedSegmentation;
                        $scope.isNewAudienceTarget = value.is_audience_activated;
                        $scope.logicalOperand = !$scope.advancedSegmentation && value.segments[0].operand ? value.segments[0].operand : $scope.logicalOperands[0];
                        notificator.success({body: `"${$scope.audience_name}" audience loaded successfully`});
                        clearNameErrors();
                        refreshContext();
                    }

                    getProgramAudience();
                    if (current_channel == "linkedin") disableEnableLinkedinSegments();
                });
            });
        });
    };

    $scope.onChannelFilterChange = (channel) => {
        context.current.audience_app.current_channel = channel;
    };

    $scope.onChannelSideMenuFilterClick = () => {
        $scope.isOpenChannelSideMenuFilter = !$scope.isOpenChannelSideMenuFilter;
    };

    function getConfirmation (load, callback) {
        if (!$scope.audienceDirty()) {
            callback();
        } else {
            var confirm = 'Continue without saving';
            var cancel = 'Cancel';
            var title = 'Audience unsaved';
            var msg = `Are you sure you want to ${load ? 'load' : 'create'} a new audience
                       without saving your changes to this audience?`;

            confirmAction.getConfirmation(msg, confirm, cancel, title, 'audience-confirmation').then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation === true) {
                        callback();
                    }
                });
            });
        }
    }

    $scope.audienceDirty = function () {
        var current_audience = _.find(programAudiences, {id: $scope.audience_id}) || {segments: [], name: '', advancedSegmentation: false};
        return angular.toJson(current_audience.segments) != angular.toJson($scope.audience_segment)
            || current_audience.name != $scope.audience_name
            || current_audience.advancedSegmentation != $scope.advancedSegmentation;
    };

    $scope.resetAudience = function () {
        getConfirmation(false, function () {
            $scope.audience_segment = [];
            $scope.audience_name = '';
            $scope.audience_id = '';
            originalAudienceName = '';
            $scope.audience_id = null;
            $scope.advancedSegmentation = false;
            $scope.isNewAudienceTarget = true;
            $scope.logicalOperand = $scope.logicalOperands[0];
            refreshContext();
            clearNameErrors();
            if (current_channel == "linkedin") disableEnableLinkedinSegments();
        });
    };

    $scope.deleteSegment = function (index) {
        let newAudienceSegment = _.cloneDeep($scope.audience_segment);
        let deletedSegment = newAudienceSegment.splice(index, 1)[0];
        $scope.audience_segment = newAudienceSegment;
        disableEnableLinkedinSegments(deletedSegment.type);
        refreshContext();
    };

    $scope.editSegment = function (index) {
        const type = $scope.audience_segment[index].type;
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];

        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].editTitle;
            $scope.reactSegmentModals[type].input = { index, ...$scope.audience_segment[index] };
            return;
        }

        const disableGeo = DEMOGRAPHICS.includes(type) && _.filter($scope.audience_segment, (seg) => DEMOGRAPHICS.includes(seg.type)).length > 1;

        modal.showModal($scope.audience_segment[index], false, disableGeo, current_channel, 
            {linkedinMetaDataPromise, abiPermissions, tvShowsMetaDataPromise: tvChannelToTvShowsMetaDataPromise[current_channel], 
                tvNetworksMetaDataPromise: tvChannelToTvNetworksMetaDataPromise[current_channel], tvGenresMetaDataPromise, debugUser}).then(function (modal) {
            modal.close.then(function (value) {
                $scope.segmentType = null;
                // When the user uses browse back btn, there is no "type" attribute.
                // We use this property as a validation, i.e. any valid response should include a "type" attribute.
                if (value && value["type"]) {
                    let newAudienceSegment = _.cloneDeep($scope.audience_segment)
                    newAudienceSegment[index] = value;
                    $scope.audience_segment = newAudienceSegment
                    refreshContext();
                }
            });
        })
    };

    $scope.newSegment = function (type) {
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];

        mixpanelAudience.trackSegment(type);
        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].newTitle;
            $scope.reactSegmentModals[type].input = {};
            return;
        }

        const prevDefinedGeo = _.filter($scope.audience_segment, (seg) => DEMOGRAPHICS.includes(seg.type))[0] || {};
        const disableGeo = DEMOGRAPHICS.includes(type) && Object.keys(prevDefinedGeo).length > 0;
        const initialDefinedProperties = disableGeo ? {geo: prevDefinedGeo.geo, ethnicity: prevDefinedGeo.ethnicity, income: prevDefinedGeo.income} : null;

        modal.showModal(initialDefinedProperties, true, disableGeo, current_channel, 
            {linkedinMetaDataPromise, abiPermissions, tvShowsMetaDataPromise: tvChannelToTvShowsMetaDataPromise[current_channel],
                tvNetworksMetaDataPromise: tvChannelToTvNetworksMetaDataPromise[current_channel], tvGenresMetaDataPromise, debugUser}).then(function (modal) {
            modal.close.then(function (value) {
                $scope.segmentType = null;
                if (value && value["type"]) {
                    value.operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
                    let newAudienceSegment = _.cloneDeep($scope.audience_segment);
                    newAudienceSegment.push(value);
                    $scope.audience_segment = newAudienceSegment;
                    refreshContext();
                    disableEnableLinkedinSegments(value["type"]);
                }
            });
        });
    };

    function isInvalidName (isEdit) {
        $scope.name_missing = !$scope.audience_name;
        var audiences = isEdit ? _.filter(programAudiences, a => a.id != $scope.audience_id) : programAudiences;
        var names = _.map(audiences, audience => audience.name.toLowerCase());
        $scope.name_exist_in_program = names.includes($scope.audience_name.toLowerCase());
        return $scope.name_missing || $scope.name_exist_in_program;
    }

    function newAudience (data) {
        return audienceMgmt.create({data: data});
    }

    function editAudience (data) {
        return audienceMgmt.update($scope.audience_id, {data: data});
    }

    var saveInProcess = false;
    $scope.saveAudience = function () {
        if (saveInProcess) return;
        if (isInvalidName($scope.audience_id)) return;

        saveInProcess = true;
        const isAudienceActivated = $scope.isAudienceTargetVisible(current_channel) && !$scope.isNewAudienceTarget;
        var data = {
            name: $scope.audience_name,
            segments: _.cloneDeep($scope.audience_segment),
            advancedSegmentation: $scope.advancedSegmentation,
            channel: current_channel,
            ...isAudienceActivated && { is_audience_activated: isAudienceActivated }
        };

        var promise = $scope.audience_id ? editAudience(data) : newAudience(data);
        promise.then((res) => {
            var msg = `The audience "${$scope.audience_name}" was saved successfully`;
            if (originalAudienceName && res.name != originalAudienceName) {
                msg = `The audience "${originalAudienceName}" was renamed to "${$scope.audience_name}" and saved successfully`;
            }

            notificator.success({body: msg});
            refreshContext();
            clearNameErrors();
            getProgramAudience();
            saveInProcess = false;
        });
    };

    $scope.clearNameErrors = clearNameErrors;

    function clearNameErrors () {
        $scope.name_exist_in_program = false;
        $scope.name_missing = false;
    }

    var programAudiences = [];

    function getProgramAudience () {
        audienceMgmt.list(current_channel).then(data => {
            programAudiences = data;
            var currentAudience = _.find(programAudiences, {name: $scope.audience_name}) || {};
            $scope.audience_id = currentAudience.id;
            originalAudienceName = currentAudience.name;
            $scope.disableLoadAudience = programAudiences.length == 0;
            // map audience ids to names, for later display
            context.current.audience_app[current_channel].audience_ids = data.reduce((obj, aud) => {
                obj[aud.id] = aud.name;
                return obj;
            }, {});
            context.current.audience_app[current_channel].audience_id = currentAudience.id;
            if ($scope.isAudienceTargetVisible(current_channel)) {
                $scope.isNewAudienceTarget = !currentAudience.is_audience_activated;
                context.current.audience_app[current_channel].audience_newTarget = $scope.isNewAudienceTarget;
                context.current.audience_app[current_channel].audience_targetDisabled = $scope.isAudienceTargetDisabled(current_channel);
                context.current.audience_app[current_channel].audience_targetDisabledTooltipText = $scope.audienceTargetDisabledTooltipText;
            }
        });
    }

    getProgramAudience();
    var unbindContextListener = context.onChange(function (newVal, oldVal) {
        if (oldVal && newVal.p_id == oldVal.p_id) return;
        clearNameErrors();
        loadFromContext();
        getProgramAudience();
    });

    $scope.$on('$destroy', unbindContextListener);
    $scope.refreshContext = refreshContext;

    function refreshContext (channel = current_channel) {
        context.current.audience_app[channel].audience_segment = _.cloneDeep($scope.audience_segment);
        context.current.audience_app[channel].audience_name = $scope.audience_name;
        context.current.audience_app[channel].audience_advancedSegmentation = $scope.advancedSegmentation;
        context.current.audience_app[channel].audience_logicalOperand = _.cloneDeep($scope.logicalOperand);
        delete context.current.audience_app[channel].audience_interestsToTest;
        if ($scope.isAudienceTargetVisible(channel)) {
            context.current.audience_app[channel].audience_newTarget = $scope.isNewAudienceTarget;
            context.current.audience_app[channel].audience_targetDisabled = $scope.isAudienceTargetDisabled(channel);
            context.current.audience_app[channel].audience_targetDisabledTooltipText = $scope.audienceTargetDisabledTooltipText;
        }
    }

    var nameInput = angular.element(document.querySelector('.segment-name'));
    var inputFont = nameInput.css('font');
    $scope.rename = function (onBlur) {
        if (saveInProcess) return;
        if ($scope.audience_name == originalAudienceName || $scope.audience_segment.length == 0) return;
        $scope.saveAudience();
        if (!onBlur) nameInput.blur();
    };

    $scope.calcAsteriskLeft = function () {
        return 10 + common.getTextWidth($scope.audience_name, inputFont) + 5;
    };

    $scope.getTooltipOffsets = function (index, textLength) {
        let elemWidth = $(".audience-builder").width();
        let limit = ($rootScope.filterMenuOpen ? 800 : 1035);
        let offset = _.max([(elemWidth - limit), 0]);
        return {x: (offset / 10) * index - textLength, y: elemWidth > limit + 200 ? -20 : -50};
    };

    $scope.getSegmentValuesSummary = function (segment) {
        return getSegmentValuesSummary(segment, filtersPartition);
    };

    $scope.onLogicOperandChange = function (segment, value, index) {
        const clonedAudienceSegment = _.cloneDeep($scope.audience_segment);
        // Because the opernad is changed mutably by the am-input, the prevProps and currentProps are the same, thus the widget does
        // not know he should update
        clonedAudienceSegment[index].immutableOperand = value;
        $scope.audience_segment = clonedAudienceSegment;
        refreshContext();
    };

    // When you switch to a new progam on audience_builder page.
    $scope.$watch('context.program', function () {
        if (context.current.audience_app) return;
        context.current.audience_app = {current_channel: $scope.audienceChannelsFilter.find(channel => channel.value == current_channel)};
        loadFromContext();
        getProgramAudience();
    });

    $scope.$watch('context.current.audience_app.current_channel', channelChanged);
    $scope.$watch('context.current.program', channelChanged);
    $($window).on("resize", recreateTooltips);
    let isRedirected = false;

    function channelChanged(newChannel = {}, oldChannel = {}) {
        if (_.isEmpty(newChannel) && _.isEmpty(oldChannel)) return;
        setDisabledFirstPartyModalValue(true, newChannel.value);
        $scope.channelAuthorized = false;
        if ($stateParams.channel) {
            const nextChannel = $stateParams.channel;
            $stateParams.channel = null;
            isRedirected = true;
            context.current.audience_app.current_channel = $scope.audienceChannelsFilter.find(channel => channel.value === nextChannel);
            return $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
        }

        $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
        channelAuthorizationCheck(newChannel).then((authorizationStatus) => {
            if (!authorizationStatus) return;
            if (!authorizationStatus.authorized) return handleChannelNotAuthorized(newChannel, oldChannel, authorizationStatus.error);
            $scope.channelAuthorized = true;
            if (newChannel.value == "articles" && abiPermissions.hasPermission('first party segments')) {
                $scope.reactSegmentModals['1st party'].firstPartyPromise =
                  audienceInsightsService.getFirstPartyDataByProgram(
                    context.program.id, (data) => setDisabledFirstPartyModalValue(_.isEmpty(data), newChannel.value));
            }
            if (['articles', 'linear_tv', 'smart_tv'].includes(newChannel.value)) {
                tvChannelToTvNetworksMetaDataPromise[newChannel.value] = audienceInsightsService.getTvNetworksMetaData(newChannel.value);
                tvGenresMetaDataPromise = audienceInsightsService.getTvGenresMetaData();
                tvChannelToTvShowsMetaDataPromise[newChannel.value] = audienceInsightsService.getTvShowsMetaData(newChannel.value);
            }
            if (newChannel.value === 'linear_tv') $scope.reactSegmentModals.linearTvCommercials.commercialsMetadataPromise = audienceInsightsService.getTvCommercialsMetaData(newChannel.value);
            if (newChannel.value === 'smart_tv') $scope.reactSegmentModals.smartTvCommercials.commercialsMetadataPromise = audienceInsightsService.getTvCommercialsMetaData(newChannel.value);
            if (newChannel.value == "linkedin") linkedinMetaDataPromise = audienceInsightsService.getLinkedinMetaData(context.current.u_id);
            //Will be used in the future when we will support more markets
            //if ($scope.isAudienceTargetVisible((newChannel ||{}).value)) $scope.marketsPromise = dspService.getMarkets('label', 'value', true);
            if (newChannel.value === oldChannel.value) return;	
            changeSegmentTypes(newChannel.value);
            updateModalLifestyles(newChannel);
            isRedirected ? isRedirected = false : refreshContext(oldChannel.value);
            loadFromContext();	
            getProgramAudience();
        })
    }

    function setDisabledFirstPartyModalValue(value, channel) {
        $scope.noFirstPartySegments = value;
        setDisabledSegmentTypes(channel);
    }

    function removeNoSegmentsTooltips() {
        $(".qtip.no-segments").remove();
    }

    function channelAuthorizationCheck(channel) {
        let tokenStatus = $stateParams.tokenStatus;
        $stateParams.tokenStatus = null;
        if (channel.value === 'linkedin' || (channel.value === 'linkedin' && tokenStatus)) return validateUserAuthentication(audienceInsightsService, context.current.u_id, tokenStatus, errorMgmt);
        return Promise.resolve({authorized: true});
    }

    
    function handleChannelNotAuthorized(newChannel, oldChannel, error) {
        console.info(`could not switch to ${newChannel.value} channel: ${error}`);
        context.current.audience_app.current_channel = $scope.audienceChannelsFilter.find(channel => channel.value === 'articles');
        $scope.channelFilterSideMenuSelectedChannel = context.current.audience_app.current_channel;
    }

    function disableEnableLinkedinSegments (type) {
        if (current_channel === "linkedin" && type) {
            let relevantSegmentType = linkedinSegmentTypes.find(segmentType => segmentType.value == type);
            Object.assign(relevantSegmentType, {isDisabled: !relevantSegmentType.isDisabled});
        } else {
            let segments = ((context.current.audience_app.linkedin || []).audience_segment || []).map(segment => segment.type);
            linkedinSegmentTypes.forEach(function (linkedinSegmentType) {
                Object.assign(linkedinSegmentType, {isDisabled: segments.includes(linkedinSegmentType.value)});
            });
        }
        changeSegmentTypes(current_channel);
    }

    function setDisabledSegmentTypes(channel) {
        $scope.segmentTypes = _.map($scope.segmentTypes, (segment, i) => {
            if (segment.value === '1st party' && $scope.noFirstPartySegments) return {...segment, isDisabled: true, tooltip: 'No segments applied for this program'};
            if (segment.value === '1st party') return _.omit(segment, ['isDisabled', 'tooltip']);
            if (comingSoonLifestyle && channel === 'articles' && segment.value === 'lifestyle') return {...segment, isDisabled: true, tagLabel: 'Coming soon'};
            return segment;
        });
        recreateTooltips();
    }

    function recreateTooltips() {
        removeNoSegmentsTooltips();
        _.each($scope.segmentTypes, function(segment, i) {
            if (segment.tooltip) {
                let offsets = $scope.getTooltipOffsets(i, segment.tooltip.length);
                let element = $("toggle-button." + _.snakeCase(segment.value));
                let options = {prerender: true,
                               position: {at: 'center',
                                          adjust: {x: offsets.x,
                                                   y: offsets.y}},
                               style: {classes: "no-segments common-tooltip-info"}};
                add_tooltip(element, 'info', options);
            }
        });
    }

    function debugOnlySegmentsOmitKeys(segments, debugUser) {
        return _.map(segments, (segment) => (
            debugUser && segment.debugOnly ? _.omit(segment, ['isDisabled', 'tagLabel']) : segment
        ));
    }

    function changeSegmentTypes(channel) {
        $scope.segmentTypes = channelToSegmentTypes[channel];
        $scope.segmentTypes = $scope.segmentTypes.filter((segmentType) => 
            segmentType.debugOnly ? (debugUser || segmentType.tagLabel)  : 
                (!segmentType.permission || abiPermissions.hasPermission(segmentType.permission))
        );
        $scope.segmentTypes = debugOnlySegmentsOmitKeys($scope.segmentTypes, debugUser);
        setDisabledSegmentTypes(channel);
    }

    function validateFirstPartySegmentContext () {
        if (abiPermissions.hasPermission('first party segments') || !_.has(context.current.audience_app, 'articles.audience_segment')) return;
        context.current.audience_app.articles.audience_segment = _.reject(context.current.audience_app.articles.audience_segment, ['type', '1st party']);
    }

    $scope.isAudienceTargetVisible = function(currentChannel) {
        return !!$scope.channelAuthorized && isAudienceTargetVisible(currentChannel, abiPermissions.hasPermission('audience activation'));
    }

    $scope.updateIsNewAudienceTarget = function(value) {
        context.current.audience_app[current_channel].audience_newTarget = $scope.isNewAudienceTarget = value;
    }

    $scope.isAudienceTargetDisabled = function(currentChannel) {
        if (!$scope.hasAudienceData) {
            $scope.audienceTargetDisabledTooltipText = "";
            return true
        } else if (_.some($scope.audience_segment, {type: 'tvShows'}) && currentChannel === 'articles') {
            $scope.audienceTargetDisabledTooltipText = "Activate an audience defined by online viewership isn't available for this channel";
            return true;
        }

        $scope.audienceTargetDisabledTooltipText = 'Save your audience before activating';
        return _.isEmpty($scope.audience_name) || $scope.audienceDirty();
    }

    $scope.updateHasAudienceData = function(hasData) {
        $scope.hasAudienceData = hasData;
    }
}

audienceBuilderModule.filter("trusted_html", ['$sce', function ($sce) {
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    }
}])
.component('reactAudiencePreviewWidget', react2angular(AudiencePreviewWidget, ['audienceDistributionService','isRequestCancelledService','audienceSegment','channel','userId','trackTooNarrow','onExploreAudience', 'onAudienceDataLoaded']));

export default audienceBuilderModule;