import React from 'react';
import PropTypes from 'prop-types';
import './WidgetOutlineView.scss';
import Spinner from '../Spinner/Spinner';
import classNames from 'classnames';
import WidgetPromiseStateManager from './WidgetPromiseStateManager';
import { omit } from 'lodash';

const AddSpinnerToMarkup = ({ markup, isSpinnerVisible, isSmallSpinner, className, style }) => (
  <div className={ classNames('widget-outline-view-component', className) } style={ style }>
    <Spinner show={ isSpinnerVisible } isSmall={ isSmallSpinner }></Spinner>
    { markup }
  </div> 
);

AddSpinnerToMarkup.propTypes = {
  markup: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  style: PropTypes.object,
  isSpinnerVisible: PropTypes.bool,
  isSmallSpinner: PropTypes.bool
};
    
AddSpinnerToMarkup.defaultProps = {
  isSmallSpinner: false
};

const WidgetOutlineView = (props) => (
  <WidgetPromiseStateManager
    { ...omit(props, ['className', 'style', 'isSmallSpinner']) }
    onRender={ (data, isPromiseStillLoading) => (
      <AddSpinnerToMarkup markup={ props.onRender(data) } isSpinnerVisible={ isPromiseStillLoading } { ...props } />
    ) }
    emptyStateRender={ (isPromiseStillLoading) => (
      <AddSpinnerToMarkup markup={ props.emptyStateRender() } isSpinnerVisible={ isPromiseStillLoading } { ...props } />
    ) }>
  </WidgetPromiseStateManager>
);
       
WidgetOutlineView.propTypes = {
  onRender: PropTypes.func.isRequired,
  emptyStateRender:  PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  isSmallSpinner: PropTypes.bool
};
  
WidgetOutlineView.defaultProps = {
  isSmallSpinner: false
};

export default WidgetOutlineView;