var d3 = require("d3"),
    add_tooltip = require('../tooltip/tooltip.js'),
    c = require("infra/utils/common");

module.exports = require("angular").module(__filename, [])
  .directive("amHeatmapChart", [function() {
    return {
      restrict: 'E',
      scope: {
        data: '<',
        colors: '<',
        topLabels: '<',
        additionalTopLabels: '<',
        sideLabels: '<',
        boldLabels: '<',
        numCols: '<',
        cardHeight: '<',
        cardWidth: '<',
        posLeft: '<',
        posTop: '<',
        posSideLabel: '<',
        paddingRight: '<',
        paddingLeft: '<'
      },
      link: function ($scope, $element, $attrs, $ctrl) {


        var GRID_SPACE = 1;

        var svg, g, container, height, width;

        $scope.$watchGroup(['[cardWidth,cardHeight]', 'sideLabels', 'data', 'colors'], function () {
          $scope.draw();
        });

        $scope.init = function () {
          container = d3.select($element[0]);
          svg = container.append('svg');
          g = svg.append('g');
        };

        function onCardMouseOver(card) {
          var element = d3.select(card);
          element.attr('x', parseInt(element.attr('x')) + 1)
            .attr('y', parseInt(element.attr('y')) + 1)
            .attr('width', $scope.cardWidth - 2)
            .attr('height', $scope.cardHeight - 2);
        }

        function onCardMouseOut(card) {
          var element = d3.select(card);
          element.attr('x', parseInt(element.attr('x')) - 1)
            .attr('y', parseInt(element.attr('y')) - 1)
            .attr('width', $scope.cardWidth)
            .attr('height', $scope.cardHeight);
        }

        function colNum(i){
          return $scope.numCols ? i % $scope.numCols : i;
        }
        
        function rowNum(i){
          return $scope.numCols ? Math.floor(i / $scope.numCols) : 0;
        }

        function addLabels() {
          if (!_.isEmpty($scope.sideLabels)) {
            var sideLabels = g.selectAll(".sideLabel")
              .data($scope.sideLabels)
              .enter().append("text")
              .text(function (d) {
                return d.hasOwnProperty("label") ? d.label : (c.isString(d) ? d : '');
              })
              .attr("title", function (d) {
                return d.hasOwnProperty("tooltip") ? d.tooltip : '';
              })
              .attr("x", c.isNumber($scope.posSideLabel) ? 0 - $scope.posSideLabel : -50)
              .attr("y", function (d, i) {
                return (i * ($scope.cardHeight + GRID_SPACE));
              })
              .style("text-anchor", "begin")
              .attr("transform", "translate(-6," + $scope.cardHeight / 1.5 + ")")
              .attr("class", "sideLabel mono axis" + ($scope.boldLabels ? " boldLabel" : ""));
          }

          if (!_.isEmpty($scope.topLabels)) {
            var topLabels = g.selectAll(".topLabel")
              .data($scope.topLabels)
              .enter().append("text")
              .text(function (d) {
                return d;
              })
              .attr("x", function (d, i) {
                return (i * ($scope.cardWidth + GRID_SPACE));
              })
              .attr("y", function (d, i) {
                return -5;
              })
              .style("text-anchor", "middle")
              .attr("transform", "translate(" + $scope.cardWidth / 2 + ", -6)")
              .attr("class", "topLabel mono axis" + ($scope.boldLabels ? " boldLabel" : ""));
          }

          if (!_.isEmpty($scope.additionalTopLabels)) {
            var addedTopLabels = g.selectAll(".addedTopLabel")
              .data($scope.additionalTopLabels)
              .enter().append("text")
              .text(function (d) {
                return d;
              })
              .attr("x", function (d, i) {
                return (i * ($scope.cardHeight + GRID_SPACE));
              })
              .attr("y", function (d, i) {
                return -17;
              })
              .style("text-anchor", "middle")
              .attr("transform", "translate(" + $scope.cardHeight / 2 + ", -6)")
              .attr("class", "addedTopLabel mono axis" + ($scope.boldLabels ? " boldLabel" : ""));
          }
        }

        function drawMainChart() {
          var cards = g.selectAll(".card")
            .data($scope.data);

          cards.enter().append("rect")
            .attr("x", function (d, i) {
              return (colNum(i) * ($scope.cardWidth + GRID_SPACE));
            })
            .attr("y", function (d, i) {
              return (rowNum(i) * ($scope.cardHeight + GRID_SPACE));
            })
            .attr("title", function (d) {
                if (_.has(d, 'title')) return d.title;
            })
            .attr("class", "card bordered heat-map")
            .attr("width", $scope.cardWidth)
            .attr("height", $scope.cardHeight)
            .on('mouseover', function(){
              onCardMouseOver(this);
            })
            .on('mouseout', function(){
              onCardMouseOut(this);
            });

          cards.transition().duration(1000)
            .attr("class", function (d,i) {
              var color = c.isArray($scope.colors) && $scope.colors.hasOwnProperty(i) ? $scope.colors[i] : '';
              return "card bordered heat-map block-" + color;
            });

          cards.exit().remove();
        }



        function addTooltips() {
          _.each(g.selectAll(".card, .sideLabel"), function (e, i) {
            add_tooltip(e, 'info', {style: {classes: 'common-tooltip-info'}});
          });
        }

        $scope.draw = function () {
          // remove all previous items before render
          g.selectAll('*').remove();

          if (!$scope.cardHeight || !$scope.cardWidth || !c.isArray($scope.data)) {
            return;
          }
          var margin_top = c.isNumber($scope.posTop) ? $scope.posTop : 0;
          var margin_left = c.isNumber($scope.posLeft) ? $scope.posLeft : 0;
          let padding_right = c.isNumber($scope.paddingRight) ? $scope.paddingRight : 0;
          let padding_left = c.isNumber($scope.paddingLeft) ? $scope.paddingLeft : 0;
          g.attr('transform', 'translate(' + margin_left + ',' + margin_top + ')');
          var numCols = $scope.numCols ? Math.min($scope.data.length,$scope.numCols) : $scope.data.length;
          var numRows = $scope.numCols ? Math.ceil($scope.data.length / $scope.numCols) : 1;
          width = numCols * ($scope.cardWidth + GRID_SPACE) + margin_left + padding_left;
          height = numRows * ($scope.cardHeight + GRID_SPACE) + margin_top;
          svg.attr('width', width)
            .attr('height', height);
          container.attr('style','width:' + (width + padding_right) + 'px; height:' + height + 'px');

          addLabels();
          drawMainChart();
          addTooltips();
        };

        $scope.init();
        $scope.draw();
      }
    }
  }]);
