module.exports = angular.module(__filename, []).service('tvShowsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(tvShows = {}, isNewSegment, disableGeo, channel, params = {}) {
        return ModalService.showModal({
            template: require('./tv-shows-modal.html'),
            inputs: {tvShows: tvShows, params: params, isNewSegment: isNewSegment, channel: channel},
            controller: ['$scope', 'close', 'tvShows', 'TermsMold', 'params', 'isNewSegment', 'channel', TvShowsModalCtrl]
        });
    }

    function TvShowsModalCtrl($scope, close, tvShows, TermsMold, params, isNewSegment, channel) {
        $scope.isNewSegment = isNewSegment;
        $scope.tvShows = _.extend({networks: [], genres: [], tv: []}, tvShows);
        $scope.close = close;
        $scope.termsMold = new TermsMold();
        $scope.networksData = [];
        $scope.genresData = [];
        $scope.tvData = [];
        $scope.tvNetworksMetaDataPromise = params.tvNetworksMetaDataPromise.then(res => $scope.networksData = _.map(res.data.networks, network => ({label: network.title, value: network.id})));
        $scope.tvGenresMetaDataPromise = params.tvGenresMetaDataPromise.then(res => $scope.genresData = _.map(res.genres, genre => ({label: genre, value: genre})));
        $scope.tvShowsMetaDataPromise = params.tvShowsMetaDataPromise.then(res => $scope.tvData = _.map(res.data.shows, show => ({label: show.title, value: show.id})));
        $scope.loadingData = true;
        $scope.tvType = 'online';
        if(channel == 'linear_tv') $scope.tvType = 'Linear';
        if(channel == 'smart_tv') $scope.tvType = 'Smart';

        $scope.onNetworkChange = networks => {$scope.tvShows.networks = [...networks]};
        $scope.onGenreChange = genres => {$scope.tvShows.genres = [...genres]};
        $scope.onTvChange = tv => {$scope.tvShows.tv = [...tv]};

        const isAllValuesSelected = (selectedValues, values) => !selectedValues.length || selectedValues.length === values.length;

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.tvShows));
        };

        $scope.networksSummaryTextBuilder = (selectedValues, values) => {
            if (isAllValuesSelected(selectedValues, values)) return 'All networks';
            return summaryTextBuilder(selectedValues);
        }

        $scope.genresSummaryTextBuilder = (selectedValues, values) => {
            if (isAllValuesSelected(selectedValues, values)) return 'All genres';
            return summaryTextBuilder(selectedValues);
        }

        $scope.tvSummaryTextBuilder = (selectedValues, values) => {
            if (isAllValuesSelected(selectedValues, values)) return 'All shows';
            return summaryTextBuilder(selectedValues);
        }

        function summaryTextBuilder(selectedValues) {
            if (selectedValues.length === 1) return selectedValues[0]['label'];
            return selectedValues.map((value) => value.label).join(', ');
        }

        function removeEmptyFilters(tvShows) {
            var results = {};
            if ((tvShows.networks || []).length > 0 && (tvShows.networks || []).length !== $scope.networksData.length) results.networks = tvShows.networks;
            if ((tvShows.genres || []).length > 0 && (tvShows.genres || []).length !== $scope.genresData.length) results.genres = tvShows.genres;
            if ((tvShows.tv || []).length > 0 && (tvShows.tv || []) !== $scope.tvData.length) results.tv = tvShows.tv;
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters(this.tvShows), {type: "tvShows", operand: this.tvShows.operand}));
        }
    }
}]);