var mixPanel = require('./mixpanel-base');

module.exports = angular.module(__filename, [
    mixPanel.name
]).service('mixpanelInsights', ['$state', '$window', 'context', 'mixpanelService', function ($state, $window, context, mixpanelService) {

    var appName = 'Insights';
    var widgetName = mixpanelService.extractWidget($state.current);
    var associations = [];
    var snapshot = context.current;
    var lastSearch = angular.copy(insights());
    var lastTerms = lastSearch.terms;
    var associationsMeasure = {"sov": "SOV", "relative": "Strength", "absolute": "Index"};

    function extractTimeframe(snapshot) {
        return mixpanelService.buildTimeframe(snapshot.insights_timeframe, 'YYYY-MM-DD');
    }

    function insights() {
        var result = {};
        var allTerms = mixpanelService.buildTerms(snapshot.terms);
        result.widget = widgetName;
        result.terms = allTerms.terms.concat(allTerms.bl_terms);
        result.refinedTerms = allTerms.bl_terms.length > 0;
        result.timeframe = extractTimeframe(snapshot);
        result.topics = _.map(snapshot.topics, 'name');
        result.geo = _.map(snapshot.geo, 'label');
        result.channels = snapshot.insightsChannels.label;
        angular.extend(result, mixpanelService.buildProgram(context));
        return result;
    }

    function trackPageView(widget) {
        mixpanelService.trackPageView(appName, widget);
        widgetName = widget;
    }

    function trackExport() {
        mixpanelService.trackExport(appName, widgetName);
    }

    function trackInsightsSearch() {
        var props = angular.copy(insights());
        if (angular.equals(props, lastSearch)) return;
        lastSearch = angular.copy(props);
        props.fullSearch = angular.copy(insights());
        mixpanelService.sendToMixpanel('Insights - Search', props);
        if (angular.equals(props.terms, lastTerms)) return;
        mixpanelService.trackSearch('Insights', widgetName, props.terms, props.refinedTerms);
        lastTerms = props.terms;
    }

    function trackAssociations(newAssociations) {
        associations = _.map(newAssociations, 'text');
        var association_mode = associationsMeasure[snapshot.measure];
        var props = {
            'Tested Associations': associations,
            'Association Mode': association_mode,
            'Search': insights()
        };
        mixpanelService.sendToMixpanel('Insights - Tested Associations', props);
    }

    return {
        trackPageView: trackPageView,
        trackExport: trackExport,
        trackInsightsSearch: trackInsightsSearch,
        trackAssociations: trackAssociations
    }
}]);
