
module.exports = angular.module(__filename, [])
    .provider("user", ["authenticationConfig", function (authenticationConfig) {
        let user = {};
        let userLoadedDefer = null;
        authenticationConfig.doAfterAuthentication.push(["authInfo", "$q", function (authInfo, $q) {
            angular.extend(user, authInfo);
            if (!userLoadedDefer) {
                userLoadedDefer = $q.defer();
            }
            userLoadedDefer.resolve(user);
            return userLoadedDefer.promise;
        }]);
        this.$get = ["$q", function ($q) {
            if (!userLoadedDefer) {
                userLoadedDefer = $q.defer();
            }
            Object.defineProperty(user, "$load", {
                get: function () {
                    return userLoadedDefer.promise;
                },
                enumerable: false
            });
            return user;
        }];
    }]
);
