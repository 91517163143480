import AbiManagementApi from '../api/AbiManagementApi';
import AudienceProfilerApi from '../api/AudienceProfilerApi';
import { executeHttpRequest } from '../infra/HttpClient';

export function getLinkedinSegmentDemographicsDistribution(audience, userId) {
  return executeHttpRequest(AbiManagementApi.getLinkedinSegmentDemographicsDistribution(audience, userId), { displayRedBannerOnError: false });
}

export function getSegmentIds(audience) {
  return executeHttpRequest(AudienceProfilerApi.getSegmentIds(audience));
}

export function createAudienceTargetTaxonomy(segmentId, channel, marketId) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetTaxonomy(segmentId, channel, marketId));
}

export function createAudienceTargetUserList(segmentId, channel, ids) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetUserList(segmentId, channel, ids));
}

export function getAudienceTaxonomyCategory(segmentId) {
  return executeHttpRequest(AbiManagementApi.getAudienceTaxonomyCategory(segmentId));
}