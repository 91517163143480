import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Spinner.scss';
import { omitBy } from 'lodash';

const Spinner = ({ show, isSmall, leftStyle, topStyle }) => {
  if (!show) return null;
  const style = omitBy({ left: leftStyle, marginLeft: (leftStyle ? 0 : null), top: topStyle,  marginTop: (topStyle ? 0 : null) }, (property) => property === null || property === undefined); 

  return ( 
    <div className="spinner-component"> 
      <div className="blocking-overlay"></div>
      <div className={ classNames('loader', { 'small-loader': isSmall }) } style={ style }>&nbsp;</div>
    </div>  
  );
};

Spinner.propTypes = {
  show: PropTypes.bool,
  isSmall: PropTypes.bool,
  leftStyle: PropTypes.string,
  topStyle: PropTypes.string
};  

Spinner.defaultProps = {
  isSmall: false    
};

export default Spinner;
