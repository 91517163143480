"use strict";
 
createTargetCtrl.$inject = ['$scope', '$state', '$http', '$q', 'TARGET_CONSTS', 'context', 'notificator', 'TargetsCommon', 'params', 'close'];
function createTargetCtrl($scope,  $state, $http, $q, TARGET_CONSTS, context, notificator, TargetsCommon, params, close) {

    $scope.createTargetCtrl = this;

    $scope.name = '';
    
    $scope.init = function () {
        setTimeout(function(){$("#target-name").focus();},300);
    };

    $scope.showTargetLoading = ()=>{
        return $scope.targetLoading && 
            params.target_type==
            TARGET_CONSTS.INVENTORY_LISTS_TARGET_TYPE;
    }

    this.close = function () {
        $scope.targetLoading = false;
        close({});
    };

    this.checkEnter = function($event){

        if($event.keyCode==13){
            this.createTarget();
        }
    }

    this.createTarget = function(){

        params.name = $("#target-name").val().trim();
        if(!params.name){
            return;
        }
        params.program_id = context.current.p_id+"";

        //validation
        let nameLength = 50, nameMsg = 'target name exceeded the max length (1-'+nameLength+')';
        if (params.name.length > nameLength) {
            notificator.error({body: nameMsg});
            return;
        }
    
        params.action = 'createNewTarget';
        TargetsCommon.TargetData.targetToServer({target: params});

        let r = $scope.$root,
            url = TargetsCommon.targetURL(params);

        $scope.targetLoading = true;
        $http.post(url, params).then((res)=>{
            notificator.success({body: 'Target "' + params.name + '" created'});
            r.$broadcast('updateTargets', {target: res.data, action: 'create-target'});
            TargetsCommon.mixpanelTargets.trackNewTarget(res.data);
            this.close();
        }, (res)=>{
            let error = res.data.errors ? _.castArray(res.data.errors).join(" ") : 'Error creating target.';
            console.log(error);
            notificator.error({body: error});
            this.close();
        });    
    }

    $scope.init();
}

module.exports = createTargetCtrl;
 
