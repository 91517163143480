require("../../data/audience-insights-service"),
require("../../infra/mixpanel/mixpanel-audience")

module.exports = angular.module(__filename, []).directive("audiencePreviewWidget", [
        'audienceInsightsService', 'mixpanelAudience', '$state', 'context', 
        function (audienceInsightsService, mixpanelAudience, $state, context) {
        return {
            restrict: 'E',
            scope: {
                audienceSegment: "=",
                channel: "=",
                onAudienceDataLoaded: "="
            },
            template: require("./audience-preview-widget.html"),
            link: function ($scope) {
                $scope.audienceDistributionService = audienceInsightsService.getDemographicsDataForPreviewBySegment;
                $scope.isRequestCancelledService = audienceInsightsService.isRequestCancelled;
                $scope.userId = context.current.u_id
            
                this.mixpanelAudience = mixpanelAudience;
                this.context = context;
            
                $scope.exploreAudience = function() {
                    context.current.audience_segment_target_ratio = null;
                    mixpanelAudience.trackExploreAudience();
                    $state.go('audience-explorer');
                };
            
                $scope.trackTooNarrow=() => {
                    const snapshot = angular.copy(this.context.current);
                    this.mixpanelAudience.trackTooNarrow(snapshot);
                }
                
            }
        }
    }]);