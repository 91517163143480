import 'angular-ui-router';

const topbarTmpl = require("./topbar.html"),
    timeFrameFilter = require("./timeframe/timeframe"),
    contextModule = require("infra/context/context"),
    mixpanel = require("infra/mixpanel/mixpanel-base");

module.exports = angular.module(__filename, [
    timeFrameFilter.name,
    contextModule.name,
    'ui.router',
    require("data/discovery-service").name
]).directive("topbar", ["context", "$state", '$rootScope', 'abiPermissions', 'mixpanelService', 'TIMES',
    function (context, $state, $rootScope, abiPermissions, mixpanelService, TIMES) {
        return {
            restrict: "E",
            template: topbarTmpl,
            link: function (scope) {
                const debugUser = $rootScope.user.userType === 'debug';
                
                scope.context = context;
                scope.$state = $state;

                scope.times = [];
                scope.minDate = null;
                scope.subtractDay = false;

                scope.hasActiveFilter = function () {
                    const state = $state.current;
                    const display = state.display.toLowerCase();
                    return (['dashboard', 'discovery', 'insights'].includes(display) || state.name.startsWith('warroom')) && $rootScope.hasActiveFilter(state.name);
                };

                scope.checkChangeProgram = function (e) {
                    //block update when warroom loads
                    if (scope.isWarroomRealtime() && $rootScope.warroomRealtime.loading) {
                        e.stopPropagation();
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    }
                };

                scope.clickApply = function () {
                    scope.$broadcast('click_apply');
                };

                scope.toggleTimeMode = function () {
                    scope.$broadcast('toggle_time_mode');
                };

                scope.setTimeframe = function () {
                    scope.$broadcast('set_time_frame');
                };

                scope.setTimezone = function () {
                    scope.$broadcast('set_time_zone');
                };

                scope.isWarroomRealtime = function () {
                    return $state.current.name.toLowerCase().includes('warroom.realtime');
                };

                scope.trackHelpCenter = function () {
                    mixpanelService.trackHelpCenter($rootScope.helpPage);
                };

                scope.hasPermission = abiPermissions.hasPermission;

                scope.hideFiltersMenu = function () {
                    const name = $state.current.name;
                    if (!name.startsWith('audience-')) return false;

                    let hasAudienceChannelPermissions = $rootScope.audienceChannelsFilter.some((channel) => channel.permission && abiPermissions.hasPermission(channel.permission) && (channel.debugOnly ? debugUser : true));
                    const hideFilterMenu = !hasAudienceChannelPermissions || name != 'audience-builder';
                    return hideFilterMenu;
                };

                scope.hideHelp = function () {
                    const state = $state.current;
                    return !scope.hasPermission(['help center'])
                        || state.name.startsWith('audience-')
                        || (state.name.startsWith('settings.') && !$state.is('settings.programs'))
                        || $state.is('insights.landscape')
                        || $state.is('insights.channels');
                };

                scope.hideExport = function () {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                           || (state.name.startsWith('settings.') && state.name !== 'settings.users' && state.name !== 'settings.targets')
                           || state.name.indexOf('warroom') != -1
                           || state.name === 'audience-builder'
                           || state.name === 'alerts-v2'
                           || !scope.hasPermission(['export']);
                };

                scope.hideTimeframe = function () {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                        || state.name.startsWith('audience-')
                        || state.name.startsWith('settings.')
                        || state.name.startsWith('alerts')
                        || (state.name.toLowerCase() === 'warroom.realtime' && $rootScope.warroomRealtime.mode == 'realtime');
                };

                scope.showDatePicker = function () {
                    const state = $state.current;
                    return (state.display.toLowerCase() === 'discovery' && scope.hasPermission(['discovery custom timeframe']))
                        || state.display.toLowerCase() === 'insights'
                        || state.display.toLowerCase() === 'real time'
                        || state.name.toLowerCase() === 'warroom.realtime';
                };

                scope.getTimeframeMold = function () {
                    const state = $state.current;
                    if (state.name.indexOf('warroom') !== -1)
                        return context.current._warroom_timeframe_mold;
                    if (state.display.toLowerCase() === 'discovery')
                        return context.current._discovery_timeframe_mold;
                    if (state.display.toLowerCase() === 'insights')
                        return context.current._insights_timeframe_mold;
                    return null;
                };

                scope.$on('timeframe-vars-update', function(event, times, minDate, subtractDay, fullWeeksDetection, noMaxTimeframeNotification) {
                    if (times) {
                        times = TIMES.getTimes(times);
                        if (!_.isEqual(times, scope.times)) {
                            scope.times = times;
                        }
                    }
                    scope.minDate = minDate;
                    scope.subtractDay = !!subtractDay;
                    scope.fullWeeksDetection = !!fullWeeksDetection;
                    scope.noMaxTimeframeNotification = !!noMaxTimeframeNotification;
                });
            }
        }
    }
]);
