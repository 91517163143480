import config from 'infra/config';
import c from 'infra/utils/common';

var CONSUMPTION_GEO_API = config.SEARCH_API + '/consumption_geo';

module.exports = angular.module(__filename, [])
    .service("consumptionGeoService", ['baseInsightsService', function(baseInsightsService) {
        const MIN_OPACITY = 0.4;
        const MAX_OPACITY = 1.5;
        const formatFormula = {
          distribution: (score) => score * 100,
          skew: (score) => score,
          consumption: (score) => score * 100,
        };

        return {
            get: getConsumptionGeo
        };

        function getConsumptionGeo(params, scaleText = null) {
            var termMap = {};
            var requestParams = baseInsightsService.buildParams(termMap, params, false, true);
            // Add scale parameter
            requestParams.scale = params.scale;
            requestParams.measure = params.measure;
            requestParams.formula = params.formula;
            requestParams.zone_ids = params.zoneIds;
            
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");

            return baseInsightsService.postAsyncData(CONSUMPTION_GEO_API, requestParams)
              .then(({ data }) => {
                let allTerms = Object.keys(termMap);
                _.each(data.plot, (zone, zone_id) =>
                  _.each(zone.values, value => {
                      if (_.isNumber(value.score) && value.score != 0) {
                        allTerms = _.reject(allTerms, t => t === value.term);
                      }
                  })
                );
                var empty = _.isEmpty(data) ? [] : allTerms ;
                baseInsightsService.notifyInsufficient(empty);
                return Object.keys(termMap).length == allTerms.length ? Promise.reject(data) : data;
              }).then(data => buildResults(data, termMap, params, scaleText))
                .catch(baseInsightsService.handleError);
        }

        function getSelectedZonesText(scaleText) {
          if (!scaleText) return null;
          const scaleTextCase = (scaleText.toUpperCase() === scaleText) ? scaleText : scaleText.toLowerCase();
          const pluralScaleText = (scaleTextCase[scaleTextCase.length -1] === 's') ? scaleTextCase : `${scaleTextCase}s`;
          return `Selected ${pluralScaleText}`;
        }

        function removeDuplicateMultiSelectedZones(plot, selectedZoneIds, selectedZonesText) {
          if (_.isEmpty(selectedZoneIds) || selectedZoneIds.length === 1) return plot;

          const selectedZoneNames = [];
          let selectedZoneIdsWithData = [ ...selectedZoneIds ];
          selectedZoneIds.forEach((zoneId) => {
            if (!plot[zoneId]) {
              selectedZoneIdsWithData = _.without(selectedZoneIdsWithData, zoneId);
            } else {
              selectedZoneNames.push(_.capitalize((plot[zoneId] || {}).name));
            }
          });
          const selectedZoneNamesText = _.compact(selectedZoneNames).sort().join(', ');
          const zoneIdsToRemove = selectedZoneIdsWithData.slice(1, selectedZoneIdsWithData.length);
          const zoneIdToUpdate = selectedZoneIdsWithData[0];
          const newPlot = _.omit(plot, zoneIdsToRemove);

          if (zoneIdToUpdate && newPlot[zoneIdToUpdate]) {
            newPlot[zoneIdToUpdate].name = `${selectedZonesText}: ${selectedZoneNamesText}`;
            newPlot[zoneIdToUpdate].selectedZonesLabel = selectedZonesText;
          }

          return newPlot;
        }

        function buildResults({plot, examples}, termsMaps, params, scaleText) {
          if (_.isEmpty(plot)) return {}
          const max = formatFormula[params.formula](_.maxBy(_.flatten(_.map(_.values(plot), 'values')), 'score').score);
          const avg = formatFormula[params.formula](_.sumBy(_.flatten(_.map(_.values(plot), 'values')), 'score') / _.flatten(_.values(plot)).length);
          const selectedZonesText = getSelectedZonesText(scaleText);
          const chartDataPlot = removeDuplicateMultiSelectedZones(plot, params.zoneIds, selectedZonesText);
          const chartData  = _.chain(chartDataPlot).map((zoneData, zoneId) => {
            const zoneName = _.capitalize(zoneData.name);
            return {
              id: zoneId,
              maxStrength: formatFormula[params.formula](_.maxBy(zoneData.values, 'score').score),
              label: zoneName,
              values: _.map(zoneData.values, (v) => ({
                class: termsMaps[v["term"]].class,
                value: formatFormula[params.formula](v.score),
                id: termsMaps[v["term"]].id || termsMaps[v["term"]].text
              })),
              selectedZonesLabel: zoneData.selectedZonesLabel || zoneName
            }
          }).orderBy('maxStrength', 'desc').value();
          return {chartData, mapData: buildMapData(plot, termsMaps, params, selectedZonesText), examples, max, avg};
        }

        function buildMapData(plot, termsMaps, params, selectedZonesText) {
          const termsLength = Object.keys(termsMaps).length;
          const newPlot = _.chain(plot).map((v,k) => {
            const maxScore = _.maxBy(v.values, 'score')
            const terms = _.values(_.omitBy(v.values, maxScore))
            return Object.assign({}, {key: k}, maxScore, {terms: terms})
          }).groupBy('term').value();
          const opacityDistributionFormula = (score, min, max, maxOpacity) => ((score - min) / (max - min) * (maxOpacity - MIN_OPACITY)) + MIN_OPACITY
          const opacitySkewFormula = (score, min, max) => {
            if (score <= 1) {
              return opacityDistributionFormula(score, min, 1, 1);
            }

            return (((score - 1) / (max - 1)) * 0.5) + 1;
          };

          const opacityFormula = (score, min, max) => {
            if ( termsLength > 2 || min == max) return 1;
            return params.formula == 'skew' && termsLength == 1 ? opacitySkewFormula(score, min, max) : opacityDistributionFormula(score, min, max, MAX_OPACITY)
          };

          return _.map(newPlot, values => {
            const max = _.maxBy(_.values(values), 'score').score;
            const min = _.minBy(_.values(values), 'score').score;
            const mapData = _.mapValues(values, zone => {
              const multiZoneText = (!_.isEmpty(params.zoneIds) && (params.zoneIds.length > 1) && params.zoneIds.includes(zone.key)) ? { multiZoneText: selectedZonesText } : {};
              return Object.assign(
                {},
                zone,
                { classColor: termsMaps[zone["term"]].class },
                { opacity: opacityFormula(zone.score, min, max) },
                multiZoneText
              )
              }
            );

            return {mapData, max, min};
          });
        }

    }]);
