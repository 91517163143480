import React from 'react';
import './BarChart.scss';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import { getAllObjectsByMaxValue } from '../../../utils/ArrayUtils';
import { ResponsiveContainer, BarChart as RechartsBarChart, XAxis, Bar, Cell, ReferenceLine, LabelList } from 'recharts';

const COMMON_STYLE_OPTIONS = {
  barColor: '#cacaca',
  barLabelColor: '#cacaca',
  barHighlightedLabelColor: 'white',
  axisLineColor: '#3e4347',
  axisLabelColor: '#cacaca',
  axisHighlightedLabelColor: 'white',
  referenceLineColor: '#444'
};

const THEMES = {
  default: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 20,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    barHighlightedColor: '#44b0c7',
    barHighlightedLabelFont: { fontFamily: 'Helvetica Bold', fontSize: 22 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 14 }
  },
  dashboard: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 20,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    barHighlightedColor: 'white',
    barHighlightedLabelFont: { fontFamily: 'Helvetica Bold', fontSize: 16 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 14 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 14 }
  },
  preview: {
    ...COMMON_STYLE_OPTIONS,
    barWidth: 13,
    barLabelFont: { fontFamily: 'Helvetica', fontSize: 12 },
    barHighlightedColor: 'white',
    barHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 13 },
    axisLabelFont: { fontFamily: 'Helvetica', fontSize: 12 },
    axisHighlightedLabelFont: { fontFamily: 'Helvetica', fontSize: 12 }
  }
};

const BarChart = ({ data, theme, styleOptions, shouldHighlightMax, referenceLine, width, height }) => {
  const themeStyle = { ...THEMES[theme], ...styleOptions };
  const highlightedObjects = (shouldHighlightMax && getAllObjectsByMaxValue(data, 'value')) || [];
  const customizedLabel = (
    <CustomizedLabel
      highlightedObjects={ highlightedObjects }
      shouldHighlightMax={ shouldHighlightMax }
      barWidth={ themeStyle.barWidth }
      barLabelFont={ themeStyle.barLabelFont }
      barHighlightedLabelFont={ themeStyle.barHighlightedLabelFont }
      barLabelColor={ themeStyle.barLabelColor }
      barHighlightedLabelColor={ themeStyle.barHighlightedLabelColor }
    />
  );
  const customizedTick = (
    <CustomizedTick 
      highlightedObjects={ highlightedObjects }
      shouldHighlightMax={ shouldHighlightMax }
      axisLabelFont={ themeStyle.axisLabelFont }
      axisHighlightedLabelFont={ themeStyle.axisHighlightedLabelFont }
      axisLabelColor={ themeStyle.axisLabelColor }
      axisHighlightedLabelColor={ themeStyle.axisHighlightedLabelColor }
    />
  );

  return (
    <div className="bar-chart-component">
      <ResponsiveContainer width={ width } height={ height }>
        <RechartsBarChart 
          data={ data }
          margin={ { top: 30, right: 0, left: 0, bottom: 10 } }>
          <XAxis dataKey="label" stroke={ themeStyle.axisLineColor } tick={ customizedTick } minTickGap={ 0 } tickLine={ false } interval="preserveStartEnd" />
          { ((referenceLine || referenceLine === 0) && referenceLine >= 0) &&
            <ReferenceLine y={ referenceLine } stroke={ themeStyle.referenceLineColor } strokeWidth={ 1 } />
          }
          <Bar dataKey="value" barSize={ themeStyle.barWidth } isAnimationActive={ false } minPointSize={ 1 }>
            <LabelList dataKey="displayValue" content={ customizedLabel } />
            {
              data.map((entry, i) => (
                <Cell 
                  key={ `cell-${i}` }
                  fill={ shouldHighlightMax && some(highlightedObjects, data[i]) ? themeStyle.barHighlightedColor : themeStyle.barColor }
                />
              ))
            }
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  ); 
};

const CustomizedLabel = ({ x, y, value, highlightedObjects, shouldHighlightMax, barWidth, barLabelFont, barHighlightedLabelFont, barLabelColor, barHighlightedLabelColor }) => {
  const displayValue = value;
  const [labelFont, fillColor] = (shouldHighlightMax && some(highlightedObjects, { displayValue })) ? 
    [barHighlightedLabelFont, barHighlightedLabelColor] : 
    [barLabelFont, barLabelColor];

  return (
    <text
      { ...labelFont }
      x={ x + (barWidth / 2) }
      y={ y }
      dy="-.7em"
      fill={ fillColor }
      textAnchor="middle">
      { displayValue }
    </text>
  );
};

const CustomizedTick = ({ x, y, payload, highlightedObjects, shouldHighlightMax, axisLabelFont, axisHighlightedLabelFont, axisLabelColor, axisHighlightedLabelColor }) => {
  const label = payload.value;
  const [labelFont, fillColor] = (shouldHighlightMax && some(highlightedObjects, { label })) ? 
    [axisHighlightedLabelFont, axisHighlightedLabelColor] : 
    [axisLabelFont, axisLabelColor];

  return (
    <text
      { ...labelFont }
      x={ x }
      y={ y + 15 }
      dy="-.7em"
      fill={ fillColor }
      alignmentBaseline="hanging"
      textAnchor="middle">
      { label }
    </text>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    displayValue: PropTypes.string
  })),
  theme: PropTypes.oneOf(Object.keys(THEMES)),
  styleOptions: PropTypes.shape({
    barWidth: PropTypes.number,
    barColor: PropTypes.string,
    barLabelColor: PropTypes.string,
    barLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    barHighlightedColor: PropTypes.string,
    barHighlightedLabelColor: PropTypes.string,
    barHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisLineColor: PropTypes.string,
    axisLabelColor: PropTypes.string,
    axisLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
    axisHighlightedLabelColor: PropTypes.string,
    referenceLineColor: PropTypes.string
  }),
  shouldHighlightMax: PropTypes.bool,
  referenceLine: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BarChart.defaultProps = {
  data: [],
  theme: 'default',
  styleOptions: {},
  shouldHighlightMax: true,
  width: '100%',
  height: '100%'
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string,
  highlightedObjects: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    displayValue: PropTypes.string
  })),
  shouldHighlightMax: PropTypes.bool,
  barWidth: PropTypes.number,
  barLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  barHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  barLabelColor: PropTypes.string,
  barHighlightedLabelColor: PropTypes.string
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    label: PropTypes.string
  }),
  highlightedObjects: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    displayValue: PropTypes.string
  })),
  shouldHighlightMax: PropTypes.bool,
  barWidth: PropTypes.number,
  axisLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  axisHighlightedLabelFont: PropTypes.shape({ fontFamily: PropTypes.string, fontSize: PropTypes.number }),
  axisLabelColor: PropTypes.string,
  axisHighlightedLabelColor: PropTypes.string
};

export { BarChart, CustomizedLabel, CustomizedTick };
