'use strict';

module.exports = angular.module(__filename, [])
    .service('tvExportService', ['Excel', 'segmentInterestExportService',
        function(Excel, segmentInterestExportService) {
            function createSummaryWorksheet(workbook, formatter, segment, demographicsData, channel) {
                const worksheet = workbook.createWorksheet({name: 'Summary'});
                worksheet.setData(segmentInterestExportService.getSegmentSummaryData(segment, demographicsData, channel, formatter));
                worksheet.setColumns([{width: 25}, {width: 25}, {width: 18}, {width: 18}, {width: 25}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }


            function createTvShowsWorksheet(workbook, interests, formatter, title) {
                const worksheet = workbook.createWorksheet({name: title});

                const titleLine = [
                    formatter(title.slice(0, title.lastIndexOf('s')), 'bold'),
                    formatter('Consumption In Audience', 'bold'),
                    formatter('Audience Portion In Consumption', 'bold'),
                    formatter('Audience Skew', 'bold'),
                    formatter('Significance Score', 'bold')
                ];

                if (title === 'TV Shows') titleLine.push(formatter('Genre', 'bold'), formatter('Network', 'bold'));

                let data = interests.map(interest => {
                    const arr = [
                        interest.title,
                        formatter(interest['interest-portion'] / 100, 'percent'),
                        formatter(interest['segment-portion'] / 100, 'percent'),
                        formatter(interest['uniqueness-index'], 'numeric'),
                        formatter(interest['score'], 'numeric')
                    ];
                    if (title === 'TV Shows') arr.push(formatter(interest['genre'], 'text'), formatter(interest['network'], 'text'));
                    return arr;
                });

                data.unshift(titleLine);
                worksheet.setData(data);
                worksheet.setColumns([{width: 20}, {width: 18}, {width: 18}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }

            const fileNamer = segmentInterestExportService.momentBasedFileNamerGen('TV Shows');

            function exportToExcel(data, demographicsData, segment, withNetworks) {
                const workbook = Excel.builder.createWorkbook();
                const formatter = Excel.formater(workbook);

                createSummaryWorksheet(workbook, formatter, segment, demographicsData);
                createTvShowsWorksheet(workbook, data.tv, formatter, 'TV Shows');
                createTvShowsWorksheet(workbook, data.genres, formatter, 'Genres');
                if (withNetworks) createTvShowsWorksheet(workbook, data.networks, formatter, 'Networks');

                workbook.fileName = fileNamer();
                return workbook;
            }

            function downloadExcel(excel) {
                return segmentInterestExportService.excelDownloader(excel, fileNamer);
            }

            return {exportToExcel, downloadExcel};
        }
    ]);
