
import React from 'react';
import BlacklistActions from './BlacklistActions';
import config from 'infra/config';

var warVars   =   require("pages/warroom/warroom-vars")
  ,associations = require("pages/warroom/warroom-associations")
  ,_          =   require("lodash");

var trendingModule = angular.module(__filename,[warVars.name]);

var refreshTrendingTimer = null;

trendingModule.stateConfig = {
  name:'trending',
  url:'/trending',
  display: 'Top Trending',
  needPermission: true,
  permissionName: 'top trending',
  displayTab: 'War Room',
  template: require('pages/warroom-trending/warroom-trending.html'),
  controller: TrendingCtrl,
  data: {
    permissions: ['war room', 'top trending']
  },
  onExit: function(){
   clearInterval(refreshTrendingTimer);
  }
};

var queries = {};

function generalQuery(settings) {
    
  var showVelocity = settings.id.indexOf("trending") !=-1;

  var columns = [
      {id: 'text', title: settings.td_title, template: 'partials/warroom-trending-text.partial.html'}];

  if(showVelocity){
    columns.push({id: 'velocity', title: 'velocity', template: 'partials/warroom-trending-velocity.partial.html'});
  }

  columns.push({id: 'volume', title: 'count'});
  columns.push({id: 'actions', title: '', template: 'partials/warroom-trending-actions.partial.html', sort:false});



  var d = {
    title: settings.title,
    dataArray: [],
    columns: columns,
    order: 'volume',
    search: '',
    loading: false,
    reverse: true,
    showVelocity: showVelocity,
    maxMin: true,
    fixedSize: true
  };

  return _.defaults(settings, d);
};


var specificQueries = [
        {id: 'all', url: '/top'},

        {title: 'Trending', td_title:'Person', id: 'person_trending'},
        {title: 'Trending', td_title:'General', id: 'general_trending'},
        {title: 'Trending', td_title:'Hashtags', id: 'hashtag_trending'},

        {title: 'Top',      td_title:'General',  id: 'general_volume'},
        {title: 'Top',      td_title:'Person',   id: 'person_volume'},
        {title: 'Top',      td_title:'Hashtags', id: 'hashtag_volume'}
];

_.each(specificQueries, function(t) {
  queries[t.id] = generalQuery(t);   
});
specificQueries = null;

const trendTextColumn=(rowData)=> (
  (rowData.id==='-1' ? '#' : '') + rowData.text
);



TrendingCtrl.$inject = ["$scope", "$http", "$state", "Excel", "$q", "$timeout", "context", "notificator"];

function TrendingCtrl($scope, $http, $state, Excel, $q, $timeout, context, notificator){
  $scope.$root.helpPage = '6_3_Following_an_Event%E2%80%99s_Trends';
  $scope.queries=queries;
  associations.$http = $http;

  $scope.pausePlayValues = [
    {value:true, icon:'play', tooltip: 'Click to resume top trending'},
    {value:false, icon:'pause', tooltip: 'Click to pause top trending'}
  ];


  var mins = localStorage["warroom_minutes"];
  if(!mins || mins>=180){ //dont save 3+ hours
    mins=15;
  }


  $scope.time = {
      play: true,
      minutes: mins,
      refreshTrendingFreq: 60*1000
  };

  $scope.lastUpdate = new Date();

  $scope.selected = {
      group: null,
      ass: null,
  };

  $scope.loading=true;
  $scope.firstLoading=true;
  $scope.searchT = null;
  $scope.showGroupsDone = true;
     
  $scope.groups =[];
  $scope.count = 30;
  $scope.phrase = '';

  $scope.queries.blacklist = {
  data: [],
  config: {
    title: 'Top trending ignores these phrases',
    
    columns: [
        {value: 'text', header: 'name',  displayValue: trendTextColumn}, 

        {value: 'id', displayValue: row => <BlacklistActions 
          row={row} 
          title={trendTextColumn(row)} 
          restore={()=>$scope.restoreBlacklist(row)}/>,

          header: 'restore', 
          flexValue: '0.5', 
          isSortable: false}
    ],

    sortByColumn: 'text',
    isSortAsc:    false
  }
  
};

  $scope.init = function() {
    $scope.loading=true;

    if(!$scope.showGroupsDone){
        return;//prevent init called twice on 2 fast updates
    }
    $scope.showGroupsDone = false;
    $timeout(function(){
      $scope.resize();
      $scope.getGroups();
      $scope.trendingOnOff($scope.time.play);
    },0); 
  };

  //events
  $scope.$on('update', function() { $scope.init();});
  $scope.$on('geo', function() { $scope.init();});
  $scope.$on('resize', function() { $scope.resize();});       

  $scope.resize = function(maxed, $event){
      if(maxed){
        $scope.maxed = $scope.maxed != maxed ? maxed : null;
      }
      $timeout(function(){

        $scope.size = warVars.getSize();
        var wid = ($scope.size.wid-674)/2;
        var hi = ($scope.size.hi-420)/3;

        var velocityWidth = 110;
        
        
        warVars.width($("#person_trending, #general_trending, #hashtag_trending").find(".quick-table .row .column:first-child"), wid);
        warVars.width($("#person_volume, #general_volume, #hashtag_volume").find(".quick-table .row .column:first-child") ,wid+velocityWidth);
        warVars.height($(".trend_2_halfs .quick-table .tbody"), hi); 

        if($scope.maxed){ //max the left & right cells
          var id = $scope.maxed.substring(0, $scope.maxed.indexOf("_")),
            id1 = "#"+id + "_volume", id2 = "#"+id+"_trending";

          $("quick-table").css('display','none');
          $(id1+" ,"+id2).css('display','block');

          var titleHeight = 33;
          warVars.height($(id1+" .quick-table .tbody , "+id2+" .quick-table .tbody"), (hi+titleHeight)*3); 
        }else{
          $("quick-table").css('display','block');
        }
    });
  };

  $scope.setTimeframe = function(mins) {

    $scope.time.minutes = mins;
    localStorage["warroom_minutes"] = mins;
    $scope.refreshTrending(true);
    $scope.setRefreshTrending();
  };

  $scope.setRefreshTrending = function(){
      clearInterval(refreshTrendingTimer);
      refreshTrendingTimer = setInterval(function(){
          if ($scope.time.play) {
              $scope.refreshTrending();
          }
      }, $scope.time.refreshTrendingFreq);
  };

  $scope.checkRefresh = function(e){
      //press enter or back + empty text

      clearTimeout($scope.searchT);
      if(e.keyCode==13){//enter
         $scope.refreshTrending(true);
      }else{
        $scope.searchT = setTimeout(function(){
            $scope.refreshTrending(true);
        },2000);
      }
  };

  $scope.trendingOnOff = function(play){
      
      $scope.time.play = play;
      $timeout(()=>{
        $scope.$apply();
        if(play){
            $scope.refreshTrending();
            $scope.setRefreshTrending();
        }else{
          $scope.showGroupsDone=true;
        }
      });
  };
    
  $scope.getTrending = function(params) {
      var deferred = $q.defer(), requests = [];

      var topQueryParams = {
          count: params.count,
          start_time: '-' + params.minutes + 'm',
          end_time: 'now'
      };
      if (params.phrase) {
          topQueryParams["keyword"] = encodeURIComponent(params.phrase); 
      }
      if (warVars.geo != 'all') {
          topQueryParams["country"] = warVars.geo;
      }

      $.each(queries, function(i, query) {
          if(query.id != 'blacklist' && query.url) {
              var url = config.REALTIME_API + query.url;
              var params = topQueryParams;
              requests.push($http.get(url, {params: params, id: query.id}));
          }
      });

      $q.all(requests).then(function(responses) {

          _.each(responses, function(response) {

              if ($scope.queries[response.config.id].url) { //process only top query
                  for (var key in response.data) {
                      //response data is {'person_volume':{...}, 'hashtag_volume':{...}...}

                      var isHashtag = key.indexOf("hashtag")!==-1;

                      var res = response.data[key].map(function(cell){
                          cell.volume = cell.volume * 1;
                          cell.isHashtag=isHashtag;
                          cell.table = key;
                          return cell;
                      });
                      $scope.queries[key].show(res);

                  };
              }
          });
          deferred.resolve();
      });
      return deferred.promise;
  };


  $scope.refreshTrending = function(showLoading) {

    if(showLoading){
        $scope.loading=true;
    }

    if($scope.time.play || showLoading) {
      $("ui-view").css("overflow-y" , "hidden");
      $timeout(function(){$scope.$apply();},0);

      clearInterval(refreshTrendingTimer);

      $scope.getTrending({minutes: $scope.time.minutes, count: $scope.count, phrase: $scope.phrase}
          ).then(function(data) {
               
                  $scope.loading=false;
                  $scope.lastUpdate = new Date();
                  $("#last-update").val(moment().format('HH:mm'));
                  
                  $timeout(function(){
                    $scope.resize();
                    $scope.firstLoading=false;
                    $("ui-view").css("overflow-y" , "auto");
                  },0);

                  $scope.setRefreshTrending();
                  $scope.showGroupsDone = true;

          });
      }; 
  }

  $scope.getFormattedDate = function(date) {
      date = date+"";
      return date.substring(date.indexOf(" ")+1,date.lastIndexOf(":"));
  };

  $scope.exportTrending = function() {
      var workbook = Excel.builder.createWorkbook();
      workbook.fileName = 'trends.xlsx';

      var enc = warVars.encodeCSV;
        

      var summary = [
        ['Program', context.program.name],
        ['Geo', JSON.parse(localStorage["warroom_geo"]).label],
        ['Time Zone', "GMT "+(-1*((new Date()).getTimezoneOffset())/60)],
        ['Last Update', $scope.getFormattedDate($scope.lastUpdate)],
        ['Timeframe', $("#timeframe option:selected").text()]
      ];

      var summarySheet = workbook.createWorksheet({name: 'summary'});
      summarySheet.setColumns([{width: 20}, {width: 20}]);
      summarySheet.setData(summary);
      workbook.addWorksheet(summarySheet);
      
      var arr = [];

      arr.push({title: ' ',velocity: '',volume: ''});

      exportTrend($scope.queries['person_trending']);
      exportTrend($scope.queries['person_volume']);
      exportTrend($scope.queries['hashtag_trending']);
      exportTrend($scope.queries['general_trending']);
      exportTrend($scope.queries['hashtag_volume']);
      exportTrend($scope.queries['general_volume']);

      var desc = {
          'Title' : {key: 'title', width: 16},
          'Velocity' : {key: 'velocity', width: 16},
          'Count' : {key: 'volume', width: 16}
      }
    
      function exportTrend(query) {

          var exported = query.title + ' '+query.td_title;
              exported += ','+(query.showVelocity ? 'Velocity' : '')+ ',Count\n';
          
          arr.push({
            title: (query.title + ' '+query.td_title),
            velocity: '',
            volume: ''
          });

          _.each(query.viewArray, function(row) {
              var isHashtag = row.isHashtag || row.text.indexOf("#") != -1;
              var text = isHashtag ? "#"+row.text.replace("#","") : row.text;
              arr.push({
                title: enc(text),
                velocity: (query.showVelocity ? row.velocity + '%' : ' '),
                volume: row.volume
              });
          });

          arr.push({title: ' ',velocity: '',volume: ''});
      };

      Excel.addTableSheet(workbook, arr, desc, {name: 'trends'});

      return workbook;
  };


  $scope.showBlacklist = function() {
      $scope.blacklistOpened = true;

      $http.get(config.REALTIME_API + '/blacklist').success(function(result) {
        $scope.queries.blacklist.data = result;
      });

  };

  $scope.blacklist = function(r) {
      
      //blacklist one item
      $scope.blacklistTrend(warVars.blacklist_name, r.id).then(function() {
          var q = $scope.queries[r.table];
          q.dataArray = _.reject(q.dataArray, {id: r.id});
          q.show(q.dataArray);
      });

      notificator.notify({body: "removed '"+r.text+"' from top trending"});
  };

  $scope.blacklistTrend = function(blacklist, textId) {
      var deferred = $q.defer();
      
      var url = config.REALTIME_API + '/blacklist/' + textId;
      $http.post(url, {}).success(function() {
          deferred.resolve();
      });
      
      return deferred.promise;
  };


  $scope.restoreBlacklist = function(row){
      //restore one blacklisted item
      var id = row.id;

      function restoreBlacklistTrend(blacklist, textId) {
        var deferred = $q.defer();
        
        var url = config.REALTIME_API + '/blacklist/'+ textId;
        $http.delete(url, {}).success(function() {
            deferred.resolve();
        });

        return deferred.promise;
      };

      restoreBlacklistTrend(warVars.blacklist_name, id).then(function() {
          notificator.notify({body: "restored '"+row.text+"' to top trending"});
          $scope.queries.blacklist.data = _.reject($scope.queries.blacklist.data, {id: row.id});
          $scope.refreshTrending();
      });
  };

  $scope.getGroups = function(){
      var selected, opts = $("#trending_groups").empty();
      $http.get(config.USER_MGMT_API + '/user-groups/user:'+$scope.$root.user.id+'|brand:'+context.program.id).
          success(function(groups) {
              $scope.groups = groups;

              $.each(groups, function(i, group){
                  selected = group.id === warVars.selectedGroupId ? 'selected' : '';
                  opts.append($("<option></option>")
                  .attr("value",group.id)
                  .attr("class",selected)
                  .text(group.name)); 
              }); 
          });
  };

  $scope.openGroups = function($event, row){
      $scope.selected.ass = row;
      $scope.groupsOpened = true;
      $("#trending_groups").val('');
  };

  $scope.closeGroups = function(){
      $scope.selected.ass = null;
      $scope.groupsOpened = false;
      $("#trending_groups_dialog").css({'opacity':0, 'z-index':-1});
  };
  

  $scope.addToGroup = function(){
      var id = $("#trending_groups").val();
      if(id){
          $scope.selected.group = _.find($scope.groups, function(g) { return ""+g.id === ""+id; });
          associations.add($scope.selected.ass, $scope, context);
          $scope.closeGroups();
      }

  };

  $scope.twitterLink = function(row){
      return 'https://twitter.com/search?q='+encodeURIComponent(
          (row.isHashtag? '#' : '') + row.text
          );
  };

  $scope.msg = function(e){
    notificator.notify(e);
  }

  $scope.resizeAssosications = function(){}
  
  $scope.init();
}

module.exports = trendingModule;

